import { render } from "./TabContainer.vue?vue&type=template&id=94a23ac8"
import script from "./TabContainer.vue?vue&type=script&lang=js"
export * from "./TabContainer.vue?vue&type=script&lang=js"

import "./TabContainer.vue?vue&type=style&index=0&id=94a23ac8&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "94a23ac8"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('94a23ac8', script)) {
    api.reload('94a23ac8', script)
  }
  
  module.hot.accept("./TabContainer.vue?vue&type=template&id=94a23ac8", () => {
    api.rerender('94a23ac8', render)
  })

}

script.__file = "src/components/TabContainer.vue"

export default script