<template>
    <div id="views">
        <div class="header">
            <el-row>
                <el-col :span="2" class="view-icon-container">
                    <i class="el-icon-view"></i>
                </el-col>
                <el-col :span="16" class="title-container">
                    <span class="title">"{{ message.title }}" - Views</span>
                </el-col>
                <el-col :span="6" class="printer-icon-container">
                    <span @click="handlePrint">
                        <i class="el-icon-printer"></i>
                        <span>Print</span>
                    </span>
                </el-col>
            </el-row>
            <el-row class="number-of-user">
                <el-col :span="18" :offset="2">
                    <span>{{ views.length }} Users viewed</span>
                </el-col>
            </el-row>
        </div>
        <el-table :show-header="false" ref="viewsTable" :data="views" :height="tableHeight" class="views-table">
            <el-table-column prop="icon" width="65" align="center" class-name="icon-col">
                <template #default="scope">
                    <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                        <template #placeholder>
                            <el-image :src="placeholder" fit="contain"></el-image>
                        </template>
                        <template #error>
                            <el-image :src="placeholder" fit="contain"></el-image>
                        </template>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column align="left" min-width="102" show-overflow-tooltip>
                <template #default="scope">
                    <span class="name">{{ scope.row.full_name }}</span>
                    <div>
                        <span class="ward">
                            {{ scope.row.rank }}
                            <span v-if="scope.row.wardList.length > 0">{{ parseWardList(scope.row.wardList) }}</span>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" align="right" class-name="date" min-width="88" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
</template>

<script>
import placeholder from "@/assets/placeholder.png";
import $ from "jquery";
export default {
    name: "Views",
    props: ["views", "message"],
    data() {
        return {
            isLoading: false,
            tableHeight: null,
            placeholder: placeholder,
            numberOfUsers: 0,
            users: [],
            windowInnerWidth: null,
        };
    },
    created() {
        window.addEventListener("resize", this.resize);
    },
    mounted() {
        this.resize();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resize);
    },
    updated() {
        this.$refs.viewsTable.doLayout();
    },
    methods: {
        parseWardList(wardList) {
            let outputString = "";
            for (let i = 0; i < wardList.length; i++) {
                if (i == 0) {
                    outputString += "(";
                }
                outputString += `${wardList[i]}`;
                if (i !== wardList.length - 1) {
                    outputString += ", ";
                } else {
                    outputString += ")";
                }
            }
            return outputString;
        },
        // table
        resize() {
            this.windowInnerWidth = window.innerWidth;
            this.tableHeight = $(".content-row")[0].clientHeight - $(".header")[0].clientHeight - 64;
        },
        handlePrint() {
            let oldframe = document.getElementById("print_frame");
            if (oldframe) {
                oldframe.parentNode.removeChild(oldframe);
            }

            let iframe = document.createElement("iframe");
            iframe.setAttribute("id", "print_frame");
            iframe.setAttribute("name", "print_frame");
            iframe.style = "position: fixed; top: 0; opacity: 0; z-index: -1;";
            document.body.appendChild(iframe);

            // style
            let style = document.createElement("style");
            style.innerHTML =
                "td, th { text-align: left; padding: 8px; }" +
                "td:last-child, th:last-child { text-align: right; }" +
                "table, th, td { border: 1px solid #000; }" +
                "table { width: 100% !important; border-collapse: collapse; }";

            // HTML layout
            // let tableBody = document.querySelector(
            // 	"#views .el-table__body-wrapper tbody"
            // ).innerHTML;
            let tableBody = this.views
                .map(user => {
                    return "<tr><td>" + user.staff_no + "</td><td>" + user.full_name + "</td><td>" + `${user.rank} ${this.parseWardList(user.wardList)}` + "</td><td>" + user.created_at + "</td></tr>";
                })
                .join("");

            let printTitle = this.message.title;
            let printCount = this.views.length;

            let tableTitle = "<h1>Module: Ms Pun's Message</h1>" + `<h2>Title: ${printTitle} - ${printCount} user(s) viewed</h2>`;
            let table = "<table>" + "<tr><th>STAFF NUMBER</th><th>STAFF NAME</th><th>STAFF RANK(DEPT)</th><th>DATE OF VIEW</th></tr>" + tableBody + "</table>";

            let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
            iframedoc.body.innerHTML = tableTitle + table;
            iframedoc.body.appendChild(style);

            window.frames["print_frame"].focus();
            window.frames["print_frame"].print();
        },
    },
};
</script>

<style lang="scss">
#views {
    height: 100%;
    .header {
        margin-bottom: 8px;
        .view-icon-container,
        .printer-icon-container,
        .title-container {
            display: flex;
            align-items: center;
        }
        .view-icon-container {
            justify-content: flex-start;
            .el-icon-view {
                color: #979797;
                font-size: 18px;
            }
        }
        .printer-icon-container {
            text-align: center;
            justify-content: flex-end;
            color: #2684ff;
            cursor: pointer;
            .el-icon-printer {
                margin-right: 8px;
            }
        }
        .number-of-user {
            margin-top: 8px;
            color: #c1c7d0;
            font-size: 13px;
            font-weight: 700;
        }
        .title {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
        }
    }
    .views-table {
        .name {
            color: #171725;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
        .ward {
            font-size: 12px;
            white-space: break-spaces;
        }
        .date {
            color: #92929d;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
        }
        .el-avatar {
            background: transparent;
        }
        .icon-col {
            .cell {
                display: flex;
                align-content: center;
                justify-content: center;
                > .cover-image {
                    display: flex;
                    align-content: center;
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                }
            }
        }
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        ::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }
        ::-webkit-scrollbar-thumb {
            background: #4ac292;
            border: 0 solid #ffffff;
            border-radius: 3px;
        }
        ::-webkit-scrollbar-track {
            background: #e8e8e8;
            border: 0 none #ffffff;
            border-radius: 3px;
        }
        ::-webkit-scrollbar-track:hover {
            background: #e8e8e8;
        }
        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
}
</style>
