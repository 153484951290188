<template>
    <div id="ward-group">
        <TabContainer>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :selection="false" :table="wardGroupTable" />
            </template>
        </TabContainer>
        <Drawer :drawer="addWardGroupDrawer" @update:isVisible="addWardGroupDrawer.isVisible = $event">
            <template v-slot:content>
                <AddWardGroupForm ref="addWardGroupForm" :isLoading="addWardGroupDrawer.isLoading" />
            </template>
        </Drawer>
        <Drawer :drawer="editWardGroupDrawer" @update:isVisible="editWardGroupDrawer.isVisible = $event">
            <template v-slot:content>
                <EditWardGroupForm ref="editWardGroupForm" :ward="editWardGroupDrawer.ward" :isLoading="editWardGroupDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchWardGroups, deleteData as deleteWardGroups } from "@/modules/settings/ward-group";
import AddWardGroupForm from "@/components/settings/AddWardGroupForm";
import EditWardGroupForm from "@/components/settings/EditWardGroupForm";
import TabContainer from "@/components/TabContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import Drawer from "@/components/Drawer";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "WardGroup",
    components: {
        TabContainer,
        ContainerHeader,
        Table,
        Drawer,
        AddWardGroupForm,
        EditWardGroupForm,
    },
    data() {
        return {
            searchInput: {
                placeholder: "Search by Dept Name, Dept Admin",
                value: "",
                callback: () => this.searchWardGroup(),
            },
            // containerDropdownMenu:
            //     getUserInfo().user_level == 3
            //         ? {
            //               name: "Action",
            //               items: [
            //                   {
            //                       name: "Delete",
            //                       icon: "el-icon-delete",
            //                       value: 0,
            //                       style: "color: #de350b;",
            //                   },
            //               ],
            //               callback: command => this.handleAction(command),
            //           }
            //         : {},
            containerButtons:
                getUserInfo().user_level == 3
                    ? [
                          {
                              name: "New Dept",
                              icon: "el-icon-plus",
                              callback: () => this.handleAddWardGroupDrawer(),
                          },
                      ]
                    : [],
            wardGroupTable: {
                name: "Settings",
                isLoading: false,
                cols: [
                    {
                        label: "dept name",
                        sortable: true,
                        prop: "name",
                        minWidth: "128",
                    },
                    {
                        label: "dept admin",
                        sortable: false,
                        prop: "admin",
                        minWidth: "200",
                    },
                    {
                        label: "users",
                        sortable: false,
                        prop: "users",
                        width: "60",
                    },
                ],
                data: [],
                numberOfItems: null,
                handleEdit: (index, row) => this.handleEdit(index, row),
                handleDelete: getUserInfo().user_level == 3 ? (index, row) => this.handleDelete(index, row) : null,
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addWardGroupDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Dept",
                save: () => this.addWardGroup(),
                close: () => this.closeAddWardGroupDrawer(),
            },
            editWardGroupDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Edit Dept",
                ward: {
                    id: null,
                    name: "",
                },
                save: () => this.editWardGroup(),
                close: () => this.closeEditWardGroupDrawer(),
            },

            userLevel: getUserInfo() ? getUserInfo().user_level : null,
        };
    },
    mounted() {
        this.fetchWardGroups();
    },
    methods: {
        async fetchWardGroups() {
            this.wardGroupTable.isLoading = true;
            let pageSize = this.wardGroupTable.pagination.pageSize;
            let pageIndex = this.wardGroupTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchWardGroups(pageSize, pageIndex, searchString);
                if (result) {
                    this.wardGroupTable.data = result.data;
                    this.wardGroupTable.numberOfItems = result.count;
                    this.$emit("update:numberOfWard", result.count);
                }
            } finally {
                this.wardGroupTable.isLoading = false;
            }
        },
        async addWardGroup(form) {
            try {
                this.addWardGroupDrawer.isLoading = true;
                const result = await this.$refs.addWardGroupForm.addWardGroup(form);
                if (result) {
                    this.addWardGroupDrawer.isVisible = false;
                    this.$notify({
                        title: "Ward added successfully",
                        message: "Ward " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchWardGroups();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addWardGroupDrawer.isLoading = false;
            }
        },
        async editWardGroup() {
            try {
                this.editWardGroupDrawer.isLoading = true;
                const result = await this.$refs.editWardGroupForm.editWardGroup();
                if (result) {
                    this.editWardGroupDrawer.isVisible = false;
                    this.$notify({
                        title: "Ward updated successfully",
                        message: "Ward " + result + " was updated successfully",
                        type: "success",
                    });
                    this.fetchWardGroups();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editWardGroupDrawer.isLoading = false;
            }
        },
        async deleteWardGroups(userList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the ward group(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteWardGroups(userList);
                    if (result) {
                        this.$notify({
                            title: "Ward group(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchWardGroups();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // table
        searchWardGroup() {
            this.wardGroupTable.pagination.currentPage = 1;
            this.fetchWardGroups();
        },
        handleDelete(index, row) {
            this.deleteWardGroups([row.ward_id]);
        },
        // pagination
        handleSizeChange(pageSize) {
            this.wardGroupTable.pagination.pageSize = pageSize;
            this.wardGroupTable.pagination.currentPage = 1;
            this.fetchWardGroups();
        },
        handleCurrentChange(currentPage) {
            this.wardGroupTable.pagination.currentPage = currentPage;
            this.fetchWardGroups();
        },

        // drawer
        handleAddWardGroupDrawer() {
            this.addWardGroupDrawer.isVisible = true;
        },
        async handleEdit(index, row) {
            try {
                this.editWardGroupDrawer.isLoading = true;
                this.editWardGroupDrawer.ward.id = row.ward_id;
                this.editWardGroupDrawer.ward.name = row.name;
                this.editWardGroupDrawer.isVisible = true;
            } catch (error) {
                console.log(error);
            } finally {
                this.editWardGroupDrawer.isLoading = false;
            }
        },
        closeAddWardGroupDrawer() {
            this.resetAddWardGroupForm();
            this.addWardGroupDrawer.isVisible = false;
        },
        closeEditWardGroupDrawer() {
            this.resetEditWardGroupForm();
            this.editWardGroupDrawer.isVisible = false;
        },
        resetAddWardGroupForm() {
            this.$refs.addWardGroupForm.resetForm();
        },
        resetEditWardGroupForm() {
            this.$refs.editWardGroupForm.resetForm();
        },
    },
};
</script>
