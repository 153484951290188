import { render } from "./Views.vue?vue&type=template&id=6ea20e4a"
import script from "./Views.vue?vue&type=script&lang=js"
export * from "./Views.vue?vue&type=script&lang=js"

import "./Views.vue?vue&type=style&index=0&id=6ea20e4a&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6ea20e4a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6ea20e4a', script)) {
    api.reload('6ea20e4a', script)
  }
  
  module.hot.accept("./Views.vue?vue&type=template&id=6ea20e4a", () => {
    api.rerender('6ea20e4a', render)
  })

}

script.__file = "src/components/no-message/Views.vue"

export default script