<template>
    <el-table
        class="no-message-table"
        ref="noMessageTable"
        :size="windowInnerWidth > 1024 ? 'medium' : 'mini'"
        :data="table.data"
        :style="table.style"
        @selection-change="rows => table.handleSelectionChange(rows)"
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
        :height="table.height || tableHeight"
        :key="table.height || tableHeight"
        row-key="id"
        v-loading="table.isLoading"
        @row-click="(row, column, e) => table.handleRowClick(row, column, e)"
        :cell-style="{ padding: windowInnerWidth > 1024 ? '10px 0' : '0' }"
    >
        <el-table-column type="selection" width="45" :selectable="handleSelectable"></el-table-column>
        <el-table-column label="MESSAGE TITLE" prop="title" sortable min-width="176" label-class-name="default-label" align="left">
            <template #default="scope">
                <span :class="[!scope.row.editable ? 'disabled' : 'link-type']">
                    {{ scope.row.title }}
                </span>
            </template>
        </el-table-column>
        <el-table-column label="PUBLISH DATE" prop="published_date" sortable min-width="132" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column label="UPDATED AT" prop="updated_at" sortable min-width="148" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column label="TARGET DEPT" prop="ward" sortable min-width="132" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column label="AUTHOR" prop="author" min-width="152" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column label="VIEWS" prop="number_of_views" class-name="user-view" label-class-name="default-label" width="152" align="left" show-overflow-tooltip>
            <template #default="scope">
                <i class="el-icon-view"></i>
                <span>{{ scope.row.number_of_views }} User(s) viewed</span>
            </template>
        </el-table-column>
        <el-table-column v-if="table.handleDelete" label="ACTION" width="88" align="left">
            <template #default="scope">
                <div class="action-button-group">
                    <el-button v-if="table.handlePin && !scope.row.pin_date" size="small" type="text" @click="(index, row) => table.handlePin(scope.$index, scope.row)" :disabled="!scope.row.editable">
                        <i class="pin-icon"></i>
                    </el-button>
                    <el-button
                        v-if="table.handleUnpin && scope.row.pin_date"
                        size="small"
                        type="text"
                        @click="(index, row) => table.handleUnpin(scope.$index, scope.row)"
                        :disabled="!scope.row.editable"
                    >
                        <i class="pin-off-icon"></i>
                    </el-button>
                    <el-button size="small" type="text" @click="(index, row) => table.handleDelete(scope.$index, scope.row)" :disabled="!scope.row.editable">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <el-row class="no-message-table-footer" v-show="!table.loading">
        <el-col :span="6">
            <span class="pagination-index">{{ startItemIndex }}-{{ endItemIndex }} of {{ table.numberOfItems }} items</span>
        </el-col>
        <el-col :span="18" class="pagination-container">
            <el-pagination
                class="pagination"
                :currentPage="table.pagination.currentPage"
                :page-size="pageSize"
                :page-sizes="pageSizes"
                :layout="pageSizes.length == 1 ? 'prev, pager, next' : 'prev, pager, next, sizes'"
                :total="Number(table.numberOfItems)"
                @size-change="pageSize => table.pagination.handleSizeChange(pageSize)"
                @current-change="currentPage => table.pagination.handleCurrentChange(currentPage)"
            ></el-pagination>
        </el-col>
    </el-row>
</template>

<script>
import $ from "jquery";

export default {
    name: "Table",
    props: {
        table: {
            required: true,
            type: Object,
        },
    },
    data(props) {
        return {
            // table
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            headerCellStyle: {
                background: "#fafafb",
                border: "0px",
            },
            tableHeight: 500,
            windowInnerWidth: null,

            // pagination
            pageSize: props.table.pagination.pageSize,
            pageSizes: [10, 20, 30],
        };
    },
    computed: {
        startItemIndex: function() {
            return 1 + this.table.pagination.pageSize * (this.table.pagination.currentPage - 1);
        },
        endItemIndex: function() {
            return this.table.pagination.pageSize * this.table.pagination.currentPage;
        },
    },
    created() {
        window.addEventListener("resize", this.resize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resize);
    },
    mounted() {
        this.resize();
    },
    methods: {
        // table
        resize() {
            this.windowInnerWidth = window.innerWidth;
            if ($(".page-content")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 88 : 62;
                this.tableHeight = $(".page-content")[0].clientHeight - $(".container-header")[0].clientHeight - constant;
            }
        },
        handleSelectable(row, index) {
            return row.editable;
        },
    },
};
</script>

<style lang="scss">
.no-message-table {
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: 8px;
    .cell-class {
        padding: 0;
    }
    .el-table__header-wrapper {
        &::-webkit-scrollbar {
            background: transparent;
            width: 7px;
        }
        &::-webkit-scrollbar-track {
            background: #fafafb;
            border: 0;
        }
        overflow-y: scroll;
        overflow-x: hidden;
        th:not(.filterable) .el-table__column-filter-trigger {
            display: none;
        }
    }
    .el-table__header,
    .el-table__body {
        width: 100%;
    }
    .el-table__body {
        padding-bottom: 10px;
    }
    .default-label {
        cursor: inherit;
        color: #44444f;
        font-size: 11px;
    }
    .link-type {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: #36b37e;
        }
    }
    .el-button {
        &.is-disabled {
            opacity: 0.25;
        }
    }
    td {
        &:first-child {
            border: 1px solid transparent;
            border-right: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:nth-child(n) {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }
        &:last-child {
            border: 1px solid transparent;
            border-left: 0;
        }
    }
    &.el-table--enable-row-hover .el-table__body tr:hover {
        td {
            background-color: #fff;
            &:first-child {
                border: 1px solid #3dd598;
                border-right: 0;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:nth-child(n) {
                border-top: 1px solid #3dd598;
                border-bottom: 1px solid #3dd598;
            }
            &:last-child {
                border: 1px solid #3dd598;
                border-left: 0;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
    @media (min-width: 1025px) {
        .hidden-bigger-than-1024 {
            display: none !important;
        }
    }
    @media only screen and (max-width: 1024px) {
        .hidden-smaller-than-1024 {
            display: none !important;
        }
    }
    .action-button-group {
        display: flex;
    }
    .user-view {
        font-size: 12px;
        color: #2684ff;

        .cell {
            cursor: pointer;
            display: flex;
            align-items: center;
            .el-icon-view {
                font-size: 18px;
                margin-right: 8px;
            }
        }
        .default-label {
            cursor: inherit;
        }
    }
    .el-icon-delete {
        font-size: 18px;
        color: #ff7452;
    }
    .el-icon-edit {
        font-size: 18px;
        color: #2684ff;
    }
    .pin-icon {
        &:before {
            content: "";
            display: inline-block;
            height: 18px;
            width: 18px;
            background-image: url("./../../assets/pin.svg");
            background-size: contain;
        }
    }
    .pin-off-icon {
        &:before {
            content: "";
            display: inline-block;
            height: 18px;
            width: 18px;
            background-image: url("./../../assets/pin-off.svg");
            background-size: contain;
        }
    }
}
.no-message-table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    width: 100%;
    margin-top: 8px;
    .pagination-index {
        font-weight: normal;
        color: #44444f;
        font-size: 13px;
    }
    .pagination-container {
        display: flex;
        justify-content: flex-end;
    }
    .pagination {
        display: flex;
        align-items: center;
        padding: 2px 0;
        li {
            font-weight: normal;
            color: #92929d;

            &.active {
                color: #36b37e;
            }
        }
        .btn-next,
        .btn-prev {
            color: #92929d;
            height: 32px;
            width: 32px;
            border: 1px solid #e2e2ea;
            padding: 0;
            border-radius: 5px;
        }
        .el-input__inner {
            height: 32px;
            color: #92929d;
        }
        .el-pagination__sizes {
            height: auto;
            margin: 0;
        }
    }
}
</style>
