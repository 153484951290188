import axios from "axios";

export const fetchTable = async (wardId, year) => {
    try {
        const result = await axios.get(`/app/request/annual-leave?wardId=${wardId}&year=${year}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postLeave = async (scheduleId, annualWeekIdList) => {
    try {
        const params = {
            annualWeekIdList: annualWeekIdList,
        };
        const result = await axios.put(`/app/request/annual-leave/${scheduleId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const shiftUser = async (scheduleId, userIdList) => {
    try {
        const params = {
            list: userIdList,
        };
        const result = await axios.put(`/app/request/annual-leave/shift/${scheduleId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchYearSettings = async scheduleId => {
    try {
        const result = await axios.get(`/app/request/annual-leave/settings/year/${scheduleId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putYearSettings = async (scheduleId, form) => {
    try {
        const params = {};
        const result = await axios.put(`/app/request/annual-leave/settings/year/${scheduleId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchWeekSettings = async scheduleId => {
    try {
        const result = await axios.get(`/app/request/annual-leave/settings/week/${scheduleId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putWeekSettings = async week => {
    try {
        const params = {
            tag: week.tag,
            limit: week.vacancy,
        };
        const result = await axios.put(`/app/request/annual-leave/settings/week/${week.id}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchUserSettings = async weekId => {
    try {
        const result = await axios.get(`/app/request/annual-leave/settings/week/block/${weekId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putAllVacancy = async (scheduleId, vacancy) => {
    try {
        const params = {
            limit: vacancy,
        };
        const result = await axios.put(`/app/request/annual-leave/settings/week/all/${scheduleId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchRanking = async scheduleId => {
    try {
        const result = await axios.get(`/app/request/annual-leave/rank/${scheduleId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchOptions = async () => {
    try {
        return [
            { id: 1, name: "", value: "" },
            { id: 2, name: "AL", value: "AL" },
        ];
    } catch (error) {
        console.error(error);
    }
};

export const addNewSchedule = async wardId => {
    try {
        const result = await axios.post(`/app/request/annual-leave?wardId=${wardId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchModalMessage = async wardId => {
    try {
        const result = await axios.get(`/app/request/annual-leave/modal-message/${wardId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

// modal settings
// export const fetchModal = async (scheduleId) => {
// 	try {
// 		const result = await axios.get(
// 			`/app/request/annual-leave/setting/${scheduleId}`
// 		);
// 		return result;
// 	} catch (error) {
// 		console.error(error);
// 	}
// };

// export const postModal = async (scheduleId, msg) => {
// 	try {
// 		const params = {
// 			modalMessage: msg,
// 		};
// 		const result = await axios.put(
// 			`/app/request/annual-leave/setting/${scheduleId}`,
// 			params
// 		);
// 		return result;
// 	} catch (error) {
// 		console.error(error);
// 	}
// };
