<template>
    <div id="action-log">
        <ViewContainer :title="`User Activities Log`" :isLoading="isLoading">
            <template v-slot:content>
                <el-space>
                    <el-date-picker v-model="startDateTime" size="small" type="datetime" placeholder="Select start date and time" :disabledDate="isDisabledStartDate"></el-date-picker>
                    <el-date-picker v-model="endDateTime" size="small" type="datetime" placeholder="Select end date and time" :disabledDate="isDisabledEndDate"></el-date-picker>
                    <el-button icon="el-icon-download" size="small" type="primary" @click="exportExcel" :loading="isLoading" :disabled="isLoading || !startDateTime || !endDateTime">
                        Export
                    </el-button>
                </el-space>
                <div class="description">
                    <span>Data Start Date: {{ displayLaunchDate }}</span>
                </div>
            </template>
        </ViewContainer>
    </div>
</template>

<script>
import ViewContainer from "@/components/ViewContainer";
import moment from "moment";
import XLSX from "xlsx";

import { fetchData } from "../modules/action-log";

export default {
    name: "ActionLog",
    components: {
        ViewContainer,
    },
    data() {
        return {
            isLoading: false,

            defaultTime: new Date(), // '12:00:00', '08:00:00'
            launchDate: new Date(2021, 6, 9), // 2021-07-09
            startDateTime: null,
            endDateTime: null,
            headers: [],
            rows: [],
            moduleType: 19, // survival working tips
        };
    },
    computed: {
        displayLaunchDate() {
            return moment(this.launchDate).format("YYYY-MM-DD");
        },
    },
    methods: {
        async fetchActionLog() {
            if (this.startDateTime && this.endDateTime) {
                const startDate = moment(this.startDateTime).format("YYYY-MM-DD HH:mm:ss");
                const endDate = moment(this.endDateTime).format("YYYY-MM-DD HH:mm:ss");
                const result = await fetchData(this.moduleType, startDate, endDate);
                if (result && result.length > 0) {
                    this.headers = Object.keys(result[0]);
                    this.rows = result;
                }
            }
        },
        async exportExcel() {
            try {
                this.isLoading = true;
                await this.fetchActionLog();
                if (this.headers.length > 0 && this.rows.length > 0) {
                    let wb = XLSX.utils.book_new();

                    let ws = XLSX.utils.json_to_sheet(this.rows, {
                        header: this.headers,
                        wrap: true,
                    });
                    const startDate = moment(this.startDateTime).format("YYYYMMDDHHmmss");
                    const endDate = moment(this.endDateTime).format("YYYYMMDDHHmmss");
                    XLSX.utils.book_append_sheet(wb, ws, "User Activity Log");
                    XLSX.writeFile(wb, `user_activity_log_${startDate}_${endDate}.csv`);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.headers = [];
                this.rows = [];
                this.isLoading = false;
            }
        },
        isDisabledStartDate(date) {
            if (date > new Date()) {
                return true;
            } else if (this.endDateTime) {
                if (date > this.endDateTime) {
                    return true;
                } else if (moment(this.endDateTime).diff(date, "days") >= 7) {
                    return true;
                } else {
                    return false;
                }
            } else if (date < this.launchDate) {
                return true;
            } else {
                return false;
            }
        },
        isDisabledEndDate(date) {
            if (date > new Date()) {
                return true;
            } else if (this.startDateTime) {
                if (date < this.startDateTime) {
                    return true;
                } else if (moment(date).diff(this.startDateTime, "days") >= 7) {
                    return true;
                } else {
                    return false;
                }
            } else if (date < this.launchDate) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss">
#action-log {
    height: 100%;
    .description {
        margin-top: 8px;
    }
}
</style>
