import axios from "axios";

export const fetchData = async () => {
    try {
        const result = await axios.get(`/admin/settings/policy`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDetails = async policyId => {
    try {
        const result = await axios.get(`/admin/settings/policy/${policyId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async policy => {
    try {
        const formData = new FormData();
        formData.append("content", policy.content);
        if (policy.coverImage) {
            formData.append("coverImage", policy.coverImage);
        }
        const result = await axios.put(`/admin/settings/policy/${policy.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};
