// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../assets/filter.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./../assets/filter-active.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".container-header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  height: auto;\n  align-items: flex-start;\n  flex-wrap: wrap;\n}\n.container-header .container-details {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n.container-header .container-details .search-input {\n  width: 304px;\n}\n.container-header .container-details .filter-btn {\n  margin-left: 8px;\n  padding: 8px 15px;\n}\n.container-header .container-details .table-actions {\n  margin-left: 24px;\n}\n.container-header .container-details .table-actions .el-dropdown-link {\n  cursor: pointer;\n  color: #44444f;\n}\n.container-header .container-details .table-actions .el-icon-caret-bottom {\n  font-size: 12px;\n}\n.container-header .container-btn-grp {\n  justify-content: flex-end;\n}\n.container-header .filter-icon:before {\n  content: \"\";\n  display: inline-block;\n  height: 12px;\n  width: 12px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n}\n.container-header .filter-active-icon:before {\n  content: \"\";\n  display: inline-block;\n  height: 12px;\n  width: 12px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: contain;\n}\n.filter-popover.el-popper {\n  padding: 16px;\n}\n.filter-popover .el-popover__title {\n  font-weight: 600;\n}\n.filter-popover .filter-content {\n  min-height: 80px;\n  padding: 8px 0;\n}\n.filter-popover .filter-footer {\n  display: flex;\n  justify-content: flex-end;\n}\n.filter-popover .filter-footer .cancel-btn {\n  margin-left: 8px;\n  background-color: #ebecf0;\n  color: #b3bac5;\n  border: 0;\n}", ""]);
// Exports
module.exports = exports;
