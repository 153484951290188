import { render } from "./AddSurvivalWorkingTipsForm.vue?vue&type=template&id=2e88e7aa"
import script from "./AddSurvivalWorkingTipsForm.vue?vue&type=script&lang=js"
export * from "./AddSurvivalWorkingTipsForm.vue?vue&type=script&lang=js"

import "./AddSurvivalWorkingTipsForm.vue?vue&type=style&index=0&id=2e88e7aa&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2e88e7aa"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2e88e7aa', script)) {
    api.reload('2e88e7aa', script)
  }
  
  module.hot.accept("./AddSurvivalWorkingTipsForm.vue?vue&type=template&id=2e88e7aa", () => {
    api.rerender('2e88e7aa', render)
  })

}

script.__file = "src/components/survival-working-tips/AddSurvivalWorkingTipsForm.vue"

export default script