<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format with a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
        </span>
    </div>
    <el-form class="category-form" ref="addCategoryForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Title" prop="title">
                    <el-input class="word-limit-input" v-model="form.title" placeholder="Enter a title" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Cover Image:" required>
                    <el-upload class="upload-cover-image" ref="uploadCoverImage" accept="image/*" :auto-upload="false" :limit="1" :on-change="handleChange" action="#">
                        <template #default v-if="isFileEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="bottom" effect="light">
                                <template #content>
                                    <img :src="imagePreview" class="cover-image-preview" />
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>
                            {{ form.coverImageError }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item v-if="type == 'survival-working-tips'" label="General:" required>
                    <el-radio-group v-model="form.isGeneral">
                        <el-radio :label="true">Yes</el-radio>
                        <el-radio :label="false">No</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-else label="Show:" required>
                    <el-radio-group class="category-type" v-model="form.isShow">
                        <el-radio :label="true">Yes</el-radio>
                        <el-radio :label="false">No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32" v-if="!form.isGeneral">
            <el-col :span="12">
                <el-form-item label="Target dept:" prop="ward" ref="ward">
                    <el-select v-model="form.ward.value" filterable placeholder="Select dept" multiple :disabled="type != 'survival-working-tips'">
                        <el-option v-for="item in form.ward.list" :key="item.name" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { postData as addCategory } from "@/modules/category";
import { fetchData as fetchWard } from "@/modules/general/ward";
import { validateWards } from "@/utils/validator";
export default {
    name: "AddCategoryForm",
    props: ["isLoading", "type", "wardId"],
    data() {
        return {
            imagePreview: "",
            form: {
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Please enter a title",
                            trigger: "blur",
                        },
                    ],
                    ward: [
                        {
                            required: true,
                            validator: validateWards,
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                title: "",
                coverImage: {},
                coverImageError: "",
                isGeneral: false,
                isShow: false,
                ward: {
                    isLoading: false,
                    value: [],
                    list: [],
                    error: "",
                },
            },
        };
    },
    computed: {
        isFileEmpty() {
            return Object.keys(this.form.coverImage).length == 0;
        },
    },
    async mounted() {
        await this.fetchWard();
        if (this.type != "survival-working-tips") {
            this.form.ward.value = [this.wardId];
        }
    },
    methods: {
        async fetchWard() {
            this.form.ward.isLoading = true;
            const data = await fetchWard();
            if (data) {
                this.form.ward.list = data.map(item => {
                    return {
                        label: item.name,
                        value: item.id,
                    };
                });
            }
            this.form.ward.isLoading = false;
        },
        async addCategory() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                const category = {
                    title: this.form.title,
                    coverImage: this.form.coverImage.raw,
                    general: this.form.isGeneral,
                    show: this.form.isShow,
                    wardList: this.form.isGeneral ? null : this.form.ward.value,
                };
                const result = await addCategory(this.type, category);
                if (result) {
                    return category.title;
                } else {
                    return false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async validateForm() {
            try {
                const imageResult = this.validateImage(this.form.coverImage);
                const result = await this.$refs.addCategoryForm.validate();
                return result && imageResult;
            } catch (error) {
                return false;
            }
        },
        // handle cover image
        handleChange(image) {
            if (this.validateImage(image)) {
                this.form.coverImage = image;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(image.raw);
            } else {
                this.$refs.uploadCoverImage.clearFiles();
            }
        },
        handleRemove() {
            this.$refs.uploadCoverImage.clearFiles();
            this.form.coverImage = {};
            this.validateImage(this.form.coverImage);
        },
        validateImage(image) {
            if (Object.keys(image).length == 0) {
                this.form.coverImageError = "Please upload a cover image";
                return false;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;

            if (!isImage) {
                this.form.coverImageError = "Image can only be png/jpeg/jpg format";
                return false;
            }
            if (!isLt10M) {
                this.form.coverImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.coverImageError = "";
            return true;
        },
        resetForm() {
            this.$refs.addCategoryForm.resetFields();
            this.$refs.uploadCoverImage.clearFiles();
            this.form.ward.value = [];
            this.form.coverImage = {};
            this.form.coverImageError = "";
        },
    },
};
</script>

<style lang="scss">
.category-type {
    display: flex;
    align-items: center;
    height: 32px;
}
</style>
