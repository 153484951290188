<template>
    <el-dialog custom-class="settings-dialog" :title="ward.label ? dialog.title + ' (' + ward.label + ')' : dialog.title" v-model="isVisible" :before-close="resetForm" width="640px" destroy-on-close>
        <div class="description">
            <i class="el-icon-warning-outline el-icon-left"></i>
            <span class="contact">
                This is the setting of duty roster on
                {{ week.label }}.
            </span>
        </div>
        <el-form class="upload-form" ref="uploadForm" :model="form" label-position="top" v-loading="isLoading" :rules="rules">
            <el-row :gutter="32">
                <el-col :span="12">
                    <el-form-item label="Cut-off date:" prop="cutOffDate">
                        <el-date-picker type="date" placeholder="Pick a date" v-model="form.cutOffDate" style="width: 100%" :disabled-date="disabledDate" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Show/Hide roster:" required>
                        <el-radio-group v-model="form.isShowRoster">
                            <el-radio :label="1">Show</el-radio>
                            <el-radio :label="0">Hide</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="submitUpload('uploadForm')" :disabled="isLoading">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="resetForm" :disabled="isLoading">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "@/components/useInput";
export default {
    name: "SettingsDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
        week: {
            type: Object,
            default: () => {},
            required: true,
        },
        ward: {
            type: Object,
            default: () => {},
            required: true,
        },
        rosterId: {
            type: Number,
            default: null,
            required: true,
        },
    },
    data(props) {
        return {
            isLoading: false,
            rosterStartDate: props.dialog.settings.week_start_date,
            form: {
                isShowRoster: props.dialog.settings.show,
                cutOffDate: props.dialog.settings.cut_off_date,
            },
            rules: {
                cutOffDate: [
                    {
                        type: "date",
                        required: true,
                        message: "Please pick a date",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    methods: {
        async submitUpload(formName) {
            this.isLoading = true;

            this.$refs[formName].validate(async valid => {
                if (valid) {
                    try {
                        await this.dialog.upload(this.rosterId, this.form);
                    } finally {
                        this.isLoading = false;
                        this.resetForm();
                    }
                } else {
                    this.isLoading = false;
                    return false;
                }
            });
        },
        disabledDate(date) {
            return date > new Date(this.rosterStartDate);
        },
        resetForm() {
            this.isVisible = false;
            this.$refs.uploadForm.resetFields();
        },
    },
};
</script>

<style lang="scss">
.settings-dialog {
    max-width: 640px;
    border-radius: 5px;
    @media only screen and (max-width: 1024px) {
        margin-top: 5vh !important;
    }
    .el-dialog__header {
        font-weight: bold;
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
    }
    .el-dialog__body {
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
        .el-form-item {
            width: 100%;
        }
    }
    .el-dialog__footer {
        .save-btn,
        .cancel-btn {
            width: 120px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 32px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
        padding: 25px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        text-align: left;
    }
}
</style>
