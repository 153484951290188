import { render } from "./AnnualLeaveDot.vue?vue&type=template&id=97ad8b52"
import script from "./AnnualLeaveDot.vue?vue&type=script&lang=js"
export * from "./AnnualLeaveDot.vue?vue&type=script&lang=js"

import "./AnnualLeaveDot.vue?vue&type=style&index=0&id=97ad8b52&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "97ad8b52"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('97ad8b52', script)) {
    api.reload('97ad8b52', script)
  }
  
  module.hot.accept("./AnnualLeaveDot.vue?vue&type=template&id=97ad8b52", () => {
    api.rerender('97ad8b52', render)
  })

}

script.__file = "src/components/annual-leave/AnnualLeaveDot.vue"

export default script