import { createRouter, createWebHashHistory } from "vue-router";
import Login from "@/views/Login";
import Wrapper from "@/components/Wrapper";
import CanteenMenu from "@/views/CanteenMenu";
import ShuttleBus from "@/views/ShuttleBus";
import SurvivalWorkingTips from "@/views/SurvivalWorkingTips";
import TelDirectory from "@/views/TelDirectory";
import Training from "@/views/Training";
import Compliment from "@/views/Compliment";
import Knowledge from "@/views/Knowledge";

// Roster
import DutyRoster from "@/views/Roster/DutyRoster";
import NODutyRoster from "@/views/Roster/NODutyRoster";
import RequestDutyRoster from "@/views/Roster/RequestDutyRoster";
import RequestNODutyRoster from "@/views/Roster/RequestNODutyRoster";

// Annual Leave
import AnnualLeaveSchedule from "@/views/AnnualLeave/AnnualLeaveSchedule";
import NOAnnualLeaveSchedule from "@/views/AnnualLeave/NOAnnualLeaveSchedule";
import RequestAnnualLeave from "@/views/AnnualLeave/RequestAnnualLeave";
import RequestNOAnnualLeave from "@/views/AnnualLeave/RequestNOAnnualLeave";

import NOMessage from "@/views/NOMessage";
import NOMessageDetailsWrapper from "@/components/no-message/NOMessageDetailsWrapper";
import Recreation from "@/views/Recreation";
import RecreationDetailsWrapper from "@/components/recreation/RecreationDetailsWrapper";

// Settings
import Settings from "@/views/Settings";

import MsPunsMessage from "@/views/MsPunsMessage";
import MsPunsMessageDetailsWrapper from "@/components/ms-puns-message/MsPunsMessageDetailsWrapper";

import ActionLog from "@/views/ActionLog";

import PageNotFound from "@/views/PageNotFound";

import Category from "@/views/Category";

// Mobile src/views/Mobile/RequestDutyRoster
import RequestDutyRosterMobile from "@/views/Mobile/RequestDutyRoster";
import RequestAnnualLeaveMobile from "@/views/Mobile/RequestAnnualLeave";

import { getUserInfo } from "@/utils/auth";

import { ElNotification } from "element-plus";

const routes = [
    {
        path: "",
        beforeEnter: function(to, from, next) {
            next("/canteen-menu");
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            checkAuth: true,
        },
    },
    {
        path: "",
        component: Wrapper,
        children: [
            {
                path: "/canteen-menu",
                name: "CanteenMenu",
                component: CanteenMenu,
            },
            {
                path: "/shuttle-bus",
                name: "ShuttleBus",
                component: ShuttleBus,
            },
            {
                path: "/duty-roster",
                name: "DutyRoster",
                component: DutyRoster,
            },
            {
                path: "/no-duty-roster",
                name: "NODutyRoster",
                component: NODutyRoster,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/request-duty-roster",
                name: "RequestDutyRoster",
                component: RequestDutyRoster,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/request-no-duty-roster",
                name: "RequestNODutyRoster",
                component: RequestNODutyRoster,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/survival-working-tips",
                name: "SurvivalWorkingTips",
                component: SurvivalWorkingTips,
            },
            {
                path: "/survival-working-tips/category",
                // name: "Category",
                component: Category,
                props: { previousPage: "survival-working-tips" },
            },
            {
                path: "/tel-directory",
                name: "TelDirectory",
                component: TelDirectory,
            },
            {
                path: "/training",
                name: "Training",
                component: Training,
            },
            {
                path: "/training/category",
                // name: "Category",
                component: Category,
                props: { previousPage: "training" },
            },
            {
                path: "/compliment",
                name: "Compliment",
                component: Compliment,
            },
            {
                path: "/knowledge",
                name: "Knowledge",
                component: Knowledge,
            },
            {
                path: "/knowledge/category",
                // name: "Category",
                component: Category,
                props: { previousPage: "knowledge" },
            },
            {
                path: "/annual-leave-schedule",
                name: "AnnualLeaveSchedule",
                component: AnnualLeaveSchedule,
            },
            {
                path: "/no-annual-leave-schedule",
                name: "NOAnnualLeaveSchedule",
                component: NOAnnualLeaveSchedule,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/request-annual-leave",
                name: "RequestAnnualLeave",
                component: RequestAnnualLeave,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/request-no-annual-leave",
                name: "RequestNOAnnualLeave",
                component: RequestNOAnnualLeave,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/no-message",
                name: "NOMessage",
                component: NOMessage,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/no-message/:id",
                name: "NOMessageDetailsWrapper",
                component: NOMessageDetailsWrapper,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/recreation",
                name: "Recreation",
                component: Recreation,
            },
            {
                path: "/recreation/:id",
                name: "RecreationDetailsWrapper",
                component: RecreationDetailsWrapper,
            },
            {
                path: "/settings/dept",
                component: Settings,
            },
            {
                path: "/settings/users",
                component: Settings,
            },
            {
                path: "/settings/ranks",
                component: Settings,
            },
            {
                path: "/settings/policies",
                component: Settings,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/ms-puns-message",
                name: "MsPunsMessage",
                component: MsPunsMessage,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/ms-puns-message/:id",
                name: "MsPunsMessageDetailsWrapper",
                component: MsPunsMessageDetailsWrapper,
                meta: {
                    checkPermission: true,
                },
            },
            {
                path: "/log-of-survival-working-tips",
                name: "ActionLog",
                component: ActionLog,
                meta: {
                    checkPermission: false,
                }
            },
            {
                path: "/:pathMatch(.*)",
                name: "PageNotFound",
                component: PageNotFound,
            },
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/app/request-duty-roster",
        name: "RequestDutyRosterMobile",
        component: RequestDutyRosterMobile,
        meta: {
            mobile: true,
        },
    },
    {
        path: "/app/request-annual-leave",
        name: "RequestAnnualLeaveMobile",
        component: RequestAnnualLeaveMobile,
        meta: {
            mobile: true,
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.checkPermission)) {
        const userLevel = getUserInfo().user_level;
        const isMsPun = getUserInfo().user_level == 4 ? true : false;
        const isManageNO = getUserInfo().is_no_admin == 1 ? true : false;
        const isNORoster = getUserInfo().is_no_roster == 1 ? true : false;
        if (isMsPun) {
            if (
                to.path == "/ms-puns-message" ||
                to.name == "MsPunsMessageDetailsWrapper" ||
                to.path == "/no-duty-roster" ||
                to.path == "/no-annual-leave-schedule" ||
                to.path == "/settings/policies"
            ) {
                next();
            } else {
                next("/page-not-found");
            }
        } else if (userLevel == 3) {
            if (
                to.path == "/no-duty-roster" ||
                to.path == "/request-duty-roster" ||
                to.path == "/request-no-duty-roster" ||
                to.path == "/request-annual-leave" ||
                to.path == "/request-no-annual-leave" ||
                to.path == "/no-annual-leave-schedule" ||
                to.path == "/no-message" ||
                to.name == "NOMessageDetailsWrapper" ||
                to.path == "/settings/policies"
            ) {
                next();
            } else {
                next("/page-not-found");
            }
        } else if (userLevel == 2) {
            if (isManageNO) {
                if (
                    to.path == "/no-duty-roster" ||
                    to.path == "/request-duty-roster" ||
                    to.path == "/request-no-duty-roster" ||
                    to.path == "/request-annual-leave" ||
                    to.path == "/request-no-annual-leave" ||
                    to.path == "/no-annual-leave-schedule" ||
                    to.path == "/no-message" ||
                    to.name == "NOMessageDetailsWrapper"
                ) {
                    next();
                } else {
                    next("/page-not-found");
                }
            } else if (isNORoster) {
                if (to.path == "/no-message" || to.path == "/request-duty-roster" || to.path == "/request-annual-leave" || to.path == "/no-message" || to.name == "NOMessageDetailsWrapper") {
                    next();
                } else {
                    next("/page-not-found");
                }
            } else {
                if (
                    to.path == "/request-duty-roster" ||
                    to.path == "/request-annual-leave"
                    // to.path == "/no-message" ||
                    // to.name == "NOMessageDetailsWrapper"
                ) {
                    next();
                } else {
                    next("/page-not-found");
                }
            }
        }
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem("jwt")) {
            next();
            return;
        } else {
            ElNotification({
                title: "Authentication Error",
                message: "Please login your account.",
                type: "error",
            });
            next("/login");
        }
    } else if (to.matched.some(record => record.meta.mobile)) {
        if (to.query.token) {
            localStorage.setItem("jwt", to.query.token);
            next();
        } else {
            return;
        }
    } else if (to.matched.some(record => record.meta.checkAuth)) {
        if (localStorage.getItem("jwt")) {
            next("/canteen-menu");
            return;
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
