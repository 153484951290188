import axios from "axios";

export const fetchData = async (moduleType, startDate, endDate) => {
    try {
        const params = {
            actionCategoryId: moduleType,
            startDate: startDate,
            endDate: endDate,
        };
        const result = await axios.get(`/admin/action-log/export`, { params: params });
        return result;
    } catch (error) {
        console.error(error);
    }
};
