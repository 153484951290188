<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may click on the week for further week setting (including block/unblock user or hot season setting).
        </span>
    </div>
    <el-form class="year-settings-form" ref="yearSettingsForm" :model="form" label-position="top" size="small" v-loading="form.isLoading">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Cut-off date:">
                    <el-date-picker type="date" placeholder="Pick a date" v-model="form.cutOffDate" style="width: 100%" :disabled-date="disabledDate" @change="handleCutOffDate" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Show/Hide schedule:">
                    <el-radio-group class="show-hide-schedule" v-model="form.isShowSchedule" @change="handleShowSchedule">
                        <el-radio :label="true">Show</el-radio>
                        <el-radio :label="false">Hide</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <el-table
        class="year-table"
        ref="yearTable"
        :size="windowInnerWidth > 1024 ? 'medium' : 'mini'"
        :data="yearTable.data"
        :header-row-style="headerRowStyle"
        :cell-class-name="windowInnerWidth > 1024 ? '' : 'cell-class'"
        :height="400"
        v-loading="isLoading"
    >
        <el-table-column prop="week" label="WEEK" align="left" width="120">
            <template #default="scope">
                <span>
                    {{ scope.row.week_start_date }} -
                    {{ scope.row.week_end_date }}
                </span>
            </template>
        </el-table-column>
        <el-table-column label="HOT SEASON" align="left" min-width="150">
            <template #default="scope">
                <template v-if="scope.row.edit">
                    <el-input v-model="scope.row.tag" size="mini" placeholder="Please enter hot season name" maxlength="50" show-word-limit />
                </template>
                <span v-else>{{ scope.row.tag }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="vacancy" label="VACANCY" align="left" min-width="104">
            <template #header>
                <span>VACANCY&nbsp;&nbsp;</span>
                <el-popover :width="200" placement="bottom" v-model:visible="vacancy.visible" popper-class="vacancy-popover" @after-leave="vacancy.value = 0">
                    <div class="form">
                        <span>Vacancy:</span>
                        <el-input-number v-model="vacancy.value" size="mini" :min="0"></el-input-number>
                    </div>
                    <div class="vacancy-button-group">
                        <el-button type="primary" size="mini" class="save-btn" @click="handleOnSaveVacancy">
                            Apply to all
                        </el-button>
                        <el-button size="mini" type="text" class="cancel-btn" @click="closeVacancyPopover">
                            Cancel
                        </el-button>
                    </div>
                    <template #reference>
                        <i @click="visible = true" class="el-icon-edit"></i>
                    </template>
                </el-popover>
            </template>
            <template #default="scope">
                <el-input-number v-if="scope.row.edit" v-model="scope.row.limit" size="mini" :min="0"></el-input-number>
                <span v-else>{{ scope.row.limit }}</span>
            </template>
        </el-table-column>
        <el-table-column label="USER(S)" align="left" min-width="68">
            <template #default="scope">
                <i class="el-icon-view" @click="handleUserSettings(scope.row)"></i>
            </template>
        </el-table-column>
        <el-table-column label="ACTION" width="132" align="left">
            <template #default="scope">
                <div class="edit-button-group" v-if="scope.row.edit">
                    <el-button class="save-btn" type="primary" size="mini" @click="confirmEdit(scope.row)">
                        Save
                    </el-button>
                    <el-button class="cancel-btn" size="mini" @click="cancelEdit(scope.row)">
                        Cancel
                    </el-button>
                </div>
                <div v-else>
                    <el-button type="text" size="small" icon="el-icon-edit" v-if="!isEditing" @click="handleEdit(scope.$index, scope.row)"></el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination-index">Total: {{ endItemIndex }} items</div>
    <UserSettingsDialog v-if="userSettingsDialog.isVisible" :dialog="userSettingsDialog" @update:isVisible="userSettingsDialog.isVisible = $event"></UserSettingsDialog>
</template>

<script>
import useInput from "@/components/useInput";
import UserSettingsDialog from "@/components/annual-leave/UserSettingsDialog";
import { fetchYearSettings, putCutOffDate, putShowSchedule, fetchWeekSettings, putWeekSettings, putAllVacancy } from "@/modules/annual-leave/request-no-annual-leave";
import moment from "moment";
export default {
    name: "NOSettings",
    components: { UserSettingsDialog },
    props: {
        drawer: {
            type: Object,
            default: () => {},
            required: true,
        },
        scheduleId: {
            type: Number,
            default: NaN,
            required: true,
        },
        year: {
            type: Number,
            default: NaN,
            required: true,
        },
    },
    data() {
        return {
            vacancy: {
                visible: false,
                value: 0,
            },
            isEditing: false,
            isLoading: false,
            windowInnerWidth: null,
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            yearTable: {
                data: [],
            },
            form: {
                isLoading: false,
                isShowSchedule: null,
                cutOffDate: null,
            },
            userSettingsDialog: {
                isVisible: false,
                title: "",
                weekId: NaN,
            },
        };
    },
    computed: {
        endItemIndex: function() {
            return this.yearTable.data.length;
        },
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.drawer, emit, "isVisible");
        return {
            isVisible,
        };
    },
    created() {
        window.addEventListener("resize", this.getWindowInnerWidth);
    },
    async mounted() {
        this.getWindowInnerWidth();
        await this.fetchWeekTable(this.scheduleId);
        await this.fetchYearSettings(this.scheduleId);
    },
    methods: {
        getWindowInnerWidth() {
            this.windowInnerWidth = window.innerWidth;
        },
        async fetchWeekTable(scheduleId) {
            try {
                this.isLoading = true;
                if (scheduleId) {
                    const data = await fetchWeekSettings(scheduleId);
                    if (data) {
                        this.yearTable.data = data;
                    }
                }
            } finally {
                this.isLoading = false;
            }
        },
        async fetchYearSettings(scheduleId) {
            try {
                this.form.isLoading = true;
                if (scheduleId) {
                    const result = await fetchYearSettings(scheduleId);
                    if (result) {
                        this.form.cutOffDate = result.cut_off_date;
                        this.form.isShowSchedule = result.show == 1 ? true : false;
                    }
                }
            } finally {
                this.form.isLoading = false;
            }
        },
        async cancelEdit(row) {
            try {
                this.isLoading = true;
                const data = await fetchWeekSettings(this.scheduleId);
                if (data) {
                    this.yearTable.data = data;
                    this.$message({
                        message: "Table has been restored to its original value",
                        type: "warning",
                    });
                    row.edit = false;
                    this.isEditing = false;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async confirmEdit(row) {
            if (row.limit == null) {
                this.$message({
                    message: "Vacancy cannot be empty",
                    type: "info",
                });
                return;
            }
            try {
                this.isLoading = true;
                const week = {
                    id: row.id,
                    tag: row.tag,
                    vacancy: row.limit,
                };
                const result = await putWeekSettings(week);
                if (result) {
                    row.edit = false;
                    let startDate = row.week_start_date;
                    let endDate = row.week_end_date;
                    this.$notify({
                        title: "Week updated successfully",
                        message: `Week (${startDate} - ${endDate}) was updated successfully`,
                        type: "success",
                    });
                    await this.fetchWeekTable(this.scheduleId);
                    this.isEditing = false;
                }
            } finally {
                this.isLoading = false;
            }
        },
        handleUserSettings(row) {
            let startDate = row.week_start_date;
            let endDate = row.week_end_date;
            this.userSettingsDialog.title = startDate && endDate ? `Block User Setting (${startDate} to ${endDate})` : "";
            this.userSettingsDialog.weekId = row.id;
            this.userSettingsDialog.isVisible = true;
        },
        async handleOnSaveVacancy() {
            if (this.vacancy.value == null) {
                this.$message({
                    message: "Vacancy cannot be empty",
                    type: "info",
                });
                return;
            }
            try {
                this.isLoading = true;
                const result = await putAllVacancy(this.scheduleId, this.vacancy.value);
                if (result) {
                    this.$notify({
                        title: "All Vacancies updated successfully",
                        message: "Vacancies was updated successfully",
                        type: "success",
                    });
                    this.vacancy.value = 0;
                    this.vacancy.visible = false;

                    await this.fetchWeekTable(this.scheduleId);
                }
            } finally {
                this.isLoading = false;
            }
        },
        closeVacancyPopover() {
            this.vacancy.value = 0;
            this.vacancy.visible = false;
        },
        async handleShowSchedule(value) {
            try {
                this.form.isLoading = true;
                if (this.scheduleId) {
                    const result = await putShowSchedule(this.scheduleId, value);
                    if (result) {
                        this.$notify({
                            title: (value ? "Show" : "Hide") + " schedule successfully",
                            message: "Show/hide schedule was updated successfully",
                            type: "success",
                        });
                    }
                }
            } finally {
                await this.fetchYearSettings(this.scheduleId);
                this.form.isLoading = false;
            }
        },
        async handleCutOffDate(value) {
            try {
                this.form.isLoading = true;
                if (this.scheduleId) {
                    let cutOffDate = moment(value).format("YYYY-MM-DD");
                    const result = await putCutOffDate(this.scheduleId, cutOffDate);
                    if (result) {
                        this.$notify({
                            title: "Cut off date updated successfully",
                            message: "Cut off date was updated successfully",
                            type: "success",
                        });
                    }
                }
            } finally {
                await this.fetchYearSettings(this.scheduleId);
                this.form.isLoading = false;
            }
        },
        disabledDate(time) {
            return moment(time).isSameOrAfter(moment(String(this.year + 1)));
        },
        handleEdit(index, row) {
            row.edit = !row.edit;
            this.isEditing = true;
        },
    },
};
</script>

<style lang="scss">
#request-annual-leave,
#request-no-annual-leave {
    .year-table {
        th {
            background-color: #fafafb;
            border: 0px;
        }
        .el-table__body {
            .cell {
                padding: 0 10px;
                height: 28px;
                display: flex;
                align-items: center;
            }
            .cell-class {
                padding: 0;
                height: 35px;
            }
        }
        .el-icon-view {
            color: #979797;
            font-size: 14px;
            &:hover {
                cursor: pointer;
                color: #36b37e;
            }
        }
        .el-icon-edit {
            cursor: pointer;
            font-size: 14px;
            color: #2684ff;
        }
        .edit-button-group {
            display: flex;
            .cancel-btn {
                margin-left: 8px;
            }
        }
        .save-btn,
        .cancel-btn {
            padding: 0;
            width: 52px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 8px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
    .pagination-index {
        font-weight: normal;
        color: #44444f;
        font-size: 12px;
        margin-top: 8px;
        margin-bottom: 12px;
    }
}
.vacancy-popover {
    .el-input-number--mini {
        width: 90px;
    }
    .form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    .vacancy-button-group {
        display: flex;
        justify-content: flex-end;
        .save-btn,
        .cancel-btn {
            padding: 0;
            width: 72px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 4px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
}
.year-settings-form {
    .el-form-item {
        width: 100%;
    }
    .show-hide-schedule {
        display: flex;
        align-items: center;
        height: 32px;
    }
}
</style>
