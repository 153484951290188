import { render } from "./User.vue?vue&type=template&id=9d5951e6"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"

import "./User.vue?vue&type=style&index=0&id=9d5951e6&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9d5951e6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9d5951e6', script)) {
    api.reload('9d5951e6', script)
  }
  
  module.hot.accept("./User.vue?vue&type=template&id=9d5951e6", () => {
    api.rerender('9d5951e6', render)
  })

}

script.__file = "src/views/Settings/User.vue"

export default script