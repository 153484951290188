import axios from "axios";

export const fetchData = async wardId => {
    try {
        const result = await axios.get(`/admin/no-annual-leave?wardId=1`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async schedule => {
    try {
        const formData = new FormData();
        formData.append("title", schedule.title);
        formData.append("year", schedule.year);
        formData.append("file", schedule.file);
        formData.append("wardId", 1);
        const result = await axios.post(`/admin/no-annual-leave`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};
