<template>
    <el-table
        class="ranking-table action-table"
        ref="rankingTable"
        :size="windowInnerWidth > 1024 ? 'medium' : 'mini'"
        :data="table.data"
        :height="tableHeight"
        :key="tableHeight"
        v-loading="table.isLoading || isLoading"
        :cell-style="{ padding: windowInnerWidth > 1024 ? '10px 0' : '0' }"
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
    >
        <el-table-column label="PRIORITY" prop="priority" align="left" show-overflow-tooltip :sortable="true" width="112">
            <template #default="scope">
                <template v-if="scope.row.edit">
                    <el-input-number v-model="scope.row.priority" size="mini" :min="1"></el-input-number>
                </template>
                <span v-else>{{ scope.row.priority }}</span>
            </template>
        </el-table-column>
        <el-table-column label="RANK NAME" prop="name" align="left" show-overflow-tooltip :sortable="true" width="160">
            <template #default="scope">
                <template v-if="scope.row.edit">
                    <el-input v-model="scope.row.name" size="mini" class="name word-limit-input" placeholder="Enter the name" maxlength="100" show-word-limit />
                </template>
                <span v-else>{{ scope.row.name }}</span>
            </template>
        </el-table-column>
        <el-table-column class-name="color-col" label="COLOR" align="left" :sortable="false" width="96">
            <template #default="scope">
                <el-color-picker class="color-picker" size="mini" v-if="scope.row.edit" v-model="scope.row.color"></el-color-picker>
                <div v-else class="ranking-color" :style="'background-color:' + scope.row.color">
                    &nbsp;
                </div>
            </template>
        </el-table-column>
        <el-table-column label="DESCRIPTION" align="left" show-overflow-tooltip :sortable="false" min-width="160">
            <template #default="scope">
                <template v-if="scope.row.edit">
                    <el-input v-model="scope.row.description" size="mini" placeholder="Please enter description" maxlength="50" show-word-limit />
                </template>
                <span v-else>{{ scope.row.description }}</span>
            </template>
        </el-table-column>
        <el-table-column label="USERS" prop="number_of_users" align="left" :sortable="false" width="60" show-overflow-tooltip></el-table-column>
        <el-table-column label="ACTION" width="144" align="left" v-if="userLevel == 3">
            <template #default="scope">
                <div class="action-button-group">
                    <div v-if="scope.row.edit">
                        <el-button class="save-btn" type="primary" size="mini" @click="confirmEdit(scope.row)">
                            Save
                        </el-button>
                        <el-button class="cancel-btn" size="mini" @click="cancelEdit(scope.row)">
                            Cancel
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button v-if="!isEditing" type="text" size="small" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row)"></el-button>
                        <el-button size="small" type="text" @click="(index, row) => table.handleDelete(scope.$index, scope.row)">
                            <i class="el-icon-delete"></i>
                        </el-button>
                    </div>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <el-row class="action-table-footer" v-show="!table.loading">
        <el-col :span="6">
            <span class="pagination-index">{{ startItemIndex }}-{{ endItemIndex }} of {{ table.numberOfItems }} items</span>
        </el-col>
        <el-col :span="18" class="pagination-container">
            <el-pagination
                class="pagination"
                :currentPage="table.pagination.currentPage"
                :page-size="pageSize"
                :page-sizes="pageSizes"
                :layout="pageSizes.length == 1 ? 'prev, pager, next' : 'prev, pager, next, sizes'"
                :total="Number(table.numberOfItems)"
                @size-change="pageSize => table.pagination.handleSizeChange(pageSize)"
                @current-change="currentPage => table.pagination.handleCurrentChange(currentPage)"
            ></el-pagination>
        </el-col>
    </el-row>
</template>

<script>
import $ from "jquery";
import { getUserInfo } from "@/utils/auth";
import { putData as editRank } from "@/modules/settings/rank";

export default {
    name: "RankingTable",
    props: {
        table: {
            required: true,
            type: Object,
        },
        selection: {
            type: Boolean,
            default: true,
        },
        canResize: {
            type: Boolean,
            default: true,
        },
        canDelete: {
            type: Boolean,
            default: true,
        },
    },
    data(props) {
        return {
            isEditing: false,
            isLoading: false,
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            // table
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            headerCellStyle: {
                background: "#fafafb",
                border: "0px",
            },
            tableHeight: 500,
            windowInnerWidth: null,

            // pagination
            pageSize: props.table.pagination.pageSize || 10,
            pageSizes: props.table.pagination.pageSizes || [10, 20, 30],
        };
    },
    computed: {
        startItemIndex: function() {
            return 1 + this.table.pagination.pageSize * (this.table.pagination.currentPage - 1);
        },
        endItemIndex: function() {
            return this.table.pagination.pageSize * this.table.pagination.currentPage;
        },
    },
    created() {
        if (this.canResize) {
            window.addEventListener("resize", this.resize);
        }
    },
    beforeUnmount() {
        if (this.canResize) {
            window.removeEventListener("resize", this.resize);
        }
    },
    mounted() {
        if (this.canResize) {
            this.resize();
        }
    },
    methods: {
        // table
        resize() {
            this.windowInnerWidth = window.innerWidth;
            if ($(".el-tabs__header")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 164 : 152;
                this.tableHeight = $(".page-content")[0].clientHeight - constant;
            } else if ($(".page-content")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 88 : 62;
                this.tableHeight = $(".page-content")[0].clientHeight - $(".container-header")[0].clientHeight - constant;
            }
        },
        cancelEdit(row) {
            row.edit = false;
            this.$emit("update:rankTable");
            this.$message({
                message: "Table has been restored to its original value",
                type: "warning",
            });
            this.isEditing = false;
        },
        async confirmEdit(row) {
            if (row.name == "") {
                this.$message({
                    message: "Ranking name cannot be empty",
                    type: "info",
                });
                return;
            } else if (row.priority == null) {
                this.$message({
                    message: "Ranking priority cannot be empty",
                    type: "info",
                });
                return;
            }
            try {
                this.isLoading = true;
                const rank = {
                    id: row.id,
                    name: row.name,
                    color: row.color,
                    description: row.description,
                    priority: row.priority,
                };
                const result = await editRank(rank);
                if (result) {
                    row.edit = false;
                    this.$notify({
                        title: "Ranking updated successfully",
                        message: "Ranking " + rank.name + " was updated successfully",
                        type: "success",
                    });
                    this.$emit("update:rankTable");
                    this.isEditing = false;
                }
            } finally {
                this.isLoading = false;
            }
        },
        handleEdit(index, row) {
            row.edit = !row.edit;
            this.isEditing = true;
        },
    },
};
</script>

<style lang="scss">
.ranking-table {
    .el-table__body {
        .el-input-number--mini {
            width: auto;
        }
        .color-col {
            .cell {
                min-height: 40px;
                display: flex;
                align-items: center;
            }
        }
        .color-picker {
            width: 100%;
            .el-color-picker__trigger {
                width: 100%;
            }
        }
        .ranking-color {
            width: 100%;
            border: 1px solid #ddd;
            border-radius: 5px;
        }
        .save-btn,
        .cancel-btn {
            padding: 0;
            width: 52px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 8px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
}
</style>
