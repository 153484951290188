<template>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    <div id="request-duty-roster">
        <el-row class="roster-header">
            <el-col :span="6" class="roster-header-details-ward">
                <el-dropdown trigger="click" @command="handleWardDropDownOnClick" v-if="wardsOptions.length > 0">
                    <span class="el-dropdown-link roster-header-title">
                        <span>{{ wardLabel }}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="item in wardsOptions" v-bind:key="item.id" :command="item.id">
                                {{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
            <el-col :span="18" v-if="weeksOptions.length" class="roster-header-details-week">
                <el-dropdown trigger="click" @command="handleWeekDropDownOnClick">
                    <span class="el-dropdown-link roster-header-title">
                        {{ weekLabel }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="item in weeksOptions" v-bind:key="item.id" :command="item.id">
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-row class="roster-header" v-if="table.cutOffDate.value">
            <el-col class="roster-header-details-ward">
                <span class="el-dropdown-link roster-header-title">Request Duty Cut-off Date: {{ table.cutOffDate.value }}</span>
            </el-col>
        </el-row>
        <div v-if="table.rosterId.value || isLoading" class="table-container">
            <Roster ref="roster" style="height: 100%" :table="table" :selectedWeek="selectedWeek" :weeksOptions="weeksOptions" />
        </div>
        <div v-else class="no-data-container">
            <el-image :src="noDataImage" fit="contain"></el-image>
            <span class="title">Roster not found</span>
        </div>
        <el-dialog custom-class="modal-dialog" v-model="centerDialogVisible" width="90%" center>
            <i class="el-icon-warning-outline" />
            <h3>Request requirement</h3>
            <div class="dialog-content">
                <pre class="modal-message"> {{ modalMessage }} </pre>
                <div class="gradient-overlay"></div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="hideDialog">Confirm</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { useState } from "@/utils/use";
import { onMounted, ref, watch, computed, reactive } from "vue";
import Roster from "@/views/Mobile/Roster";
import { ElNotification } from "element-plus";
import placeholder from "@/assets/no-data.png";
import XEUtils from "xe-utils";

import { fetchSet as fetchSetData } from "@/modules/general/mobileSet";
import { fetchData as fetchWardData } from "@/modules/roster/mobile/nurse-ward";
import {
    fetchData as fetchDutyRosterData,
    fetchModalMessage as fetchModalMessageData,
    fetchOptions as fetchDutyOptionsData,
    postData as postDutyData,
} from "@/modules/roster/mobile/request-duty-roster";
export default {
    name: "RequestDutyRosterMobile",
    components: {
        Roster,
    },
    setup() {
        const centerDialogVisible = ref(false);
        const modalMessage = ref(null);
        const isLoading = ref(false);
        const isTableLoading = ref(false);
        const isManage = ref(false);
        const rosterDotList = ref([]);
        const rosterId = ref(null);
        const rows = ref([]);
        const columnHeader = ref([]);
        const dutyOptions = ref([]);
        const roster = ref(null);
        const weeksOptions = ref([]);
        const [selectedWeek, setSelectedWeek] = useState();
        const wardsOptions = ref([]);
        const [selectedWard, setSelectedWard] = useState();
        const cutOffDate = ref(null);
        const wardLabel = computed(() => {
            if (wardsOptions.value && wardsOptions.value.length > 0) {
                const target = wardsOptions.value.find(({ id }) => id === selectedWard.value);
                if (target) {
                    return target.name;
                }
            }
            return "";
        });
        const weekLabel = computed(() => {
            if (weeksOptions.value && weeksOptions.value.length > 0) {
                const target = weeksOptions.value.find(({ id }) => id === selectedWeek.value);
                if (target) {
                    return target.label;
                }
            }
            return "";
        });

        const noDataImage = ref(placeholder);

        watch(selectedWard, async wardId => {
            weeksOptions.value = [];
            setSelectedWeek(null);
            rosterId.value = null;
            fetchSet(wardId);
            fetchModalMessage();
        });

        watch(selectedWeek, async weekId => {
            if (weekId && selectedWard.value) {
                await fetchDutyRoster(selectedWard.value, weekId);
            }
        });

        const fetchDutyRoster = async (ward, week) => {
            try {
                isLoading.value = true;
                isManage.value = false;
                const result = await fetchDutyRosterData(ward, week);
                if (result) {
                    let additionRow = {
                        full_name: "",
                        color: "transparent",
                        duty: [],
                        user_id: null,
                    };
                    for (var i = 0; i < 10; i++) {
                        result.data.push(additionRow);
                    }
                    rows.value = reactive(XEUtils.clone(result.data, true));
                    columnHeader.value = result.day_value_count;
                    rosterId.value = result.roster_id;
                    cutOffDate.value = result.cut_off_date;
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchModalMessage = async () => {
            try {
                isLoading.value = true;
                const data = await fetchModalMessageData(selectedWard.value);
                if (data) {
                    modalMessage.value = data;
                    centerDialogVisible.value = true;
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchWard = async () => {
            try {
                isLoading.value = true;
                const data = await fetchWardData();
                if (data && data.length > 0) {
                    wardsOptions.value = data;
                    setSelectedWard(data[0].id);
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchSet = async wardId => {
            try {
                isLoading.value = true;
                const data = await fetchSetData(wardId);
                if (data && data.length > 0) {
                    weeksOptions.value = data;
                    setSelectedWeek(data[0].id);
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchDutyOptions = async () => {
            try {
                isLoading.value = true;
                const data = await fetchDutyOptionsData();
                if (data) {
                    dutyOptions.value = data.map(duty => {
                        return {
                            label: duty.name ? duty.name : " ",
                            value: duty.id,
                        };
                    });
                }
            } finally {
                isLoading.value = false;
            }
        };

        const postDuty = async (duty, scope) => {
            try {
                isTableLoading.value = true;
                let dutyEdited = {
                    date: columnHeader.value[scope.columnIndex - 1].date,
                    optionId: duty.value,
                };
                const result = await postDutyData(rosterId.value, dutyEdited);
                if (result) {
                    ElNotification.success({
                        title: "Duty saved successfully",
                    });
                }
            } finally {
                fetchDutyRoster(selectedWard.value, selectedWeek.value);
                isTableLoading.value = false;
            }
        };

        const handleWardDropDownOnClick = e => {
            setSelectedWard(e);
        };

        const handleWeekDropDownOnClick = e => {
            setSelectedWeek(e);
        };

        const hideDialog = () => {
            centerDialogVisible.value = false;
        };

        onMounted(async () => {
            await fetchWard();
            await fetchDutyOptions();
        });

        return {
            noDataImage,
            centerDialogVisible,
            hideDialog,
            modalMessage,
            isLoading,
            rosterDotList,
            weeksOptions,
            selectedWeek,
            setSelectedWeek,
            wardsOptions,
            selectedWard,
            setSelectedWard,
            wardLabel,
            weekLabel,
            handleWardDropDownOnClick,
            handleWeekDropDownOnClick,
            roster,
            table: {
                isLoading: isTableLoading,
                isManage,
                rosterId,
                cutOffDate,
                rows,
                columnHeader,
                selectedWeek,
                dutyOptions,
                postDuty,
            },
        };
    },
};
</script>

<style lang="scss">
#request-duty-roster {
    .modal-dialog {
        .el-dialog__body {
            text-align: center;
            ::-webkit-scrollbar {
                display: none;
            }
            ::-webkit-scrollbar-button {
                width: 0;
                height: 0;
            }
            ::-webkit-scrollbar-corner {
                background: transparent;
            }
        }
        .el-icon-warning-outline {
            font-size: 40px;
            color: #57d9a3;
        }

        .dialog-content {
            position: relative;
            overflow: hidden;
        }

        .gradient-overlay {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            z-index: 2;
            pointer-events: none;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
        }
    }
    .roster-header-details-ward {
        justify-content: flex-start;
        padding-left: 16px;
    }
    .roster-header-details-week {
        justify-content: flex-end;
        padding-right: 16px;
        text-align: right;
    }
    .modal-message {
        white-space: break-spaces;
        overflow-y: scroll;
        text-align: left;
        height: 250px;
        padding-bottom: 60px;
    }
    .highlight-row {
        background: #f0f9eb;
    }
}
</style>
