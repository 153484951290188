import axios from "axios";

export const fetchData = async type => {
    try {
        const result = await axios.get(`/admin/general/ward${type ? "?type=" + type : ""}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
