import { render } from "./DutyRoster.vue?vue&type=template&id=315615db"
import script from "./DutyRoster.vue?vue&type=script&lang=js"
export * from "./DutyRoster.vue?vue&type=script&lang=js"

import "./DutyRoster.vue?vue&type=style&index=0&id=315615db&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "315615db"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('315615db', script)) {
    api.reload('315615db', script)
  }
  
  module.hot.accept("./DutyRoster.vue?vue&type=template&id=315615db", () => {
    api.rerender('315615db', render)
  })

}

script.__file = "src/views/Roster/DutyRoster.vue"

export default script