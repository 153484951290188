<template>
    <el-row class="roster-header">
        <el-col :span="12" class="roster-header-details">
            <el-dropdown trigger="click" @command="handleDropDownOnClick">
                <span class="el-dropdown-link roster-header-title">
                    {{ title }} :
                    {{ weekLabel }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for="item in weeksOptions" v-bind:key="item.id" :command="item.id">
                            {{ item.label }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
        <el-col :span="12" class="roster-header-badge">
            <RosterDot v-for="item in rosterDotList" :key="item.name" :name="item.name" :color="item.color" />
        </el-col>
    </el-row>
</template>

<script>
import RosterDot from "@/components/roster/RosterDot";
export default {
    name: "RosterHeader",
    props: ["title", "weeksOptions", "rosterDotList", "selectedWeek", "setSelectedWeek"],
    components: { RosterDot },
    setup(props) {
        const handleDropDownOnClick = e => {
            props.setSelectedWeek(e);
        };
        return {
            handleDropDownOnClick,
        };
    },
    computed: {
        weekLabel() {
            if (this.weeksOptions && this.weeksOptions.length > 0) {
                const target = this.weeksOptions.find(({ id }) => id === this.selectedWeek);
                if (target) {
                    return target.label;
                }
            }
            return "";
        },
    },
};
</script>

<style lang="scss">
.roster-header {
    height: auto;
    align-items: center;
    padding-bottom: 8px;
    .el-dropdown {
        cursor: pointer;
    }
}
.roster-header-title {
    /* font-family: Lato;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	/* identical to box height */
    letter-spacing: 0.1px;
    color: #92929d;
    white-space: nowrap;
}
.roster-header-badge {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}
</style>
