<template>
    <el-dialog custom-class="upload-dialog" :title="dialog.title" v-model="isVisible" :before-close="done => done()" width="640px" destroy-on-close>
        <div class="description">
            <i class="el-icon-warning-outline el-icon-left"></i>
            <span class="contact">
                1. The AL schedule is creating for the next year. (If there is a schedule, it won't be created again.)
                <br />
                2. The AL schedule is HIDED in default after created, which only for managing purpose (including ordering of nurse(s), block/unblock of nurse and hot season settings).
                <br />
                3. The ordering of nurse(s) in the schedule CANNOT be changed if he/she is before the current priority.
            </span>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="handleSave" :disabled="isLoading">
                    New
                </el-button>
                <el-button class="cancel-btn" @click="isVisible = false" :disabled="isLoading">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "@/components/useInput";
export default {
    name: "NewScheduleDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    methods: {
        async handleSave() {
            this.isLoading = true;
            try {
                await this.dialog.handleSave(this.form);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
