import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/no-duty-roster?wardId=${wardId}&pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSingleData = async rosterId => {
    try {
        const result = await axios.get(`/admin/no-duty-roster/${rosterId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async roster => {
    try {
        const formData = new FormData();
        formData.append("title", roster.title);
        formData.append("file", roster.file);
        formData.append("wardId", roster.wardId);
        formData.append("setId", roster.setId);
        formData.append("remarks", roster.remarks || "");
        const result = await axios.post(`/admin/no-duty-roster`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async roster => {
    try {
        const formData = new FormData();
        formData.append("title", roster.title);
        if (roster.file) {
            formData.append("file", roster.file);
        }
        formData.append("setId", roster.setId);
        formData.append("remarks", roster.remarks || "");
        const result = await axios.put(`/admin/no-duty-roster/${roster.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async rosterList => {
    const params = {
        list: rosterList,
    };
    try {
        const result = await axios.post(`/admin/no-duty-roster/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
