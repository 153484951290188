<template>
    <div id="recreation-details">
        <el-container class="recreation-details-container">
            <el-header class="recreation-details-header">
                <el-breadcrumb class="recreation-details-breadcrumb" separator="/">
                    <el-breadcrumb-item>
                        <a href="/">Home</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        <a href="#/recreation">Recreation</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        {{ recreation.title }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </el-header>
            <el-main class="content-container" v-loading="isLoading">
                <el-row class="content-row" :gutter="32" v-if="!isLoading">
                    <el-col class="details-container" :span="windowInnerWidth > 1024 && recreation.type == 1 ? 16 : 24">
                        <el-main class="details-content">
                            <RecreationDetails :recreation="recreation" :feedback="feedback" :result="result" :isSecret="isSecret" @update:recreation="handleUpdateRecreation" />
                        </el-main>
                    </el-col>
                    <el-col :span="8" v-if="windowInnerWidth > 1024">
                        <el-main class="feedback-content">
                            <Feedback :feedback="feedback" :isSecret="isSecret" />
                        </el-main>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { fetchDetails as fetchRecreationDetails, fetchFeedback, fetchResult } from "@/modules/recreation";
import Feedback from "@/components/recreation/Feedback";
import RecreationDetails from "@/components/recreation/RecreationDetails";

export default {
    name: "RecreationDetailsWrapper",
    components: {
        RecreationDetails,
        Feedback,
    },
    data() {
        return {
            isLoading: false,
            windowInnerWidth: null,
            recreationId: this.$route.params.id,
            recreation: {},
            result: [],
            feedback: [],
            isSecret: false,
        };
    },
    created() {
        window.addEventListener("resize", this.getWindowInnerWidth);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.getWindowInnerWidth);
    },
    async mounted() {
        this.getWindowInnerWidth();
        await this.fetchRecreationDetails(this.recreationId);
        if (this.recreation.type == 1) {
            this.fetchResult(this.recreationId);
            this.fetchFeedback(this.recreationId);
        }
    },
    methods: {
        async fetchRecreationDetails(id) {
            try {
                this.isLoading = true;
                const data = await fetchRecreationDetails(id);
                if (data) {
                    this.recreation = data;
                    this.isSecret = !data.remember_name;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async fetchResult(id) {
            try {
                this.isLoading = true;
                const result = await fetchResult(id);
                if (result) {
                    for (let i = 0; i < result.data.length; i++) {
                        result.data[i].alphabet = String.fromCharCode(i + 65);
                    }
                    result.highestVote = result.data.reduce((max, option) => (max.vote > option.vote ? max : option));
                    this.result = result;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async fetchFeedback(id) {
            try {
                this.isLoading = true;
                const data = await fetchFeedback(id);
                if (data) {
                    this.feedback = data.map(feedback => {
                        return {
                            ...feedback,
                            optionAlphabet: String.fromCharCode(feedback.polling_option_priority - 1 + 65),
                        };
                    });
                }
            } finally {
                this.isLoading = false;
            }
        },
        getWindowInnerWidth() {
            this.windowInnerWidth = window.innerWidth;
        },
        handleUpdateRecreation() {
            this.fetchRecreationDetails(this.recreationId);
        },
    },
};
</script>

<style lang="scss">
#recreation-details {
    height: 100%;
    @media (min-width: 1025px) {
        .hidden-bigger-than-1024 {
            display: none !important;
        }
    }
    @media only screen and (max-width: 1024px) {
        .hidden-smaller-than-1024 {
            display: none !important;
        }
    }
    .recreation-details-container {
        height: 100%;
    }
    .recreation-details-header {
        display: flex;
        align-items: center;
        height: 40px !important;
        padding: 0px;
        .recreation-details-breadcrumb {
            height: auto;
            font-size: 14px;
            line-height: 36px;
            .el-breadcrumb__inner {
                a {
                    color: #c1c7d0;
                    font-weight: normal;
                    &:hover {
                        color: #36b37e;
                    }
                }
            }
            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    font-weight: bold;
                    color: #303133;
                }
            }
        }
    }
    .content-container {
        padding: 8px 0;
        height: 100%;
        overflow: hidden;
        .content-row {
            height: 100%;
        }
        .details-container {
            height: 100%;
        }
        .details-content,
        .feedback-content {
            box-shadow: 3px 5px 22px rgba(0, 0, 0, 0.03);
            border-radius: 5px;
            background-color: #fff;
            padding: 32px;
            text-align: left;
            height: 100%;
        }
    }
}
</style>
