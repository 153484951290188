import { render } from "./AddPostForm.vue?vue&type=template&id=14cc2b80"
import script from "./AddPostForm.vue?vue&type=script&lang=js"
export * from "./AddPostForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "14cc2b80"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('14cc2b80', script)) {
    api.reload('14cc2b80', script)
  }
  
  module.hot.accept("./AddPostForm.vue?vue&type=template&id=14cc2b80", () => {
    api.rerender('14cc2b80', render)
  })

}

script.__file = "src/components/training/AddPostForm.vue"

export default script