<template>
    <div id="no-message">
        <ViewContainer :title="`NO Message`" :numberOfItems="nOMessageTable.numberOfItems ? nOMessageTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="nOMessageTable" />
            </template>
        </ViewContainer>
        <ViewsDialog v-if="viewsDialog.isVisible" :dialog="viewsDialog" @update:isVisible="viewsDialog.isVisible = $event" />
        <Drawer :drawer="addNOMessageDrawer" @update:isVisible="addNOMessageDrawer.isVisible = $event">
            <template v-slot:content>
                <AddNOMessageForm ref="addNOMessageForm" :isLoading="addNOMessageDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchMessages, deleteData as deleteMessages, pinData as pinMessages, unpinData as unpinMessages } from "@/modules/no-message.js";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/no-message/Table";
import Drawer from "@/components/Drawer";
import ViewsDialog from "@/components/no-message/ViewsDialog";
import AddNOMessageForm from "@/components/no-message/AddNOMessageForm";

export default {
    name: "NOMessage",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        Drawer,
        ViewsDialog,
        AddNOMessageForm,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, target dept, author",
                value: "",
                callback: () => this.searchMessages(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                    {
                        name: "Pin",
                        value: 1,
                        class: "pin",
                        style: "color: #2684ff;",
                    },
                    {
                        name: "Unpin",
                        value: 2,
                        class: "unpin",
                        style: "color: #2684ff;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "New Message",
                    icon: "el-icon-plus",
                    callback: () => this.handleNewMessage(),
                },
            ],
            nOMessageTable: {
                name: "NOMessage",
                isLoading: false,
                handleRowClick: (row, column, e) => this.handleRowClick(row, column, e),
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handlePin: (index, row) => this.handlePin(index, row),
                handleUnpin: (index, row) => this.handleUnpin(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            viewsDialog: {
                isVisible: false,
                msgTitle: "",
                messageId: null,
            },
            addNOMessageDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New NO Message",
                save: () => this.addMessage(),
                close: done => this.closeAddNOMessageDrawer(done),
            },
        };
    },
    mounted() {
        this.fetchMessages();
    },
    methods: {
        async fetchMessages() {
            this.isLoading = true;
            let pageSize = this.nOMessageTable.pagination.pageSize;
            let pageIndex = this.nOMessageTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchMessages(pageSize, pageIndex, searchString);
                if (result) {
                    this.nOMessageTable.data = result.data;
                    this.nOMessageTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addMessage() {
            try {
                this.addNOMessageDrawer.isLoading = true;
                const result = await this.$refs.addNOMessageForm.addMessage();
                if (result) {
                    this.addNOMessageDrawer.isVisible = false;
                    this.$notify({
                        title: "NO Message added successfully",
                        message: "NO Message " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchMessages();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addNOMessageDrawer.isLoading = false;
            }
        },
        async deleteMessages(messageList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the message(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteMessages(messageList);
                    if (result) {
                        this.$notify({
                            title: "Message(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchMessages();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        async pinMessages(messageList) {
            try {
                this.isLoading = true;
                const result = await pinMessages(messageList);
                if (result) {
                    this.$notify({
                        title: "Message(s) pinned successfully",
                        type: "success",
                    });
                }
                this.fetchMessages();
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        async unpinMessages(messageList) {
            try {
                this.isLoading = true;
                const result = await unpinMessages(messageList);
                if (result) {
                    this.$notify({
                        title: "Message(s) unpinned successfully",
                        type: "success",
                    });
                }
                this.fetchMessages();
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // header
        handleAction(selection) {
            if (this.nOMessageTable.multipleSelection.length > 0) {
                if (selection == 0) {
                    this.deleteMessages(this.nOMessageTable.multipleSelection);
                } else if (selection == 1) {
                    this.pinMessages(this.nOMessageTable.multipleSelection);
                } else if (selection == 2) {
                    this.unpinMessages(this.nOMessageTable.multipleSelection);
                }
            }
        },
        handleNewMessage() {
            this.addNOMessageDrawer.isVisible = true;
        },
        // table
        searchMessages() {
            this.nOMessageTable.pagination.currentPage = 1;
            this.fetchMessages();
        },
        handleDelete(index, row) {
            this.deleteMessages([row.id]);
        },
        handlePin(index, row) {
            this.pinMessages([row.id]);
        },
        handleUnpin(index, row) {
            this.unpinMessages([row.id]);
        },
        handleSelectionChange(rows) {
            this.nOMessageTable.multipleSelection = rows.map(post => post.id);
        },
        handleRowClick(row, column) {
            if (column.property == "number_of_views") {
                this.viewsDialog.isVisible = true;
                this.viewsDialog.msgTitle = row.title;
                this.viewsDialog.messageId = row.id;
            } else if (column.property == "title") {
                if (row.editable) {
                    this.$router.push({
                        path: "/no-message/" + row.id,
                    });
                }
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.nOMessageTable.pagination.pageSize = pageSize;
            this.fetchMessages();
        },
        handleCurrentChange(currentPage) {
            this.nOMessageTable.pagination.currentPage = currentPage;
            this.fetchMessages();
        },
        closeAddNOMessageDrawer() {
            this.$refs.addNOMessageForm.resetForm();
            this.addNOMessageDrawer.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
#no-message {
    height: 100%;
}
</style>
