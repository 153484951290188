<template>
    <el-drawer :title="drawer.title" custom-class="rtl-drawer" v-model="isVisible" direction="rtl" size="70%" ref="drawer" :destroy-on-close="true" :before-close="done => drawer.close(done)">
        <el-container class="drawer-container">
            <el-main class="drawer-content">
                <slot name="content"></slot>
            </el-main>
            <el-footer class="drawer-footer" style="height: auto;">
                <el-button class="save-btn" v-if="drawer.save" @click="() => drawer.save()" type="primary" :disabled="drawer.isLoading || (type == 'survival-working-tips' && userLevel == 2)">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="$refs.drawer.handleClose" :disabled="drawer.isLoading">
                    {{ drawer.save ? "Cancel" : "Close" }}
                </el-button>
            </el-footer>
        </el-container>
    </el-drawer>
</template>

<script>
import useInput from "@/components/useInput";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "Drawer",
    props: ["drawer", "type"],
    data() {
        return {
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.drawer, emit, "isVisible");
        let buttonRefs = [];
        const setButtonRef = el => {
            if (el) {
                buttonRefs.push(el);
            }
        };
        return {
            isVisible,
            setButtonRef,
        };
    },
};
</script>

<style lang="scss">
.rtl-drawer {
    overflow: auto;
    @media (min-width: 1025px) {
        max-width: 640px;
    }
    &:focus {
        outline: 0;
    }
    .el-drawer__header {
        border-bottom: 1px solid #e2e2ea;
        padding: 16px 32px;
        margin-bottom: 0px;
        color: #171725;
        font-size: 20px;
        font-weight: bold;
        .el-drawer__close-btn {
            margin-right: -20px;
        }
    }
    .el-drawer__body {
        overflow: auto;
    }
    .drawer-container {
        height: 100%;
        .drawer-content {
            background-color: #fff;
            border-bottom: 1px solid #e2e2ea;
            padding: 16px 32px;
        }
        .drawer-footer {
            padding: 16px 32px;
            .el-button {
                width: 120px;
                border: 0;
                border-radius: 5px;
            }
            .cancel-btn {
                background-color: #ebecf0;
                color: #b3bac5;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: #4ac292;
        border: 0 solid #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #e8e8e8;
        border: 0 none #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #e8e8e8;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}
</style>
