<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format with a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
        </span>
    </div>
    <el-form class="add-user-form" ref="addUserForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules">
        <div class="sub-section">
            <div class="sub-title">
                <span class="user-details">User Details</span>
            </div>
            <el-row :gutter="32">
                <el-col :span="8">
                    <el-form-item label="User Name:" prop="username" size="small" required>
                        <el-input class="username" v-model="form.username" placeholder="Enter the user name" @input="handleUsernameChange"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="First Name:" prop="firstName" size="small" required>
                        <el-input v-model="form.firstName" placeholder="Enter the first name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Last Name:" prop="lastName" size="small" required>
                        <el-input v-model="form.lastName" placeholder="Enter the last name"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="8">
                    <el-form-item label="Email:" prop="email" size="small" required>
                        <el-input class="email" v-model="form.email" placeholder="Enter the email"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Staff No.:" prop="staffNumber" size="small" required>
                        <el-input class="staff-number" v-model.number="form.staffNumber" placeholder="Enter the staff number" @input="handleStaffNumberChange"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Staff rank:" prop="rank" ref="rank" size="small">
                        <el-select
                            v-model="form.rank.value"
                            filterable
                            remote
                            reserve-keyword
                            multiple
                            :multiple-limit="1"
                            placeholder="Select a staff rank"
                            :remote-method="filterRankSelect"
                            :loading="form.rank.isLoading"
                        >
                            <el-option v-for="item in form.rank.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="8">
                    <el-form-item label="Staff Card Image:" size="small">
                        <el-upload class="upload-staff-card-image" ref="uploadStaffCardImage" accept="image/*" :auto-upload="false" :limit="1" :on-change="handleImageChange" action="#">
                            <template #default v-if="isStaffCardImageEmpty">
                                <el-button type="primary">
                                    <i class="el-icon-upload el-icon-left"></i>
                                    <span>Click to upload</span>
                                </el-button>
                            </template>
                            <template #file="{ file }">
                                <el-tooltip placement="bottom" effect="light">
                                    <template #content>
                                        <img :src="imagePreview" class="staff-card-image-preview" />
                                    </template>
                                    <div class="el-upload-list__item-name">
                                        <i class="el-icon-document"></i>
                                        <span class="file-name">
                                            {{ file.name }}
                                        </span>
                                        <div class="upload-actions">
                                            <i class="el-icon-upload-success el-icon-circle-check"></i>
                                            <i class="el-icon-close el-icon-circle-close" @click="handleImageRemove"></i>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-upload>
                        <div class="upload-staff-card-image-error">
                            <span>{{ form.staffCardImageError }}</span>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
        <div class="sub-section">
            <div class="sub-title">
                <span class="permissions">Permissions</span>
            </div>
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="Level:" prop="level" size="small">
                        <el-radio-group v-model="form.level.value" @change="levelChange">
                            <el-radio-button label="1">LEVEL 1</el-radio-button>
                            <el-radio-button label="2" :disabled="userLevel <= 2">
                                LEVEL 2
                            </el-radio-button>
                            <el-radio-button label="3" :disabled="userLevel < 3">
                                LEVEL 3
                            </el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="Belongs ward and roster you are going to request:" v-if="form.level.value && form.level.value == 1" size="small" prop="belongWard">
                        <el-select v-model="form.belongWard.value" placeholder="Select a ward" :loading="form.belongWard.isLoading">
                            <el-option v-for="item in form.belongWard.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="Manage dept:" v-if="form.level.value && form.level.value == 2" size="small" prop="manageWards">
                        <el-select v-model="form.manageWards.value" placeholder="Select dept" :loading="form.manageWards.isLoading" multiple>
                            <el-option v-for="item in form.manageWards.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="Belongs to dept:" v-if="form.level.value && form.level.value == 3" size="small" prop="belongWards">
                        <el-select v-model="form.belongWards.value" placeholder="Select dept" :loading="form.belongWards.isLoading" multiple>
                            <el-option v-for="item in form.belongWards.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="Which roster you are going to request:" v-if="form.level.value && form.level.value > 1" size="small" prop="rosterWard">
                        <el-select v-model="form.rosterWard.value" placeholder="Select a ward" :loading="form.rosterWard.isLoading">
                            <el-option v-for="item in form.rosterWard.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item prop="isManageNORoster" v-if="form.level.value && form.level.value == 2">
                        <el-checkbox v-model="form.isManageNORoster">
                            Manage NO Roster & Annual Leave
                        </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </el-form>
</template>

<script>
import { postData as addUser } from "@/modules/settings/user";
import { fetchData as fetchRanking } from "@/modules/general/rank";
import { fetchData as fetchWard } from "@/modules/general/ward";
import { fetchData as fetchLevel } from "@/modules/general/level";
import { getUserInfo } from "@/utils/auth";
import { validateStaffNumber, validateRank, validateLevel, validateWard, validateWards } from "@/utils/validator";
export default {
    name: "AddUserForm",
    props: ["isLoading"],
    components: {},
    data() {
        return {
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            imagePreview: "",
            form: {
                rules: {
                    username: [
                        {
                            required: true,
                            message: "Please enter the username",
                            trigger: ["blur", "change"],
                        },
                    ],
                    firstName: [
                        {
                            required: true,
                            message: "Please enter the first name",
                            trigger: ["blur", "change"],
                        },
                    ],
                    lastName: [
                        {
                            required: true,
                            message: "Please enter the last name",
                            trigger: ["blur", "change"],
                        },
                    ],
                    email: [
                        {
                            required: true,
                            message: "Please enter the email",
                            trigger: ["blur", "change"],
                        },
                        {
                            type: "email",
                            message: "Please enter the correct format",
                            trigger: ["blur", "change"],
                        },
                    ],
                    staffNumber: [
                        {
                            required: true,
                            validator: validateStaffNumber,
                            trigger: ["blur", "change"],
                        },
                    ],
                    rank: [
                        {
                            required: true,
                            validator: validateRank,
                            trigger: ["change"],
                        },
                    ],
                    level: [
                        {
                            required: true,
                            validator: validateLevel,
                            trigger: ["blur", "change"],
                        },
                    ],
                    belongWard: [
                        {
                            required: true,
                            validator: validateWard,
                            trigger: ["blur", "change"],
                        },
                    ],
                    belongWards: [
                        {
                            required: true,
                            validator: validateWards,
                            trigger: ["blur", "change"],
                        },
                    ],
                    manageWards: [
                        {
                            required: true,
                            validator: validateWards,
                            trigger: ["blur", "change"],
                        },
                    ],
                    rosterWard: [
                        {
                            required: true,
                            validator: validateWard,
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                // user details
                username: "",
                firstName: "",
                lastName: "",

                email: "",
                staffNumber: "",
                rank: {
                    isLoading: false,
                    value: [],
                    options: [],
                    list: [],
                    error: "",
                },

                staffCardImage: {},
                staffCardImageError: "",

                // permissions
                belongWard: {
                    isLoading: false,
                    options: [],
                    value: null,
                },
                belongWards: {
                    isLoading: false,
                    options: [],
                    value: [],
                },
                manageWards: {
                    isLoading: false,
                    options: [],
                    value: [],
                },
                rosterWard: {
                    isLoading: false,
                    options: [],
                    value: null,
                },
                level: {
                    isLoading: false,
                    options: [],
                    value: null,
                },
                isManageNORoster: false,
            },
        };
    },
    mounted() {
        this.fetchLevel();
        this.fetchRanking();
        this.fetchWard();
        this.fetchRosterWard();
    },
    computed: {
        isStaffCardImageEmpty() {
            return Object.keys(this.form.staffCardImage).length == 0;
        },
    },
    methods: {
        async fetchRanking() {
            try {
                this.form.rank.isLoading = true;
                const data = await fetchRanking();
                if (data) {
                    this.form.rank.list = data.map(item => {
                        return { label: item.name, value: item.id };
                    });
                    this.form.rank.options = this.form.rank.list;
                }
            } finally {
                this.form.rank.isLoading = false;
            }
        },
        async fetchWard() {
            this.form.manageWards.isLoading = true;
            this.form.belongWard.isLoading = true;
            this.form.belongWards.isLoading = true;
            try {
                const data = await fetchWard();
                if (data) {
                    this.form.belongWard.options = data.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    });
                    this.form.belongWards.options = this.form.belongWard.options;
                    this.form.manageWards.options = this.form.belongWard.options;
                }
            } finally {
                this.form.manageWards.isLoading = false;
                this.form.belongWard.isLoading = false;
                this.form.belongWards.isLoading = false;
            }
        },
        async fetchRosterWard() {
            this.form.rosterWard.isLoading = true;
            try {
                let type = "roster";
                const data = await fetchWard(type);
                if (data) {
                    this.form.rosterWard.options = data.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    });
                }
            } finally {
                this.form.rosterWard.isLoading = false;
            }
        },
        async fetchLevel() {
            this.form.level.isLoading = true;
            try {
                const data = await fetchLevel();
                if (data) {
                    this.form.level.options = data;
                }
            } finally {
                this.form.level.isLoading = false;
            }
        },
        async addUser() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                let user = {
                    // user details
                    username: this.form.username,
                    firstName: this.form.firstName,
                    lastName: this.form.lastName,

                    email: this.form.email,
                    staffNumber: this.form.staffNumber,
                    rankId: this.form.rank.value,

                    staffCardImage: this.isStaffCardImageEmpty ? "null" : this.form.staffCardImage.raw,

                    // permissions
                    userLevel: this.form.level.value,
                    rosterWard: this.form.rosterWard.value,
                    isManageNORoster: this.form.isManageNORoster,
                };

                if (this.form.level.value == 3) {
                    user.wardList = this.form.belongWards.value;
                } else if (this.form.level.value == 2) {
                    user.wardList = this.form.manageWards.value;
                } else {
                    user.wardList = this.form.belongWard.value;
                }

                const result = await addUser(user);
                let name = this.form.firstName + " " + this.form.lastName;
                if (result) {
                    return name;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        handleUsernameChange(value) {
            this.form.username = value.toLowerCase().trim();
        },
        handleStaffNumberChange(value) {
            this.form.staffNumber = value.trim();
        },
        // staff image
        handleImageChange(file) {
            if (this.validateImage(file)) {
                this.form.staffCardImage = file;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file.raw);
            } else {
                this.$refs.uploadStaffCardImage.clearFiles();
            }
        },
        handleImageRemove() {
            this.$refs.uploadStaffCardImage.clearFiles();
            this.form.staffCardImage = {};
            this.validateImage(this.form.staffCardImage);
        },
        validateImage(image) {
            if (Object.keys(image).length == 0) {
                return true;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;

            if (!isImage) {
                this.form.staffCardImageError = "Image can only be png/jpeg/jpg format";
                return false;
            }
            if (!isLt10M) {
                this.form.staffCardImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.staffCardImageError = "";
            return true;
        },
        filterRankSelect(query) {
            if (this.form.rank.options) {
                this.form.rank.options = this.form.rank.list.filter(item => {
                    return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }
        },
        async levelChange() {
            this.form.belongWard.value = null;
            this.form.belongWards.value = [];
            this.form.manageWards.value = [];
            this.form.rosterWard.value = null;
            this.form.isManageNORoster = false;
            this.$refs.addUserForm.clearValidate("belongWard");
            this.$refs.addUserForm.clearValidate("belongWards");
            this.$refs.addUserForm.clearValidate("manageWards");
            this.$refs.addUserForm.clearValidate("rosterWard");
        },
        async validateForm() {
            try {
                const imageResult = this.validateImage(this.form.staffCardImage);
                const result = await this.$refs.addUserForm.validate();
                return result && imageResult;
            } catch (error) {
                return false;
            }
        },
        resetForm() {
            this.$refs.addUserForm.resetFields();

            // user details
            this.form.username = "";
            this.form.firstName = "";
            this.form.lastName = "";

            this.form.email = "";
            this.form.staffNumber = null;
            this.form.rank = {
                isLoading: false,
                options: [],
                value: null,
            };

            this.form.staffCardImage = {};
            this.$refs.uploadStaffCardImage.clearFiles();
            this.form.staffCardImageError = "";

            // permissions
            this.form.belongWard = {
                isLoading: false,
                options: [],
                value: null,
            };
            this.form.belongWards = {
                isLoading: false,
                options: [],
                value: [],
            };
            this.form.manageWards = {
                isLoading: false,
                options: [],
                value: [],
            };
            this.form.rosterWard = {
                isLoading: false,
                options: [],
                value: null,
            };
            this.form.level = {
                isLoading: false,
                options: [],
                value: null,
            };
        },
    },
};
</script>
