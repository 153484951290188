<template>
    <el-form class="add-rank-form" ref="addRankForm" size="small" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules">
        <el-row :gutter="32">
            <el-col :span="24">
                <el-form-item label="Name:" prop="name">
                    <el-input class="name word-limit-input" v-model="form.name" placeholder="Enter the name" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Priority:" prop="priority">
                    <el-input-number class="priority" v-model="form.priority" placeholder="Enter the priority" :min="1"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Color:" prop="color">
                    <el-color-picker class="color-picker" size="small" v-model="form.color"></el-color-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="Description:" style="width: 100%" prop="description" size="small">
                    <el-input class="upload-textarea" type="textarea" v-model="form.description" placeholder="Please enter description" maxlength="50" show-word-limit></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { postData as addRank } from "@/modules/settings/rank";
export default {
    name: "AddRankForm",
    props: ["isLoading"],
    components: {},
    data() {
        return {
            form: {
                rules: {
                    name: [
                        {
                            required: true,
                            message: "Please enter name",
                            trigger: ["blur", "change"],
                        },
                    ],
                    color: [
                        {
                            required: true,
                            message: "Please select color",
                            trigger: ["blur", "change"],
                        },
                    ],
                    priority: [
                        {
                            required: true,
                            message: "Please enter priority",
                            trigger: ["blur", "change"],
                        },
                        {
                            type: "number",
                            message: "Please enter a number",
                            trigger: ["blur", "change"],
                        },
                        {
                            type: "number",
                            min: 1,
                            message: "Priority starts from 1",
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                name: "",
            },
        };
    },
    methods: {
        async addRank() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                let rank = {
                    name: this.form.name,
                    color: this.form.color,
                    description: this.form.description,
                    priority: this.form.priority,
                };
                const result = await addRank(rank);
                if (result) {
                    return rank.name;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async validateForm() {
            try {
                const result = await this.$refs.addRankForm.validate();
                return result;
            } catch (error) {
                return false;
            }
        },
        resetForm() {
            this.$refs.addRankForm.resetFields();
        },
    },
};
</script>

<style lang="scss">
.add-rank-form {
    .priority {
        width: 100%;
    }
    .color-picker {
        width: 100%;
        .el-color-picker__trigger {
            width: 100%;
        }
    }
    .textarea {
        .el-textarea__inner {
            height: 100px;
            resize: none;
        }
        .el-input__count {
            right: 8px;
            bottom: -16px;
            background: transparent;
        }
    }
}
</style>
