<template>
    <div id="tel-directory">
        <ViewContainer :title="`Tel Directory`" :numberOfItems="telDirectoryTable.numberOfItems ? telDirectoryTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="telDirectoryTable" />
            </template>
        </ViewContainer>
    </div>
    <UploadDialog v-if="uploadDialog.isVisible" :dialog="uploadDialog" @update:isVisible="uploadDialog.isVisible = $event" :hasTargetWard="true" />
</template>

<script>
import { fetchData as fetchTelDirectories, postData as addTelDirectory, deleteData as deleteTelDirectories } from "@/modules/tel-directory.js";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import UploadDialog from "@/components/UploadDialog";
import moment from "moment";

export default {
    name: "TelDirectory",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        UploadDialog,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, author, remarks",
                value: "",
                callback: () => this.searchTelDirectories(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleUploadDialog(),
                },
            ],
            telDirectoryTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getPdf(row, column, e),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "ward",
                        width: "132",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "95",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        minWidth: "130",
                    },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            uploadDialog: {
                isVisible: false,
                title: "Tel Directory",
                upload: form => this.addTelDirectory(form),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchTelDirectories();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchTelDirectories() {
            this.isLoading = true;
            let pageSize = this.telDirectoryTable.pagination.pageSize;
            let pageIndex = this.telDirectoryTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchTelDirectories(this.ward.value, pageSize, pageIndex, searchString);
                if (result) {
                    this.telDirectoryTable.data = result.data;
                    this.telDirectoryTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addTelDirectory(form) {
            const telDirectory = {
                title: form.title,
                wardList: form.ward.value,
                file: form.file.raw,
                publishDate: moment(form.publishDate).format("YYYY-MM-DD HH:mm"),
                endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm"),
                remarks: form.remarks,
            };
            const result = await addTelDirectory(telDirectory);
            if (result) {
                this.uploadDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "Tel directory uploaded successfully",
                    message: name ? name + " was uploaded successfully" : "",
                    type: "success",
                });
                this.fetchTelDirectories();
            }
        },
        async deleteTelDirectories(telDirectoryList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the tel directory(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteTelDirectories(telDirectoryList);
                    if (result) {
                        this.$notify({
                            title: "Tel directory(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchTelDirectories();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.telDirectoryTable.pagination.currentPage = 1;
            this.fetchTelDirectories();
        },
        // header
        handleUploadDialog() {
            this.uploadDialog.isVisible = true;
        },
        handleAction() {
            if (this.telDirectoryTable.multipleSelection.length > 0) {
                this.deleteTelDirectories(this.telDirectoryTable.multipleSelection);
            }
        },
        // table
        searchTelDirectories() {
            this.telDirectoryTable.pagination.currentPage = 1;
            this.fetchTelDirectories();
        },
        handleDelete(index, row) {
            this.deleteTelDirectories([row.id]);
        },
        handleSelectionChange(rows) {
            this.telDirectoryTable.multipleSelection = rows.map(post => post.id);
        },
        getPdf(row, column) {
            if (column.property == "title" && row.file_path) {
                window.open(row.file_path, "_blank");
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.telDirectoryTable.pagination.pageSize = pageSize;
            this.fetchTelDirectories();
        },
        handleCurrentChange(currentPage) {
            this.telDirectoryTable.pagination.currentPage = currentPage;
            this.fetchTelDirectories();
        },
    },
};
</script>

<style lang="scss">
#tel-directory {
    height: 100%;
}
</style>
