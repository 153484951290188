import { render } from "./ShuttleBus.vue?vue&type=template&id=45a29d41"
import script from "./ShuttleBus.vue?vue&type=script&lang=js"
export * from "./ShuttleBus.vue?vue&type=script&lang=js"

import "./ShuttleBus.vue?vue&type=style&index=0&id=45a29d41&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "45a29d41"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('45a29d41', script)) {
    api.reload('45a29d41', script)
  }
  
  module.hot.accept("./ShuttleBus.vue?vue&type=template&id=45a29d41", () => {
    api.rerender('45a29d41', render)
  })

}

script.__file = "src/views/ShuttleBus.vue"

export default script