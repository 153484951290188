import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/table/compliment?wardId=${wardId}&pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async compliment => {
    try {
        const formData = new FormData();
        formData.append("title", compliment.title);
        formData.append("file", compliment.file);
        formData.append("publishDate", compliment.publishDate);
        formData.append("endDate", compliment.endDate);
        formData.append("remarks", compliment.remarks);
        for (let i = 0; i < compliment.wardList.length; i++) {
            formData.append("wardList[]", compliment.wardList[i]);
        }
        const result = await axios.post(`/admin/table/compliment`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async compliment => {
    const formData = new FormData();
    formData.append("title", compliment.title);
    try {
        const result = await axios.put(`/admin/table/compliment/${compliment.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async complimentList => {
    const params = {
        list: complimentList,
    };
    try {
        const result = await axios.post(`/admin/table/compliment/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
