import { render } from "./UserSettingsDialog.vue?vue&type=template&id=c1618a88"
import script from "./UserSettingsDialog.vue?vue&type=script&lang=js"
export * from "./UserSettingsDialog.vue?vue&type=script&lang=js"

import "./UserSettingsDialog.vue?vue&type=style&index=0&id=c1618a88&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c1618a88"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c1618a88', script)) {
    api.reload('c1618a88', script)
  }
  
  module.hot.accept("./UserSettingsDialog.vue?vue&type=template&id=c1618a88", () => {
    api.rerender('c1618a88', render)
  })

}

script.__file = "src/components/annual-leave/UserSettingsDialog.vue"

export default script