import { render } from "./Training.vue?vue&type=template&id=74e69e14"
import script from "./Training.vue?vue&type=script&lang=js"
export * from "./Training.vue?vue&type=script&lang=js"

import "./Training.vue?vue&type=style&index=0&id=74e69e14&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "74e69e14"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('74e69e14', script)) {
    api.reload('74e69e14', script)
  }
  
  module.hot.accept("./Training.vue?vue&type=template&id=74e69e14", () => {
    api.rerender('74e69e14', render)
  })

}

script.__file = "src/views/Training.vue"

export default script