import axios from "axios";

export const fetchData = async (wardId, setId) => {
    try {
        const result = await axios.get(`/app/request/duty-roster?wardId=${wardId}&setId=${setId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchModalMessage = async wardId => {
    try {
        const result = await axios.get(`/app/request/duty-roster/modal-message/${wardId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async (rosterId, duty) => {
    try {
        const result = await axios.put(`/app/request/duty-roster/${rosterId}`, duty);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchOptions = async () => {
    try {
        const result = await axios.get(`/app/request/duty-roster/option`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchModal = async rosterId => {
    try {
        const result = await axios.get(`/app/request/duty-roster/modal-message/${rosterId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchCutOffDate = async rosterId => {
    try {
        const result = await axios.get(`/app/request/duty-roster/cut-off-date/${rosterId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
