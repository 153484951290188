import { render } from "./PoliciesTable.vue?vue&type=template&id=6baff73c"
import script from "./PoliciesTable.vue?vue&type=script&lang=js"
export * from "./PoliciesTable.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6baff73c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6baff73c', script)) {
    api.reload('6baff73c', script)
  }
  
  module.hot.accept("./PoliciesTable.vue?vue&type=template&id=6baff73c", () => {
    api.rerender('6baff73c', render)
  })

}

script.__file = "src/components/settings/PoliciesTable.vue"

export default script