<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format and media in mp3/mpeg/m4a/x-m4a/mp4/ogg/webm with a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
        </span>
    </div>
    <el-form class="edit-no-message-form" ref="editNOMessageForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Title:" prop="title">
                    <el-input class="word-limit-input" v-model="form.title" placeholder="Enter a title" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Dept:" prop="ward" ref="ward">
                    <el-select v-model="form.ward.value" filterable placeholder="Select dept" multiple>
                        <el-option v-for="item in form.ward.list" :key="item.name" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <div class="ward-error">
                        <span>
                            {{ form.ward.error }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="Content:" prop="content">
                    <TinyMCE @update:content="handleUpdateContent" :initialValue="form.content"></TinyMCE>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Cover Image:">
                    <el-upload
                        class="upload-cover-image"
                        ref="uploadCoverImage"
                        accept="image/*"
                        :auto-upload="false"
                        :limit="1"
                        :on-change="handleImageChange"
                        :on-remove="handleImageRemove"
                        action="#"
                        :file-list="form.oldCoverImage"
                    >
                        <template #default v-if="isCoverImageEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="top" effect="light">
                                <template #content>
                                    <img :src="imagePreview" class="staff-card-image-preview" />
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleImageRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-cover-image-error">
                        <span>{{ form.coverImageError }}</span>
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Media:">
                    <el-upload
                        class="upload-media"
                        ref="uploadMedia"
                        accept="*"
                        :auto-upload="false"
                        :limit="1"
                        :on-change="handleMediaChange"
                        :on-remove="handleMediaRemove"
                        action="#"
                        :file-list="form.oldMedia"
                    >
                        <template #default v-if="isMediaEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="top" effect="light">
                                <template #content>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name" @click="handleMediaPreview(file)">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleMediaRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>
                            {{ form.mediaError }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Publish date:" prop="publishDate">
                    <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.publishDate" format="YYYY-MM-DD HH:mm" />
                </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
                <el-form-item label="End date:" prop="endDate">
                    <el-date-picker type="date" placeholder="Pick a date" v-model="form.endDate" style="width: 100%" />
                </el-form-item>
            </el-col> -->
        </el-row>
        <el-row>
            <el-col :span="12">
                <div class="description">
                    <span class="contact">
                        注意：
                        <br />
                        預定時間發報的手機通知有可能會出現不多於3分鐘的延遲送達情況。
                    </span>
                </div>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { putData as editMessage } from "@/modules/no-message";
import { fetchData as fetchWard } from "@/modules/general/ward";
import TinyMCE from "@/components/TinyMCE";
import moment from "moment";
export default {
    name: "EditNOMessageForm",
    components: {
        TinyMCE,
    },
    props: ["isLoading", "message"],
    data() {
        const validateWard = (rule, ward, callback) => {
            if (ward.value.length == 0) {
                callback(new Error("Please select dept"));
            } else {
                callback();
            }
        };
        // const validatePublishDate = (rule, publishDate, callback) => {
        // 	if (!publishDate) {
        // 		callback(new Error("Please pick a date"));
        // 	} else if (
        // 		moment(publishDate).isSameOrAfter(moment(this.form.endDate))
        // 	) {
        // 		this.$refs.editNOMessageForm.clearValidate("endDate");
        // 		callback(new Error("Publish date must be before end date"));
        // 	} else {
        // 		callback();
        // 	}
        // };
        // const validateEndDate = (rule, endDate, callback) => {
        // 	if (!endDate) {
        // 		callback(new Error("Please pick a date"));
        // 	} else if (
        // 		moment(endDate).isSameOrBefore(moment(this.form.publishDate))
        // 	) {
        // 		this.$refs.editNOMessageForm.clearValidate("publishDate");
        // 		callback(new Error("End date must be after publish date"));
        // 	} else {
        // 		callback();
        // 	}
        // };
        return {
            imagePreview: "",
            form: {
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Please enter a title",
                            trigger: "blur",
                        },
                    ],
                    ward: [
                        {
                            required: true,
                            validator: validateWard,
                            trigger: ["blur", "change"],
                        },
                    ],
                    content: [
                        {
                            required: true,
                            message: "Please enter content",
                            trigger: "blur",
                        },
                    ],
                    publishDate: [
                        {
                            required: true,
                            // validator: validatePublishDate,
                            message: "Please pick a date",
                            trigger: ["blur", "change"],
                        },
                    ],
                    // endDate: [
                    // 	{
                    // 		required: true,
                    // 		validator: validateEndDate,
                    // 		message: "Please pick a date",
                    // 		trigger: ["blur", "change"],
                    // 	},
                    // ],
                },
                title: "",
                ward: {
                    isLoading: false,
                    value: [],
                    list: [],
                    error: "",
                },
                content: "",
                oldCoverImage: [],
                coverImage: {},
                coverImageError: "",
                oldMedia: [],
                media: {},
                mediaError: "",
                publishDate: new Date(),
                endDate: new Date(2038, 0, 1),
            },
        };
    },
    computed: {
        isCoverImageEmpty() {
            return Object.keys(this.form.coverImage).length == 0 && this.form.oldCoverImage.length == 0;
        },
        isMediaEmpty() {
            return Object.keys(this.form.media).length == 0 && this.form.oldMedia.length == 0;
        },
    },
    async mounted() {
        await this.fetchWard();
        this.form.title = this.message.title;
        this.form.ward.value = this.message.ward_list;
        this.form.content = this.message.content;
        this.form.oldCoverImage = this.message.cover_image_path
            ? [
                  {
                      name: this.message.cover_image_path,
                      url: this.message.cover_image_path,
                  },
              ]
            : [];
        this.imagePreview = this.message.cover_image_path;
        this.form.oldMedia = this.message.media_path
            ? [
                  {
                      name: this.message.media_path,
                      url: this.message.media_path,
                  },
              ]
            : [];
        this.form.publishDate = this.message.published_date;
        this.form.endDate = this.message.end_date;
    },
    methods: {
        async editMessage() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                const message = {
                    id: this.message.id,
                    title: this.form.title,
                    wardList: this.form.ward.value,
                    content: this.form.content,
                    coverImage: this.isCoverImageEmpty ? "null" : this.form.coverImage.raw,
                    media: this.isMediaEmpty ? "null" : this.form.media.raw,
                    publishDate: moment(this.form.publishDate).format("YYYY-MM-DD HH:mm"),
                    endDate: moment(this.form.endDate).format("YYYY-MM-DD HH:mm"),
                };
                const result = await editMessage(message);
                if (result) {
                    return message.title;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async validateForm() {
            try {
                const imageResult = this.form.oldCoverImage.length == 0 ? this.validateImage(this.form.coverImage) : true;
                const mediaResult = this.form.media.length == 0 ? this.validateMedia(this.form.media) : true;
                const result = await this.$refs.editNOMessageForm.validate();
                return imageResult && mediaResult && result;
            } catch (error) {
                return false;
            }
        },
        async fetchWard() {
            try {
                this.form.ward.isLoading = true;
                const data = await fetchWard();
                if (data) {
                    this.form.ward.list = data.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    });
                }
            } finally {
                this.form.ward.isLoading = false;
            }
        },
        handleImageChange(image) {
            if (this.validateImage(image)) {
                this.form.coverImage = image;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(image.raw);
            } else {
                this.$refs.uploadCoverImage.clearFiles();
            }
        },
        handleImageRemove() {
            this.$refs.uploadCoverImage.clearFiles();
            this.imagePreview = "";
            this.form.oldCoverImage = [];
            this.form.coverImage = {};
            this.validateImage(this.form.coverImage);
        },
        validateImage(image) {
            if (Object.keys(image).length == 0) {
                return true;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;

            if (!isImage) {
                this.form.coverImageError = "Image can only be pdf format";
                return false;
            }
            if (!isLt10M) {
                this.form.coverImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.coverImageError = "";
            return true;
        },
        handleMediaChange(media) {
            if (this.validateMedia(media)) {
                this.form.media = media;
            } else {
                this.$refs.uploadMedia.clearFiles();
            }
        },
        handleMediaRemove() {
            this.$refs.uploadMedia.clearFiles();
            this.form.oldMedia = [];
            this.form.media = {};
            this.validateMedia(this.form.media);
        },
        handleMediaPreview(file) {
            if (file.url) {
                window.open(file.url, "_blank");
            }
        },
        validateMedia(media) {
            if (Object.keys(media).length == 0) {
                return true;
            }
            const isMedia =
                media.raw.type === "video/mp4" ||
                media.raw.type === "video/ogg" ||
                media.raw.type === "video/webm" ||
                media.raw.type === "audio/mp3" ||
                media.raw.type === "audio/mpeg" ||
                media.raw.type === "audio/m4a" ||
                media.raw.type === "audio/x-m4a" ||
                media.raw.type === "audio/ogg";
            const isLt10M = media.size / 1024 / 1024 < 100;

            if (!isMedia) {
                this.form.mediaError = "Media can only be mp3/mpeg/m4a/x-m4a /mp4/ogg/webm format";
                return false;
            }
            if (!isLt10M) {
                this.form.mediaError = "Media cannot exceed 100MB";
                return false;
            }
            this.form.mediaError = "";
            return true;
        },
        resetForm() {
            this.$refs.editNOMessageForm.resetFields();
            this.$refs.uploadCoverImage.clearFiles();
            this.$refs.uploadMedia.clearFiles();

            this.form.content = "";
            this.form.oldCoverImage = [];
            this.form.coverImage = {};
            this.form.coverImageError = "";
            this.form.oldMedia = [];
            this.form.media = {};
            this.form.mediaError = "";
            this.form.publishDate = new Date();
            this.form.endDate = new Date(2038, 0, 1);
        },
        handleUpdateContent(value) {
            this.form.content = value;
        },
    },
};
</script>
