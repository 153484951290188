import { render } from "./CanteenMenu.vue?vue&type=template&id=10dcda5f"
import script from "./CanteenMenu.vue?vue&type=script&lang=js"
export * from "./CanteenMenu.vue?vue&type=script&lang=js"

import "./CanteenMenu.vue?vue&type=style&index=0&id=10dcda5f&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "10dcda5f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('10dcda5f', script)) {
    api.reload('10dcda5f', script)
  }
  
  module.hot.accept("./CanteenMenu.vue?vue&type=template&id=10dcda5f", () => {
    api.rerender('10dcda5f', render)
  })

}

script.__file = "src/views/CanteenMenu.vue"

export default script