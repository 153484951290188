import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/post/training?${wardId ? `wardId=${wardId}&` : ``}pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSingleData = async postId => {
    try {
        const result = await axios.get(`/admin/post/training/${postId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchCategory = async () => {
    try {
        const result = await axios.get(`/admin/post/training/category`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async post => {
    try {
        const formData = new FormData();
        formData.append("title", post.title);
        if (post.file) {
            formData.append("file", post.file);
        }
        formData.append("publishDate", post.publishDate);
        formData.append("endDate", post.endDate);
        for (let i = 0; i < post.categoryList.length; i++) {
            formData.append("categoryList[]", post.categoryList[i]);
        }
        formData.append("remarks", post.remarks);
        if (post.coverImage) {
            formData.append("coverImage", post.coverImage);
        }
        const result = await axios.post(`/admin/post/training`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async post => {
    try {
        const formData = new FormData();
        formData.append("title", post.title);
        if (post.file) {
            formData.append("file", post.file);
        }
        formData.append("publishDate", post.publishDate);
        formData.append("endDate", post.endDate);
        for (let i = 0; i < post.categoryList.length; i++) {
            formData.append("categoryList[]", post.categoryList[i]);
        }
        formData.append("remarks", post.remarks);
        if (post.coverImage) {
            formData.append("coverImage", post.coverImage);
        }
        const result = await axios.put(`/admin/post/training/${post.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async postList => {
    const params = {
        list: postList,
    };
    try {
        const result = await axios.post(`/admin/post/training/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const sendNotification = async notification => {
    let params = {
        title: notification.title,
        targetId: notification.post.id,
        body: notification.content,
        type: notification.type,
    };
    if (notification.wardList.length > 0) {
        params.wardList = notification.wardList;
    } else if (notification.userList.length > 0) {
        params.userList = notification.userList;
    } else {
        return false;
    }
    try {
        const result = await axios.post(`/admin/general/notification`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
