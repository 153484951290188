import { render } from "./ChangePasswordDialog.vue?vue&type=template&id=75d4dcd6"
import script from "./ChangePasswordDialog.vue?vue&type=script&lang=js"
export * from "./ChangePasswordDialog.vue?vue&type=script&lang=js"

import "./ChangePasswordDialog.vue?vue&type=style&index=0&id=75d4dcd6&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "75d4dcd6"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('75d4dcd6', script)) {
    api.reload('75d4dcd6', script)
  }
  
  module.hot.accept("./ChangePasswordDialog.vue?vue&type=template&id=75d4dcd6", () => {
    api.rerender('75d4dcd6', render)
  })

}

script.__file = "src/components/header/ChangePasswordDialog.vue"

export default script