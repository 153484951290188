import axios from "axios";

export const imageUpload = async image => {
    try {
        const formData = new FormData();
        formData.append("file", image);
        const result = await axios.post(`/admin/general/upload/image`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};
