export const validateStaffNumber = (rule, staffNumber, callback) => {
    if (staffNumber == "") {
        callback(new Error("Please enter staff number"));
    } else if (!staffNumber.match(/[0-9]+$/)) {
        callback(new Error("Please enter in number format"));
    } else {
        callback();
    }
};
export const validateRank = (rule, rank, callback) => {
    if (rank.value && rank.value.length < 1) {
        callback(new Error("Please select a rank"));
    } else {
        callback();
    }
};
export const validateLevel = (rule, level, callback) => {
    if (!level.value) {
        callback(new Error("Please select a level"));
    } else {
        callback();
    }
};
export const validateWards = (rule, wards, callback) => {
    if (wards.value.length == 0) {
        callback(new Error("Please select dept"));
    } else {
        callback();
    }
};
export const validateWard = (rule, ward, callback) => {
    if (!ward.value) {
        callback(new Error("Please select a dept"));
    } else {
        callback();
    }
};
