import { render } from "./EditPolicyForm.vue?vue&type=template&id=d8504328"
import script from "./EditPolicyForm.vue?vue&type=script&lang=js"
export * from "./EditPolicyForm.vue?vue&type=script&lang=js"

import "./EditPolicyForm.vue?vue&type=style&index=0&id=d8504328&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d8504328"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d8504328', script)) {
    api.reload('d8504328', script)
  }
  
  module.hot.accept("./EditPolicyForm.vue?vue&type=template&id=d8504328", () => {
    api.rerender('d8504328', render)
  })

}

script.__file = "src/components/settings/EditPolicyForm.vue"

export default script