import { render } from "./ViewContainer.vue?vue&type=template&id=69f0515c"
import script from "./ViewContainer.vue?vue&type=script&lang=js"
export * from "./ViewContainer.vue?vue&type=script&lang=js"

import "./ViewContainer.vue?vue&type=style&index=0&id=69f0515c&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "69f0515c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('69f0515c', script)) {
    api.reload('69f0515c', script)
  }
  
  module.hot.accept("./ViewContainer.vue?vue&type=template&id=69f0515c", () => {
    api.rerender('69f0515c', render)
  })

}

script.__file = "src/components/ViewContainer.vue"

export default script