import { render } from "./AnnualLeave.vue?vue&type=template&id=6f28797c"
import script from "./AnnualLeave.vue?vue&type=script&lang=js"
export * from "./AnnualLeave.vue?vue&type=script&lang=js"

import "./AnnualLeave.vue?vue&type=style&index=0&id=6f28797c&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6f28797c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6f28797c', script)) {
    api.reload('6f28797c', script)
  }
  
  module.hot.accept("./AnnualLeave.vue?vue&type=template&id=6f28797c", () => {
    api.rerender('6f28797c', render)
  })

}

script.__file = "src/components/annual-leave/AnnualLeave.vue"

export default script