<template>
    <div id="knowledge">
        <ViewContainer :title="`Knowledge Sharing`" :numberOfItems="knowledgeTable.numberOfItems ? knowledgeTable.numberOfItems : 0" :isLoading="isLoading" :buttons="containerHeaderButtons">
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="knowledgeTable" />
            </template>
        </ViewContainer>
        <Drawer ref="addKnowledgeDrawer" :drawer="addKnowledgeDrawer" @update:isVisible="addKnowledgeDrawer.isVisible = $event">
            <template v-slot:content>
                <AddPostForm ref="addKnowledgeForm" :isLoading="addKnowledgeDrawer.isLoading" />
            </template>
        </Drawer>
        <Drawer ref="editKnowledgeDrawer" :drawer="editKnowledgeDrawer" @update:isVisible="editKnowledgeDrawer.isVisible = $event">
            <template v-slot:content>
                <EditPostForm ref="editKnowledgeForm" :post="editKnowledgeDrawer.post" :isLoading="editKnowledgeDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchKnowledges, fetchSingleData as fetchKnowledge, deleteData as deleteKnowledges } from "@/modules/knowledge";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import Drawer from "@/components/Drawer";
import AddPostForm from "@/components/knowledge/AddPostForm";
import EditPostForm from "@/components/knowledge/EditPostForm";
export default {
    name: "Knowledge",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        Drawer,
        AddPostForm,
        EditPostForm,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, category, author, remarks",
                value: "",
                callback: () => this.fetchKnowledges(),
            },
            filterPopover: {
                isVisible: false,
                title: true,
                content: false,
                applyFilter: filter => this.applyFilter(filter),
                cancelFilter: () => this.cancelFilter(),
            },
            containerHeaderButtons: [
                {
                    name: "Manage Category",
                    callback: () => this.handleCategory(),
                },
            ],
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleAddKnowledgeDrawer(),
                },
            ],
            knowledgeTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getFile(row, column, e),
                classChecker: ({ row, column, rowIndex, columnIndex }) => this.classChecker({ row, column }),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                    },
                    {
                        label: "category",
                        sortable: false,
                        prop: "category_name",
                        minWidth: "112",
                    },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "target_ward",
                        width: "132",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "95",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        minWidth: "136",
                    },
                    // {
                    //     label: "end date",
                    //     sortable: true,
                    //     prop: "end_date",
                    //     minWidth: "136",
                    // },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handleEdit: (index, row) => this.handleEdit(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addKnowledgeDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Knowledge Sharing",
                save: () => this.addPost(),
                close: done => this.closeAddKnowledgeDrawer(done),
            },
            editKnowledgeDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Edit Knowledge Sharing",
                save: () => this.editPost(),
                close: done => this.closeEditKnowledgeDrawer(done),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
        };
    },
    computed: {
        isVideoEmpty() {
            return Object.keys(this.form.video).length == 0;
        },
        isCoverImageEmpty() {
            return Object.keys(this.form.coverImage).length == 0;
        },
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchKnowledges();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
        filterPopover: {
            handler() {
                this.searchInput.placeholder = `Search by ${this.filterPopover.title ? "title, " : ""}${this.filterPopover.content ? "content, " : ""}category, author, remarks`;
            },
            deep: true,
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchKnowledges() {
            this.isLoading = true;
            let pageSize = this.knowledgeTable.pagination.pageSize;
            let pageIndex = this.knowledgeTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            let filter = {
                title: this.filterPopover.title,
                content: this.filterPopover.content,
            };
            try {
                const result = await fetchKnowledges(this.ward.value, pageSize, pageIndex, searchString, filter);
                if (result) {
                    this.knowledgeTable.data = result.data;
                    this.knowledgeTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addPost() {
            try {
                this.addKnowledgeDrawer.isLoading = true;
                const result = await this.$refs.addKnowledgeForm.addPost();
                if (result) {
                    this.addKnowledgeDrawer.isVisible = false;
                    this.$notify({
                        title: "Knowledge uploaded successfully",
                        message: result + " was uploaded successfully",
                        type: "success",
                    });
                    this.fetchKnowledges();
                }
            } finally {
                this.addKnowledgeDrawer.isLoading = false;
            }
        },
        async editPost() {
            try {
                this.editKnowledgeDrawer.isLoading = true;
                const result = await this.$refs.editKnowledgeForm.editPost();
                if (result) {
                    this.editKnowledgeDrawer.isVisible = false;
                    this.$notify({
                        title: "Knowledge updated successfully",
                        message: result + " was updated successfully",
                        type: "success",
                    });
                    this.fetchKnowledges();
                }
            } finally {
                this.editKnowledgeDrawer.isLoading = false;
            }
        },
        async deleteKnowledges(action, postList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the knowledge(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteKnowledges(action, postList);
                    if (result) {
                        this.$notify({
                            title: "Knowledge(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchKnowledges();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },

        // container header
        handleCategory() {
            this.$router.push({
                path: "/knowledge/category",
            });
        },

        // header
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.knowledgeTable.pagination.currentPage = 1;
            this.fetchKnowledges();
        },
        handleAddKnowledgeDrawer() {
            this.addKnowledgeDrawer.isVisible = true;
        },
        closeAddKnowledgeDrawer() {
            this.$refs.addKnowledgeForm.resetForm();
            this.addKnowledgeDrawer.isVisible = false;
        },
        handleEditKnowledgeDrawer() {
            this.editKnowledgeDrawer.isVisible = true;
        },
        closeEditKnowledgeDrawer() {
            this.$refs.editKnowledgeForm.resetForm();
            this.editKnowledgeDrawer.isVisible = false;
        },
        handleAction() {
            if (this.knowledgeTable.multipleSelection.length > 0) {
                this.deleteKnowledges(this.knowledgeTable.multipleSelection);
            }
        },
        applyFilter(filter) {
            this.filterPopover.title = JSON.parse(JSON.stringify(filter.title));
            this.filterPopover.content = JSON.parse(JSON.stringify(filter.content));
            this.filterPopover.isVisible = false;
        },
        cancelFilter() {
            this.filterPopover.isVisible = false;
        },

        // table
        handleDelete(index, row) {
            this.deleteKnowledges([row.id]);
        },
        async handleEdit(index, row) {
            try {
                this.editKnowledgeDrawer.isLoading = true;
                const result = await fetchKnowledge(row.id);
                if (result) {
                    this.editKnowledgeDrawer.post = result;
                    this.editKnowledgeDrawer.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editKnowledgeDrawer.isLoading = false;
            }
        },
        handleSelectionChange(rows) {
            this.knowledgeTable.multipleSelection = rows.map(knowledge => knowledge.id);
        },
        getFile(row, column) {
            if (column.property == "title") {
                if (row.video_path) {
                    window.open(row.video_path, "_blank");
                } else if (row.cover_image_path) {
                    window.open(row.cover_image_path, "_blank");
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        classChecker({ row, column }) {
            if (column.property == "title") {
                const videoPath = row.video_path ? row.video_path : null;
                const imagePath = row.cover_image_path ? row.cover_image_path : null;

                if (videoPath || imagePath) {
                    return "link-type";
                } else {
                    return "";
                }
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.knowledgeTable.pagination.pageSize = pageSize;
            this.knowledgeTable.pagination.currentPage = 1;
            this.fetchKnowledges();
        },
        handleCurrentChange(currentPage) {
            this.knowledgeTable.pagination.currentPage = currentPage;
            this.fetchKnowledges();
        },
    },
};
</script>

<style lang="scss">
#knowledge {
    height: 100%;
    .category-error {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}
</style>
