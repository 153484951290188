import { render } from "./AddWardGroupForm.vue?vue&type=template&id=79357d5c"
import script from "./AddWardGroupForm.vue?vue&type=script&lang=js"
export * from "./AddWardGroupForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "79357d5c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('79357d5c', script)) {
    api.reload('79357d5c', script)
  }
  
  module.hot.accept("./AddWardGroupForm.vue?vue&type=template&id=79357d5c", () => {
    api.rerender('79357d5c', render)
  })

}

script.__file = "src/components/settings/AddWardGroupForm.vue"

export default script