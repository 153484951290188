<template>
    <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

* {
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}
#app {
    height: 100%;
}
.el-notification__content {
    text-align: left !important;
}
.is-message-box {
    .cancel-btn {
        float: right;
        margin-left: 12px;
        background-color: #ebecf0;
        border-color: #ebecf0;
        color: #b3bac5;
        &:hover {
            background-color: #ebecf0;
            border-color: #ebecf0;
            color: #b3bac5;
        }
    }
}
.el-input--small {
    .el-input__inner {
        line-height: 32px;
    }
}
.el-input--mini {
    .el-input__inner {
        line-height: 28px;
    }
}
.el-date-picker {
    .el-date-picker__time-header {
        padding: 3px;
    }
    .el-date-picker__header {
        margin: 0;
        padding-bottom: 0;
    }
    .el-picker-panel__content {
        margin: 0 15px;
        .el-date-table {
            th {
                padding: 0;
            }
            td div {
                height: 22px;
            }
        }
        .el-year-table {
            td {
                padding: 15px 3px;
            }
        }
    }
}
</style>
