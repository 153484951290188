<template>
    <div id="canteen-menu">
        <ViewContainer :title="`Canteen Menu`" :numberOfItems="canteenMenuTable.numberOfItems ? canteenMenuTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="canteenMenuTable" />
            </template>
        </ViewContainer>
    </div>
    <UploadDialog v-if="uploadDialog.isVisible" :dialog="uploadDialog" @update:isVisible="uploadDialog.isVisible = $event" :hasEndDate="true" />
</template>

<script>
import { fetchData as fetchMenus, postData as addMenu, deleteData as deleteMenus } from "@/modules/canteen-menu.js";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import UploadDialog from "@/components/UploadDialog";
import moment from "moment";

export default {
    name: "CanteenMenu",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        UploadDialog,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, author, remarks",
                value: "",
                callback: () => this.searchMenus(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleUploadDialog(),
                },
            ],
            canteenMenuTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getPdf(row, column, e),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "95",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        minWidth: "136",
                    },
                    {
                        label: "end date",
                        sortable: true,
                        prop: "end_date",
                        minWidth: "136",
                    },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            uploadDialog: {
                isVisible: false,
                title: "Canteen menu",
                endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
                upload: form => this.addMenu(form),
            },
        };
    },
    mounted() {
        this.fetchMenus();
    },
    methods: {
        async fetchMenus() {
            this.isLoading = true;
            let pageSize = this.canteenMenuTable.pagination.pageSize;
            let pageIndex = this.canteenMenuTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchMenus(pageSize, pageIndex, searchString);
                if (result) {
                    this.canteenMenuTable.data = result.data;
                    this.canteenMenuTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addMenu(form) {
            const menu = {
                title: form.title,
                file: form.file.raw,
                publishDate: moment(form.publishDate).format("YYYY-MM-DD HH:mm"),
                endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm"),
                remarks: form.remarks,
            };
            const result = await addMenu(menu);
            if (result) {
                this.uploadDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "Canteen menu uploaded successfully",
                    message: name ? name + " was uploaded successfully" : "",
                    type: "success",
                });
                this.fetchMenus();
            }
        },
        async deleteMenus(menuList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the canteen menu(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteMenus(menuList);
                    if (result) {
                        this.$notify({
                            title: "Canteen menu(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchMenus();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // header
        handleUploadDialog() {
            this.uploadDialog.isVisible = true;
        },
        handleAction() {
            if (this.canteenMenuTable.multipleSelection.length > 0) {
                this.deleteMenus(this.canteenMenuTable.multipleSelection);
            }
        },
        // table
        searchMenus() {
            this.canteenMenuTable.pagination.currentPage = 1;
            this.fetchMenus();
        },
        handleDelete(index, row) {
            this.deleteMenus([row.id]);
        },
        handleSelectionChange(rows) {
            this.canteenMenuTable.multipleSelection = rows.map(post => post.id);
        },
        getPdf(row, column) {
            if (column.property == "title" && row.file_path) {
                window.open(row.file_path, "_blank");
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.canteenMenuTable.pagination.pageSize = pageSize;
            this.fetchMenus();
        },
        handleCurrentChange(currentPage) {
            this.canteenMenuTable.pagination.currentPage = currentPage;
            this.fetchMenus();
        },
    },
};
</script>

<style lang="scss">
#canteen-menu {
    height: 100%;
    .action-table {
        margin-top: 8px;
    }
}
</style>
