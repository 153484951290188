<template>
    <span class="polling-question">{{ result.polling_question }}</span>
    <el-row>
        <el-col :span="24">
            <el-table
                :show-header="false"
                ref="resultTable"
                :data="result.data"
                class="result-table"
                v-loading="isLoading"
                size="small"
                cell-class-name="cell-class"
                :fit="false"
                :row-class-name="rowClassName || ''"
            >
                <el-table-column width="48" align="right" class-name="icon-col">
                    <template #default="scope">{{ scope.row.alphabet }}.</template>
                </el-table-column>
                <el-table-column align="left" class-name="info-col" width="480">
                    <template #default="scope">
                        <span class="option">
                            {{ scope.row.option }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column align="right" class-name="date" width="100">
                    <template #default="scope">
                        <span class="vote">{{ scope.row.percentage }}% ({{ scope.row.vote }})</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "ResultTab",
    props: ["result"],
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        rowClassName({ row }) {
            if (row.option_id == this.result.highestVote.option_id) {
                return "highlight";
            } else {
                return "";
            }
        },
    },
};
</script>

<style lang="scss">
.polling-question {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}
.result-table {
    &:before {
        height: 0;
    }
    .el-avatar {
        background: transparent;
    }
    .alphabet {
        color: #171725;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
    }
    .option {
        color: #44444f;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .vote {
        color: #44444f;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .highlight {
        background: #f0f9eb;
    }
}
</style>
