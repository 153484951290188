import { render } from "./NestedDraggable.vue?vue&type=template&id=374a18b5"
import script from "./NestedDraggable.vue?vue&type=script&lang=js"
export * from "./NestedDraggable.vue?vue&type=script&lang=js"

import "./NestedDraggable.vue?vue&type=style&index=0&id=374a18b5&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "374a18b5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('374a18b5', script)) {
    api.reload('374a18b5', script)
  }
  
  module.hot.accept("./NestedDraggable.vue?vue&type=template&id=374a18b5", () => {
    api.rerender('374a18b5', render)
  })

}

script.__file = "src/components/category/NestedDraggable.vue"

export default script