<template>
    <el-dialog custom-class="views-dialog" v-model="isVisible" width="640px" destroy-on-close center :show-close="false">
        <template #title>
            <el-row>
                <el-col :span="2" class="view-icon-container">
                    <i class="el-icon-view"></i>
                </el-col>
                <el-col :span="18">
                    <span class="title">"{{ dialog.msgTitle }}" - Views</span>
                </el-col>
                <el-col :span="4" class="printer-icon-container">
                    <span @click="handlePrint">
                        <i class="el-icon-printer"></i>
                        Print
                    </span>
                </el-col>
            </el-row>
            <el-row class="number-of-user">
                <el-col :span="18" :offset="2">
                    <span>{{ numberOfUsers }} Users viewed</span>
                </el-col>
            </el-row>
        </template>
        <el-table :show-header="false" ref="viewsTable" :data="users" height="350" class="views-table" size="small">
            <el-table-column prop="icon" width="84" align="center" class-name="icon-col">
                <template #default="scope">
                    <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                        <template #placeholder>
                            <el-image :src="placeholder" fit="contain"></el-image>
                        </template>
                        <template #error>
                            <el-image :src="placeholder" fit="contain"></el-image>
                        </template>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column align="left" min-width="102" show-overflow-tooltip>
                <template #default="scope">
                    <span class="name">{{ scope.row.full_name }}</span>
                    <div>
                        <span class="ward">
                            {{ scope.row.rank }}
                            <span v-if="scope.row.wardList.length > 0">{{ parseWardList(scope.row.wardList) }}</span>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" align="right" class-name="date"></el-table-column>
        </el-table>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" class="ok-btn" @click="isVisible = false" :disabled="isLoading">
                    OK
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "@/components/useInput";
import placeholder from "@/assets/placeholder.png";
import { fetchViews } from "@/modules/no-message";
export default {
    name: "ViewsDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            placeholder: placeholder,
            numberOfUsers: 0,
            users: [],
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    mounted() {
        this.fetchViews();
    },
    methods: {
        async fetchViews() {
            let loading;
            let id = this.dialog.messageId;
            try {
                loading = this.$loading({ target: ".views-dialog" });
                const result = await fetchViews(id);
                if (result) {
                    this.numberOfUsers = result.length || 0;
                    this.users = result;
                }
            } finally {
                loading.close();
            }
        },
        parseWardList(wardList) {
            let outputString = "";
            for (let i = 0; i < wardList.length; i++) {
                if (i == 0) {
                    outputString += "(";
                }
                outputString += `${wardList[i]}`;
                if (i !== wardList.length - 1) {
                    outputString += ", ";
                } else {
                    outputString += ")";
                }
            }
            return outputString;
        },
        handlePrint() {
            let oldframe = document.getElementById("print_frame");
            if (oldframe) {
                oldframe.parentNode.removeChild(oldframe);
            }

            let iframe = document.createElement("iframe");
            iframe.setAttribute("id", "print_frame");
            iframe.setAttribute("name", "print_frame");
            iframe.style = "position: fixed; top: 0; opacity: 0; z-index: -1;";
            document.body.appendChild(iframe);

            // style
            let style = document.createElement("style");
            style.innerHTML =
                "td, th { text-align: left; padding: 8px; }" +
                "td:last-child, th:last-child { text-align: right; }" +
                "table, th, td { border: 1px solid #000; }" +
                "table { width: 100% !important; border-collapse: collapse; }";

            // HTML layout
            // let tableBody = document.querySelector(
            // 	"#no-message .views-table .el-table__body-wrapper tbody"
            // ).innerHTML;
            let tableBody = this.users
                .map(user => {
                    return "<tr><td>" + user.staff_no + "</td><td>" + user.full_name + "</td><td>" + `${user.rank} ${this.parseWardList(user.wardList)}` + "</td><td>" + user.created_at + "</td></tr>";
                })
                .join("");

            let printTitle = this.dialog.msgTitle;
            let printCount = this.numberOfUsers;

            let tableTitle = "<h1>Module: NO Message</h1>" + `<h2>Title: ${printTitle} - ${printCount} user(s) viewed</h2>`;
            let table = "<table>" + "<tr><th>STAFF NUMBER</th><th>STAFF NAME</th><th>STAFF RANK(DEPT)</th><th>DATE OF VIEW</th></tr>" + tableBody + "</table>";

            let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
            iframedoc.body.innerHTML = tableTitle + table;
            iframedoc.body.appendChild(style);

            window.frames["print_frame"].focus();
            window.frames["print_frame"].print();
        },
    },
};
</script>
