<template>
    <el-table :show-header="true" ref="feedbackTable" :data="feedback" class="feedback-table" v-loading="isLoading" size="mini" cell-class-name="cell-class">
        <el-table-column prop="icon" width="65" align="center" class-name="icon-col">
            <template #default="scope">
                <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                    <template #placeholder>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                    <template #error>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                </el-image>
            </template>
        </el-table-column>
        <el-table-column align="left" width="288" label="User">
            <template #default="scope">
                <span class="name">{{ scope.row.full_name }}</span>
                <div>
                    <span class="ward">
                        {{ scope.row.rank }}
                        <span v-if="scope.row.wardList.length > 0">{{ parseWardList(scope.row.wardList) }}</span>
                    </span>
                </div>
            </template>
        </el-table-column>
        <el-table-column align="left" label="Vote" v-if="!isSecret">
            <template #default="scope">
                <span>{{ scope.row.optionAlphabet }}. {{ scope.row.polling_option_name }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="date" align="right" label="Updated At">
            <template #default="scope">
                <div>
                    <span class="date">{{ scope.row.updated_at }}</span>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import placeholder from "@/assets/placeholder.png";

export default {
    name: "FeedbackTab",
    props: ["feedback", "isSecret"],
    data() {
        return {
            isLoading: false,
            placeholder: placeholder,
        };
    },
    methods: {
        parseWardList(wardList) {
            let outputString = "";
            for (let i = 0; i < wardList.length; i++) {
                if (i == 0) {
                    outputString += "(";
                }
                outputString += `${wardList[i]}`;
                if (i !== wardList.length - 1) {
                    outputString += ", ";
                } else {
                    outputString += ")";
                }
            }
            return outputString;
        },
    },
};
</script>

<style lang="scss">
.el-tabs {
    .feedback-table {
        margin-top: -8px;
        th,
        td {
            padding: 2px 0;
        }
        .name {
            color: #171725;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
        }
        .ward {
            font-size: 12px;
            white-space: break-spaces;
        }
        .date {
            color: #92929d;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
        }
        .icon-col {
            .cell {
                display: flex;
                align-content: center;
                justify-content: center;
                > .cover-image {
                    display: flex;
                    align-content: center;
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                }
            }
        }
    }
}
</style>
