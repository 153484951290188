<template>
    <div id="request-no-annual-leave">
        <ViewContainer
            title="Request NO Annual Leave Schedule"
            :buttons="table.scheduleId.value ? (table.isManage.value ? newScheduleButton.concat(headerButtonsDisabled) : newScheduleButton.concat(headerButtons)) : newScheduleButton"
            :isLoading="isLoading"
        >
            <template v-slot:header v-if="yearOptions.length > 0">
                <AnnualLeaveHeader title="Year" :yearOptions="yearOptions" :annualLeaveDotList="annualLeaveDotList" :selectedYear="selectedYear" :setSelectedYear="setSelectedYear" />
            </template>
            <template v-slot:content>
                <div v-if="table.scheduleId.value || isLoading" class="table-container">
                    <AnnualLeave ref="annualLeave" style="height: 100%;" :table="table" :selectedYear="selectedYear" :yearOptions="yearOptions" />
                    <el-footer class="edit-footer" v-if="table.isManage.value" style="height: auto;">
                        <el-button type="primary" class="check" size="small" @click="handleOnSave">
                            Save
                        </el-button>
                        <el-button class="cancel" size="small" @click="handleOnCancel">
                            Cancel
                        </el-button>
                    </el-footer>
                </div>
                <div v-else class="no-data-container">
                    <el-image :src="noDataImage" fit="contain"></el-image>
                    <div class="right-side">
                        <span class="title">Schedule not found...</span>
                        <span class="description">
                            We can't seem to find the schedule you're looking for.
                            <br />
                            Try creating a new schedule for next year on the top right "New" button or Click on the left menu for more navigation.
                        </span>
                    </div>
                </div>
            </template>
        </ViewContainer>
        <Drawer :drawer="settingsDrawer" @update:isVisible="settingsDrawer.isVisible = $event">
            <template v-slot:content>
                <NOSettings :drawer="settingsDrawer" :scheduleId="table.scheduleId.value" :year="table.selectedYear.value" />
            </template>
        </Drawer>
        <NewScheduleDialog v-if="newScheduleDialog.isVisible" :dialog="newScheduleDialog" @update:isVisible="newScheduleDialog.isVisible = $event" />
        <ModalEditDialog v-if="modalEditDialog.isVisible" :dialog="modalEditDialog" @update:isVisible="modalEditDialog.isVisible = $event" :ward="{ label: '' }" :selectedWard="noWardId" />
    </div>
</template>

<script>
import { useState } from "@/utils/use";
import { nextTick, onMounted, ref, watch, computed, reactive } from "vue";
import { ElNotification } from "element-plus";
import Sortable from "sortablejs";
import XLSX from "xlsx";
import XEUtils from "xe-utils";

import { fetchYear as fetchYearData } from "@/modules/general/set";
import {
    fetchTable,
    postLeave as postLeaveData,
    shiftUser as shiftAnnualLeaveData,
    fetchOptions as fetchOptionsData,
    fetchRanking as fetchRankingData,
    addNewSchedule,
    passUser as passToNextUser,
    nextRound as passToNextRound,
    fetchModal as fetchModalData,
    postModal as postModalData,
} from "@/modules/annual-leave/request-no-annual-leave";

import ViewContainer from "@/components/ViewContainer";
import Drawer from "@/components/Drawer";
import placeholder from "@/assets/no-data.png";

import AnnualLeave from "@/components/annual-leave/AnnualLeave";
import AnnualLeaveHeader from "@/components/annual-leave/AnnualLeaveHeader";
import NOSettings from "@/components/annual-leave/NOSettings";
import ModalEditDialog from "@/components/annual-leave/ModalEditDialog";
import NewScheduleDialog from "@/components/annual-leave/NewScheduleDialog";

export default {
    name: "RequestAnnualLeave",
    components: {
        ViewContainer,
        Drawer,
        AnnualLeave,
        AnnualLeaveHeader,
        NOSettings,
        NewScheduleDialog,
        ModalEditDialog,
    },
    setup() {
        const isLoading = ref(false);
        const isTableLoading = ref(false);
        const isManage = ref(false);
        const annualLeaveDotList = ref([]);
        const scheduleId = ref(null);
        const rows = ref([]);
        const columnHeader = ref([]);
        const leaveOptions = ref([]);
        const editable = ref([]);
        const annualLeave = ref({});
        const sortable = ref(null);
        const currPriority = ref(NaN);
        const round = ref(NaN);
        const noWardId = 1;

        // year dropdown list
        const yearOptions = ref([]);
        const [selectedYear, setSelectedYear] = useState();
        const yearLabel = computed(() => {
            if (yearOptions.value && yearOptions.value.length > 0) {
                const target = yearOptions.value.find(year => year === selectedYear.value);
                if (target) {
                    return target;
                }
            }
            return "";
        });

        const newScheduleButton = [
            {
                name: "New",
                icon: "el-icon-document",
                callback: () => {
                    newScheduleDialog.value.isVisible = true;
                },
            },
            {
                name: "Request Instruction Edit",
                icon: "el-icon-document",
                callback: () => fetchModal(),
            },
        ];
        const headerButtons = [
            {
                name: "Settings",
                icon: "uni-icon-setting",
                callback: () => {
                    fetchSettings();
                },
            },
            {
                name: "Manage",
                icon: "el-icon-notebook-2",
                callback: () => {
                    if (rows.value.length > 0) {
                        annualLeave.value.clearScroll();
                        isManage.value = true;
                        sortable.value.option("disabled", false);
                    }
                },
            },
            {
                name: "Export",
                icon: "el-icon-download",
                callback: () => exportExcel(),
            },
        ];
        const headerButtonsDisabled = [
            {
                name: "Settings",
                icon: "uni-icon-setting",
                callback: () => {
                    fetchSettings();
                },
            },
            {
                name: "Manage",
                icon: "el-icon-notebook-2",
                disabled: true,
            },
            {
                name: "Export",
                icon: "el-icon-download",
                callback: () => exportExcel(),
            },
        ];
        const noDataImage = ref(placeholder);

        const modalEditDialog = ref({
            isVisible: false,
            isLoading: false,
            title: "Request NO Annual Leave - Request Instruction Edit",
            modal: "",
            upload: (selectedWard, form) => postModal(selectedWard, form),
        });

        let settingsDrawer = ref({
            isVisible: false,
            isLoading: false,
            title: `Request Annual Leave - Settings`,
            close: done => closeSettingsDrawer(done),
        });

        const newScheduleDialog = ref({
            isVisible: false,
            title: "New Annual Leave Schedule",
            handleSave: () => addSchedule(),
        });

        watch(selectedYear, async yearId => {
            if (yearId) {
                await fetchAnnualLeave(noWardId, selectedYear.value);
                if (scheduleId.value) {
                    await fetchRanking();
                }
            }
        });

        const fetchAnnualLeave = async (wardId, year) => {
            try {
                isLoading.value = true;
                isManage.value = false;
                const result = await fetchTable(wardId, year);
                if (result) {
                    scheduleId.value = result.request_annual_leave_id;
                    currPriority.value = result.current_priority;
                    round.value = result.round;
                    result.data.forEach(row => {
                        row.annual.forEach(annual => {
                            annual.label = {
                                label: annual.value,
                                value: annual.value,
                            };
                        });
                    });
                    rows.value = reactive(XEUtils.clone(result.data, true));
                    columnHeader.value = result.week_value_count;
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchYear = async () => {
            try {
                isLoading.value = true;
                const data = await fetchYearData(noWardId);
                if (data && data.length > 0) {
                    yearOptions.value = data;
                    setSelectedYear(data[0]);
                }
                return;
            } finally {
                isLoading.value = false;
            }
        };

        const fetchRanking = async () => {
            try {
                isLoading.value = true;
                const data = await fetchRankingData(scheduleId.value);
                if (data) {
                    annualLeaveDotList.value = data;
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchLeaveOptions = async () => {
            try {
                isLoading.value = true;
                const data = await fetchOptionsData();
                if (data) {
                    leaveOptions.value = data.map(leave => {
                        return {
                            label: leave.name,
                            value: leave.value,
                        };
                    });
                } else {
                    throw new Error();
                }
            } finally {
                isLoading.value = false;
            }
        };

        const postAnnualLeave = async (leave, scope) => {
            try {
                isTableLoading.value = true;
                let al = {
                    userId: scope.row.user_id,
                    weekId: columnHeader.value[scope.columnIndex - 1].request_week_id,
                    value: leave.value,
                };
                const result = await postLeaveData(scheduleId.value, al);
                if (result) {
                    ElNotification.success({
                        title: "Annual Leave Schedule saved successfully",
                    });
                }
            } finally {
                fetchAnnualLeave(noWardId, selectedYear.value);
                isTableLoading.value = false;
            }
        };

        const shiftAnnualLeave = async (scheduleId, userIdList) => {
            try {
                isTableLoading.value = true;
                const result = await shiftAnnualLeaveData(scheduleId, userIdList);
                if (result) {
                    ElNotification.success({
                        title: "Annual Leave Schedule ordering modified successfully",
                    });
                }
            } finally {
                fetchAnnualLeave(1, selectedYear.value);
                isTableLoading.value = false;
            }
        };

        const setSort = () => {
            const $table = annualLeave.value;
            sortable.value = Sortable.create($table.$el.querySelector(".vxe-table--body-wrapper >.vxe-table--body tbody"), {
                ghostClass: "sortable-ghost",
                animation: 150,
                disabled: true,
                filter: ".drag-disabled",
                setData: function(dataTransfer) {
                    dataTransfer.setData("Text", "");
                },
                onEnd: evt => {
                    const targetRow = rows.value.splice(evt.oldIndex, 1)[0];
                    rows.value.splice(evt.newIndex, 0, targetRow);
                },
                onMove: evt => {
                    return evt.related.className.indexOf("drag-disabled") === -1;
                },
            });
        };

        const handleOnSave = async () => {
            isManage.value = false;
            sortable.value.option("disabled", true);
            let userIdList = [];
            let tableRows = rows.value;
            for (let i = 0; i < tableRows.length; i++) {
                userIdList.push(tableRows[i].user_id);
            }
            shiftAnnualLeave(scheduleId.value, userIdList);
        };

        const handleOnCancel = async () => {
            isManage.value = false;
            sortable.value.option("disabled", true);
            fetchAnnualLeave(noWardId, selectedYear.value);
        };

        const exportExcel = async () => {
            let wb = XLSX.utils.book_new();
            let data = [];
            let rowData = {};
            rowData[" "] = "name";
            for (let i = 0; i < columnHeader.value.length; i++) {
                let startDate = columnHeader.value[i].week_start_date;
                let endDate = columnHeader.value[i].week_end_date;
                rowData[startDate] = endDate;
            }
            data.push(rowData);

            for (let i = 0; i < rows.value.length; i++) {
                let leaves = rows.value[i].annual;
                let rowData = {};
                rowData[" "] = rows.value[i].full_name;
                for (let j = 0; j < leaves.length; j++) {
                    let startDate = leaves[j].week_start_date;
                    let leave = leaves[j].value;
                    rowData[startDate] = leave;
                }
                data.push(rowData);
            }

            let header = [" "];
            let date = columnHeader.value.map(col => {
                return col.week_start_date;
            });
            header = header.concat(date);

            let ws = XLSX.utils.json_to_sheet(data, {
                header: header,
                wrap: true,
            });
            XLSX.utils.book_append_sheet(wb, ws, String(yearLabel.value));
            XLSX.writeFile(wb, "Request Annual Leave Schedule (" + String(yearLabel.value) + ").xlsx");
        };

        const fetchSettings = async () => {
            settingsDrawer.value.isVisible = true;
        };

        const closeSettingsDrawer = async done => {
            settingsDrawer.value.isVisible = false;
            done();
            await fetchAnnualLeave(noWardId, selectedYear.value);
        };

        const addSchedule = async () => {
            try {
                isLoading.value = true;
                const result = await addNewSchedule(noWardId);
                if (result) {
                    ElNotification.success({
                        title: "Request Annual Leave Schdedule added successfully",
                        message: "Request Annual Leave Schdedule was added successfully",
                    });
                    newScheduleDialog.value.isVisible = false;
                    await fetchYear();
                }
            } finally {
                isLoading.value = false;
            }
        };

        const passUser = async () => {
            try {
                isLoading.value = true;
                const result = await passToNextUser(scheduleId.value);
                if (result) {
                    ElNotification.success({
                        title: "Pass to next user successfully",
                        message: "Passed to next user successfully",
                    });
                    newScheduleDialog.value.isVisible = false;
                    await fetchAnnualLeave(noWardId, selectedYear.value);
                }
            } finally {
                isLoading.value = false;
            }
        };

        const nextRound = async () => {
            try {
                isLoading.value = true;
                const result = await passToNextRound(scheduleId.value);
                if (result) {
                    ElNotification.success({
                        title: "Pass to next round successfully",
                        message: round.value === 0 ? "The application of schedule has been started " : "The sequence of users is reversed",
                    });
                    newScheduleDialog.value.isVisible = false;
                    await fetchAnnualLeave(noWardId, selectedYear.value);
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchModal = async () => {
            try {
                modalEditDialog.value.isLoading = true;
                const result = await fetchModalData(noWardId);
                if (result) {
                    modalEditDialog.value.modal = result.modal_message;
                    modalEditDialog.value.isVisible = true;
                }
            } finally {
                modalEditDialog.value.isLoading = false;
            }
        };

        const postModal = async (selectedWard, form) => {
            try {
                modalEditDialog.value.isLoading = true;
                let msg = form.modal;
                const result = await postModalData(selectedWard, msg);
                if (result) {
                    ElNotification.success({
                        title: "Modal saved successfully",
                    });
                }
            } finally {
                modalEditDialog.value.isLoading = false;
            }
        };

        onMounted(async () => {
            await fetchLeaveOptions();
            await fetchYear();
        });

        let initTime;
        nextTick(() => {
            // 加载完成之后在绑定拖动事件
            initTime = setTimeout(() => {
                setSort();
            }, 500);
        });

        return {
            noDataImage,
            isLoading,
            annualLeaveDotList,
            newScheduleButton,
            headerButtons,
            headerButtonsDisabled,
            yearOptions,
            selectedYear,
            setSelectedYear,
            yearLabel,
            setSort,
            sortable,
            annualLeave,
            table: {
                isLoading: isTableLoading,
                isManage,
                scheduleId,
                currPriority,
                round,
                rows,
                columnHeader,
                selectedYear,
                leaveOptions,
                editable,
                postAnnualLeave,
                passUser,
                nextRound,
            },
            settingsDrawer,
            newScheduleDialog,
            handleOnSave,
            handleOnCancel,
            modalEditDialog,
            postModal,
            noWardId,
        };
    },
};
</script>

<style lang="scss">
#request-no-annual-leave {
    height: 100%;
    .page-header {
        height: initial;
        min-height: 40px;
        .page-details {
            span {
                white-space: nowrap;
            }
        }
        .page-btn-grp {
            flex-wrap: wrap;
            gap: 8px;
            .el-button--small {
                padding: 9px;
            }
            .el-button + .el-button {
                margin-left: 0;
            }
        }
    }
    .table-container {
        height: calc(100% - 27px - 40px);
    }
    .no-data-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 32px;
        .el-image {
            max-width: 480px;
            @media only screen and (max-width: 1024px) {
                max-width: 320px;
            }
        }
        .right-side {
            display: flex;
            flex-direction: column;
            max-width: 480px;
            .title {
                font-size: 40px;
                font-weight: bold;
                color: #44444f;
                margin-bottom: 16px;
            }
            .description {
                font-size: 13px;
                color: #aaa;
                line-height: 1.5;
                margin-bottom: 40px;
            }
            .back-btn {
                width: 120px;
                border-radius: 5px;
            }
        }
    }
    .edit-footer {
        padding: 8px 20px 0 8px;
        display: flex;
        align-items: center;
        background: #fff;
        .cancel,
        .check {
            width: 80px;
            display: flex;
            justify-content: center;
        }
        .cancel {
            margin-left: 24px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
    .uni-icon-setting {
        margin-right: 4px;
        &:before {
            color: #fff;
        }
    }
    .el-drawer .drawer-footer .cancel-btn {
        background: #5ec298;
        border-color: #5ec298;
        color: #fff;
    }
}
</style>
