import { render } from "./AddRankForm.vue?vue&type=template&id=a018d756"
import script from "./AddRankForm.vue?vue&type=script&lang=js"
export * from "./AddRankForm.vue?vue&type=script&lang=js"

import "./AddRankForm.vue?vue&type=style&index=0&id=a018d756&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a018d756"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a018d756', script)) {
    api.reload('a018d756', script)
  }
  
  module.hot.accept("./AddRankForm.vue?vue&type=template&id=a018d756", () => {
    api.rerender('a018d756', render)
  })

}

script.__file = "src/components/settings/AddRankForm.vue"

export default script