import { render } from "./ViewsTab.vue?vue&type=template&id=23f6ed18"
import script from "./ViewsTab.vue?vue&type=script&lang=js"
export * from "./ViewsTab.vue?vue&type=script&lang=js"

import "./ViewsTab.vue?vue&type=style&index=0&id=23f6ed18&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "23f6ed18"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('23f6ed18', script)) {
    api.reload('23f6ed18', script)
  }
  
  module.hot.accept("./ViewsTab.vue?vue&type=template&id=23f6ed18", () => {
    api.rerender('23f6ed18', render)
  })

}

script.__file = "src/components/ms-puns-message/tabs/ViewsTab.vue"

export default script