<template>
    <vxe-table
        border
        row-key
        row-id="user_id"
        auto-resize
        show-overflow
        show-header-overflow
        max-height="100%"
        ref="roster"
        class="roster-table"
        empty-text="No Data"
        size="mini"
        v-loading="table.isLoading.value"
        :data="table.rows.value"
        :edit-config="{ trigger: 'click', mode: 'cell', showIcon: false, activeMethod: props => checkPermission(props) }"
        :row-class-name="highlightCurrUser || ''"
    >
        <vxe-table-column width="150" fixed="left" v-if="!table.isManage.value">
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span class="month-and-year date-color">
                            {{ parseCornerHeaderYear() }}
                        </span>
                        <span class="month-and-year primary-font-color">
                            {{ parseCornerHeaderMonth() }}
                        </span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <RosterDot :class="[table.isManage.value ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" />
            </template>
        </vxe-table-column>
        <vxe-table-column width="150" v-else>
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span class="month-and-year date-color">
                            {{ parseCornerHeaderYear() }}
                        </span>
                        <span class="month-and-year primary-font-color">
                            {{ parseCornerHeaderMonth() }}
                        </span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <RosterDot :class="[table.isManage.value ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" />
            </template>
        </vxe-table-column>
        <vxe-table-column v-for="(item, index) in table.columnHeader.value" :key="index" width="84" align="center" :edit-render="{}">
            <template #header>
                <div class="header-details">
                    <span class="date date-color" :class="[parseHeaderDay(item) == 'Sun' ? 'holiday' : '']">
                        {{ parseHeaderDate(item) }}
                    </span>
                    <span class="day primary-font-color" :class="[parseHeaderDay(item) == 'Sun' ? 'holiday' : '']">
                        {{ parseHeaderDay(item) }}
                    </span>
                    <div class="duty date-color">
                        {{ parseHeaderDuty(item) }}
                    </div>
                </div>
            </template>
            <template #default="scope">
                <span>{{ parseDuty(scope, item) }}</span>
            </template>
            <template #edit="scope">
                <vxe-select placeholder="Select" @change="(value, $event) => table.postDuty(value, scope)" transfer>
                    <vxe-option
                        v-for="(options, index) in table.dutyOptions.value"
                        :key="index"
                        :value="options.value"
                        :label="options.label"
                        :class-name="parseSelectedDuty(scope, item, options.value)"
                    ></vxe-option>
                </vxe-select>
            </template>
        </vxe-table-column>
    </vxe-table>
</template>

<script>
import RosterDot from "@/components/roster/RosterDot";
import moment from "moment";
import { getUserInfo } from "@/utils/auth";

export default {
    name: "RosterMobile",
    components: {
        RosterDot,
    },
    props: {
        table: {
            required: true,
            type: Object,
        },
        weeksOptions: {
            type: Array,
        },
        selectedWeek: {
            type: Number,
        },
    },
    data() {
        return {
            userId: getUserInfo().id || null,
        };
    },
    computed: {
        weekLabel() {
            if (this.weeksOptions && this.weeksOptions.length > 0) {
                const target = this.weeksOptions.find(({ id }) => id === this.selectedWeek);
                if (target) {
                    return target.label;
                }
            }
            return "";
        },
    },
    methods: {
        parseCornerHeaderYear() {
            const dates = this.weekLabel.split(" - ");
            if (dates[0]) {
                const fromDate = new Date(dates[0].trim());
                const toDate = new Date(dates[1].trim());
                return `${fromDate.getFullYear()} - ${toDate.getFullYear()}`;
            } else {
                return "";
            }
        },
        parseCornerHeaderMonth() {
            const dates = this.weekLabel.split(" - ");
            if (dates[0]) {
                const fromDate = new Date(dates[0].trim());
                const toDate = new Date(dates[1].trim());
                const MONTHS = ["Jan", "Feb", "Mar", "Api", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                return `${MONTHS[fromDate.getMonth()]} - ${MONTHS[toDate.getMonth()]}`;
            } else {
                return "";
            }
        },
        parseHeaderDate(item) {
            const date = new Date(item.date);
            return `${date.getDate()}-${date.getMonth() + 1}`;
        },
        parseHeaderDay(item) {
            const date = new Date(item.date);
            const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            return `${day[date.getDay()]}`;
        },
        parseHeaderDuty(item) {
            const duties = item.data;
            const keys = Object.keys(duties);
            let result = "";
            for (let i = 0; i < keys.length; i++) {
                if (duties[keys[i]] > 0) {
                    result = result + keys[i] + duties[keys[i]] + " ";
                }
            }
            return result;
        },
        parseDuty(scope, item) {
            if (scope.row.duty && scope.row.duty.length > 0) {
                const duty = scope.row.duty.find(({ date }) => date == item.date);
                if (duty) {
                    return duty.value;
                }
            }
            return "";
        },
        parseSelectedDuty(scope, item, optionValue) {
            if (scope.row.duty && scope.row.duty.length > 0) {
                const duty = scope.row.duty.find(({ date }) => date == item.date);
                if (duty) {
                    return duty.option_id == optionValue ? "is--selected" : "";
                }
            }
        },
        checkPermission(scope) {
            const selectedUser = scope ? scope.row.user_id : null;
            var today = moment().format("YYYY-MM-DD");

			const currentRow = this.table.columnHeader.value[
				scope.columnIndex - 1
			];

			var requestDate = currentRow.date

			const dutyRows = scope ? scope.row.duty : [];

			console.log(dutyRows)

			const selectedDutyRow = dutyRows.find((row) => {
				return row.date == requestDate;
			});

			if (selectedDutyRow && selectedDutyRow.updated_by != this.userId && selectedDutyRow.value != "" ) {
				return false
			}

			const isBeforeCutOffDate = this.table.cutOffDate ? moment(today).isSameOrBefore(this.table.cutOffDate.value) : true;

            if (selectedUser == this.userId && isBeforeCutOffDate) {
                return true;
            } else {
                return false;
            }
        },
        highlightCurrUser({ row }) {
            if (row.user_id == this.userId) {
                return "highlight-row";
            } else {
                return "";
            }
        },
    },
};
</script>
