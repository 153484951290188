<template>
    <div id="duty-roster">
        <ViewContainer :title="`Duty Roster`" :numberOfItems="dutyRosterTable.numberOfItems ? dutyRosterTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="dutyRosterTable" />
            </template>
        </ViewContainer>
    </div>
    <UploadRosterDialog :wardId="ward.value" v-if="uploadDialog.isVisible" :dialog="uploadDialog" @update:isVisible="uploadDialog.isVisible = $event" />
    <EditRosterDialog :wardId="ward.value" v-if="editDialog.isVisible" :dialog="editDialog" @update:isVisible="editDialog.isVisible = $event" />
</template>

<script>
import { fetchData as fetchDutyRoster, fetchSingleData as fetchSingleDutyRoster, postData as addDutyRoster, putData as editDutyRoster, deleteData as deleteDutyRoster } from "@/modules/duty-roster.js";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import UploadRosterDialog from "@/components/roster/UploadRosterDialog";
import EditRosterDialog from "@/components/roster/EditRosterDialog";
export default {
    name: "DutyRoster",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        UploadRosterDialog,
        EditRosterDialog,
    },
    data() {
        return {
            isLoading: false,
            ward: {
                options: [],
                label: "",
                value: null,
            },
            searchInput: {
                placeholder: "Search by week, title, author, remarks",
                value: "",
                callback: () => this.searchDutyRoster(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleUploadDialog(),
                },
            ],
            dutyRosterTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getPdf(row, column, e),
                cols: [
                    {
                        label: "week",
                        sortable: true,
                        prop: "week",
                        width: "160",
                    },
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "96",
                    },
                    {
                        label: "create date",
                        sortable: true,
                        prop: "created_at",
                        minWidth: "124",
                    },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handleEdit: (index, row) => this.handleEdit(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            uploadDialog: {
                isVisible: false,
                title: `Duty Roster`,
                upload: form => this.addDutyRoster(form),
            },
            editDialog: {
                isVisible: false,
                isLoading: false,
                title: `Edit Duty Roster`,
                roster: {},
                upload: form => this.editDutyRoster(form),
            },
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchDutyRoster();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data && data.length > 0) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchDutyRoster() {
            this.isLoading = true;
            let wardId = this.ward.value;
            let pageSize = this.dutyRosterTable.pagination.pageSize;
            let pageIndex = this.dutyRosterTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                if (wardId && pageSize && pageIndex) {
                    const result = await fetchDutyRoster(wardId, pageSize, pageIndex, searchString);
                    if (result) {
                        this.dutyRosterTable.data = result.data;
                        this.dutyRosterTable.numberOfItems = result.count;
                    }
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addDutyRoster(form) {
            const roster = {
                title: form.title,
                file: form.file.raw,
                wardId: form.wardId,
                setId: form.set.value,
                remarks: form.remarks,
            };
            const result = await addDutyRoster(roster);
            if (result) {
                this.uploadDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "Duty roster uploaded successfully",
                    message: name ? name + " was uploaded successfully" : "",
                    type: "success",
                });
                this.fetchDutyRoster();
            }
        },
        async editDutyRoster(form) {
            const roster = {
                id: form.id,
                title: form.title,
                file: form.oldFile.length == 0 ? form.file.raw : null,
                setId: form.set.value,
                remarks: form.remarks,
            };
            const result = await editDutyRoster(roster);
            if (result) {
                this.editDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "Duty roster updated successfully",
                    message: name ? name + " was updated successfully" : "",
                    type: "success",
                });
                this.fetchDutyRoster();
            }
        },
        async deleteDutyRoster(rosterList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the duty roster(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteDutyRoster(rosterList);
                    if (result) {
                        this.$notify({
                            title: "Duty roster(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchDutyRoster();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // container header
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.dutyRosterTable.pagination.currentPage = 1;
            this.fetchDutyRoster();
        },
        // header
        handleUploadDialog() {
            this.uploadDialog.title = this.ward.label ? `Duty Roster (${this.ward.label})` : `Duty Roster`;
            this.uploadDialog.isVisible = true;
        },
        handleAction() {
            if (this.dutyRosterTable.multipleSelection.length > 0) {
                this.deleteDutyRoster(this.dutyRosterTable.multipleSelection);
            }
        },
        // table
        searchDutyRoster() {
            this.dutyRosterTable.pagination.currentPage = 1;
            this.fetchDutyRoster();
        },
        handleDelete(index, row) {
            this.deleteDutyRoster([row.id]);
        },
        async handleEdit(index, row) {
            try {
                this.editDialog.isLoading = true;
                const result = await fetchSingleDutyRoster(row.id);
                if (result) {
                    this.editDialog.roster = result;
                    this.editDialog.title = this.ward.label ? `Edit Duty Roster (${this.ward.label})` : `Edit Duty Roster`;
                    this.editDialog.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editDialog.isLoading = false;
            }
        },
        handleSelectionChange(rows) {
            this.dutyRosterTable.multipleSelection = rows.map(post => post.id);
        },
        getPdf(row, column) {
            if (column.property == "title" && row.file_path) {
                window.open(row.file_path, "_blank");
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.dutyRosterTable.pagination.pageSize = pageSize;
            this.fetchDutyRoster();
        },
        handleCurrentChange(currentPage) {
            this.dutyRosterTable.pagination.currentPage = currentPage;
            this.fetchDutyRoster();
        },
    },
};
</script>

<style lang="scss">
#duty-roster {
    height: 100%;
}
</style>
