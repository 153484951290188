<template>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    <div id="request-annual-leave-mobile">
        <el-row v-loading.fullscreen.lock="isLoading || table.isLoading.value" class="roster-header">
            <el-col :span="8" class="roster-header-details-ward">
                <el-dropdown trigger="click" @command="handleWardDropDownOnClick">
                    <span class="el-dropdown-link roster-header-title">
                        <span>{{ wardLabel }}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="item in wardsOptions" v-bind:key="item.id" :command="item.id">
                                {{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
            <el-col :span="8" class="roster-header-details-ward round-row">
                <span class="round-title">Round: {{ currentRound }}</span>
            </el-col>
            <el-col :span="8" v-if="yearOptions.length" class="roster-header-details-week">
                <el-dropdown trigger="click" @command="handleYearDropDownOnClick">
                    <span class="el-dropdown-link roster-header-title">
                        {{ yearLabel }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="item in yearOptions" v-bind:key="item" :command="item">
                                {{ item }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-col>
        </el-row>
        <el-row class="roster-header" v-if="table.cutOffDate.value">
            <el-col class="roster-header-details-ward">
                <span class="el-dropdown-link roster-header-title">Request AL Cut-off Date: {{ table.cutOffDate.value }}</span>
            </el-col>
        </el-row>
        <div v-if="table.scheduleId.value || isLoading" class="table-container">
            <AnnualLeave style="height: 100%;" ref="annualLeave" :table="table" :selectedYear="selectedYear" :yearOptions="yearOptions" @click:editCell="handleDisableEditCell" />
            <el-footer
                v-if="table.currentPriority.value == currentUserPriority"
                class="edit-footer"
                style="height: auto;
					display: flex;
    				justify-content: center;
    				margin-top: 8px;"
            >
                <el-button type="primary" class="check" size="small" @click="handleOnSave">
                    Save
                </el-button>
                <el-button class="cancel" size="small" @click="handleOnCancel">
                    Cancel
                </el-button>
            </el-footer>
        </div>
        <div v-else class="no-data-container">
            <el-image :src="noDataImage" fit="contain"></el-image>
            <span class="title">Schedule not found</span>
        </div>
        <el-dialog custom-class="modal-dialog" v-model="centerDialogVisible" width="90%" center>
            <i class="el-icon-warning-outline" />
            <h3>Request requirement</h3>
            <div class="dialog-content">
                <pre class="modal-message"> {{ modalMessage }} </pre>
                <div class="gradient-overlay"></div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="hideDialog">Confirm</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { useState } from "@/utils/use";
import { onMounted, ref, watch, computed, reactive } from "vue";
import { ElNotification } from "element-plus";
import XEUtils from "xe-utils";

import { fetchYear as fetchYearData } from "@/modules/general/mobileSet";
import { fetchAnnualWardData as fetchWardData } from "@/modules/roster/mobile/nurse-ward";
import {
    fetchTable,
    postLeave as postLeaveData,
    fetchOptions as fetchOptionsData,
    fetchRanking as fetchRankingData,
    addNewSchedule,
    fetchModalMessage as fetchModalMessageData,
} from "@/modules/annual-leave/mobile/request-annual-leave";

import placeholder from "@/assets/no-data.png";
import { getUserInfo } from "@/utils/auth";

import AnnualLeave from "@/views/Mobile/AnnualLeave.vue";
import AnnualLeaveHeader from "@/components/annual-leave/AnnualLeaveHeader";

export default {
    name: "RequestAnnualLeave",
    components: {
        AnnualLeave,
        AnnualLeaveHeader,
    },
    setup() {
        const centerDialogVisible = ref(false);
        const modalMessage = ref(null);
        const isLoading = ref(false);
        const isTableLoading = ref(false);
        const isManage = ref(false);
        const annualLeaveDotList = ref([]);
        const scheduleId = ref(null);
        const rows = ref([]);
        const columnHeader = ref([]);
        const leaveOptions = ref([]);
        const editable = ref([]);
        const annualLeave = ref(null);
        // const sortable = ref(null);
        const requestedAnnual = ref([]);
        const showTable = ref(true);
        const currentPriority = ref(1);
        const currentUserPriority = ref(1);
        const currentRound = ref(1);
        const cutOffDate = ref(null);

        // year dropdown list
        const yearOptions = ref([]);
        const [selectedYear, setSelectedYear] = useState();
        const yearLabel = computed(() => {
            if (yearOptions.value && yearOptions.value.length > 0) {
                const target = yearOptions.value.find(year => year === selectedYear.value);
                if (target) {
                    return target;
                }
            }
            return "";
        });

        // ward dropdown list
        const wardsOptions = ref([]);
        const [selectedWard, setSelectedWard] = useState();
        const wardLabel = computed(() => {
            if (wardsOptions.value && wardsOptions.value.length > 0) {
                const target = wardsOptions.value.find(({ id }) => id === selectedWard.value);
                if (target) {
                    return target.name;
                }
            }
            return "";
        });
        const newScheduleButton = [
            {
                name: "New",
                icon: "el-icon-document",
                callback: () => {
                    newScheduleDialog.value.isVisible = true;
                },
            },
        ];
        const noDataImage = ref(placeholder);

        let settingsDrawer = ref({
            isVisible: false,
            isLoading: false,
            title: `Request Annual Leave - Settings`,
            close: () => closeSettingsDrawer(),
        });

        const newScheduleDialog = ref({
            isVisible: false,
            title: "New Annual Leave Schedule",
            handleSave: () => addSchedule(),
        });

        watch(selectedWard, async wardId => {
            yearOptions.value = [];
            setSelectedYear(null);
            scheduleId.value = null;
            await fetchYear(wardId);
            fetchModalMessage();
        });

        watch(selectedYear, async yearId => {
            if (yearId) {
                await fetchAnnualLeave(selectedWard.value, selectedYear.value);
                if (scheduleId.value) {
                    await fetchRanking();
                }
            }
        });

        const fetchAnnualLeave = async (wardId, year) => {
            try {
                isTableLoading.value = true;
                isManage.value = false;
                requestedAnnual.value = [];
                const result = await fetchTable(wardId, year);
                if (result) {
                    currentPriority.value = result.current_priority;
                    currentRound.value = result.round;
                    scheduleId.value = result.request_annual_leave_id;
                    cutOffDate.value = result.cut_off_date;
                    result.data.forEach(row => {
                        row.annual.forEach(annual => {
                            annual.label = {
                                label: annual.value,
                                value: annual.value,
                            };
                        });
                    });
                    let additionRow = {
                        full_name: "",
                        color: "transparent",
                        duty: [],
                        user_id: null,
                    };
                    for (var i = 0; i < 10; i++) {
                        result.data.push(additionRow);
                    }
                    rows.value = reactive(XEUtils.clone(result.data, true));
                    columnHeader.value = result.week_value_count;

                    const currentUser = result.data.find(user => {
                        return user.user_id == (getUserInfo().id || "");
                    });

                    currentUserPriority.value = currentUser ? currentUser.al_priority : "";
                }
            } finally {
                isTableLoading.value = false;
                showTable.value = true;
            }
        };

        const fetchModalMessage = async () => {
            try {
                isLoading.value = true;
                const data = await fetchModalMessageData(selectedWard.value);
                if (data) {
                    modalMessage.value = data;
                    centerDialogVisible.value = true;
                }
            } finally {
                isLoading.value = false;
            }
        };

        const fetchWard = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchWardData();
                if (data && data.length > 0) {
                    wardsOptions.value = data;
                    setSelectedWard(data[0].id);
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchYear = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchYearData(selectedWard.value);
                if (data && data.length > 0) {
                    yearOptions.value = data;
                    setSelectedYear(data[0]);
                }
                return;
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchRanking = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchRankingData(scheduleId.value);
                if (data) {
                    annualLeaveDotList.value = data;
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchLeaveOptions = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchOptionsData();
                if (data) {
                    leaveOptions.value = data.map(leave => {
                        return {
                            label: leave.name,
                            value: leave.value,
                        };
                    });
                } else {
                    throw new Error();
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const addRequestAnnual = leave => {
            if (leave.value == "") {
                requestedAnnual.value = requestedAnnual.value.filter(annual => {
                    return !(annual.weekId == leave.weekId);
                });
            } else {
                const duplicate_annual = requestedAnnual.value.find(annual => {
                    return annual.weekId == leave.weekId;
                });

                if (duplicate_annual) {
                    return;
                }

                requestedAnnual.value.push(leave);
            }
        };

        const postAnnualLeave = async annualWeekIdList => {
            try {
                isTableLoading.value = true;

                const result = await postLeaveData(scheduleId.value, annualWeekIdList);
                if (result) {
                    ElNotification.success({
                        title: "Annual Leave Schedule saved successfully",
                    });
                }
            } finally {
                fetchAnnualLeave(selectedWard.value, selectedYear.value);
                isTableLoading.value = false;
            }
        };

        const handleWardDropDownOnClick = e => {
            setSelectedWard(e);
        };

        const handleYearDropDownOnClick = e => {
            setSelectedYear(e);
        };

        const handleDisableEditCell = scope => {
            const selectedIndex = scope ? scope.$index : null;
            const jsonData = JSON.parse(scope ? scope.column.label : null);
            const selectedWeekId = jsonData ? jsonData.request_week_id : null;
            const numberOfCells = editable.value.length;

            for (let i = 0; i < numberOfCells; i++) {
                const weekId = editable.value[i].weekId;
                const index = editable.value[i].index;

                if (weekId !== selectedWeekId || index !== selectedIndex) {
                    editable.value[i].canEdit = false;
                }
            }
        };

        const handleOnSave = async () => {
            isManage.value = false;
            // sortable.value.option("disabled", true);
            const annualWeekIdList = requestedAnnual.value.map(({ weekId }) => weekId);
            postAnnualLeave(annualWeekIdList);
        };

        const handleOnCancel = async () => {
            isManage.value = false;
            showTable.value = false;
            // sortable.value.option("disabled", true);
            fetchAnnualLeave(selectedWard.value, selectedYear.value);
        };

        const closeSettingsDrawer = async () => {
            settingsDrawer.value.isVisible = false;
            await fetchAnnualLeave(selectedWard.value, selectedYear.value);
        };

        const addSchedule = async () => {
            try {
                isLoading.value = true;
                const result = await addNewSchedule(selectedWard.value);
                if (result) {
                    ElNotification.success({
                        title: "Request Annual Leave Schdedule added successfully",
                        message: "Request Annual Leave Schdedule was added successfully",
                    });
                    newScheduleDialog.value.isVisible = true;
                    await fetchYear();
                    if (selectedYear.value) {
                        await fetchAnnualLeave(selectedWard.value, selectedYear.value);
                    }
                }
            } finally {
                isLoading.value = false;
            }
        };

        const hideDialog = () => {
            centerDialogVisible.value = false;
        };

        onMounted(async () => {
            try {
                await fetchLeaveOptions();
                await fetchWard();
            } catch (error) {
                console.log(error);
            }
        });

        return {
            noDataImage,
            isLoading,
            centerDialogVisible,
            hideDialog,
            modalMessage,
            annualLeaveDotList,
            newScheduleButton,
            yearOptions,
            selectedYear,
            setSelectedYear,
            wardsOptions,
            selectedWard,
            setSelectedWard,
            wardLabel,
            yearLabel,
            handleWardDropDownOnClick,
            handleYearDropDownOnClick,
            annualLeave,
            table: {
                isLoading: isTableLoading,
                cutOffDate,
                isManage,
                scheduleId,
                rows,
                columnHeader,
                selectedYear,
                leaveOptions,
                editable,
                requestedAnnual,
                addRequestAnnual,
                currentPriority,
            },
            handleDisableEditCell,
            settingsDrawer,
            newScheduleDialog,
            handleOnSave,
            handleOnCancel,
            showTable,
            currentRound,
            currentUserPriority,
        };
    },
};
</script>

<style lang="scss">
#request-annual-leave-mobile {
    height: 100%;
    .table-container {
        height: calc(100% - 27px - 80px);
    }
    .no-data-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .el-image {
            max-width: 480px;
            @media only screen and (max-width: 1024px) {
                max-width: 320px;
            }
        }
        .title {
            margin-top: 8px;
        }
    }
    .round-row {
        text-align: center;
        .round-title {
            letter-spacing: 0.1px;
            color: #92929d;
            font-size: 14px;
        }
    }
    .modal-dialog {
        .el-dialog__body {
            text-align: center;
            ::-webkit-scrollbar {
                display: none;
            }
            ::-webkit-scrollbar-button {
                width: 0;
                height: 0;
            }
            ::-webkit-scrollbar-corner {
                background: transparent;
            }
        }
        .el-icon-warning-outline {
            font-size: 40px;
            color: #57d9a3;
        }

        .dialog-content {
            position: relative;
            overflow: hidden;
        }

        .gradient-overlay {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            z-index: 2;
            pointer-events: none;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
        }
    }
    .roster-header-details-ward {
        justify-content: flex-start;
        padding-left: 16px;
    }
    .roster-header-details-week {
        justify-content: flex-end;
        padding-right: 16px;
        text-align: right;
    }
    .modal-message {
        white-space: break-spaces;
        overflow-y: scroll;
        height: 250px;
    }
}
</style>
