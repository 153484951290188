import { render } from "./MessageDetails.vue?vue&type=template&id=7f47c58f"
import script from "./MessageDetails.vue?vue&type=script&lang=js"
export * from "./MessageDetails.vue?vue&type=script&lang=js"

import "./MessageDetails.vue?vue&type=style&index=0&id=7f47c58f&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7f47c58f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7f47c58f', script)) {
    api.reload('7f47c58f', script)
  }
  
  module.hot.accept("./MessageDetails.vue?vue&type=template&id=7f47c58f", () => {
    api.rerender('7f47c58f', render)
  })

}

script.__file = "src/components/no-message/MessageDetails.vue"

export default script