import { render } from "./AddCategoryForm.vue?vue&type=template&id=10fafa02"
import script from "./AddCategoryForm.vue?vue&type=script&lang=js"
export * from "./AddCategoryForm.vue?vue&type=script&lang=js"

import "./AddCategoryForm.vue?vue&type=style&index=0&id=10fafa02&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "10fafa02"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('10fafa02', script)) {
    api.reload('10fafa02', script)
  }
  
  module.hot.accept("./AddCategoryForm.vue?vue&type=template&id=10fafa02", () => {
    api.rerender('10fafa02', render)
  })

}

script.__file = "src/components/category/AddCategoryForm.vue"

export default script