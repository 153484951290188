<template>
    <div class="info">
        <el-row :gutter="32" class="info-row">
            <el-col :span="20" class="recreation-info">
                <el-row class="title">
                    <el-col :span="24">
                        <span>{{ recreation.title }}</span>
                        <template v-if="recreation.type == 1">
                            <el-tag size="mini" type="danger" v-if="isSecret">Secret Ballot</el-tag>
                            <el-tag size="mini" type="info" v-else>Open Ballot</el-tag>
                        </template>
                    </el-col>
                </el-row>
                <el-row class="post-meta">
                    <el-col :span="12">
                        <div class="field">
                            <span class="field-title">Post Publish Date:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ recreation.published_date || "N/A" }}
                            </span>
                        </div>
                        <div class="field" v-if="recreation.type == 1">
                            <span class="field-title">Polling status:</span>
                            <el-tag size="mini" :type="isPollingActive ? 'success' : 'danger'">
                                {{ isPollingActive ? "Active" : "Inactive" }}
                            </el-tag>
                        </div>
                        <div class="field" v-if="recreation.type == 1">
                            <span class="field-title">Show Polling Result:</span>
                            <el-tag size="mini" :type="recreation.show_result ? 'success' : 'danger'">
                                {{ recreation.show_result ? "Active" : "Inactive" }}
                            </el-tag>
                        </div>
                        <div class="field">
                            <span class="field-title">Author:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ recreation.author || "N/A" }}
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="field">
                            <span class="field-title">Target Dept:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ recreation.ward || "N/A" }}
                            </span>
                        </div>
                        <div class="field">
                            <span class="field-title">Post End Date:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ recreation.end_date || "N/A" }}
                            </span>
                        </div>
                        <div class="field" v-if="recreation.type == 1">
                            <span class="field-title">Polling End Date:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ recreation.polling_end_date || "N/A" }}
                            </span>
                        </div>
                        <div class="field file-path">
                            <span class="field-title">File:&nbsp;&nbsp;</span>
                            <span class="field-value" :class="[recreation.file_path ? 'field-link' : '']" @click="handleFileOpen(recreation.file_path)">
                                {{ recreation.file_path ? "Preview" : "N/A" }}
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="4">
                <div class="action-btns">
                    <el-button @click="handleEditRecreation" class="save-btn" type="primary" size="small" :disabled="!recreation.id">
                        Edit
                    </el-button>
                </div>
            </el-col>
        </el-row>
    </div>
    <div class="details">
        <el-tabs v-model="activeName">
            <el-tab-pane class="content" label="Content" name="Content">
                <div class="recreation-content rich-editor-preview" v-html="recreation.content"></div>
            </el-tab-pane>
            <el-tab-pane class="result" name="result" v-if="recreation.type == 1">
                <template #label>
                    Polling result
                </template>
                <ResultTab :result="result" />
            </el-tab-pane>
            <el-tab-pane class="feedback" name="Feedback" v-if="windowInnerWidth < 1025 && recreation.type == 1">
                <template #label>
                    Voted user
                    <span>({{ feedback.length }})</span>
                </template>
                <FeedbackTab :feedback="feedback" :isSecret="isSecret" />
            </el-tab-pane>
        </el-tabs>
    </div>
    <Drawer :drawer="editRecreationDrawer" @update:isVisible="editRecreationDrawer.isVisible = $event">
        <template v-slot:content>
            <EditRecreationForm ref="editRecreationForm" :isLoading="editRecreationDrawer.isLoading" :recreation="recreation" />
        </template>
    </Drawer>
</template>

<script>
import Drawer from "@/components/Drawer";
import EditRecreationForm from "@/components/recreation/EditRecreationForm";
import ResultTab from "@/components/recreation/tabs/ResultTab";
import FeedbackTab from "@/components/recreation/tabs/FeedbackTab";
import moment from "moment";
export default {
    components: {
        Drawer,
        EditRecreationForm,
        ResultTab,
        FeedbackTab,
    },
    props: ["recreation", "feedback", "result", "isSecret"],
    data() {
        return {
            windowInnerWidth: null,
            isLoading: false,
            // info
            editRecreationDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Edit Recreation Post",
                save: () => this.editRecreation(),
                close: done => this.closeDrawer(done),
            },
            // tabs
            activeName: "Content",
        };
    },
    created() {
        window.addEventListener("resize", this.getWindowInnerWidth);
    },
    mounted() {
        this.getWindowInnerWidth();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.getWindowInnerWidth);
    },
    computed: {
        isPollingActive() {
            return moment().isBetween(moment(this.recreation.published_date), moment(this.recreation.polling_end_date), null, "[)");
        },
    },
    methods: {
        async editRecreation() {
            this.editRecreationDrawer.isLoading = true;
            try {
                const result = await this.$refs.editRecreationForm.editRecreation();
                if (result) {
                    this.editRecreationDrawer.isVisible = false;
                    this.$notify({
                        title: "Recreation updated successfully",
                        message: "Recreation " + result + " was updated successfully",
                        type: "success",
                    });
                    this.$emit("update:recreation");
                }
            } finally {
                this.editRecreationDrawer.isLoading = false;
            }
        },
        handleEditRecreation() {
            if (this.isEditDisable()) {
                this.$notify({
                    title: "Warning",
                    message: "Post cannot be edited after published",
                    type: "warning",
                });
                return;
            }
            this.editRecreationDrawer.isVisible = true;
        },
        closeDrawer() {
            this.$refs.editRecreationForm.resetForm();
            this.editRecreationDrawer.isVisible = false;
        },
        getWindowInnerWidth() {
            this.windowInnerWidth = window.innerWidth;
            if (this.windowInnerWidth > 1024) {
                this.activeName = "Content";
            }
        },
        handleFileOpen(filePath) {
            if (filePath) {
                window.open(filePath, "_blank");
            }
        },
        isEditDisable() {
            let publishDate = this.recreation.published_date;
            if (moment().isSameOrAfter(publishDate)) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss">
#recreation-details {
    .details-content {
        display: flex;
        flex-direction: column;
    }
    .post-meta {
        flex: 1;
        overflow: auto;
        .field {
            margin-top: 8px;
        }
        .file-path {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .field-link {
                cursor: pointer;
                color: #36b37e;
            }
            .field-link:hover {
                text-decoration: underline;
            }
        }
        .field-title {
            color: #44444f;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
        }
        .field-value {
            color: #92929d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
    }
    .info {
        height: 152px;
        .info-row,
        .el-image {
            height: 100%;
        }
        .info-row {
            flex: 1;
        }
        .action-btns {
            display: flex;
            justify-content: flex-end;
            @media (min-width: 1025px) {
                margin-bottom: 40px;
            }
        }
        .recreation-info {
            text-align: left;
            display: flex;
            flex-direction: column;
            height: 100%;
            .title {
                color: #171725;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
            }
            .el-tag {
                margin-left: 8px;
            }
        }
    }
    .details {
        flex: 1;
        overflow: auto;
        .el-tabs {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .el-tab-pane {
            height: 100%;
            overflow-y: auto;
        }
        .recreation-content {
            padding-right: 7px;
        }
        .feedback {
            .el-avatar {
                background: transparent;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: #4ac292;
        border: 0 solid #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #e8e8e8;
        border: 0 none #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #e8e8e8;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}
</style>
