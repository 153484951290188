<template>
    <div id="category">
        <el-container class="category-container">
            <el-header class="category-header" style="height: auto;">
                <el-breadcrumb class="category-breadcrumb" separator="/">
                    <el-breadcrumb-item>
                        <a href="/">Home</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        <a :href="previousPageHref">{{ previousPageTitle }}</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>Manage Category</el-breadcrumb-item>
                </el-breadcrumb>
                <el-dropdown
                    trigger="click"
                    @command="handleWardChange"
                    v-if="ward.options.length > 0 && ward.value && type != 'survival-working-tips'"
                    :class="[isLoading || isManage ? 'is-disabled' : '']"
                    :disabled="isLoading || isManage"
                >
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </el-header>
            <ViewContainer>
                <template v-slot:content>
                    <el-container class="category-content" v-loading="isLoading">
                        <el-header class="header" height="auto">
                            <el-row class="details-container">
                                <el-col :span="12">
                                    <span class="title">{{ numberOfCategory }} Category</span>
                                </el-col>
                                <el-col :span="12" class="btn-container">
                                    <div class="manage-actions" v-if="isManage">
                                        <el-button type="primary" class="check" size="small" @click="shiftCategory">
                                            Save
                                        </el-button>
                                        <el-button type="primary" class="cancel" size="small" @click="cancelEdit">
                                            Cancel
                                        </el-button>
                                    </div>
                                    <div class="category-actions" v-if="!(userLevel == 2 && type == 'survival-working-tips') && !isManage">
                                        <el-button type="primary" class="manage-category" size="small" icon="el-icon-notebook-2" @click="isManage = true">
                                            Manage
                                        </el-button>
                                        <el-button type="primary" class="add-category" size="small" icon="el-icon-circle-plus-outline" @click="handleAddCategory">
                                            Add Category
                                        </el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-header>
                        <el-main class="content" :class="isManage ? 'edit' : ''">
                            <div class="draggable-container">
                                <nested-draggable :fullList="list" :list="list" :type="type" :isManage="isManage" @edit:category="handleEditCategory" @delete:category="handleDeleteCategory" />
                            </div>
                        </el-main>
                    </el-container>
                </template>
            </ViewContainer>
        </el-container>
        <Drawer :drawer="addCategoryDrawer" @update:isVisible="addCategoryDrawer.isVisible = $event">
            <template v-slot:content>
                <AddCategoryForm :isLoading="addCategoryDrawer.isLoading" ref="addCategoryForm" :type="type" :wardId="ward.value" />
            </template>
        </Drawer>
        <Drawer :drawer="editCategoryDrawer" @update:isVisible="editCategoryDrawer.isVisible = $event" :type="type">
            <template v-slot:content>
                <EditCategoryForm
                    :isLoading="editCategoryDrawer.isLoading"
                    :categoryId="editCategoryDrawer.categoryId"
                    :category="editCategoryDrawer.category"
                    ref="editCategoryForm"
                    :type="type"
                    :list="list"
                />
            </template>
        </Drawer>
    </div>
</template>

<script>
import ViewContainer from "@/components/ViewContainer";
import nestedDraggable from "@/components/category/NestedDraggable";
import Drawer from "@/components/Drawer";
import AddCategoryForm from "@/components/category/AddCategoryForm";
import EditCategoryForm from "@/components/category/EditCategoryForm";
import { fetchData as fetchCategory, shiftData as shiftCategory, deleteData as deleteCategoryById } from "@/modules/category";
import { fetchData as fetchWard } from "@/modules/general/ward";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "Category",
    components: {
        ViewContainer,
        nestedDraggable,
        Drawer,
        AddCategoryForm,
        EditCategoryForm,
    },
    props: ["previousPage"],
    data() {
        return {
            isLoading: false,
            isManage: false,
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            type: this.previousPage,
            previousPageHref: "#/" + this.previousPage,
            numberOfCategory: 0,
            list: [],
            addCategoryDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Add Category",
                save: () => this.addCategory(),
                close: done => this.closeAddCategoryDrawer(done),
            },
            editCategoryDrawer: {
                isVisible: false,
                isLoading: false,
                categoryId: null,
                category: {},
                title: "Edit Category",
                save: () => this.editCategory(),
                close: done => this.closeEditCategoryDrawer(done),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
        };
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
    },
    async mounted() {
        if (this.type != "survival-working-tips") {
            await this.fetchWard();
        }
        await this.fetchCategory();
    },
    computed: {
        previousPageTitle() {
            let breadcrumbTitle = this.previousPage.charAt(0).toUpperCase() + this.previousPage.slice(1);
            breadcrumbTitle = breadcrumbTitle.replace(/-/g, " ");
            if (breadcrumbTitle == "Knowledge") {
                breadcrumbTitle = "Knowledge Sharing"
            }
            return breadcrumbTitle;
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchCategory() {
            try {
                this.isLoading = true;
                const result = await fetchCategory(this.type, this.ward.value);
                if (result) {
                    this.list = result.data;
                    this.numberOfCategory = result.count;
                } else {
                    this.list = [];
                    this.numberOfCategory = 0;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async shiftCategory() {
            try {
                this.isLoading = true;
                const result = await shiftCategory(this.type, this.list);
                if (result) {
                    this.$notify({
                        title: "Category updated successfully",
                        type: "success",
                    });
                } else {
                    this.$notify({
                        title: "Category update failed",
                        type: "error",
                    });
                }
            } finally {
                this.isManage = false;
                this.isLoading = false;
                this.fetchCategory();
            }
        },
        async deleteCategoryById(item) {
            this.isLoading = true;
            const result = await deleteCategoryById(this.type, item.id);
            if (result) {
                this.$notify({
                    title: "Category delete successfully",
                    type: "success",
                });
            }
            this.isManage = false;
            this.isLoading = false;
            this.fetchCategory();
        },
        async handleDeleteCategory(item) {
            if (this.isManage) {
                return this.$notify({
                    title: "Please save your changes before deleting the category",
                    type: "error",
                });
            }
            try {
                const confirmMsg = await this.$confirm(
                    "Remember to clear the post(s) and sub-category(s) under this category before removing. This will permanently delete the category. Continue?",
                    "Warning",
                    {
                        confirmButtonText: "Confirm",
                        cancelButtonText: "Cancel",
                        cancelButtonClass: "cancel-btn",
                        type: "warning",
                    },
                );

                if (confirmMsg === "confirm") {
                    if (item.child.length == 0 && item.number_of_post == 0) {
                        return await this.deleteCategoryById(item);
                    }
                    return this.$notify({
                        title: "This category cannot be deleted",
                        message: "Please remove the post(s) or sub-category(s) first",
                        type: "error",
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        async addCategory() {
            try {
                this.addCategoryDrawer.isLoading = true;
                const result = await this.$refs.addCategoryForm.addCategory();
                if (result) {
                    this.addCategoryDrawer.isVisible = false;
                    this.$notify({
                        title: "Category added successfully",
                        message: "Category " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchCategory();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addCategoryDrawer.isLoading = false;
            }
        },
        async editCategory() {
            try {
                this.editCategoryDrawer.isLoading = true;
                const result = await this.$refs.editCategoryForm.editCategory();
                if (result) {
                    this.editCategoryDrawer.isVisible = false;
                    this.$notify({
                        title: "Category edited successfully",
                        message: "Category " + result + " was edited successfully",
                        type: "success",
                    });
                    this.fetchCategory();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editCategoryDrawer.isLoading = false;
            }
        },
        handleWardChange(ward) {
            this.ward.value = ward;
            this.fetchCategory();
        },
        handleAddCategory() {
            this.addCategoryDrawer.isVisible = true;
        },
        handleEditCategory(category) {
            if (this.isManage) {
                return this.$notify({
                    title: "Please save your changes before editing the category",
                    type: "error",
                });
            }
            this.editCategoryDrawer.categoryId = category.id;
            this.editCategoryDrawer.category = category;
            this.editCategoryDrawer.isVisible = true;
        },
        cancelEdit() {
            this.isManage = false;
            this.fetchCategory();
        },
        closeAddCategoryDrawer() {
            this.$refs.addCategoryForm.resetForm();
            this.addCategoryDrawer.isVisible = false;
        },
        closeEditCategoryDrawer() {
            this.$refs.editCategoryForm.resetForm();
            this.editCategoryDrawer.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
#category {
    height: 100%;
    .category-container {
        height: 100%;
    }
    .category-header {
        display: flex;
        height: auto;
        padding: 0px;
        margin-bottom: 16px;
        @media only screen and (max-width: 1024px) {
            margin-bottom: 8px;
        }
        .category-breadcrumb {
            height: auto;
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 0px;
            .el-breadcrumb__inner {
                a {
                    color: #c1c7d0;
                    font-weight: normal;
                    &:hover {
                        color: #36b37e;
                    }
                }
            }
            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    font-weight: bold;
                    color: #303133;
                }
            }
        }
        .el-dropdown {
            margin-left: 8px;
            &.is-disabled {
                .el-dropdown-link {
                    cursor: not-allowed;
                    opacity: 0.25;
                }
            }
            .el-dropdown-link {
                cursor: pointer;
                color: #92929d;
            }
            .el-icon-arrow-down {
                font-size: 12px;
            }
        }
    }
    .category-content {
        height: 100%;
    }
    .page-header-container {
        display: none;
    }
    .header-row,
    .content-row {
        height: auto;
    }
    .header {
        height: auto;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding: 0;
        .details-container {
            width: 100%;
            .el-col {
                display: flex;
            }
            .btn-container {
                justify-content: flex-end;
            }
            .title {
                display: flex;
                align-items: center;
                color: #505f79;
                font-size: 18px;
                line-height: 1.2;
            }
            .add-category,
            .manage-category {
                display: flex;
                align-items: center;
            }
            i {
                font-size: 12px;
            }
        }
    }
    .content {
        width: 100%;
        height: 100%;
        background-color: #fafbfc;
        border: 1px solid #c1c7d0;
        box-sizing: border-box;
        border-radius: 5px;
        padding-bottom: 5px;
        .draggable-container {
            height: 100%;
        }
    }
    .manage-actions,
    .category-actions {
        display: flex;
        align-items: center;
        .cancel,
        .check {
            width: 80px;
        }
        .cancel {
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
    .category-form {
        .search-category-post {
            width: 100%;
        }
        .title {
            margin-bottom: 0;
        }
        .number-of-post-selected {
            position: absolute;
            top: 10px;
            right: 0;
            color: #606266;
            font-size: 13px;
            font-weight: bold;
        }
        .category-table {
            margin-bottom: 20px;
            .action-table {
                margin-top: 0;
            }
        }
    }
}
</style>
