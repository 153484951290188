import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString, filter) => {
    try {
        const result = await axios.get(
            `/admin/post/survival-working-tips?${wardId == "" ? "" : "wardId=" + wardId + "&"}pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}&title=${
                filter.title
            }&content=${filter.content}`,
        );
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSingleData = async tipsId => {
    try {
        const result = await axios.get(`/admin/post/survival-working-tips/${tipsId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchCategory = async () => {
    try {
        const result = await axios.get(`/admin/post/survival-working-tips/category`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async tips => {
    try {
        const formData = new FormData();
        formData.append("title", tips.title);
        for (let i = 0; i < tips.categoryList.length; i++) {
            formData.append("categoryList[]", tips.categoryList[i]);
        }
        formData.append("description", tips.description);
        formData.append("publishDate", tips.publishDate);
        formData.append("endDate", tips.endDate);
        formData.append("remarks", tips.remarks);
        if (tips.coverImage) {
            formData.append("coverImage", tips.coverImage);
        }
        if (tips.video) {
            formData.append("video", tips.video);
        }
        const result = await axios.post(`/admin/post/survival-working-tips`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async tips => {
    try {
        const formData = new FormData();
        formData.append("title", tips.title);
        for (let i = 0; i < tips.categoryList.length; i++) {
            formData.append("categoryList[]", tips.categoryList[i]);
        }
        formData.append("description", tips.description);
        formData.append("publishDate", tips.publishDate);
        formData.append("endDate", tips.endDate);
        formData.append("remarks", tips.remarks);
        if (tips.coverImage) {
            formData.append("coverImage", tips.coverImage);
        }
        if (tips.video) {
            formData.append("video", tips.video);
        }
        const result = await axios.put(`/admin/post/survival-working-tips/${tips.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async tipsList => {
    const params = {
        list: tipsList,
    };
    try {
        const result = await axios.post(`/admin/post/survival-working-tips/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
