import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/no-message?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDetails = async id => {
    try {
        const result = await axios.get(`/admin/no-message/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchViews = async id => {
    try {
        const result = await axios.get(`/admin/no-message/views/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchComments = async id => {
    try {
        const result = await axios.get(`/admin/no-message/comments/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteComments = async messageList => {
    const params = {
        list: messageList,
    };
    try {
        const result = await axios.post(`/admin/no-message/comments/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async message => {
    try {
        const formData = new FormData();
        formData.append("title", message.title);
        if (message.coverImage) {
            formData.append("coverImage", message.coverImage);
        }
        if (message.media) {
            formData.append("media", message.media);
        }
        formData.append("content", message.content);
        formData.append("publishedDate", message.publishDate);
        formData.append("endDate", message.endDate);

        for (let i = 0; i < message.wardList.length; i++) {
            formData.append("wardList[]", message.wardList[i]);
        }

        const result = await axios.post(`/admin/no-message`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async message => {
    try {
        const formData = new FormData();
        formData.append("title", message.title);
        if (message.coverImage) {
            formData.append("coverImage", message.coverImage);
        }
        if (message.media) {
            formData.append("media", message.media);
        }
        formData.append("content", message.content);
        formData.append("publishedDate", message.publishDate);
        formData.append("endDate", message.endDate);

        for (let i = 0; i < message.wardList.length; i++) {
            formData.append("wardList[]", message.wardList[i]);
        }

        const result = await axios.put(`/admin/no-message/${message.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async messageList => {
    const params = {
        list: messageList,
    };
    try {
        const result = await axios.post(`/admin/no-message/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const pinData = async messageList => {
    const params = {
        list: messageList,
        pin: true,
    };
    try {
        const result = await axios.post(`/admin/no-message/pin`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const unpinData = async messageList => {
    const params = {
        list: messageList,
        pin: false,
    };
    try {
        const result = await axios.post(`/admin/no-message/pin`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
