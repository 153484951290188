import { render } from "./Feedback.vue?vue&type=template&id=df650fbc"
import script from "./Feedback.vue?vue&type=script&lang=js"
export * from "./Feedback.vue?vue&type=script&lang=js"

import "./Feedback.vue?vue&type=style&index=0&id=df650fbc&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "df650fbc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('df650fbc', script)) {
    api.reload('df650fbc', script)
  }
  
  module.hot.accept("./Feedback.vue?vue&type=template&id=df650fbc", () => {
    api.rerender('df650fbc', render)
  })

}

script.__file = "src/components/recreation/Feedback.vue"

export default script