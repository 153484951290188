<template>
    <div id="settings">
        <ViewContainer title="Settings">
            <template v-slot:content>
                <el-tabs v-model="activeName">
                    <el-tab-pane :label="wardTab.label" :name="wardTab.path">
                        <WardGroup ref="ward-group" v-if="activeName == wardTab.path" @update:numberOfWard="handleNumberOfWard" />
                    </el-tab-pane>
                    <el-tab-pane :label="userTab.label" :name="userTab.path">
                        <User ref="user" v-if="activeName == userTab.path" @update:numberOfUser="handleNumberOfUser" />
                    </el-tab-pane>
                    <el-tab-pane :label="rankingTab.label" :name="rankingTab.path">
                        <Ranking ref="ranking" v-if="activeName == rankingTab.path" @update:numberOfRank="handleNumberOfRank" />
                    </el-tab-pane>
                    <el-tab-pane :label="policiesTab.label" :name="policiesTab.path" v-if="userLevel > 2">
                        <Policies ref="policies" v-if="activeName == policiesTab.path" @update:numberOfPolicies="handleNumberOfPolicies" />
                    </el-tab-pane>
                </el-tabs>
            </template>
        </ViewContainer>
    </div>
</template>

<script>
import ViewContainer from "@/components/ViewContainer";
import User from "@/views/Settings/User";
import WardGroup from "@/views/Settings/WardGroup";
import Ranking from "@/views/Settings/Ranking";
import Policies from "@/views/Settings/Policies";
import { getUserInfo } from "@/utils/auth";

export default {
    name: "Settings",
    components: { ViewContainer, User, WardGroup, Ranking, Policies },
    data() {
        return {
            userLevel: getUserInfo().user_level ? getUserInfo().user_level : null,
            isLoading: false,
            activeName: this.$route.path,
            numberOfUser: null,
            numberOfWard: null,
            numberOfRank: null,
            numberOfPolicies: null,
            wardTab: {
                label: "Dept",
                path: "/settings/dept",
            },
            userTab: {
                label: "Users",
                path: "/settings/users",
            },
            rankingTab: {
                label: "Ranks",
                path: "/settings/ranks",
            },
            policiesTab: {
                label: "Privacy & Terms",
                path: "/settings/policies",
            },
        };
    },
    watch: {
        numberOfUser(count) {
            this.userTab.label = count ? "Users (" + count + ")" : "Users (0)";
        },
        numberOfWard(count) {
            this.wardTab.label = count ? "Dept (" + count + ")" : "Dept (0)";
        },
        numberOfRank(count) {
            this.rankingTab.label = count ? "Ranks (" + count + ")" : "Ranks (0)";
        },
        numberOfPolicies(count) {
            this.policiesTab.label = count ? "Privacy & Terms (" + count + ")" : "Privacy & Terms (0)";
        },
        activeName(name) {
            this.$router.push(name);
        },
        "$route.path"(path) {
            this.activeName = path;
        },
    },
    methods: {
        handleNumberOfUser(count) {
            this.numberOfUser = count;
        },
        handleNumberOfWard(count) {
            this.numberOfWard = count;
        },
        handleNumberOfRank(count) {
            this.numberOfRank = count;
        },
        handleNumberOfPolicies(count) {
            this.numberOfPolicies = count;
        },
    },
};
</script>
<style lang="scss">
#settings {
    height: 100%;
    .el-tabs__item {
        font-weight: 700;
    }
}
</style>
