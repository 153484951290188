import { render } from "./MsPunsMessageDetailsWrapper.vue?vue&type=template&id=10ad18e4"
import script from "./MsPunsMessageDetailsWrapper.vue?vue&type=script&lang=js"
export * from "./MsPunsMessageDetailsWrapper.vue?vue&type=script&lang=js"

import "./MsPunsMessageDetailsWrapper.vue?vue&type=style&index=0&id=10ad18e4&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "10ad18e4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('10ad18e4', script)) {
    api.reload('10ad18e4', script)
  }
  
  module.hot.accept("./MsPunsMessageDetailsWrapper.vue?vue&type=template&id=10ad18e4", () => {
    api.rerender('10ad18e4', render)
  })

}

script.__file = "src/components/ms-puns-message/MsPunsMessageDetailsWrapper.vue"

export default script