import { render } from "./UploadDialog.vue?vue&type=template&id=a8ba6b4e"
import script from "./UploadDialog.vue?vue&type=script&lang=js"
export * from "./UploadDialog.vue?vue&type=script&lang=js"

import "./UploadDialog.vue?vue&type=style&index=0&id=a8ba6b4e&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a8ba6b4e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a8ba6b4e', script)) {
    api.reload('a8ba6b4e', script)
  }
  
  module.hot.accept("./UploadDialog.vue?vue&type=template&id=a8ba6b4e", () => {
    api.rerender('a8ba6b4e', render)
  })

}

script.__file = "src/components/UploadDialog.vue"

export default script