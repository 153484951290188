<template>
    <div id="ranking">
        <TabContainer>
            <template v-slot:header>
                <ContainerHeader :searchInput="searchInput" :searchString="searchInput.value" @update:searchString="searchInput.value = $event" :buttons="containerButtons" />
            </template>
            <template v-slot:content>
                <RankingTable :table="rankingTable" @update:rankTable="fetchRanks" />
            </template>
        </TabContainer>
        <Drawer :drawer="addRankDrawer" @update:isVisible="addRankDrawer.isVisible = $event">
            <template v-slot:content>
                <AddRankForm ref="addRankForm" :isLoading="addRankDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchRanks, deleteData as deleteRank } from "@/modules/settings/rank";
import AddRankForm from "@/components/settings/AddRankForm";
import TabContainer from "@/components/TabContainer";
import ContainerHeader from "@/components/ContainerHeader";
import RankingTable from "@/components/settings/RankingTable";
import Drawer from "@/components/Drawer";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "Ranking",
    components: {
        TabContainer,
        ContainerHeader,
        RankingTable,
        Drawer,
        AddRankForm,
    },
    data() {
        return {
            searchInput: {
                placeholder: "Search by Rank name, Rank description",
                value: "",
                callback: () => this.searchRank(),
            },
            containerButtons:
                getUserInfo().user_level == 3
                    ? [
                          {
                              name: "New Rank",
                              icon: "el-icon-plus",
                              callback: () => this.handleAddRankDrawer(),
                          },
                      ]
                    : [],
            rankingTable: {
                isLoading: false,
                data: [],
                numberOfItems: null,
                handleDelete: getUserInfo().user_level == 3 ? (index, row) => this.handleDelete(index, row) : null,
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addRankDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Rank",
                save: () => this.addRank(),
                close: () => this.closeAddRankDrawer(),
            },
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
        };
    },
    mounted() {
        this.fetchRanks();
    },
    methods: {
        async fetchRanks() {
            this.rankingTable.isLoading = true;
            let pageSize = this.rankingTable.pagination.pageSize;
            let pageIndex = this.rankingTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchRanks(pageSize, pageIndex, searchString);
                if (result) {
                    this.rankingTable.data = result.data;
                    this.rankingTable.numberOfItems = result.count;
                    this.$emit("update:numberOfRank", result.count);
                }
            } finally {
                this.rankingTable.isLoading = false;
            }
        },
        async addRank(form) {
            try {
                this.addRankDrawer.isLoading = true;
                const result = await this.$refs.addRankForm.addRank(form);
                if (result) {
                    this.addRankDrawer.isVisible = false;
                    this.$notify({
                        title: "Rank added successfully",
                        message: "Rank " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchRanks();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addRankDrawer.isLoading = false;
            }
        },
        async deleteRank(rank) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the rank. Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteRank(rank);
                    if (result) {
                        this.$notify({
                            title: "Rank deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchRanks();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // table
        searchRank() {
            this.rankingTable.pagination.currentPage = 1;
            this.fetchRanks();
        },
        handleDelete(index, row) {
            if (row.number_of_users == 0) {
                this.deleteRank([row.id]);
            } else {
                this.$message({
                    message: "Please remove users before deleting the rank.",
                    type: "info",
                });
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.rankingTable.pagination.pageSize = pageSize;
            this.rankingTable.pagination.currentPage = 1;
            this.fetchRanks();
        },
        handleCurrentChange(currentPage) {
            this.rankingTable.pagination.currentPage = currentPage;
            this.fetchRanks();
        },

        // drawer
        handleAddRankDrawer() {
            this.addRankDrawer.isVisible = true;
        },
        closeAddRankDrawer() {
            this.resetAddRankForm();
            this.addRankDrawer.isVisible = false;
        },
        resetAddRankForm() {
            this.$refs.addRankForm.resetForm();
        },
    },
};
</script>
