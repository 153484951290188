import { render } from "./Recreation.vue?vue&type=template&id=4f7772cc"
import script from "./Recreation.vue?vue&type=script&lang=js"
export * from "./Recreation.vue?vue&type=script&lang=js"

import "./Recreation.vue?vue&type=style&index=0&id=4f7772cc&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f7772cc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f7772cc', script)) {
    api.reload('4f7772cc', script)
  }
  
  module.hot.accept("./Recreation.vue?vue&type=template&id=4f7772cc", () => {
    api.rerender('4f7772cc', render)
  })

}

script.__file = "src/views/Recreation.vue"

export default script