<template>
    <div id="feedback">
        <div class="header">
            <el-row>
                <el-col :span="2" class="view-icon-container">
                    <i class="el-icon-view"></i>
                </el-col>
                <el-col :span="22" class="title-container">
                    <span class="title">Voted User List</span>
                </el-col>
            </el-row>
            <el-row class="number-of-user">
                <el-col :span="18" :offset="2">
                    <span>{{ feedback.length }} Users voted</span>
                </el-col>
            </el-row>
        </div>
        <el-table :show-header="false" ref="feedbackTable" :data="feedback" :height="tableHeight" class="feedback-table">
            <el-table-column prop="icon" width="65" align="center" class-name="icon-col">
                <template #default="scope">
                    <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                        <template #placeholder>
                            <el-image :src="placeholder" fit="contain"></el-image>
                        </template>
                        <template #error>
                            <el-image :src="placeholder" fit="contain"></el-image>
                        </template>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column align="left" show-overflow-tooltip>
                <template #default="scope">
                    <span class="name">{{ scope.row.full_name }}</span>
                    <div>
                        <span class="ward">
                            {{ scope.row.rank }}
                            <span v-if="scope.row.wardList.length > 0">{{ parseWardList(scope.row.wardList) }}</span>
                        </span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="right" class-name="date" show-overflow-tooltip>
                <template #default="scope">
                    <el-tag size="mini" type="info" v-if="!isSecret">{{ scope.row.optionAlphabet }}</el-tag>
                    <div>
                        <span class="date">{{ scope.row.updated_at }}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import placeholder from "@/assets/placeholder.png";
import $ from "jquery";
export default {
    name: "Feedback",
    props: ["feedback", "isSecret"],
    data() {
        return {
            isLoading: false,
            tableHeight: null,
            placeholder: placeholder,
            numberOfUsers: 0,
            users: [],
            windowInnerWidth: null,
        };
    },
    created() {
        window.addEventListener("resize", this.resize);
    },
    mounted() {
        this.resize();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resize);
    },
    updated() {
        this.$refs.feedbackTable.doLayout();
    },
    methods: {
        // table
        resize() {
            this.windowInnerWidth = window.innerWidth;
            this.tableHeight = $(".content-row")[0].clientHeight - $(".header")[0].clientHeight - 64;
        },
        parseWardList(wardList) {
            let outputString = "";
            for (let i = 0; i < wardList.length; i++) {
                if (i == 0) {
                    outputString += "(";
                }
                outputString += `${wardList[i]}`;
                if (i !== wardList.length - 1) {
                    outputString += ", ";
                } else {
                    outputString += ")";
                }
            }
            return outputString;
        },
    },
};
</script>

<style lang="scss">
#feedback {
    height: 100%;
    .header {
        margin-bottom: 8px;
        .view-icon-container,
        .title-container {
            display: flex;
            align-items: center;
        }
        .view-icon-container {
            justify-content: flex-start;
            .el-icon-view {
                color: #979797;
                font-size: 18px;
            }
        }
        .number-of-user {
            margin-top: 8px;
            margin-left: 8px;
            color: #c1c7d0;
            font-size: 13px;
            font-weight: 700;
        }
        .title {
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            margin-left: 8px;
        }
    }
    .feedback-table {
        .name {
            color: #171725;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
        .ward {
            font-size: 12px;
            white-space: break-spaces;
        }
        .date {
            color: #92929d;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
        }
        .el-avatar {
            background: transparent;
        }
        .icon-col {
            .cell {
                display: flex;
                align-content: center;
                justify-content: center;
                > .cover-image {
                    display: flex;
                    align-content: center;
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                }
            }
        }
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        ::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }
        ::-webkit-scrollbar-thumb {
            background: #4ac292;
            border: 0 solid #ffffff;
            border-radius: 3px;
        }
        ::-webkit-scrollbar-track {
            background: #e8e8e8;
            border: 0 none #ffffff;
            border-radius: 3px;
        }
        ::-webkit-scrollbar-track:hover {
            background: #e8e8e8;
        }
        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
}
</style>
