import { render } from "./TinyMCE.vue?vue&type=template&id=6dfc58ef"
import script from "./TinyMCE.vue?vue&type=script&lang=js"
export * from "./TinyMCE.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6dfc58ef"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6dfc58ef', script)) {
    api.reload('6dfc58ef', script)
  }
  
  module.hot.accept("./TinyMCE.vue?vue&type=template&id=6dfc58ef", () => {
    api.rerender('6dfc58ef', render)
  })

}

script.__file = "src/components/TinyMCE.vue"

export default script