import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString, filter) => {
    try {
        const result = await axios.get(
            `/admin/post/knowledge?wardId=${wardId}&pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}&title=${filter.title}&content=${filter.content}`,
        );
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSingleData = async postId => {
    try {
        const result = await axios.get(`/admin/post/knowledge/${postId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchCategory = async () => {
    try {
        const result = await axios.get(`/admin/post/knowledge/category`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async knowledge => {
    try {
        const formData = new FormData();
        formData.append("title", knowledge.title);
        for (let i = 0; i < knowledge.categoryList.length; i++) {
            formData.append("categoryList[]", knowledge.categoryList[i]);
        }
        formData.append("description", knowledge.description);
        formData.append("publishDate", knowledge.publishDate);
        formData.append("endDate", knowledge.endDate);
        if (knowledge.coverImage) {
            formData.append("coverImage", knowledge.coverImage);
        }
        if (knowledge.video) {
            formData.append("video", knowledge.video);
        }
        formData.append("remarks", knowledge.remarks);

        const result = await axios.post(`/admin/post/knowledge`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async knowledge => {
    try {
        const formData = new FormData();
        formData.append("title", knowledge.title);
        for (let i = 0; i < knowledge.categoryList.length; i++) {
            formData.append("categoryList[]", knowledge.categoryList[i]);
        }
        formData.append("description", knowledge.description);
        formData.append("publishDate", knowledge.publishDate);
        formData.append("endDate", knowledge.endDate);
        if (knowledge.coverImage) {
            formData.append("coverImage", knowledge.coverImage);
        }
        if (knowledge.video) {
            formData.append("video", knowledge.video);
        }
        formData.append("remarks", knowledge.remarks);

        const result = await axios.put(`/admin/post/knowledge/${knowledge.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async knowledgeList => {
    const params = {
        list: knowledgeList,
    };
    try {
        const result = await axios.post(`/admin/post/knowledge/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
