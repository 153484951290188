<template>
    <el-table :show-header="false" ref="viewsTable" :data="views" class="views-table" v-loading="isLoading" size="small" cell-class-name="cell-class">
        <el-table-column prop="icon" width="65" align="center" class-name="icon-col">
            <template #default="scope">
                <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                    <template #placeholder>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                    <template #error>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                </el-image>
            </template>
        </el-table-column>
        <el-table-column align="left" min-width="288" show-overflow-tooltip>
            <template #default="scope">
                <span class="name">{{ scope.row.full_name }}</span>
                <div>
                    <span class="ward">
                        {{ scope.row.rank }}
                        <span v-if="scope.row.wardList.length > 0">{{ parseWardList(scope.row.wardList) }}</span>
                    </span>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="date" align="right">
            <template #default="scope">
                <span class="date">{{ scope.row.created_at }}</span>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import placeholder from "@/assets/placeholder.png";

export default {
    name: "ViewsTab",
    props: ["views"],
    data() {
        return {
            isLoading: false,
            placeholder: placeholder,
        };
    },
    methods: {
        parseWardList(wardList) {
            let outputString = "";
            for (let i = 0; i < wardList.length; i++) {
                if (i == 0) {
                    outputString += "(";
                }
                outputString += `${wardList[i]}`;
                if (i !== wardList.length - 1) {
                    outputString += ", ";
                } else {
                    outputString += ")";
                }
            }
            return outputString;
        },
    },
};
</script>

<style lang="scss">
.views-table {
    .name {
        color: #171725;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
    }
    .date {
        color: #92929d;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
    .icon-col {
        .cell {
            display: flex;
            align-content: center;
            justify-content: center;
            > .cover-image {
                display: flex;
                align-content: center;
                border-radius: 50%;
                width: 44px;
                height: 44px;
            }
        }
    }
}
</style>
