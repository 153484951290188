import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/table/canteen-menu?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async menu => {
    try {
        const formData = new FormData();
        formData.append("title", menu.title);
        formData.append("file", menu.file);
        formData.append("publishDate", menu.publishDate);
        formData.append("endDate", menu.endDate);
        formData.append("remarks", menu.remarks);
        const result = await axios.post(`/admin/table/canteen-menu`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async menuList => {
    const params = {
        list: menuList,
    };
    try {
        const result = await axios.post(`/admin/table/canteen-menu/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
