import { render } from "./EditRecreationForm.vue?vue&type=template&id=b447f106"
import script from "./EditRecreationForm.vue?vue&type=script&lang=js"
export * from "./EditRecreationForm.vue?vue&type=script&lang=js"

import "./EditRecreationForm.vue?vue&type=style&index=0&id=b447f106&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "b447f106"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b447f106', script)) {
    api.reload('b447f106', script)
  }
  
  module.hot.accept("./EditRecreationForm.vue?vue&type=template&id=b447f106", () => {
    api.rerender('b447f106', render)
  })

}

script.__file = "src/components/recreation/EditRecreationForm.vue"

export default script