import { render } from "./Drawer.vue?vue&type=template&id=0547bea1"
import script from "./Drawer.vue?vue&type=script&lang=js"
export * from "./Drawer.vue?vue&type=script&lang=js"

import "./Drawer.vue?vue&type=style&index=0&id=0547bea1&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0547bea1"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0547bea1', script)) {
    api.reload('0547bea1', script)
  }
  
  module.hot.accept("./Drawer.vue?vue&type=template&id=0547bea1", () => {
    api.rerender('0547bea1', render)
  })

}

script.__file = "src/components/Drawer.vue"

export default script