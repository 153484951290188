<template>
    <div id="nav-menu">
        <el-menu class="el-menu-vertical-demo" :collapse="collapse" :router="true" :default-active="activeLink">
            <div class="logo-item" :class="collapse ? 'collapse' : ''">
                <img class="logo" src="./../assets/nav-logo.svg" />
                <span class="logo-text" v-if="!collapse">MCG APP Panel</span>
            </div>
            <el-menu-item class="first-item" index="/canteen-menu">
                <img src="./../assets/icon/nav-menu/canteen-menu.svg" />
                <template #title>
                    <span>Canteen Menu</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/shuttle-bus">
                <img src="./../assets/icon/nav-menu/shuttle-bus.svg" />
                <template #title>
                    <span>Shuttle Bus</span>
                </template>
            </el-menu-item>
            <el-submenu index="3">
                <template #title>
                    <img src="./../assets/icon/nav-menu/roster.svg" />
                    <span>Roster</span>
                </template>
                <el-menu-item-group>
                    <template #title><span>Roster</span></template>
                    <el-menu-item index="/duty-roster">
                        Duty Roster
                    </el-menu-item>
                    <el-menu-item index="/no-duty-roster" v-if="isManageNORoster || userLevel == 3 || isMsPun">
                        NO Duty Roster
                    </el-menu-item>
                    <el-menu-item index="/request-duty-roster" v-if="!isMsPun">
                        Request Duty Roster
                    </el-menu-item>
                    <el-menu-item index="/request-no-duty-roster" v-if="(isManageNORoster || userLevel == 3) && !isMsPun">
                        Request NO Duty Roster
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="/survival-working-tips">
                <img src="./../assets/icon/nav-menu/survival-working-tips.svg" />
                <template #title>
                    <span>Survival Working Tips</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/tel-directory">
                <img src="./../assets/icon/nav-menu/tel-directory.svg" />
                <template #title>
                    <span>Tel Directory</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/training">
                <img src="./../assets/icon/nav-menu/training.svg" />
                <template #title>
                    <span>Training</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/compliment">
                <img src="./../assets/icon/nav-menu/compliment.svg" />
                <template #title>
                    <span>Compliment</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/knowledge">
                <img src="./../assets/icon/nav-menu/knowledge.svg" />
                <template #title>
                    <span>Knowledge Sharing</span>
                </template>
            </el-menu-item>
            <el-submenu index="9">
                <template #title>
                    <img src="./../assets/icon/nav-menu/annual-leave.svg" />
                    <span>Annual Leave</span>
                </template>
                <el-menu-item-group>
                    <template #title><span>Annual Leave</span></template>
                    <el-menu-item index="/annual-leave-schedule">Annual Leave Schedule</el-menu-item>
                    <el-menu-item index="/no-annual-leave-schedule" v-if="isManageNORoster || userLevel == 3 || isMsPun">
                        NO Annual Leave Schedule
                    </el-menu-item>
                    <el-menu-item index="/request-annual-leave" v-if="!isMsPun">
                        Request Annual Leave
                    </el-menu-item>
                    <el-menu-item index="/request-no-annual-leave" v-if="(isManageNORoster || userLevel == 3) && !isMsPun">
                        <span>Request NO Annual Leave</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="/no-message" v-if="!isMsPun && (isNORoster || isManageNORoster || userLevel == 3)">
                <img src="./../assets/icon/nav-menu/no-message.svg" />
                <template #title>
                    <span>NO Message</span>
                </template>
            </el-menu-item>
            <el-menu-item index="/recreation">
                <img src="./../assets/icon/nav-menu/recreation.svg" />
                <template #title>
                    <span>Recreation</span>
                </template>
            </el-menu-item>
            <el-submenu index="12">
                <template #title>
                    <img src="./../assets/icon/nav-menu/settings.svg" />
                    <span>Settings</span>
                </template>
                <el-menu-item-group>
                    <template #title><span>Roster</span></template>
                    <el-menu-item index="/settings/dept">
                        <span>Dept</span>
                    </el-menu-item>
                    <el-menu-item index="/settings/users">
                        <span>Users</span>
                    </el-menu-item>
                    <el-menu-item index="/settings/ranks">
                        <span>Ranks</span>
                    </el-menu-item>
                    <el-menu-item index="/settings/policies" v-if="userLevel > 2">
                        <span>Privacy & Terms</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item index="/ms-puns-message" v-if="isMsPun">
                <img src="./../assets/icon/nav-menu/ms-puns-message.svg" />
                <template #title>
                    <span>Ms Pun's Message</span>
                </template>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
import { getUserInfo } from "@/utils/auth";
export default {
    name: "NavMenu",
    props: ["collapse"],
    data() {
        return {
            activeLink: null,
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            isManageNORoster: getUserInfo().is_no_admin == 1 ? true : false,
            isMsPun: getUserInfo().user_level == 4 ? true : false,
            isNORoster: getUserInfo().is_no_roster == 1 ? true : false,
        };
    },
    mounted() {
        this.activeLink = this.$route.path;
        if (this.$route.path == "/training/category") {
            this.activeLink = "/training";
        } else if (this.$route.path == "/survival-working-tips/category") {
            this.activeLink = "/survival-working-tips";
        } else if (this.$route.path == "/knowledge/category") {
            this.activeLink = "/knowledge";
        } else if (this.$route.name == "RecreationDetailsWrapper") {
            this.activeLink = "/recreation";
        } else if (this.$route.name == "NOMessageDetailsWrapper") {
            this.activeLink = "/no-message";
        } else if (this.$route.name == "MsPunsMessageDetailsWrapper") {
            this.activeLink = "/ms-puns-message";
        }
    },
    watch: {
        $route(to) {
            this.activeLink = to.path;
            if (to.path == "/training/category") {
                this.activeLink = "/training";
            } else if (this.$route.path == "/survival-working-tips/category") {
                this.activeLink = "/survival-working-tips";
            } else if (this.$route.path == "/knowledge/category") {
                this.activeLink = "/knowledge";
            } else if (this.$route.name == "RecreationDetailsWrapper") {
                this.activeLink = "/recreation";
            } else if (this.$route.name == "NOMessageDetailsWrapper") {
                this.activeLink = "/no-message";
            } else if (this.$route.name == "MsPunsMessageDetailsWrapper") {
                this.activeLink = "/ms-puns-message";
            }
        },
    },
};
</script>

<style lang="scss">
.el-aside {
    overflow: overlay;
}
.el-menu {
    text-align: left;
    // transform: translateZ(0);
}
.el-submenu__title,
.el-menu-item {
    div > img,
    > img {
        width: 24px;
        height: 24px;
    }
    > img {
        padding-right: 16px;
    }
    &.is-active {
        background-color: #f2fffa;
    }
    &:hover,
    &:focus {
        background-color: #f2fffa !important;
        > span,
        > img {
            opacity: 0.75;
        }
    }
}
.el-menu-item-group__title {
    display: none;
}
.el-submenu {
    line-height: 56px;
    padding: 0;
    &.is-active {
        background-color: #f2fffa;
        .el-submenu__title,
        i {
            color: #36b37e;
        }
    }
    &.is-opened {
        .el-menu-item-group {
            .el-menu-item {
                padding-left: 60px !important;
            }
        }
    }
}
.el-menu-vertical-demo {
    height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 250px;
}
.logo-item {
    background-color: #fff;
    height: 59px;
    @media only screen and (max-width: 1024px) {
        height: 47px;
    }
    width: 210px;
    display: flex;
    position: fixed;
    z-index: 2;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    border-bottom: 1px solid #e2e2ea;
    transition: all 0.3s;
    .logo {
        max-width: 44px;
        margin-right: 16px;
    }
    .logo-text {
        font-size: 14px;
    }
}
.el-menu-item.first-item {
    margin-top: 88px;
    @media only screen and (max-width: 1024px) {
        margin-top: 48px;
    }
}
.el-menu--collapse {
    .logo-item {
        width: 24px;
    }
    .logo {
        width: 50px;
        height: 50px;
        margin-left: -11px;
        margin-right: 0;
    }
    .el-submenu {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .el-submenu__title {
            padding: 0 !important;
        }
    }
    .el-submenu__title,
    .el-menu-item {
        > img {
            padding-right: 0;
        }
    }
    @media only screen and (max-width: 1024px) {
        width: 48px;
        .el-menu-item {
            > div {
                padding: 0 !important;
                display: flex !important;
                align-items: center;
                justify-content: center;
            }
        }
        .logo-item {
            height: 47px;
            width: 31px;
            padding-left: 8px;
            padding-right: 8px;
        }
        .logo {
            width: 32px;
            height: 32px;
            margin-left: 0;
            margin-right: 0;
        }
    }
}
</style>
