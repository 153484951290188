<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format and file in pdf format a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
            <br />
            - Polling end date must be after post publish date and before/same with post end date.
            <br />
            - Polling starts on post publish date.
            <br />
            - Post cannot be changed after published.
            <br />
            - Post type cannot be changed after created.
        </span>
    </div>
    <el-form class="add-recreation-form" ref="addRecreationForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Title:" prop="title">
                    <el-input class="word-limit-input" v-model="form.title" placeholder="Enter a title" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Dept:" prop="ward" ref="ward">
                    <el-select v-model="form.ward.value" filterable placeholder="Select dept" multiple>
                        <el-option v-for="item in form.ward.list" :key="item.name" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <div class="ward-error">
                        <span>
                            {{ form.ward.error }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Post Type:" required>
                    <el-radio-group class="post-type" v-model="form.postType">
                        <el-radio :label="0">Normal Post</el-radio>
                        <el-radio :label="1">Post with polling</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="File:">
                    <el-upload class="upload-file" ref="upload" accept=".pdf" :auto-upload="false" :limit="1" :on-change="handleChange" action="#">
                        <template #default v-if="isFileEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <div class="el-upload-list__item-name">
                                <i class="el-icon-document"></i>
                                <span class="file-name">{{ file.name }}</span>
                                <div class="upload-actions">
                                    <i class="el-icon-upload-success el-icon-circle-check"></i>
                                    <i class="el-icon-close el-icon-circle-close" @click="handleRemove"></i>
                                </div>
                            </div>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>{{ form.fileError }}</span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Publish date:" prop="publishDate">
                    <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.publishDate" format="YYYY-MM-DD HH:mm" />
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.postType == 1">
                <el-form-item label="End date:" prop="endDate">
                    <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.endDate" format="YYYY-MM-DD HH:mm" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <div class="description">
                    <span class="contact">
                        注意：
                        <br />
                        預定時間發報的手機通知有可能會出現不多於3分鐘的延遲送達情況。
                    </span>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="Content:" prop="content">
                    <TinyMCE @update:content="handleUpdateContent"></TinyMCE>
                </el-form-item>
            </el-col>
        </el-row>
        <div v-if="form.postType == 1">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Polling end date:" prop="pollingEndDate">
                        <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.pollingEndDate" format="YYYY-MM-DD HH:mm" :disabled-date="disabledDate" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="Polling question:" prop="pollingQuestion">
                        <el-input class="word-limit-input" v-model="form.pollingQuestion" placeholder="Where do we go for the Party?" maxlength="50" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-for="(option, index) in form.pollingOptions" :key="index">
                <el-col :span="24">
                    <el-form-item class="options-input" :label="'Polling option ' + (index + 1) + ':'" :prop="'pollingOptions.' + index + '.value'" :rules="form.rules.pollingOption">
                        <el-input class="word-limit-input" v-model="option.value" :placeholder="'Option ' + (index + 1)" maxlength="100" show-word-limit></el-input>
                        <div class="input-btn" v-if="form.pollingOptions.length > 2">
                            <i class="el-icon-delete" @click="deleteItem(option, index)"></i>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="12">
                    <el-form-item prop="voteChange">
                        <el-checkbox v-model="form.voteChange">
                            Vote Change
                        </el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="add-option-container">
                        <el-button class="add-option-btn" @click="addOption" type="primary" icon="el-icon-plus" v-if="form.pollingOptions.length < 6">
                            New Option
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="12">
                    <el-form-item prop="showResult">
                        <el-checkbox v-model="form.showResult">
                            Show Polling Result
                        </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item prop="isSecret">
                        <el-checkbox v-model="form.isSecret" :disabled="form.showResult == false">
                            Secret Ballot
                        </el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
        </div>
    </el-form>
</template>

<script>
import { postData as addRecreation } from "@/modules/recreation";
import { fetchData as fetchWard } from "@/modules/general/ward";
import moment from "moment";
import TinyMCE from "@/components/TinyMCE";
export default {
    name: "AddRecreationForm",
    components: {
        TinyMCE,
    },
    props: ["isLoading"],
    data() {
        const validateWard = (rule, ward, callback) => {
            if (ward.value.length == 0) {
                callback(new Error("Please select dept"));
            } else {
                callback();
            }
        };
        const validatePublishDate = (rule, publishDate, callback) => {
            if (!publishDate) {
                callback(new Error("Please pick a date"));
            } else if (moment(publishDate).isSameOrAfter(moment(this.form.endDate))) {
                this.$refs.addRecreationForm.clearValidate("endDate");
                callback(new Error("Publish date must be before end date"));
            } else if (moment(publishDate).isAfter(moment(this.form.pollingEndDate))) {
                callback(new Error("Publish date must be before or the same with polling end date"));
            } else {
                callback();
            }
        };
        const validateEndDate = (rule, endDate, callback) => {
            if (!endDate) {
                callback(new Error("Please pick a date"));
            } else if (moment(endDate).isSameOrBefore(moment(this.form.publishDate))) {
                this.$refs.addRecreationForm.clearValidate("publishDate");
                callback(new Error("End date must be after publish date"));
            } else if (moment(endDate).isBefore(moment(this.form.pollingEndDate))) {
                callback(new Error("End date must be after polling end date"));
            } else {
                callback();
            }
        };
        const validatePollingEndDate = (rule, pollingEndDate, callback) => {
            if (!pollingEndDate) {
                callback(new Error("Please pick a date"));
            } else if (moment(pollingEndDate).isSameOrBefore(moment(this.form.publishDate)) || moment(pollingEndDate).isAfter(moment(this.form.endDate))) {
                callback(new Error("Polling end date must be between publish date and end date"));
            } else {
                callback();
            }
        };
        return {
            form: {
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Please enter a title",
                            trigger: "blur",
                        },
                    ],
                    ward: [
                        {
                            required: true,
                            validator: validateWard,
                            trigger: ["blur", "change"],
                        },
                    ],
                    // content: [
                    // 	{
                    // 		required: true,
                    // 		message: "Please enter content",
                    // 		trigger: ["blur", "change"],
                    // 	},
                    // ],
                    publishDate: [
                        {
                            required: true,
                            validator: validatePublishDate,
                            trigger: ["blur", "change"],
                        },
                    ],
                    endDate: [
                        {
                            required: true,
                            validator: validateEndDate,
                            trigger: ["blur", "change"],
                        },
                    ],
                    pollingEndDate: [
                        {
                            required: true,
                            validator: validatePollingEndDate,
                            trigger: ["blur", "change"],
                        },
                    ],
                    pollingQuestion: [
                        {
                            required: true,
                            message: "Please enter the question",
                            trigger: ["blur", "change"],
                        },
                    ],
                    pollingOption: [
                        {
                            required: true,
                            message: "Please enter option",
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                title: "",
                ward: {
                    isLoading: false,
                    value: [],
                    list: [],
                    error: "",
                },
                postType: 0,
                file: {},
                fileError: "",
                publishDate: new Date(moment().format("YYYY-MM-DD HH:mm")),
                endDate: new Date(
                    moment()
                        .add({ months: 1 })
                        .format("YYYY-MM-DD HH:mm"),
                ),
                content: "",
                pollingEndDate: null,
                pollingQuestion: "",
                pollingOptions: [{ value: "" }, { value: "" }],
                voteChange: false,
                showResult: true,
                isSecret: false,
            },
        };
    },
    computed: {
        isFileEmpty() {
            return Object.keys(this.form.file).length == 0;
        },
    },
    mounted() {
        this.fetchWard();
    },
    methods: {
        async addRecreation() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                let form = {};
                if (this.form.postType == 0) {
                    // normal post
                    form = {
                        title: this.form.title,
                        wardList: this.form.ward.value,
                        postType: this.form.postType,
                        file: this.form.file.raw,
                        publishDate: moment(this.form.publishDate).format("YYYY-MM-DD HH:mm"),
                        endDate: moment("2038-01-01").format("YYYY-MM-DD HH:mm"),
                        content: this.form.content,
                    };
                } else if (this.form.postType == 1) {
                    let pollingOptionsValue = [];
                    for (let i = 0; i < this.form.pollingOptions.length; i++) {
                        pollingOptionsValue.push(this.form.pollingOptions[i].value);
                    }
                    form = {
                        title: this.form.title,
                        wardList: this.form.ward.value,
                        postType: this.form.postType,
                        file: this.form.file.raw,
                        publishDate: moment(this.form.publishDate).format("YYYY-MM-DD HH:mm"),
                        endDate: moment(this.form.endDate).format("YYYY-MM-DD HH:mm"),
                        content: this.form.content,

                        pollingEndDate: moment(this.form.pollingEndDate).format("YYYY-MM-DD HH:mm"),
                        pollingQuestion: this.form.pollingQuestion,
                        pollingOptions: pollingOptionsValue,

                        voteChange: this.form.voteChange,
                        showResult: this.form.showResult,
                        isSecret: this.form.isSecret,
                    };
                }

                const result = await addRecreation(form);
                if (result) {
                    return form.title;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async validateForm() {
            try {
                const fileResult = this.validateFile(this.form.file);
                const result = await this.$refs.addRecreationForm.validate();
                return fileResult && result;
            } catch (error) {
                return false;
            }
        },
        async fetchWard() {
            try {
                this.form.ward.isLoading = true;
                const data = await fetchWard();
                if (data) {
                    this.form.ward.list = data.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    });
                }
            } finally {
                this.form.ward.isLoading = false;
            }
        },
        handleChange(file) {
            if (this.validateFile(file)) {
                this.form.file = file;
            } else {
                this.$refs.upload.clearFiles();
            }
        },
        handleRemove() {
            this.$refs.upload.clearFiles();
            this.form.file = {};
            this.validateFile(this.form.file);
        },
        validateFile(file) {
            if (Object.keys(file).length == 0) {
                return true;
            }
            const isPdf = file.raw.type === "application/pdf";
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isPdf) {
                this.form.fileError = "File can only be pdf format";
                return false;
            }
            if (!isLt10M) {
                this.form.fileError = "File cannot exceed 10MB";
                return false;
            }
            this.form.fileError = "";
            return true;
        },
        disabledDate(time) {
            return time < this.form.publishDate || time > this.form.endDate;
        },
        addOption() {
            let optionsLength = this.form.pollingOptions.length;
            if (optionsLength < 6) {
                this.form.pollingOptions.push({
                    value: "",
                });
            }
        },
        deleteItem(option, index) {
            let optionsLength = this.form.pollingOptions.length;
            if (optionsLength > 2) {
                this.form.pollingOptions.splice(index, 1);
            }
        },
        resetForm() {
            this.$refs.addRecreationForm.resetFields();
            this.$refs.upload.clearFiles();

            this.form.file = {};
            this.form.fileError = "";
            this.form.publishDate = new Date(
                moment()
                    .add({ days: 1 })
                    .format("YYYY-MM-DD"),
            );
            this.form.endDate = new Date(
                moment()
                    .add({ days: 1, months: 1 })
                    .format("YYYY-MM-DD"),
            );
            this.form.content = "";
            this.form.pollingEndDate = null;
            this.form.pollingQuestion = "";
            this.form.pollingOptions = [{ value: "" }, { value: "" }];
            this.form.voteChange = false;
            this.form.showResult = true;
            this.form.isSecret = false;
        },
        handleUpdateContent(value) {
            this.form.content = value;
        },
    },
};
</script>

<style lang="scss">
#recreation {
    .post-type {
        display: flex;
        align-items: center;
        height: 32px;
    }
}
</style>
