<template>
    <el-table
        class="policies-table action-table"
        ref="policiesTable"
        :size="windowInnerWidth > 1024 ? 'medium' : 'mini'"
        :data="table.data"
        :height="tableHeight"
        :key="tableHeight"
        v-loading="table.isLoading"
        :cell-class-name="windowInnerWidth > 1024 ? '' : 'cell-class'"
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
    >
        <el-table-column label="POLICY TITLE" prop="title" align="left" show-overflow-tooltip min-width="300"></el-table-column>
        <el-table-column label="ACTION" width="88" align="left" v-if="userLevel == 3">
            <template #default="scope">
                <div class="action-button-group">
                    <el-button type="text" size="small" icon="el-icon-edit" @click="table.handleEdit(scope.$index, scope.row)"></el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import $ from "jquery";
import { getUserInfo } from "@/utils/auth";

export default {
    name: "PoliciesTable",
    props: {
        table: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            // table
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            headerCellStyle: {
                background: "#fafafb",
                border: "0px",
            },
            tableHeight: 500,
            windowInnerWidth: null,
        };
    },
    created() {
        window.addEventListener("resize", this.resize);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.resize);
    },
    mounted() {
        this.resize();
    },
    methods: {
        // table
        resize() {
            this.windowInnerWidth = window.innerWidth;
            if ($(".el-tabs__header")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 132 : 100;
                this.tableHeight = $(".page-content")[0].clientHeight - constant;
            } else if ($(".page-content")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 88 : 62;
                this.tableHeight = $(".page-content")[0].clientHeight - $(".container-header")[0].clientHeight - constant;
            }
        },
    },
};
</script>
