<template>
    <el-row>
        <el-col :span="6" class="fold-icon">
            <span v-if="!collapse" v-on:click="collapse = true" class="el-icon-s-fold"></span>
            <span v-else v-on:click="collapse = false" class="el-icon-s-unfold"></span>
        </el-col>
        <el-col :span="12" class="user-dropdown-container" :push="6">
            <el-dropdown class="user-dropdown" style="display: flex" trigger="click" size="small" placement="bottom-end">
                <span class="el-dropdown-link">
                    <span class="user-icon-container">
                        <el-avatar :size="30" :src="userIcon || placeholder" class="user-icon" @error="errorHandler">
                            <img :src="placeholder" />
                        </el-avatar>
                    </span>
                    <div class="user-details">
                        <span class="username">{{ userName }}</span>
                        <span class="user-description" v-if="userDescription">
                            {{ userDescription }}
                        </span>
                    </div>
                    <i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item icon="el-icon-lock" @click="handleChangePassword">
                            Change Password
                        </el-dropdown-item>
                        <el-dropdown-item icon="el-icon-switch-button" @click="handleLogoutOnClick">
                            Logout
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
    </el-row>
    <ChangePasswordDialog v-if="changePasswordDialog.isVisible" :dialog="changePasswordDialog" @update:isVisible="changePasswordDialog.isVisible = $event"></ChangePasswordDialog>
</template>

<script>
import { logout } from "@/modules/auth";
import placeholder from "@/assets/placeholder.png";
import ChangePasswordDialog from "@/components/header/ChangePasswordDialog";
export default {
    name: "Header",
    components: {
        ChangePasswordDialog,
    },
    data() {
        return {
            collapse: false,
            userIcon: "",
            userName: "",
            userDescription: "",
            placeholder: placeholder,
            changePasswordDialog: {
                isVisible: false,
            },
        };
    },
    mounted() {
        this.collapse = window.innerWidth > 1024 ? false : true;
        this.$emit("update:collapse", this.collapse);

        this.getUserDetails();
        if (localStorage.getItem("change_pw") == 1) {
            this.changePasswordDialog.isVisible = true;
        }
    },
    watch: {
        collapse: function(val) {
            this.$emit("update:collapse", val);
        },
    },
    methods: {
        getUserDetails() {
            this.userDescription = localStorage.getItem("rank_description") || "";
            this.userName = localStorage.getItem("name") || "";
            this.userIcon = localStorage.getItem("icon") == "null" ? "" : localStorage.getItem("icon");
        },
        async handleLogoutOnClick() {
            try {
                await logout();
                this.$message({
                    showClose: true,
                    type: "success",
                    message: "Logged out successfully.",
                });
            } catch (error) {
                console.log(error);
            }
        },
        errorHandler() {
            this.userIcon = this.placeholder;
            return true;
        },
        handleChangePassword() {
            this.changePasswordDialog.isVisible = true;
        },
    },
};
</script>

<style lang="scss">
#header {
    .el-row {
        height: 100%;
        .el-col {
            height: 100%;
            display: flex;
            align-items: center;
            &.fold-icon {
                color: #505f79;
                text-align: left;
                .el-icon-s-fold,
                .el-icon-s-unfold {
                    font-size: 24px;
                    cursor: pointer;
                }
            }
            &.user-dropdown-container {
                justify-content: flex-end;
                .el-dropdown-link {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
                .el-icon-caret-bottom {
                    font-size: 12px;
                }
            }
        }
    }
    .user-icon-container {
        margin-right: 10px;
        line-height: 14px;
        .user-icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }
        .el-icon-user-solid {
            font-size: 25px;
        }
        .el-avatar {
            background: transparent;
            img {
                width: 100%;
            }
        }
    }
    .user-details {
        display: flex;
        flex-direction: column;
        line-height: 14px;
        .username {
            font-weight: bold;
            color: #000;
        }
        .user-description {
            font-size: 13px;
            color: #92929d;
        }
    }
}
</style>
