import { render } from "./ViewsDialog.vue?vue&type=template&id=44ba139c"
import script from "./ViewsDialog.vue?vue&type=script&lang=js"
export * from "./ViewsDialog.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "44ba139c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('44ba139c', script)) {
    api.reload('44ba139c', script)
  }
  
  module.hot.accept("./ViewsDialog.vue?vue&type=template&id=44ba139c", () => {
    api.rerender('44ba139c', render)
  })

}

script.__file = "src/components/no-message/ViewsDialog.vue"

export default script