<template>
    <div id="no-duty-roster">
        <ViewContainer :title="`NO Duty Roster`" :numberOfItems="noDutyRosterTable.numberOfItems ? noDutyRosterTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="noDutyRosterTable" />
            </template>
        </ViewContainer>
    </div>
    <UploadRosterDialog :wardId="ward.value" v-if="uploadDialog.isVisible" :dialog="uploadDialog" @update:isVisible="uploadDialog.isVisible = $event" />
    <EditRosterDialog :wardId="ward.value" v-if="editDialog.isVisible" :dialog="editDialog" @update:isVisible="editDialog.isVisible = $event" />
</template>

<script>
import {
    fetchData as fetchNODutyRoster,
    fetchSingleData as fetchSingleNODutyRoster,
    postData as addNODutyRoster,
    putData as editNODutyRoster,
    deleteData as deleteNODutyRoster,
} from "@/modules/no-duty-roster";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import UploadRosterDialog from "@/components/roster/UploadRosterDialog";
import EditRosterDialog from "@/components/roster/EditRosterDialog";
export default {
    name: "NoDutyRoster",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        UploadRosterDialog,
        EditRosterDialog,
    },
    data() {
        return {
            isLoading: false,
            ward: {
                options: [],
                label: "",
                value: 1,
            },
            searchInput: {
                placeholder: "Search by week, title, author, remarks",
                value: "",
                callback: () => this.searchNoDutyRoster(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleUploadDialog(),
                },
            ],
            noDutyRosterTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getPdf(row, column, e),
                cols: [
                    {
                        label: "week",
                        sortable: true,
                        prop: "week",
                        width: "160",
                    },
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "96",
                    },
                    {
                        label: "create date",
                        sortable: true,
                        prop: "created_at",
                        minWidth: "124",
                    },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handleEdit: (index, row) => this.handleEdit(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            uploadDialog: {
                isVisible: false,
                title: `Upload NO Duty Roster`,
                upload: form => this.addNODutyRoster(form),
            },
            editDialog: {
                isVisible: false,
                isLoading: false,
                title: `Edit NO Duty Roster`,
                roster: {},
                upload: form => this.editNODutyRoster(form),
            },
        };
    },
    mounted() {
        this.fetchNODutyRoster();
    },
    methods: {
        async fetchNODutyRoster() {
            this.isLoading = true;
            let wardId = this.ward.value;
            let pageSize = this.noDutyRosterTable.pagination.pageSize;
            let pageIndex = this.noDutyRosterTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchNODutyRoster(wardId, pageSize, pageIndex, searchString);
                if (result) {
                    this.noDutyRosterTable.data = result.data;
                    this.noDutyRosterTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addNODutyRoster(form) {
            const roster = {
                title: form.title,
                file: form.file.raw,
                wardId: form.wardId,
                setId: form.set.value,
                remarks: form.remarks,
            };
            const result = await addNODutyRoster(roster);
            if (result) {
                this.uploadDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "NO Duty roster uploaded successfully",
                    message: name ? name + " was uploaded successfully" : "",
                    type: "success",
                });
                this.fetchNODutyRoster();
            }
        },
        async editNODutyRoster(form) {
            const roster = {
                id: form.id,
                title: form.title,
                file: form.oldFile.length == 0 ? form.file.raw : null,
                setId: form.set.value,
                remarks: form.remarks,
            };
            const result = await editNODutyRoster(roster);
            if (result) {
                this.editDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "NO Duty roster updated successfully",
                    message: name ? name + " was updated successfully" : "",
                    type: "success",
                });
                this.fetchNODutyRoster();
            }
        },
        async deleteNODutyRoster(action, rosterList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the NO duty roster(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteNODutyRoster(action, rosterList);
                    if (result) {
                        this.$notify({
                            title: "NO Duty roster(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchNODutyRoster();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // container header
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.noDutyRosterTable.pagination.currentPage = 1;
            this.fetchNODutyRoster();
        },
        // header
        handleUploadDialog() {
            this.uploadDialog.title = this.ward.label ? `NO Duty Roster (${this.ward.label})` : `NO Duty Roster`;
            this.uploadDialog.isVisible = true;
        },
        handleAction() {
            if (this.noDutyRosterTable.multipleSelection.length > 0) {
                this.deleteNODutyRoster(this.noDutyRosterTable.multipleSelection);
            }
        },
        // table
        searchNoDutyRoster() {
            this.noDutyRosterTable.pagination.currentPage = 1;
            this.fetchNODutyRoster();
        },
        handleDelete(index, row) {
            this.deleteNODutyRoster([row.id]);
        },
        async handleEdit(index, row) {
            try {
                this.editDialog.isLoading = true;
                const result = await fetchSingleNODutyRoster(row.id);
                if (result) {
                    this.editDialog.roster = result;
                    this.editDialog.title = "Edit NO Duty Roster";
                    this.editDialog.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editDialog.isLoading = false;
            }
        },
        handleSelectionChange(rows) {
            this.noDutyRosterTable.multipleSelection = rows.map(post => post.id);
        },
        getPdf(row, column) {
            if (column.property == "title" && row.file_path) {
                window.open(row.file_path, "_blank");
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.noDutyRosterTable.pagination.pageSize = pageSize;
            this.fetchNODutyRoster();
        },
        handleCurrentChange(currentPage) {
            this.noDutyRosterTable.pagination.currentPage = currentPage;
            this.fetchNODutyRoster();
        },
    },
};
</script>

<style lang="scss">
#no-duty-roster {
    height: 100%;
}
</style>
