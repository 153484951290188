import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/settings/ward?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async ward => {
    try {
        const params = {
            name: ward.name,
        };
        const result = await axios.post(`/admin/settings/ward`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async ward => {
    try {
        const params = {
            name: ward.name,
        };
        const result = await axios.put(`/admin/settings/ward/${ward.id}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async wardGroupList => {
    const params = {
        list: wardGroupList,
    };
    try {
        const result = await axios.post(`/admin/settings/ward/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
