import { render } from "./NOSettings.vue?vue&type=template&id=585ba6aa"
import script from "./NOSettings.vue?vue&type=script&lang=js"
export * from "./NOSettings.vue?vue&type=script&lang=js"

import "./NOSettings.vue?vue&type=style&index=0&id=585ba6aa&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "585ba6aa"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('585ba6aa', script)) {
    api.reload('585ba6aa', script)
  }
  
  module.hot.accept("./NOSettings.vue?vue&type=template&id=585ba6aa", () => {
    api.rerender('585ba6aa', render)
  })

}

script.__file = "src/components/annual-leave/NOSettings.vue"

export default script