<template>
    <el-dialog custom-class="edit-roster-dialog" :title="dialog.title" v-model="isVisible" :before-close="done => resetForm(done)" width="640px" destroy-on-close>
        <div class="description">
            <i class="el-icon-warning-outline el-icon-left"></i>
            <span class="contact">
                You may upload file in pdf format with a size less than 10MB at any time.
            </span>
        </div>
        <el-form class="upload-form" ref="uploadForm" :model="form" label-position="top" v-loading="isLoading" :rules="rules">
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="Week:" prop="set" size="small" required>
                        <el-select v-model="form.set.value" placeholder="Select a week" :loading="form.set.isLoading">
                            <el-option v-for="item in form.set.options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Title:" prop="title" size="small">
                        <el-input class="title word-limit-input" v-model="form.title" placeholder="Enter a title" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="File:" required size="small">
                        <el-upload
                            class="upload-pdf"
                            ref="upload"
                            accept=".pdf"
                            :auto-upload="false"
                            :limit="1"
                            :on-change="handleChange"
                            :on-remove="handleRemove"
                            action="#"
                            :file-list="form.oldFile"
                        >
                            <template #default v-if="isFileEmpty">
                                <el-button type="primary">
                                    <i class="el-icon-upload el-icon-left"></i>
                                    <span>Click to upload</span>
                                </el-button>
                            </template>
                            <template #file="{ file }">
                                <el-tooltip placement="top" effect="light">
                                    <template #content>
                                        <span class="file-name">
                                            {{ file.name }}
                                        </span>
                                    </template>
                                    <div class="el-upload-list__item-name">
                                        <i class="el-icon-document"></i>
                                        <span class="file-name" @click="handleFileClick(file)">
                                            {{ file.name }}
                                        </span>
                                        <div class="upload-actions">
                                            <i class="el-icon-upload-success el-icon-circle-check"></i>
                                            <i class="el-icon-close el-icon-circle-close" @click="handleRemove"></i>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-upload>
                        <div class="upload-error">
                            <span>{{ form.fileError }}</span>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="Remarks:" style="width: 100%" prop="remarks" size="small">
                    <el-input type="textarea" class="upload-textarea" v-model="form.remarks" placeholder="Please enter remarks" maxlength="50" show-word-limit></el-input>
                </el-form-item>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="submitUpload('uploadForm')" :disabled="isLoading">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="isVisible = false" :disabled="isLoading">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "../useInput";
import { fetchSet } from "@/modules/general/set";
export default {
    name: "EditRosterDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
        wardId: {
            type: Number,
            default: null,
        },
    },

    data() {
        const validateSet = (rule, set, callback) => {
            if (!set.value) {
                callback(new Error("Please select a week"));
            } else {
                callback();
            }
        };
        return {
            isLoading: false,
            form: {
                id: null,
                title: "",
                file: {},
                oldFile: [],
                fileError: "",
                set: {
                    isLoading: false,
                    options: [],
                    value: null,
                },
                remarks: "",
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: "Please enter a title",
                        trigger: ["blur", "change"],
                    },
                ],
                set: [
                    {
                        required: true,
                        validator: validateSet,
                        trigger: ["blur", "change"],
                    },
                ],
                remarks: [],
            },
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    computed: {
        isFileEmpty() {
            return Object.keys(this.form.file).length == 0 && this.form.oldFile.length == 0;
        },
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchSet();
            let roster = this.dialog.roster;
            this.form.id = roster.id;
            this.form.set.value = roster.set_id;
            this.form.title = roster.title;
            this.form.oldFile = [
                {
                    name: roster.file_path,
                    url: roster.file_path,
                },
            ];
            this.form.remarks = roster.remarks;
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        async fetchSet() {
            try {
                this.form.set.isLoading = true;
                const data = await fetchSet();
                if (data) {
                    this.form.set.options = data.map(set => {
                        return {
                            label: set.label,
                            value: set.id,
                        };
                    });
                }
            } finally {
                this.form.set.isLoading = false;
            }
        },
        async submitUpload(formName) {
            this.isLoading = true;

            this.$refs[formName].validate(async valid => {
                if (this.isFileEmpty) {
                    valid = this.validateFile(this.form.file);
                } else if (Object.keys(this.form.file).length != 0) {
                    valid = this.validateFile(this.form.file);
                } else if (this.form.oldFile.length > 0) {
                    valid = true;
                }
                if (valid) {
                    try {
                        await this.dialog.upload(this.form);
                    } finally {
                        this.isLoading = false;
                    }
                } else {
                    this.isLoading = false;
                    return false;
                }
            });
        },
        handleFileClick(file) {
            if (file.url) {
                window.open(file.url, "_blank");
            }
        },
        handleRemove() {
            this.$refs.upload.clearFiles();
            this.form.oldFile = [];
            this.form.file = {};
            this.validateFile(this.form.file);
        },
        validateFile(file) {
            if (Object.keys(file).length == 0) {
                this.form.fileError = "Please upload a pdf";
                return false;
            }
            const isPdf = file.raw.type === "application/pdf";
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isPdf) {
                this.form.fileError = "File can only be pdf format";
                return false;
            }
            if (!isLt10M) {
                this.form.fileError = "File cannot exceed 10MB";
                return false;
            }
            this.form.fileError = "";
            return true;
        },
        resetForm(done) {
            this.$refs.uploadForm.resetFields();

            this.form.oldFile = [];
            this.form.file = {};
            this.form.fileError = "";
            done();
        },
        handleChange(file) {
            if (this.validateFile(file)) {
                this.form.file = file;
            } else {
                this.$refs.upload.clearFiles();
            }
        },
    },
};
</script>
