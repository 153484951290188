<template>
    <el-container style="height: 100%;">
        <el-aside style="width: inherit;">
            <NavMenu :collapse="collapse" />
        </el-aside>
        <el-container>
            <el-header style="border-bottom: 1px solid #E2E2EA;" id="header">
                <Header @update:collapse="setCollapse" />
            </el-header>
            <el-main style="height: 100%; background-color: #f9f9f9;">
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import NavMenu from "@/components/NavMenu";
import Header from "@/components/Header";

export default {
    name: "Wrapper",
    components: {
        NavMenu,
        Header,
    },
    data() {
        return {
            collapse: false,
        };
    },
    methods: {
        setCollapse(val) {
            this.collapse = val;
        },
    },
};
</script>

<style lang="scss">
#header {
    padding: 0 40px;
    @media only screen and (max-width: 1024px) {
        padding: 0 16px;
        height: 48px !important;
    }
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
.page-content {
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: #4ac292;
        border: 0 solid #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #e8e8e8;
        border: 0 none #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #e8e8e8;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}
.el-main {
    background-color: #fbfbfb;
    height: 100%;
    padding: 40px;
    @media only screen and (max-width: 1024px) {
        padding: 4px 16px;
    }
}
.page-header-container {
    padding: 0;
}
.page-content-container {
    box-shadow: 3px 5px 22px rgba(0, 0, 0, 0.03);
    padding: 0;
}
.page-container {
    height: 100%;
}
</style>
