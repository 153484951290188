<template>
    <div id="no-annual-leave-schedule">
        <ViewContainer title="NO Annual Leave Schedule" :isLoading="isLoading" :buttons="containerHeaderButtons">
            <template v-slot:header>
                <div class="al-schedule-dropdown">
                    <el-dropdown trigger="click" @command="handleScheduleChange" v-if="schedule.options.length > 0 && schedule.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                        <span class="el-dropdown-link roster-header-title">
                            <span>
                                {{ schedule.label }}
                            </span>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item
                                    v-for="item in schedule.options"
                                    v-bind:key="item.value"
                                    :command="item.value"
                                    :class="[item.value == schedule.value ? 'is-chosen' : '']"
                                    :disabled="item.value == schedule.value"
                                >
                                    {{ item.label }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </template>
            <template v-slot:content>
                <div id="viewerContainer" v-if="Object.keys(schedule.target).length > 0 || isLoading">
                    <div id="innerViewerContainer"><div id="viewer" class="pdfViewer"></div></div>
                </div>
                <div class="no-data-container" v-else>
                    <el-image :src="noDataImageSrc" fit="contain">
                        <template #placeholder>
                            <div class="image-slot">
                                Loading
                                <span class="dot">...</span>
                            </div>
                        </template>
                    </el-image>
                    <div class="right-side">
                        <span class="title">No Schedule...</span>
                        <span class="description">
                            We can't seem to find the schedule in this ward
                            <br />
                            Try uploading a new schedule or Click on the top left dropdown menu for other wards.
                        </span>
                    </div>
                </div>
            </template>
        </ViewContainer>
        <UploadDialog v-if="uploadDialog.isVisible" :dialog="uploadDialog" :title="`Upload NO Annual Leave Schedule`" @update:isVisible="uploadDialog.isVisible = $event" />
    </div>
</template>

<script>
import { fetchData as fetchALSchedule, postData as addALSchedule } from "@/modules/annual-leave/no-annual-leave";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import UploadDialog from "@/components/annual-leave/UploadDialog";
import moment from "moment";
import noDataImageSrc from "@/assets/no-data.png";

import * as pdfjs from "pdfjs-dist/es5/build/pdf";
//import { GlobalWorkerOptions, getDocument } from "pdfjs-dist/es5";
import { PDFViewer, EventBus } from "pdfjs-dist/es5/web/pdf_viewer";
import "pdfjs-dist/es5/web/pdf_viewer.css";
import * as workerSrc from "pdfjs-dist/es5/build/pdf.worker.entry.js";
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

export default {
    name: "NOAnnualLeaveSchedule",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        UploadDialog,
    },
    data() {
        return {
            noDataImageSrc: noDataImageSrc,
            isLoading: false,
            containerHeaderButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleUploadDialog(),
                },
            ],
            uploadDialog: {
                isVisible: false,
                upload: form => this.addALSchedule(form),
            },
            schedule: {
                options: [],
                label: "",
                value: null,
                target: {},
            },
            container: null,
            loadingTask: {},
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchALSchedule();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "schedule.value"(val) {
            if (this.schedule.options && this.schedule.options.length > 0) {
                const target = this.schedule.options.find(({ value }) => value === val);
                if (target) {
                    this.schedule.label = target.label;
                    this.schedule.target = target;
                }
            }
            return "";
        },
        "schedule.target": {
            async handler() {
                if (Object.keys(this.loadingTask).length > 0) {
                    this.loadingTask.destroy();
                }
                this.$nextTick(async () => {
                    this.getPdf();
                });
            },
            deep: true,
        },
    },
    methods: {
        async getPdf() {
            const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
            const CMAP_PACKED = true;

            if (!this.schedule.target.file_path) {
                return;
            }
            const DEFAULT_URL = this.schedule.target.file_path;
            // To test the AcroForm and/or scripting functionality, try e.g. this file:
            // var DEFAULT_URL = "../../test/pdfs/160F-2019.pdf";

            const container = document.getElementById("innerViewerContainer");

            const eventBus = new EventBus();

            const pdfViewer = new PDFViewer({
                container,
                eventBus,
                enableScripting: true,
            });

            eventBus.on("pagesinit", function() {
                // We can use pdfViewer now, e.g. let's change default scale.
                pdfViewer.currentScaleValue = "page-width";
            });

            // Loading document.
            this.loadingTask = pdfjs.getDocument({
                url: DEFAULT_URL,
                cMapUrl: CMAP_URL,
                cMapPacked: CMAP_PACKED,
            });
            this.isLoading = true;
            this.loadingTask.promise
                .then(async pdfDocument => {
                    // Document loaded, specifying document for the viewer and
                    // the (optional) linkService.
                    pdfViewer.setDocument(pdfDocument);
                })
                .finally(() => {
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async fetchALSchedule() {
            this.isLoading = true;
            try {
                const data = await fetchALSchedule();
                if (data) {
                    this.schedule.options = data.map(item => {
                        return {
                            value: item.id,
                            label: `${item.year}: ` + item.title,
                            year: item.year,
                            file_path: item.file_path,
                        };
                    });

                    const target = this.schedule.options.find(({ year }) => {
                        return moment().year() <= Number(year);
                    });
                    if (target) {
                        this.schedule.value = target.value;
                    } else {
                        this.schedule.value = this.schedule.options[0] ? this.schedule.options[0].value : null;
                    }
                    await this.getPdf();
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addALSchedule(form) {
            try {
                this.isLoading = true;
                const schedule = {
                    title: form.title,
                    year: moment(form.year).format("YYYY"),
                    file: form.file.raw,
                };
                const result = await addALSchedule(schedule);
                if (result) {
                    this.uploadDialog.isVisible = false;
                    let name = form.file.name;
                    this.$notify({
                        title: "Annual Leave Schedule uploaded successfully",
                        message: name ? name + " was uploaded successfully" : "",
                        type: "success",
                    });
                    this.fetchALSchedule();
                }
            } finally {
                this.isLoading = false;
            }
        },
        handleScheduleChange(scheduleId) {
            this.schedule.value = scheduleId;
        },
        // header
        handleUploadDialog() {
            this.uploadDialog.isVisible = true;
        },
    },
};
</script>

<style lang="scss">
#no-annual-leave-schedule {
    height: 100%;
    .pdf-container {
        width: 100%;
        height: 100%;
    }
    .page-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .al-schedule-dropdown {
            width: 100%;
        }
        #viewerContainer {
            position: relative;
            height: 100%;
        }
        #innerViewerContainer {
            position: absolute;
            width: 100%;
            height: calc(100% - 20px);
            margin-top: 8px;
            overflow: auto;
        }
        .no-data-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .el-image {
                max-width: 480px;
                @media only screen and (max-width: 1024px) {
                    max-width: 320px;
                }
            }
            .right-side {
                display: flex;
                flex-direction: column;
                max-width: 480px;
                @media only screen and (max-width: 1024px) {
                    max-width: 320px;
                }
                .title {
                    font-size: 40px;
                    font-weight: bold;
                    color: #44444f;
                    margin-bottom: 16px;
                }
                .description {
                    font-size: 13px;
                    color: #aaa;
                    line-height: 1.5;
                    margin-bottom: 40px;
                }
            }
        }
    }
}
</style>
