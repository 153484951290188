import { render } from "./EditPostForm.vue?vue&type=template&id=df0fa362"
import script from "./EditPostForm.vue?vue&type=script&lang=js"
export * from "./EditPostForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "df0fa362"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('df0fa362', script)) {
    api.reload('df0fa362', script)
  }
  
  module.hot.accept("./EditPostForm.vue?vue&type=template&id=df0fa362", () => {
    api.rerender('df0fa362', render)
  })

}

script.__file = "src/components/knowledge/EditPostForm.vue"

export default script