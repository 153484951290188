<template>
    <el-form class="edit-policy-form" ref="editPolicyForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Cover Image:">
                    <el-upload
                        class="upload-cover-image"
                        ref="uploadCoverImage"
                        accept="image/*"
                        :auto-upload="false"
                        :limit="1"
                        :on-change="handleImageChange"
                        action="#"
                        :file-list="form.oldCoverImage"
                    >
                        <template #default v-if="isCoverImageEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="bottom" effect="light">
                                <template #content>
                                    <img :src="imagePreview ? imagePreview : form.oldCoverImage[0] ? form.oldCoverImage[0].url : ''" class="cover-image-preview" />
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleImageRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>{{ form.coverImageError }}</span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="24">
                <el-form-item label="Content:" prop="content">
                    <TinyMCE @update:content="handleUpdateContent" :initialValue="form.content" :image="false"></TinyMCE>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { putData as editPolicy } from "@/modules/settings/policies";
import TinyMCE from "@/components/TinyMCE";
export default {
    name: "EditPolicyForm",
    props: ["isLoading", "policy"],
    components: { TinyMCE },
    data() {
        return {
            imagePreview: "",
            form: {
                rules: {
                    content: [
                        {
                            required: true,
                            message: "Please enter content",
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                id: null,
                content: "",
                oldCoverImage: [],
                coverImage: {},
                coverImageError: "",
            },
        };
    },
    async mounted() {
        let loading;
        try {
            loading = this.$loading({ target: ".edit-policy-form" });
            this.form.id = this.policy.id;
            this.form.content = this.policy.content;
            this.form.oldCoverImage = this.policy.cover_image_path
                ? [
                      {
                          name: this.policy.cover_image_path,
                          url: this.policy.cover_image_path,
                      },
                  ]
                : [];
        } catch (error) {
            console.log(error);
        } finally {
            loading.close();
        }
    },
    computed: {
        isCoverImageEmpty() {
            return Object.keys(this.form.coverImage).length == 0 && this.form.oldCoverImage.length == 0;
        },
    },
    methods: {
        async editPolicy() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                const policy = {
                    id: this.form.id,
                    content: this.form.content,
                    coverImage: this.isCoverImageEmpty ? "null" : this.form.coverImage.raw,
                };
                const result = await editPolicy(policy);
                return result;
            } catch (error) {
                console.log(error);
            }
        },
        handleImageChange(image) {
            if (this.validateImage(image)) {
                this.form.coverImage = image;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(image.raw);
            } else {
                this.$refs.uploadCoverImage.clearFiles();
            }
        },
        handleImageRemove() {
            this.$refs.uploadCoverImage.clearFiles();
            this.form.oldCoverImage = [];
            this.form.coverImage = {};
            this.validateImage(this.form.coverImage);
        },
        validateImage(image) {
            if (Object.keys(image).length == 0) {
                return true;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;
            if (!isImage) {
                this.form.coverImageError = "Image can only be png/jpeg/jpg format";
                return false;
            }
            if (!isLt10M) {
                this.form.coverImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.coverImageError = "";
            return true;
        },
        async validateForm() {
            try {
                const imageResult = this.validateImage(this.form.coverImage);
                const result = await this.$refs.editPolicyForm.validate();
                return result && imageResult;
            } catch (error) {
                return false;
            }
        },
        resetForm() {
            this.$refs.editPolicyForm.resetFields();
            this.$refs.uploadCoverImage.clearFiles();
            this.form.oldCoverImage = [];
            this.form.coverImage = {};
            this.form.coverImageError = "";
            this.form.content = "";
        },
        handleUpdateContent(value) {
            this.form.content = value;
        },
    },
};
</script>

<style lang="scss">
#survival-working-tips {
    .category-error {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}
</style>
