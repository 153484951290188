import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString, postType, filter) => {
    try {
        const result = await axios.get(
            `/admin/recreation?wardId=${wardId}&pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}&type=${postType ? postType : ""}&title=${filter.title}&content=${
                filter.content
            }`,
        );
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDetails = async id => {
    try {
        const result = await axios.get(`/admin/recreation/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchResult = async id => {
    try {
        const result = await axios.get(`/admin/recreation/result/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchFeedback = async id => {
    try {
        const result = await axios.get(`/admin/recreation/feedback/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async recreation => {
    try {
        const formData = new FormData();
        formData.append("title", recreation.title);
        for (let i = 0; i < recreation.wardList.length; i++) {
            formData.append("wardList[]", recreation.wardList[i]);
        }
        // formData.append("postType", recreation.postType);
        if (recreation.file) {
            formData.append("file", recreation.file);
        }
        formData.append("content", recreation.content);
        formData.append("publishedDate", recreation.publishDate);
        formData.append("endDate", recreation.endDate);

        if (recreation.postType == 1) {
            formData.append("pollingEndDate", recreation.pollingEndDate);
            formData.append("pollingQuestion", recreation.pollingQuestion);
            for (let i = 0; i < recreation.pollingOptions.length; i++) {
                formData.append("pollingOptions[]", recreation.pollingOptions[i]);
            }
            formData.append("voteChange", recreation.voteChange);
            formData.append("showResult", recreation.showResult);
            formData.append("rememberName", !recreation.isSecret);
        }

        const result = await axios.post(`/admin/recreation`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async recreation => {
    try {
        const formData = new FormData();
        formData.append("title", recreation.title);
        for (let i = 0; i < recreation.wardList.length; i++) {
            formData.append("wardList[]", recreation.wardList[i]);
        }
        // formData.append("postType", recreation.postType);
        if (recreation.file) {
            formData.append("file", recreation.file);
        }
        formData.append("content", recreation.content);
        formData.append("publishedDate", recreation.publishDate);
        formData.append("endDate", recreation.endDate);

        if (recreation.postType == 1) {
            formData.append("pollingEndDate", recreation.pollingEndDate);
            formData.append("pollingQuestion", recreation.pollingQuestion);
            for (let i = 0; i < recreation.pollingOptions.length; i++) {
                formData.append("pollingOptions[]", recreation.pollingOptions[i]);
            }
            formData.append("voteChange", recreation.voteChange);
            formData.append("showResult", recreation.showResult);
            formData.append("rememberName", !recreation.isSecret);
        }

        const result = await axios.put(`/admin/recreation/${recreation.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async recreationList => {
    const params = {
        list: recreationList,
    };
    try {
        const result = await axios.post(`/admin/recreation/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
