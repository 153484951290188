<template>
    <el-container class="tab-container">
        <el-main class="tab" v-loading="isLoading">
            <slot name="header" class="tab-header"></slot>
            <slot name="content" class="tab-content"></slot>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: "TabContainer",
    props: ["isLoading"],
};
</script>

<style lang="scss">
.tab-container {
    overflow: auto;
}
.tab {
    box-shadow: 3px 5px 22px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    background-color: #fff;
    text-align: left;
    padding: 0 16px;
}
</style>
