<template>
    <div id="recreation">
        <ViewContainer :title="`Recreation`" :numberOfItems="recreationTable.numberOfItems ? recreationTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="recreationTable" />
            </template>
        </ViewContainer>
        <Drawer :drawer="addRecreationDrawer" @update:isVisible="addRecreationDrawer.isVisible = $event">
            <template v-slot:content>
                <AddRecreationForm ref="addRecreationForm" :isLoading="addRecreationDrawer.isLoading" />
            </template>
        </Drawer>
        <Drawer :drawer="editRecreationDrawer" @update:isVisible="editRecreationDrawer.isVisible = $event">
            <template v-slot:content>
                <EditRecreationForm ref="editRecreationForm" :isLoading="editRecreationDrawer.isLoading" :recreation="editRecreationDrawer.recreation" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchRecreation, deleteData as deleteRecreation, fetchDetails as fetchRecreationDetails } from "@/modules/recreation";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import Drawer from "@/components/Drawer";
import AddRecreationForm from "@/components/recreation/AddRecreationForm";
import EditRecreationForm from "@/components/recreation/EditRecreationForm";
import moment from "moment";

export default {
    name: "Recreation",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        Drawer,
        AddRecreationForm,
        EditRecreationForm,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, target dept, author",
                value: "",
                callback: () => this.searchRecreation(),
            },
            filterPopover: {
                isVisible: false,
                title: true,
                content: false,
                applyFilter: filter => this.applyFilter(filter),
                cancelFilter: () => this.cancelFilter(),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleNewRecreation(),
                },
            ],
            recreationTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.handleRowClick(row, column, e),
                filterChange: filters => this.filterChange(filters),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "type",
                        sortable: false,
                        prop: "type",
                        minWidth: "80",
                        filters: [
                            { text: "Polling", value: "Polling" },
                            { text: "Normal", value: "Normal" },
                        ],
                        className: "filterable",
                        filterMultiple: false,
                    },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "ward",
                        width: "132",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "152",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        width: "152",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handleEdit: (index, row) => this.handleEditRecreation(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addRecreationDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Recreation Post",
                save: () => this.addRecreation(),
                close: done => this.closeAddRecreationDrawer(done),
            },
            editRecreationDrawer: {
                recreation: {},
                isVisible: false,
                isLoading: false,
                title: "Edit Recreation Post",
                save: () => this.editRecreation(),
                close: done => this.closeEditRecreationDrawer(done),
            },
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchRecreation();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
        filterPopover: {
            handler() {
                this.searchInput.placeholder = `Search by ${this.filterPopover.title ? "title, " : ""}${this.filterPopover.content ? "content, " : ""}category, author, remarks`;
            },
            deep: true,
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchRecreation(type) {
            this.isLoading = true;
            let pageSize = this.recreationTable.pagination.pageSize;
            let pageIndex = this.recreationTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            let postType = type;
            let filter = {
                title: this.filterPopover.title,
                content: this.filterPopover.content,
            };
            try {
                const result = await fetchRecreation(this.ward.value, pageSize, pageIndex, searchString, postType, filter);
                if (result) {
                    this.recreationTable.data = result.data;
                    this.recreationTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addRecreation() {
            try {
                this.addRecreationDrawer.isLoading = true;
                const result = await this.$refs.addRecreationForm.addRecreation();
                if (result) {
                    this.addRecreationDrawer.isVisible = false;
                    this.$notify({
                        title: "Recreation added successfully",
                        message: "Recreation " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchRecreation();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addRecreationDrawer.isLoading = false;
            }
        },
        async deleteRecreation(recreationList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the recreation(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteRecreation(recreationList);
                    if (result) {
                        this.$notify({
                            title: "Recreation(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchRecreation();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchRecreationDetails(id) {
            try {
                this.editRecreationDrawer.isLoading = true;
                const data = await fetchRecreationDetails(id);
                if (data) {
                    this.editRecreationDrawer.recreation = data;
                }
            } finally {
                this.editRecreationDrawer.isLoading = false;
            }
        },
        async editRecreation() {
            this.editRecreationDrawer.isLoading = true;
            try {
                const result = await this.$refs.editRecreationForm.editRecreation();
                if (result) {
                    this.editRecreationDrawer.isVisible = false;
                    this.$notify({
                        title: "Recreation updated successfully",
                        message: "Recreation " + result + " was updated successfully",
                        type: "success",
                    });
                    this.fetchRecreation();
                }
            } finally {
                this.editRecreationDrawer.isLoading = false;
            }
        },
        // header
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.recreationTable.pagination.currentPage = 1;
            this.fetchRecreation();
        },
        handleAction(selection) {
            if (this.recreationTable.multipleSelection.length > 0) {
                if (selection == 0) {
                    this.deleteMessages(this.recreationTable.multipleSelection);
                }
            }
        },
        handleNewRecreation() {
            this.addRecreationDrawer.isVisible = true;
        },
        applyFilter(filter) {
            this.filterPopover.title = JSON.parse(JSON.stringify(filter.title));
            this.filterPopover.content = JSON.parse(JSON.stringify(filter.content));
            this.filterPopover.isVisible = false;
        },
        cancelFilter() {
            this.filterPopover.isVisible = false;
        },

        // table
        searchRecreation() {
            this.recreationTable.pagination.currentPage = 1;
            this.fetchRecreation();
        },
        handleDelete(index, row) {
            this.deleteRecreation([row.id]);
        },
        async handleEditRecreation(index, row) {
            this.isLoading = true;
            try {
                const data = await fetchRecreationDetails(row.id);
                if (data) {
                    this.editRecreationDrawer.recreation = data;
                    if (this.isEditDisable()) {
                        this.$notify({
                            title: "Warning",
                            message: "Post cannot be edited after published",
                            type: "warning",
                        });
                        this.editRecreationDrawer.isVisible = false;
                    } else {
                        this.editRecreationDrawer.isVisible = true;
                    }
                }
            } finally {
                this.isLoading = false;
            }
        },
        isEditDisable() {
            let publishDate = this.editRecreationDrawer.recreation.published_date;
            if (moment().isSameOrAfter(publishDate)) {
                return true;
            } else {
                return false;
            }
        },
        handleSelectionChange(rows) {
            this.recreationTable.multipleSelection = rows.map(post => post.id);
        },
        handleRowClick(row, column) {
            if (column.property == "title") {
                this.$router.push({
                    path: "/recreation/" + row.id,
                });
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.recreationTable.pagination.pageSize = pageSize;
            this.fetchRecreation();
        },
        handleCurrentChange(currentPage) {
            this.recreationTable.pagination.currentPage = currentPage;
            this.fetchRecreation();
        },
        closeAddRecreationDrawer() {
            this.$refs.addRecreationForm.resetForm();
            this.addRecreationDrawer.isVisible = false;
        },
        closeEditRecreationDrawer() {
            this.$refs.editRecreationForm.resetForm();
            this.editRecreationDrawer.isVisible = false;
        },
        filterChange(filters) {
            this.fetchRecreation(filters.type[0]);
        },
    },
};
</script>

<style lang="scss">
#recreation {
    height: 100%;
}
</style>
