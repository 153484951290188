<template>
    <div id="survival-working-tips">
        <ViewContainer
            :title="`Survival Working Tips`"
            :numberOfItems="survivalWorkingTipsTable.numberOfItems ? survivalWorkingTipsTable.numberOfItems : 0"
            :isLoading="isLoading"
            :buttons="containerHeaderButtons"
        >
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value != null" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="survivalWorkingTipsTable" />
            </template>
        </ViewContainer>
        <Drawer ref="addTipsDrawer" :drawer="addTipsDrawer" @update:isVisible="addTipsDrawer.isVisible = $event">
            <template v-slot:content>
                <AddSurvivalWorkingTipsForm ref="addTipsForm" :isLoading="addTipsDrawer.isLoading" />
            </template>
        </Drawer>
        <Drawer ref="editTipsDrawer" :drawer="editTipsDrawer" @update:isVisible="editTipsDrawer.isVisible = $event">
            <template v-slot:content>
                <EditSurvivalWorkingTipsForm ref="editTipsForm" :tips="editTipsDrawer.tips" :isLoading="editTipsDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchTips, fetchSingleData as fetchSingleTips, deleteData as deleteTips } from "@/modules/survival-working-tips.js";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import Drawer from "@/components/Drawer";
import AddSurvivalWorkingTipsForm from "@/components/survival-working-tips/AddSurvivalWorkingTipsForm";
import EditSurvivalWorkingTipsForm from "@/components/survival-working-tips/EditSurvivalWorkingTipsForm";

export default {
    name: "SurvivalWorkingTips",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        Drawer,
        AddSurvivalWorkingTipsForm,
        EditSurvivalWorkingTipsForm,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, category, author, remarks",
                value: "",
                callback: () => this.fetchTips(),
            },
            filterPopover: {
                isVisible: false,
                title: true,
                content: false,
                applyFilter: filter => this.applyFilter(filter),
                cancelFilter: () => this.cancelFilter(),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
            containerHeaderButtons: [
                {
                    name: "Manage Category",
                    callback: () => this.handleCategory(),
                },
            ],
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleAddTipsDrawer(),
                },
            ],
            survivalWorkingTipsTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getFile(row, column, e),
                classChecker: ({ row, column, rowIndex, columnIndex }) => this.classChecker({ row, column }),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                    },
                    {
                        label: "category",
                        sortable: false,
                        prop: "category_name",
                        minWidth: "112",
                    },
                    // {
                    //     label: "end date",
                    //     sortable: true,
                    //     prop: "end_date",
                    //     minWidth: "136",
                    // },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "target_ward",
                        width: "132",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "95",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        minWidth: "136",
                    },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handleEdit: (index, row) => this.handleEdit(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addTipsDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Survival Working Tips",
                save: () => this.addTips(),
                close: done => this.closeAddTipsDrawer(done),
            },
            editTipsDrawer: {
                isVisible: false,
                isLoading: false,
                tips: {},
                title: "Edit Survival Working Tips",
                save: () => this.editTips(),
                close: done => this.closeEditTipsDrawer(done),
            },
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchTips();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
        filterPopover: {
            handler() {
                this.searchInput.placeholder = `Search by ${this.filterPopover.title ? "title, " : ""}${this.filterPopover.content ? "content, " : ""}category, author, remarks`;
            },
            deep: true,
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.options.unshift({ value: "", label: "All" });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchTips() {
            this.isLoading = true;
            let pageSize = this.survivalWorkingTipsTable.pagination.pageSize;
            let pageIndex = this.survivalWorkingTipsTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            let filter = {
                title: this.filterPopover.title,
                content: this.filterPopover.content,
            };
            try {
                const result = await fetchTips(this.ward.value, pageSize, pageIndex, searchString, filter);
                if (result) {
                    this.survivalWorkingTipsTable.data = result.data;
                    this.survivalWorkingTipsTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addTips() {
            try {
                this.addTipsDrawer.isLoading = true;
                const result = await this.$refs.addTipsForm.addTips();
                if (result) {
                    this.addTipsDrawer.isVisible = false;
                    this.$notify({
                        title: "Survival Working Tips uploaded successfully",
                        message: result + " was uploaded successfully",
                        type: "success",
                    });
                    this.fetchTips();
                }
            } finally {
                this.addTipsDrawer.isLoading = false;
            }
        },
        async editTips() {
            try {
                this.editTipsDrawer.isLoading = true;
                const result = await this.$refs.editTipsForm.editTips();
                if (result) {
                    this.editTipsDrawer.isVisible = false;
                    this.$notify({
                        title: "Survival Working Tips updated successfully",
                        message: result + " was updated successfully",
                        type: "success",
                    });
                    this.fetchTips();
                }
            } finally {
                this.editTipsDrawer.isLoading = false;
            }
        },
        async deleteTips(action, tipsList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the survival working tips. Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteTips(action, tipsList);
                    if (result) {
                        this.$notify({
                            title: "Survival working tip(s) deleted successfully",
                            type: "success",
                        });
                        this.fetchTips();
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },

        // container header
        handleCategory() {
            this.$router.push({
                path: "/survival-working-tips/category",
            });
        },

        // header
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.survivalWorkingTipsTable.pagination.currentPage = 1;
            this.fetchTips();
        },
        handleAddTipsDrawer() {
            this.addTipsDrawer.isVisible = true;
        },
        closeAddTipsDrawer() {
            this.$refs.addTipsForm.resetForm();
            this.addTipsDrawer.isVisible = false;
        },
        handleEditTipsDrawer() {
            this.editTipsDrawer.isVisible = true;
        },
        closeEditTipsDrawer() {
            this.$refs.editTipsForm.resetForm();
            this.editTipsDrawer.isVisible = false;
        },
        handleAction() {
            if (this.survivalWorkingTipsTable.multipleSelection.length > 0) {
                this.deleteTips(this.survivalWorkingTipsTable.multipleSelection);
            }
        },
        applyFilter(filter) {
            this.filterPopover.title = JSON.parse(JSON.stringify(filter.title));
            this.filterPopover.content = JSON.parse(JSON.stringify(filter.content));
            this.filterPopover.isVisible = false;
        },
        cancelFilter() {
            this.filterPopover.isVisible = false;
        },

        // table
        handleDelete(index, row) {
            this.deleteTips([row.id]);
        },
        async handleEdit(index, row) {
            try {
                this.editTipsDrawer.isLoading = true;
                const result = await fetchSingleTips(row.id);
                if (result) {
                    this.editTipsDrawer.tips = result;
                    this.editTipsDrawer.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editTipsDrawer.isLoading = false;
            }
        },
        handleSelectionChange(rows) {
            this.survivalWorkingTipsTable.multipleSelection = rows.map(tips => tips.id);
        },
        getFile(row, column) {
            if (column.property == "title") {
                if (row.video_path) {
                    window.open(row.video_path, "_blank");
                } else if (row.cover_image_path) {
                    window.open(row.cover_image_path, "_blank");
                }
                return;
            } else {
                return;
            }
        },
        classChecker({ row, column }) {
            if (column.property == "title") {
                const videoPath = row.video_path ? row.video_path : null;
                const imagePath = row.cover_image_path ? row.cover_image_path : null;

                if (videoPath || imagePath) {
                    return "link-type";
                } else {
                    return "";
                }
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.survivalWorkingTipsTable.pagination.pageSize = pageSize;
            this.survivalWorkingTipsTable.pagination.currentPage = 1;
            this.fetchTips();
        },
        handleCurrentChange(currentPage) {
            this.survivalWorkingTipsTable.pagination.currentPage = currentPage;
            this.fetchTips();
        },
    },
};
</script>

<style lang="scss">
#survival-working-tips {
    height: 100%;
}
</style>
