<template>
    <div id="login-container">
        <el-row id="login-row" type="flex" justify="center" align="middle">
            <el-col :md="10" :xs="24">
                <div class="login-left-side">
                    <img id="image-logo" src="../assets/logo-login.svg" />
                    <div id="mcg-text">MCG Functionalities</div>
                    <img id="image-app" src="../assets/illustration-login.svg" />
                </div>
            </el-col>
            <el-col :md="14" :xs="24" v-if="isForgotPassword">
                <div class="login-right-side">
                    <div><h2 id="login-topic">Forgot Password</h2></div>
                    <el-form ref="forgotPasswordForm" :model="ruleForm" :rules="rules" v-loading="isLoading">
                        <el-form-item label="Email:" prop="email">
                            <el-input size="small" v-model="ruleForm.email" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-button class="confirm-btn" type="primary" native-type="submit" @click="handleForgotPassword('forgotPasswordForm')">
                            Confirm
                        </el-button>
                        <el-button class="cancel-btn" native-type="submit" @click="isForgotPassword = false">
                            Back
                        </el-button>
                    </el-form>
                </div>
            </el-col>
            <el-col :md="14" :xs="24" v-else>
                <div class="login-right-side">
                    <div><h2 id="login-topic">Log In to Your Account</h2></div>
                    <el-form ref="loginForm" :model="ruleForm" :rules="rules" v-loading="isLoading">
                        <el-form-item label="Email / User name:" prop="username" :error="inputError.username">
                            <el-input size="small" v-model="ruleForm.username" suffix-icon="el-icon-message"></el-input>
                        </el-form-item>
                        <el-form-item label="Password:" prop="password" :error="inputError.password">
                            <el-input size="small" v-model="ruleForm.password" type="password" suffix-icon="el-icon-lock"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button id="login-button" type="primary" native-type="submit" v-on:click="handleSubmit">
                                Login
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <div class="forgot-password" @click="isForgotPassword = true">
                        Forgot password?
                    </div>
                    <div class="version-number">v{{ version }}</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { login, forgotPassword } from "@/modules/auth";
export default {
    name: "Login",
    data() {
        const ruleForm = {
            username: "",
            password: "",
            email: "",
            currentPassword: "",
            newPassword: "",
            newPassword2: "",
        };
        const inputError = {
            username: "",
            password: "",
        };
        const rules = {
            username: [
                {
                    required: true,
                    message: "Please enter the username",
                    trigger: "blur",
                },
                {
                    min: 1,
                    message: "Username is at least 1 character",
                    trigger: "blur",
                },
            ],
            password: [
                {
                    required: true,
                    message: "Please enter the password",
                    trigger: "blur",
                },
            ],
            email: [
                {
                    required: true,
                    message: "Please enter the email",
                    trigger: ["blur", "change"],
                },
                {
                    type: "email",
                    message: "Please enter the correct format",
                    trigger: ["blur", "change"],
                },
            ],
        };
        return {
            isLoading: false,
            isForgotPassword: false,
            ruleForm,
            inputError,
            rules,
            version: process.env.VUE_APP_VERSION,
        };
    },
    methods: {
        async handleSubmit(e) {
            e.preventDefault();
            try {
                this.isLoading = true;
                this.$data.inputError.username = "";
                this.$data.inputError.password = "";
                const validation = this.$refs["loginForm"].validate();
                if (validation) {
                    const loginResult = await login(this.ruleForm.username, this.ruleForm.password);
                    if (loginResult) {
                        if (this.$route.params.nextUrl != null) {
                            this.$router.push(this.$route.params.nextUrl);
                        } else {
                            this.$router.push("/canteen-menu");
                        }
                    }
                }
            } catch (error) {
                if (error.toString().indexOf("Error: User not found") !== -1) {
                    this.$data.inputError.username = "User not found";
                } else if (error.toString().indexOf("Error: Password is incorrect") !== -1) {
                    this.$data.inputError.password = "Password is incorrect";
                } else if (error.toString().indexOf("invalid request payload input") !== -1) {
                    this.$data.inputError.username = "Invalid input";
                    this.$data.inputError.password = "Invalid input";
                } else if (error.toString().indexOf("Error: Permission denied") !== -1) {
                    this.$notify({
                        title: "Error: 1010",
                        message: "Permission denied",
                        type: "error",
                    });
                }
            } finally {
                this.isLoading = false;
            }
        },
        async handleForgotPassword(formName) {
            this.$refs[formName].validate(async valid => {
                if (valid) {
                    try {
                        this.isLoading = true;
                        const result = await forgotPassword(this.ruleForm);
                        if (result) {
                            this.$notify({
                                title: "Success",
                                message: "We have sent a new password to your email",
                                type: "success",
                            });
                        }
                    } finally {
                        this.isLoading = false;
                    }
                } else {
                    this.isLoading = false;
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss">
#login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 5px 40px rgba(205, 205, 212, 0.2);
    border-radius: 25px;
    .forgot-password {
        font-size: 12px;
        color: #606266;
        &:hover {
            color: #36b37e;
            cursor: pointer;
        }
    }
    .version-number {
        text-align: end;
        font-size: 12px;
        color: #ccc;
    }
    .confirm-btn {
        margin-top: 4px;
        width: 100%;
        background-color: #36b37e;
        border: 0;
    }
    .cancel-btn {
        margin-left: 0;
        margin-top: 8px;
        width: 100%;
        background-color: #ebecf0;
        color: #b3bac5;
        border: 0;
    }
}
#login-row {
    width: 40vw;
    @media only screen and (max-width: 1024px) {
        min-width: 80vw;
    }
    box-shadow: 3px 5px 40px rgba(205, 205, 212, 0.2);
    border-radius: 25px;
}
.login-left-side {
    text-align: center;
    background: #f2fffa;
    padding: 40px;
}
.login-right-side {
    height: 100%;
    padding: 0 40px;
}
#image-logo {
    width: 100%;
    max-width: 65%;
    height: auto;
}
#mcg-text {
    padding-top: 2em;
    padding-bottom: 2em;
}
#image-app {
    height: 14em;
    width: auto;
}
#login-topic {
    text-align: left;
    font-weight: 600;
}
#login-button {
    margin-top: 16px;
    width: 100%;
    background-color: #36b37e;
    border-color: #36b37e;
}
</style>
