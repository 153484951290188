import { render } from "./ResultTab.vue?vue&type=template&id=76d12884"
import script from "./ResultTab.vue?vue&type=script&lang=js"
export * from "./ResultTab.vue?vue&type=script&lang=js"

import "./ResultTab.vue?vue&type=style&index=0&id=76d12884&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "76d12884"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('76d12884', script)) {
    api.reload('76d12884', script)
  }
  
  module.hot.accept("./ResultTab.vue?vue&type=template&id=76d12884", () => {
    api.rerender('76d12884', render)
  })

}

script.__file = "src/components/recreation/tabs/ResultTab.vue"

export default script