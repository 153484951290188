<template>
    <div id="compliment">
        <ViewContainer :title="`Compliment`" :numberOfItems="complimentTable.numberOfItems ? complimentTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="complimentTable" />
            </template>
        </ViewContainer>
    </div>
    <UploadDialog v-if="uploadDialog.isVisible" :dialog="uploadDialog" @update:isVisible="uploadDialog.isVisible = $event" :hasTargetWard="true" />
    <EditComplimentDialog v-if="editComplimentDialog.isVisible" :dialog="editComplimentDialog" @update:isVisible="editComplimentDialog.isVisible = $event" />
</template>

<script>
import { fetchData as fetchCompliments, postData as addCompliment, putData as editCompliment, deleteData as deleteCompliments } from "@/modules/compliment";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import UploadDialog from "@/components/UploadDialog";
import EditComplimentDialog from "@/components/EditComplimentDialog";
import moment from "moment";
export default {
    name: "Compliment",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        UploadDialog,
        EditComplimentDialog,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, author, remarks",
                value: "",
                callback: () => this.searchCompliments(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleUploadDialog(),
                },
            ],
            complimentTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getPdf(row, column, e),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "ward",
                        width: "132",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "95",
                    },
                    {
                        label: "compliment date",
                        sortable: true,
                        prop: "published_date",
                        width: "160",
                    },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleEdit: (index, row) => this.handleEdit(index, row),
                handleDelete: (index, row) => this.handleDelete(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            uploadDialog: {
                isVisible: false,
                title: "Compliment",
                publishDateLabel: "Compliment date:",
                upload: form => this.addCompliment(form),
            },
            editComplimentDialog: {
                isVisible: false,
                title: "Edit Compliment",
                form: {},
                upload: form => this.editCompliment(form),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchCompliments();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchCompliments() {
            this.isLoading = true;
            let pageSize = this.complimentTable.pagination.pageSize;
            let pageIndex = this.complimentTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            let wardId = this.ward.value;
            try {
                if (wardId) {
                    const result = await fetchCompliments(this.ward.value, pageSize, pageIndex, searchString);
                    if (result) {
                        this.complimentTable.data = result.data;
                        this.complimentTable.numberOfItems = result.count;
                    } else {
                        this.complimentTable.data = [];
                        this.complimentTable.numberOfItems = 0;
                    }
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addCompliment(form) {
            const compliment = {
                title: form.title,
                wardList: form.ward.value,
                file: form.file.raw,
                publishDate: moment(form.publishDate).format("YYYY-MM-DD HH:mm"),
                endDate: moment(form.endDate).format("YYYY-MM-DD HH:mm"),
                remarks: form.remarks,
            };
            const result = await addCompliment(compliment);
            if (result) {
                this.uploadDialog.isVisible = false;
                let name = form.file.name;
                this.$notify({
                    title: "Compliment uploaded successfully",
                    message: name ? name + " was uploaded successfully" : "",
                    type: "success",
                });
                this.fetchCompliments();
            }
        },
        async editCompliment(form) {
            const compliment = {
                id: form.id,
                title: form.title,
            };
            const result = await editCompliment(compliment);
            if (result) {
                this.editComplimentDialog.isVisible = false;
                let title = compliment.title;
                this.$notify({
                    title: "Compliment edited successfully",
                    message: title ? title + " was edited successfully" : "",
                    type: "success",
                });
                this.fetchCompliments();
            }
        },
        async deleteCompliments(complimentList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the compliment(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteCompliments(complimentList);
                    if (result) {
                        this.$notify({
                            title: "Compliment(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchCompliments();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.complimentTable.pagination.currentPage = 1;
            this.fetchCompliments();
        },
        // header
        handleUploadDialog() {
            this.uploadDialog.isVisible = true;
        },
        handleAction() {
            if (this.complimentTable.multipleSelection.length > 0) {
                this.deleteCompliments(this.complimentTable.multipleSelection);
            }
        },
        // table
        searchCompliments() {
            this.complimentTable.pagination.currentPage = 1;
            this.fetchCompliments();
        },
        handleEdit(index, row) {
            if (row.id && row.title) {
                this.editComplimentDialog.form = row;
                this.editComplimentDialog.isVisible = true;
            } else {
                this.$notify({
                    title: "Compliment edit failed",
                    message: "Compliment cannot be found",
                    type: "error",
                });
            }
        },
        handleDelete(index, row) {
            this.deleteCompliments([row.id]);
        },
        handleSelectionChange(rows) {
            this.complimentTable.multipleSelection = rows.map(post => post.id);
        },
        getPdf(row, column) {
            if (column.property == "title" && row.file_path) {
                window.open(row.file_path, "_blank");
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.complimentTable.pagination.pageSize = pageSize;
            this.fetchCompliments();
        },
        handleCurrentChange(currentPage) {
            this.complimentTable.pagination.currentPage = currentPage;
            this.fetchCompliments();
        },
    },
};
</script>

<style lang="scss">
#compliment {
    height: 100%;
}
</style>
