import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/table/shuttle-bus?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async schedule => {
    try {
        const formData = new FormData();
        formData.append("title", schedule.title);
        formData.append("file", schedule.file);
        formData.append("publishDate", schedule.publishDate);
        formData.append("endDate", schedule.endDate);
        formData.append("remarks", schedule.remarks);
        const result = await axios.post(`/admin/table/shuttle-bus`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async scheduleList => {
    const params = {
        list: scheduleList,
    };
    try {
        const result = await axios.post(`/admin/table/shuttle-bus/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
