<template>
    <div id="no-message-details">
        <el-container class="no-message-details-container">
            <el-header class="no-message-details-header">
                <el-breadcrumb class="no-message-details-breadcrumb" separator="/">
                    <el-breadcrumb-item>
                        <a href="/">Home</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>
                        <a href="#/no-message">NO Message</a>
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>{{ message.title }}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-header>
            <el-main class="content-container" v-loading="isLoading">
                <el-row class="content-row" :gutter="32">
                    <el-col class="details-container" :span="windowInnerWidth > 1024 ? 16 : 24">
                        <el-main class="details-content">
                            <MessageDetails :message="message" :views="views" :comments="comments" @update:message="handleUpdateMessage" />
                        </el-main>
                    </el-col>
                    <el-col :span="8" v-if="windowInnerWidth > 1024">
                        <el-main class="views-content">
                            <Views :views="views" :message="message" />
                        </el-main>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { fetchDetails as fetchMessageDetails, fetchViews, fetchComments, deleteComments } from "@/modules/no-message";
import Views from "@/components/no-message/Views";
import MessageDetails from "@/components/no-message/MessageDetails";

export default {
    name: "NOMessageDetailsWrapper",
    components: {
        MessageDetails,
        Views,
    },
    data() {
        return {
            isLoading: false,
            windowInnerWidth: null,
            messageId: this.$route.params.id,
            message: {},
            views: [],
            comments: {
                data: [],
                callback: id => this.deleteComments(id),
            },
        };
    },
    created() {
        window.addEventListener("resize", this.getWindowInnerWidth);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.getWindowInnerWidth);
    },
    mounted() {
        this.getWindowInnerWidth();
        this.fetchMessageDetails(this.messageId);
        this.fetchViews(this.messageId);
        this.fetchComments(this.messageId);
    },
    methods: {
        async fetchMessageDetails(id) {
            try {
                this.isLoading = true;
                const data = await fetchMessageDetails(id);
                if (data) {
                    this.message = data;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async fetchViews(id) {
            try {
                this.isLoading = true;
                const data = await fetchViews(id);
                if (data) {
                    this.views = data;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async fetchComments(id) {
            try {
                this.isLoading = true;
                const data = await fetchComments(id);
                if (data) {
                    this.comments.data = data;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async deleteComments(id) {
            let loading;
            try {
                loading = this.$loading({ target: "#pane-Comments" });
                const result = await deleteComments([id]);
                if (result) {
                    this.$notify({
                        title: "Comment deleted successfully",
                        type: "success",
                    });
                    this.fetchComments(this.messageId);
                }
            } finally {
                loading.close();
            }
        },
        getWindowInnerWidth() {
            this.windowInnerWidth = window.innerWidth;
        },
        handleUpdateMessage() {
            this.fetchMessageDetails(this.messageId);
        },
    },
};
</script>

<style lang="scss">
#no-message-details {
    height: 100%;
    @media (min-width: 1025px) {
        .hidden-bigger-than-1024 {
            display: none !important;
        }
    }
    @media only screen and (max-width: 1024px) {
        .hidden-smaller-than-1024 {
            display: none !important;
        }
    }
    .no-message-details-container {
        height: 100%;
    }
    .no-message-details-header {
        display: flex;
        align-items: center;
        height: 40px !important;
        padding: 0px;
        .no-message-details-breadcrumb {
            height: auto;
            font-size: 14px;
            line-height: 36px;
            .el-breadcrumb__inner {
                a {
                    color: #c1c7d0;
                    font-weight: normal;
                    &:hover {
                        color: #36b37e;
                    }
                }
            }
            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    font-weight: bold;
                    color: #303133;
                }
            }
        }
    }
    .content-container {
        padding: 8px 0;
        height: 100%;
        overflow: hidden;
        .content-row {
            height: 100%;
        }
        .details-container {
            height: 100%;
        }
        .details-content,
        .views-content {
            box-shadow: 3px 5px 22px rgba(0, 0, 0, 0.03);
            border-radius: 5px;
            background-color: #fff;
            padding: 32px;
            text-align: left;
            height: 100%;
        }
    }
}
</style>
