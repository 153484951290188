import { render } from "./Ranking.vue?vue&type=template&id=27533564"
import script from "./Ranking.vue?vue&type=script&lang=js"
export * from "./Ranking.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "27533564"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('27533564', script)) {
    api.reload('27533564', script)
  }
  
  module.hot.accept("./Ranking.vue?vue&type=template&id=27533564", () => {
    api.rerender('27533564', render)
  })

}

script.__file = "src/views/Settings/Ranking.vue"

export default script