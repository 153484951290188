<template>
    <div id="training">
        <ViewContainer :title="`Training`" :numberOfItems="trainingTable.numberOfItems ? trainingTable.numberOfItems : 0" :isLoading="isLoading" :buttons="containerHeaderButtons">
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleWardChange" v-if="ward.options.length > 0 && ward.value" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading">
                    <span class="el-dropdown-link roster-header-title">
                        <span>
                            Show :
                            {{ ward.label }}
                        </span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in ward.options"
                                v-bind:key="item.value"
                                :command="item.value"
                                :class="[item.value == ward.value ? 'is-chosen' : '']"
                                :disabled="item.value == ward.value"
                            >
                                {{ item.label }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="trainingTable" />
            </template>
        </ViewContainer>
        <Drawer ref="addTrainingDrawer" :drawer="addTrainingDrawer" @update:isVisible="addTrainingDrawer.isVisible = $event">
            <template v-slot:content>
                <AddPostForm ref="addPostForm" :isLoading="addTrainingDrawer.isLoading" />
            </template>
        </Drawer>
        <Drawer ref="editTrainingDrawer" :drawer="editTrainingDrawer" @update:isVisible="editTrainingDrawer.isVisible = $event">
            <template v-slot:content>
                <EditPostForm ref="editPostForm" :post="editTrainingDrawer.post" :isLoading="editTrainingDrawer.isLoading" />
            </template>
        </Drawer>
        <Drawer ref="notificationDrawer" :drawer="notificationDrawer" @update:isVisible="notificationDrawer.isVisible = $event">
            <template v-slot:content>
                <NotificationForm ref="notificationForm" :isLoading="notificationDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchPosts, fetchSingleData as fetchPost, deleteData as deletePosts } from "@/modules/training";
import { fetchData as fetchWard } from "@/modules/general/ward";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import Drawer from "@/components/Drawer";
import AddPostForm from "@/components/training/AddPostForm";
import EditPostForm from "@/components/training/EditPostForm";
import NotificationForm from "@/components/training/NotificationForm";

export default {
    name: "Training",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        Drawer,
        AddPostForm,
        EditPostForm,
        NotificationForm,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by title, category, author, remarks",
                value: "",
                callback: () => this.fetchPosts(),
            },
            ward: {
                options: [],
                label: "",
                value: null,
            },
            containerHeaderButtons: [
                {
                    name: "Notifications",
                    icon: "el-icon-position",
                    callback: () => this.handleNotification(),
                },
                {
                    name: "Manage Category",
                    callback: () => this.handleCategory(),
                },
            ],
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "Upload",
                    icon: "el-icon-upload",
                    callback: () => this.handleAddTrainingDrawer(),
                },
            ],
            trainingTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.getPdf(row, column, e),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "category",
                        sortable: false,
                        prop: "category_name",
                        minWidth: "112",
                    },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "target_ward",
                        width: "132",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        minWidth: "95",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        minWidth: "136",
                    },
                    // {
                    //     label: "end date",
                    //     sortable: true,
                    //     prop: "end_date",
                    //     minWidth: "136",
                    // },
                    {
                        label: "remarks",
                        sortable: false,
                        prop: "remarks",
                        minWidth: "120",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                handleEdit: (index, row) => this.handleEdit(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addTrainingDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Training Post",
                save: () => this.addPost(),
                close: () => this.closeAddTrainingDrawer(),
            },
            editTrainingDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Edit Training Post",
                post: {},
                save: () => this.editPost(),
                close: () => this.closeEditTrainingDrawer(),
            },
            notificationDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Send Notification",
                post: {},
                save: () => this.sendNotification(),
                close: () => this.closeNotificationDrawer(),
            },
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            await this.fetchWard();
            await this.fetchPosts();
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        "ward.value"(val) {
            if (this.ward.options && this.ward.options.length > 0) {
                const target = this.ward.options.find(({ value }) => value === val);
                if (target) {
                    this.ward.label = target.label;
                }
            }
            return "";
        },
    },
    methods: {
        async fetchWard() {
            const data = await fetchWard();
            if (data) {
                this.ward.options = data.map(item => {
                    return { value: item.id, label: item.name };
                });
                this.ward.value = this.ward.options[0].value;
            }
        },
        async fetchPosts() {
            this.isLoading = true;
            let pageSize = this.trainingTable.pagination.pageSize;
            let pageIndex = this.trainingTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchPosts(this.ward.value, pageSize, pageIndex, searchString);
                if (result) {
                    this.trainingTable.data = result.data;
                    this.trainingTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addPost() {
            try {
                this.addTrainingDrawer.isLoading = true;
                const result = await this.$refs.addPostForm.addPost();
                if (result) {
                    this.addTrainingDrawer.isVisible = false;
                    this.$notify({
                        title: "Training uploaded successfully",
                        message: result + " was uploaded successfully",
                        type: "success",
                    });
                    this.fetchPosts();
                }
            } finally {
                this.addTrainingDrawer.isLoading = false;
            }
        },
        async editPost() {
            try {
                this.editTrainingDrawer.isLoading = true;
                const result = await this.$refs.editPostForm.editPost();
                if (result) {
                    this.editTrainingDrawer.isVisible = false;
                    this.$notify({
                        title: "Training updated successfully",
                        message: result + " was updated successfully",
                        type: "success",
                    });
                    this.fetchPosts();
                }
            } finally {
                this.editTrainingDrawer.isLoading = false;
            }
        },
        async deletePosts(postList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the training posts. Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deletePosts(postList);
                    if (result) {
                        this.$notify({
                            title: "Training post(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchPosts();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        async sendNotification() {
            try {
                this.notificationDrawer.isLoading = true;
                const result = await this.$refs.notificationForm.sendNotification();
                if (result) {
                    this.notificationDrawer.isVisible = false;
                    this.$notify({
                        title: "Notification sent successfully",
                        message: "Notification was sent successfully",
                        type: "success",
                    });
                }
            } finally {
                this.notificationDrawer.isLoading = false;
            }
        },

        // container header
        handleNotification() {
            this.notificationDrawer.isVisible = true;
        },
        handleCategory() {
            this.$router.push({
                path: "/training/category",
            });
        },

        // header
        handleWardChange(ward) {
            this.searchInput.value = "";
            this.ward.value = ward;
            this.trainingTable.pagination.currentPage = 1;
            this.fetchPosts();
        },
        handleAddTrainingDrawer() {
            this.addTrainingDrawer.isVisible = true;
        },
        closeAddTrainingDrawer() {
            this.$refs.addPostForm.resetForm();
            this.addTrainingDrawer.isVisible = false;
        },
        handleEditTrainingDrawer() {
            this.editTrainingDrawer.isVisible = true;
        },
        closeEditTrainingDrawer() {
            this.$refs.editPostForm.resetForm();
            this.editTrainingDrawer.isVisible = false;
        },
        closeNotificationDrawer() {
            this.$refs.notificationForm.resetForm();
            this.notificationDrawer.isVisible = false;
        },
        handleAction() {
            if (this.trainingTable.multipleSelection.length > 0) {
                this.deletePosts(this.trainingTable.multipleSelection);
            }
        },

        // table
        handleDelete(index, row) {
            this.deletePosts([row.id]);
        },
        async handleEdit(index, row) {
            try {
                this.editTrainingDrawer.isLoading = true;
                const result = await fetchPost(row.id);
                if (result) {
                    this.editTrainingDrawer.post = result;
                    this.editTrainingDrawer.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editTrainingDrawer.isLoading = false;
            }
        },
        handleSelectionChange(rows) {
            this.trainingTable.multipleSelection = rows.map(post => post.id);
        },
        getPdf(row, column) {
            if (column.property == "title" && row.file_path) {
                window.open(row.file_path, "_blank");
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.trainingTable.pagination.pageSize = pageSize;
            this.trainingTable.pagination.currentPage = 1;
            this.fetchPosts();
        },
        handleCurrentChange(currentPage) {
            this.trainingTable.pagination.currentPage = currentPage;
            this.fetchPosts();
        },
    },
};
</script>

<style lang="scss">
#training {
    height: 100%;
    .category-error {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}
</style>
