<template>
    <div id="request-duty-roster">
        <ViewContainer
            title="Request Duty Roster"
            :buttons="table.rosterId.value ? (table.isManage.value ? headerButtonsDisabled : headerButtons) : []"
            :isLoading="isLoading.value || table.isLoading.value"
        >
            <template v-slot:page-info>
                <el-dropdown trigger="click" @command="handleDropDownOnClick" :class="[isLoading ? 'is-disabled' : '']" :disabled="isLoading" v-if="wardsOptions.length > 0">
                    <span class="el-dropdown-link roster-header-title">
                        <span>Show : {{ wardLabel }}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item
                                v-for="item in wardsOptions"
                                v-bind:key="item.id"
                                :command="item.id"
                                :class="[item.id == selectedWard ? 'is-chosen' : '']"
                                :disabled="item.id == selectedWard"
                            >
                                {{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
            <template v-slot:header>
                <RosterHeader title="Week" :weeksOptions="weeksOptions" :rosterDotList="rosterDotList" :selectedWeek="selectedWeek" :setSelectedWeek="setSelectedWeek" v-if="selectedWard" />
            </template>
            <template v-slot:content>
                <div v-if="table.rosterId.value || isLoading" class="table-container">
                    <Roster style="height: 100%;" ref="roster" :table="table" :selectedWeek="selectedWeek" :weeksOptions="weeksOptions" />
                    <el-footer class="edit-footer" v-if="table.isManage.value" style="height: auto;">
                        <el-button type="primary" class="check" size="small" @click="handleOnSave">
                            Save
                        </el-button>
                        <el-button class="cancel" size="small" @click="handleOnCancel">
                            Cancel
                        </el-button>
                    </el-footer>
                </div>
                <div v-else class="no-data-container">
                    <el-image :src="noDataImage" fit="contain"></el-image>
                    <div class="right-side">
                        <span class="title">Roster not found...</span>
                        <span class="description">
                            We can't seem to find the roster you're looking for.
                            <br />
                            Try clicking on the left menu or change the ward for more navigation.
                        </span>
                    </div>
                </div>
            </template>
        </ViewContainer>
        <ModalEditDialog
            v-if="modalEditDialog.isVisible"
            :dialog="modalEditDialog"
            @update:isVisible="modalEditDialog.isVisible = $event"
            :ward="{ label: wardLabel }"
            :rosterId="table.rosterId.value"
        />
        <SettingsDialog
            v-if="settingsDialog.isVisible"
            :dialog="settingsDialog"
            @update:isVisible="settingsDialog.isVisible = $event"
            :week="{ label: weekLabel }"
            :ward="{ label: wardLabel }"
            :rosterId="table.rosterId.value"
        />
    </div>
</template>

<script>
import { useState } from "@/utils/use";
import { onMounted, ref, watch, computed, reactive } from "vue";
import { ElNotification } from "element-plus";
import XLSX from "xlsx";
import XEUtils from "xe-utils";
import moment from "moment";

import placeholder from "@/assets/no-data.png";
import ViewContainer from "@/components/ViewContainer";
import Roster from "@/components/roster/Roster";
import RosterHeader from "@/components/roster/RosterHeader";
import ModalEditDialog from "@/components/roster/ModalEditDialog";
import SettingsDialog from "@/components/roster/SettingsDialog";
import { fetchSet as fetchSetData } from "@/modules/general/set";
import { fetchData as fetchWardData } from "@/modules/general/ward";
import {
    fetchData as fetchDutyRosterData,
    fetchOptions as fetchDutyOptionsData,
    postData as postDutyData,
    putData as shiftDutyData,
    fetchModal as fetchModalData,
    postModal as postModalData,
    fetchSettings as fetchSettingsData,
    putSettings as putSettingsData,
    fetchRanking as fetchRankingData,
} from "@/modules/roster/request-duty-roster";

export default {
    name: "RequestDutyRoster",
    components: {
        ViewContainer,
        Roster,
        RosterHeader,
        ModalEditDialog,
        SettingsDialog,
    },
    setup() {
        const isLoading = ref(false);
        const isTableLoading = ref(false);
        const isManage = ref(false);
        const rosterDotList = ref([]);
        const rosterId = ref(null);
        const rows = ref([]);
        const columnHeader = ref([]);
        const dutyOptions = ref([]);
        const editable = ref([]);
        const roster = ref({});
        const weeksOptions = ref([]);
        const [selectedWeek, setSelectedWeek] = useState();
        const wardsOptions = ref([]);
        const [selectedWard, setSelectedWard] = useState();
        const wardLabel = computed(() => {
            if (wardsOptions.value && wardsOptions.value.length > 0) {
                const target = wardsOptions.value.find(({ id }) => id === selectedWard.value);
                if (target) {
                    return target.name;
                }
            }
            return "";
        });
        const weekLabel = computed(() => {
            if (weeksOptions.value && weeksOptions.value.length > 0) {
                const target = weeksOptions.value.find(({ id }) => id === selectedWeek.value);
                if (target) {
                    return target.label;
                }
            }
            return "";
        });
        const headerButtons = [
            {
                name: "Settings",
                icon: "uni-icon-setting",
                callback: () => {
                    fetchSettings();
                },
            },
            {
                name: "Request Instruction Edit",
                icon: "el-icon-document",
                callback: () => {
                    fetchModal();
                },
            },
            {
                name: "Manage",
                icon: "el-icon-notebook-2",
                callback: () => {
                    if (rows.value.length > 0) {
                        if (!isManage.value) {
                            roster.value.clearScroll();
                            isManage.value = true;
                            roster.value.enableSort();
                        } else {
                            handleOnSave();
                        }
                    }
                },
            },
            {
                name: "Export",
                icon: "el-icon-download",
                callback: () => exportExcel(),
            },
        ];

        const headerButtonsDisabled = [
            {
                name: "Settings",
                icon: "uni-icon-setting",
                callback: () => {
                    fetchSettings();
                },
            },
            {
                name: "Request Instruction Edit",
                icon: "el-icon-document",
                callback: () => {
                    fetchModal();
                },
            },
            {
                name: "Manage",
                icon: "el-icon-notebook-2",
                disabled: true,
            },
            {
                name: "Export",
                icon: "el-icon-download",
                callback: () => exportExcel(),
            },
        ];

        const noDataImage = ref(placeholder);

        const modalEditDialog = ref({
            isVisible: false,
            isLoading: false,
            title: "Request Duty Roster - Request Instruction Edit",
            modal: "",
            upload: (rosterId, form) => postModal(rosterId, form),
        });

        const settingsDialog = ref({
            isVisible: false,
            isLoading: false,
            title: "Request Duty Roster - Settings",
            settings: {},
            week: null,
            upload: (rosterId, form) => putSettings(rosterId, form),
        });

        const cutOffDate = ref(null);

        watch(selectedWard, async wardId => {
            weeksOptions.value = [];
            setSelectedWeek(null);
            rosterId.value = null;
            if (wardId) {
                await fetchSet(wardId);
            }
        });

        watch(selectedWeek, async weekId => {
            if (weekId && selectedWard.value) {
                await fetchDutyRoster(selectedWard.value, weekId);
                if (rosterId.value) {
                    await fetchRanking(rosterId.value);
                }
            }
        });

        const fetchDutyRoster = async (ward, week) => {
            try {
                isTableLoading.value = true;
                isManage.value = false;
                const result = await fetchDutyRosterData(ward, week);
                if (result) {
                    rows.value = reactive(XEUtils.clone(result.data, true));
                    columnHeader.value = result.day_value_count;
                    rosterId.value = result.roster_id;
                    cutOffDate.value = result.cut_off_date;
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchWard = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchWardData();
                if (data && data.length > 0) {
                    wardsOptions.value = data;
                    setSelectedWard(data[0].id);
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchSet = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchSetData();
                if (data && data.length > 0) {
                    weeksOptions.value = data;
                    setSelectedWeek(data[0].id);
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchRanking = async rosterId => {
            try {
                isTableLoading.value = true;
                const data = await fetchRankingData(rosterId);
                if (data) {
                    rosterDotList.value = data;
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const fetchDutyOptions = async () => {
            try {
                isTableLoading.value = true;
                const data = await fetchDutyOptionsData();
                if (data) {
                    dutyOptions.value = data.map(duty => {
                        return {
                            label: duty.name ? duty.name : " ",
                            value: duty.id,
                        };
                    });
                }
            } finally {
                isTableLoading.value = false;
            }
        };

        const postDuty = async (duty, scope) => {
            try {
                isTableLoading.value = true;
                let dutyEdited = {
                    userId: scope.row.user_id,
                    date: columnHeader.value[scope.columnIndex - 1].date,
                    optionId: duty.value,
                };
                const result = await postDutyData(rosterId.value, dutyEdited);
                if (result) {
                    ElNotification.success({
                        title: "Duty saved successfully",
                    });
                }
            } finally {
                fetchDutyRoster(selectedWard.value, selectedWeek.value);
                isTableLoading.value = false;
            }
        };

        const shiftDuty = async (rosterId, userIdList) => {
            try {
                isTableLoading.value = true;
                const result = await shiftDutyData(rosterId, userIdList);
                if (result) {
                    ElNotification.success({
                        title: "The order of users in Duty Roster modified successfully",
                        message: "The order of users was modified successfully",
                    });
                }
            } finally {
                fetchDutyRoster(selectedWard.value, selectedWeek.value);
                isTableLoading.value = false;
            }
        };

        const handleDropDownOnClick = e => {
            setSelectedWard(e);
        };

        const fetchModal = async () => {
            try {
                if (rosterId.value) {
                    modalEditDialog.value.isLoading = true;
                    const result = await fetchModalData(rosterId.value);
                    if (result) {
                        modalEditDialog.value.modal = result.modal_message;
                        modalEditDialog.value.isVisible = true;
                    }
                }
            } finally {
                modalEditDialog.value.isLoading = false;
            }
        };

        const postModal = async (rosterId, form) => {
            try {
                modalEditDialog.value.isLoading = true;
                let msg = form.modal;
                const result = await postModalData(rosterId, msg);
                if (result) {
                    ElNotification.success({
                        title: "Modal saved successfully",
                    });
                }
            } finally {
                modalEditDialog.value.isLoading = false;
            }
        };

        const handleOnSave = async () => {
            isManage.value = false;
            roster.value.disableSort();
            let userIdList = [];
            let tableRows = rows.value;
            for (let i = 0; i < tableRows.length; i++) {
                userIdList.push(tableRows[i].user_id);
            }
            if (userIdList.length > 0) {
                shiftDuty(rosterId.value, userIdList);
            }
        };

        const handleOnCancel = async () => {
            isManage.value = false;
            roster.value.disableSort();
            fetchDutyRoster(selectedWard.value, selectedWeek.value);
        };

        const exportExcel = async () => {
            let wb = XLSX.utils.book_new();
            let data = [];

            for (let i = 0; i < rows.value.length; i++) {
                let duties = rows.value[i].duty;
                let rowData = {};
                rowData.name = rows.value[i].full_name;
                for (let j = 0; j < duties.length; j++) {
                    let date = duties[j].date;
                    let duty = duties[j].value;
                    rowData[date] = duty;
                }
                data.push(rowData);
            }

            let header = ["name"];
            let date = columnHeader.value.map(col => {
                return col.date;
            });
            header = header.concat(date);

            let ws = XLSX.utils.json_to_sheet(data, {
                header: header,
            });
            XLSX.utils.book_append_sheet(wb, ws, roster.value.weekLabel);
            XLSX.writeFile(wb, "Request Duty Roster (" + roster.value.weekLabel + ").xlsx");
        };

        const fetchSettings = async () => {
            try {
                if (rosterId.value) {
                    settingsDialog.value.isLoading = true;
                    const result = await fetchSettingsData(rosterId.value);
                    if (result) {
                        settingsDialog.value.settings = result;
                        settingsDialog.value.isVisible = true;
                    }
                }
            } finally {
                settingsDialog.value.isLoading = false;
            }
        };

        const putSettings = async (rosterId, form) => {
            try {
                settingsDialog.value.isLoading = true;
                form.cutOffDate = moment(form.cutOffDate).format("YYYY-MM-DD");
                const result = await putSettingsData(rosterId, form);
                if (result) {
                    ElNotification.success({
                        title: "Roster settings saved successfully",
                    });
                    await fetchDutyRoster(selectedWard.value, selectedWeek.value);
                }
            } finally {
                settingsDialog.value.isLoading = false;
            }
        };

        onMounted(async () => {
            await fetchWard();
            await fetchSet();
            await fetchDutyOptions();
        });

        return {
            noDataImage,
            isLoading,
            rosterDotList,
            headerButtons,
            headerButtonsDisabled,
            weeksOptions,
            selectedWeek,
            setSelectedWeek,
            wardsOptions,
            selectedWard,
            setSelectedWard,
            wardLabel,
            weekLabel,
            handleDropDownOnClick,
            roster,
            table: {
                isLoading: isTableLoading,
                isManage,
                rosterId,
                rows,
                columnHeader,
                selectedWeek,
                dutyOptions,
                editable,
                postDuty,
            },
            modalEditDialog,
            settingsDialog,
            postModal,
            handleOnSave,
            handleOnCancel,
        };
    },
};
</script>

<style lang="scss">
#request-duty-roster {
    height: 100%;
    .table-container {
        height: calc(100% - 27px - 40px);
    }
    .no-data-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 32px;
        .el-image {
            max-width: 480px;
            @media only screen and (max-width: 1024px) {
                max-width: 320px;
            }
        }
        .right-side {
            display: flex;
            flex-direction: column;
            max-width: 480px;
            .title {
                font-size: 40px;
                font-weight: bold;
                color: #44444f;
                margin-bottom: 16px;
            }
            .description {
                font-size: 13px;
                color: #aaa;
                line-height: 1.5;
                margin-bottom: 40px;
            }
            .back-btn {
                width: 120px;
                border-radius: 5px;
            }
        }
    }
    .edit-footer {
        padding: 8px 20px 0 8px;
        display: flex;
        align-items: center;
        background: #fff;
        .cancel,
        .check {
            width: 80px;
            display: flex;
            justify-content: center;
        }
        .cancel {
            margin-left: 24px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
    .uni-icon-setting {
        margin-right: 4px;
        &:before {
            color: #fff;
        }
    }
}
</style>
