<template>
    <el-container class="page-container">
        <el-header class="page-header-container" style="height: auto;">
            <el-row class="page-header">
                <el-col :span="buttons ? 12 : 24" class="page-details">
                    <span class="page-title">{{ title }}</span>
                    <slot name="page-info"></slot>
                    <span class="page-description" v-if="numberOfItems != null && !isLoading">({{ numberOfItems }} items found)</span>
                </el-col>
                <el-col v-if="buttons && buttons.length > 0" :span="12" class="page-btn-grp">
                    <el-button
                        v-for="button in buttons"
                        v-bind:key="button.name"
                        :ref="setButtonRef"
                        :icon="button.icon"
                        :disabled="button.disabled"
                        type="primary"
                        size="small"
                        @click="() => button.callback()"
                    >
                        {{ button.name }}
                    </el-button>
                </el-col>
            </el-row>
        </el-header>
        <el-main class="page-content" v-loading="isLoading">
            <slot name="header"></slot>
            <slot name="content"></slot>
        </el-main>
    </el-container>
</template>

<script>
import { onBeforeUpdate, onUpdated } from "vue";

export default {
    name: "ViewContainer",
    props: ["title", "description", "numberOfItems", "buttons", "isLoading"],
    setup() {
        let buttonRefs = [];
        const setButtonRef = el => {
            if (el) {
                buttonRefs.push(el);
            }
        };
        onBeforeUpdate(() => {
            buttonRefs = [];
        });
        onUpdated(() => {
            // console.log(buttonRefs);
        });
        return {
            setButtonRef,
        };
    },
};
</script>

<style lang="scss">
.page-header {
    height: 40px;
    align-items: center;
    .page-details {
        display: flex;
        align-items: baseline;
    }
    .page-title {
        font-size: 20px;
        font-weight: 700;
        margin-right: 8px;
        line-height: 2;
        @media only screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 1.4;
        }
    }
    .page-description {
        color: #92929d;
        line-height: 2;
        @media only screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 1.4;
        }
    }
    .page-btn-grp {
        display: flex;
        justify-content: flex-end;
    }
}
.page-header-container {
    padding: 0;
    height: auto;
    margin-bottom: 24px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 8px;
    }
}
.page-container {
    overflow: auto;
}
.page-content {
    box-shadow: 3px 5px 22px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    background-color: #fff;
    padding: 16px 16px 8px 16px;
    text-align: left;
    @media only screen and (max-width: 1024px) {
        padding: 8px 8px 0;
    }
}
</style>
