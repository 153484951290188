<template>
    <div class="roster-dot">
        <div class="roster-dot-background">
            <span class="roster-dot-item" :style="styledColor"></span>
        </div>
        <span class="roster-dot-text">{{ name }}</span>
    </div>
</template>

<script>
import { computed } from "vue";
export default {
    name: "RosterDot",
    props: ["name", "color"],
    setup(props) {
        const styledColor = computed(() => `background: ${props.color};`);
        return {
            styledColor,
        };
    },
};
</script>

<style lang="scss">
.roster-dot {
    display: flex;
}
.roster-dot-item {
    height: 10px;
    width: 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.roster-dot-text {
    margin: 0 16px 0 6px;
}
</style>
