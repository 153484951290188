// import axios from "axios";

export const fetchData = async () => {
    // try {
    return [
        { label: "LEVEL 1", value: 1 },
        { label: "LEVEL 2", value: 2 },
        { label: "LEVEL 3", value: 3 },
    ];
    // } catch (error) {
    // 	console.error(error);
    // }
};
