import { render } from "./TelDirectory.vue?vue&type=template&id=1038c4cc"
import script from "./TelDirectory.vue?vue&type=script&lang=js"
export * from "./TelDirectory.vue?vue&type=script&lang=js"

import "./TelDirectory.vue?vue&type=style&index=0&id=1038c4cc&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1038c4cc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1038c4cc', script)) {
    api.reload('1038c4cc', script)
  }
  
  module.hot.accept("./TelDirectory.vue?vue&type=template&id=1038c4cc", () => {
    api.rerender('1038c4cc', render)
  })

}

script.__file = "src/views/TelDirectory.vue"

export default script