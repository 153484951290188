<template>
    <el-dialog custom-class="user-settings-dialog upload-dialog" :title="dialog.title" v-model="isVisible" width="640px" destroy-on-close>
        <el-table
            class="user-table"
            ref="userTable"
            :size="windowInnerWidth > 1024 ? 'medium' : 'mini'"
            :data="userTable.data.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
            :header-row-style="headerRowStyle"
            :cell-class-name="windowInnerWidth > 1024 ? '' : 'cell-class'"
            :height="windowInnerWidth > 1024 ? 400 : 300"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="USER(S)" align="left" min-width="68">
                <template #header>
                    <span class="user-header">USER(S)</span>
                    <el-input v-model="search" size="mini" placeholder="Type to search" />
                    <el-dropdown trigger="click" class="table-actions" size="mini" @command="handleDropdownOnClick">
                        <el-button class="el-dropdown-link" size="small">
                            Action
                            <i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="'block'" icon="el-icon-check" class="block">
                                    <span>Block</span>
                                </el-dropdown-item>
                                <el-dropdown-item :command="'unblocked'" icon="el-icon-close" class="unblock">
                                    <span>Unblock</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
                <template #default="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>
            <el-table-column width="132" align="left">
                <template #default="scope">
                    <el-tag v-if="scope.row.blocked" type="danger" size="small">
                        Blocked
                    </el-tag>
                </template>
            </el-table-column>
        </el-table>
        <span class="pagination-index">Total: {{ endItemIndex }} items</span>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="submitUserSettings">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="isVisible = false">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "@/components/useInput";
import { fetchUserSettings, putUserSettings } from "@/modules/annual-leave/request-annual-leave";
export default {
    name: "UserSettingsDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
        title: {
            type: String,
            default: "",
            required: true,
        },
    },
    data() {
        return {
            search: "",
            isLoading: false,
            windowInnerWidth: null,
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            userTable: {
                data: [],
            },
            multipleSelection: [],
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    computed: {
        endItemIndex: function() {
            return this.userTable.data.length;
        },
    },
    created() {
        window.addEventListener("resize", this.getWindowInnerWidth);
    },
    async mounted() {
        this.getWindowInnerWidth();
        await this.fetchUserTable(this.dialog.weekId);
    },
    methods: {
        getWindowInnerWidth() {
            this.windowInnerWidth = window.innerWidth;
        },
        async fetchUserTable(weekId) {
            let loading;
            try {
                loading = this.$loading({ target: ".user-settings-dialog" });
                if (weekId) {
                    const data = await fetchUserSettings(weekId);
                    if (data) {
                        this.userTable.data = data;
                    }
                }
            } finally {
                loading.close();
            }
        },
        async putUserSettings(users) {
            let loading;
            try {
                loading = this.$loading({ target: ".user-settings-dialog" });
                if (users.length > 0) {
                    const data = await putUserSettings(this.dialog.weekId, users);
                    if (data) {
                        await this.fetchUserTable(this.dialog.weekId);
                    }
                }
            } finally {
                loading.close();
            }
        },
        handleSelectionChange(rows) {
            this.multipleSelection = rows.map(({ id }) => id);
        },
        handleDropdownOnClick(selection) {
            if (this.multipleSelection.length > 0) {
                this.multipleSelection.map(id => {
                    this.userTable.data.find(user => {
                        if (id == user.id) {
                            user.blocked = selection == "block" ? true : false;
                        }
                    });
                });
            }
        },
        submitUserSettings() {
            // const blockedUser = this.userTable.data.filter(user => user.blocked).map(user => user.id);
            const users = this.userTable.data.map(user => {
                return {
                    id: user.id,
                    block: user.blocked,
                };
            });
            this.putUserSettings(users);
            this.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
.user-settings-dialog {
    .user-table {
        th {
            background-color: #fafafb;
            border: 0px;
            .cell {
                display: flex;
                align-items: center;
            }
        }
        .user-header {
            margin-right: 8px;
        }
        .el-icon-check {
            color: #de350b;
        }
        .el-icon-close {
            color: #2684ff;
        }
    }
    .el-dialog__body .el-input {
        width: 200px;
        margin-right: 8px;
    }
    .pagination-index {
        font-weight: normal;
        color: #44444f;
        font-size: 12px;
    }
}
.el-dropdown-menu {
    .block {
        i,
        span {
            color: #de350b;
        }
    }
    .unblock {
        i,
        span {
            color: #2684ff;
        }
    }
}
</style>
