import axios from "axios";

export const fetchData = async (type, wardId) => {
    try {
        let result;
        if (type == "survival-working-tips") {
            result = await axios.get(`/admin/post/category/${type}`);
        } else {
            result = await axios.get(`/admin/post/category/${type}?wardId=${wardId}`);
        }
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const shiftData = async (type, categoryList) => {
    try {
        const result = await axios.put(`/admin/post/category/${type}/shift`, categoryList);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async (type, categoryId) => {
    try {
        const result = await axios.delete(`/admin/post/category/${type}/${categoryId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchTableData = async (type, pageSize, pageIndex, searchString, categoryId) => {
    try {
        const result = await axios.get(`/admin/post/category/${type}/post${categoryId ? `/${categoryId}` : ``}?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async (type, category) => {
    try {
        const formData = new FormData();
        formData.append("title", category.title);
        formData.append("coverImage", category.coverImage);
        formData.append("show", category.show);
        formData.append("general", category.general);
        if (category.wardList) {
            for (let i = 0; i < category.wardList.length; i++) {
                formData.append("wardList[]", category.wardList[i]);
            }
        }

        const result = await axios.post(`/admin/post/category/${type}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async (type, category) => {
    try {
        const formData = new FormData();
        formData.append("title", category.title);
        if (category.coverImage) {
            formData.append("coverImage", category.coverImage);
        }
        formData.append("general", category.general);
        formData.append("show", category.show);
        if (category.wardList && !category.general) {
            for (let i = 0; i < category.wardList.length; i++) {
                formData.append("wardList[]", category.wardList[i]);
            }
        }
        const result = await axios.put(`/admin/post/category/${type}/${category.id}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDetails = async (type, id) => {
    try {
        const result = await axios.get(`/admin/post/category/${type}/details/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
