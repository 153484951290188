<template>
    <div id="page-not-found">
        <el-image :src="src" fit="contain">
            <template #placeholder>
                <div class="image-slot">
                    Loading
                    <span class="dot">...</span>
                </div>
            </template>
        </el-image>
        <div class="right-side">
            <span class="title">Page not found...</span>
            <span class="description">
                We can't seem to find the page you're looking for.
                <br />
                Try going back to previous page or Click on the left menu for more navigation.
            </span>
            <el-button class="back-btn" type="primary" @click="back">Back</el-button>
        </div>
    </div>
</template>

<script>
import noDataImage from "@/assets/no-data.png";
export default {
    name: "PageNotFound",
    data() {
        return {
            src: noDataImage,
        };
    },
    methods: {
        back() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss">
#page-not-found {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    .el-image {
        max-width: 480px;
        @media only screen and (max-width: 1024px) {
            max-width: 320px;
        }
    }
    .right-side {
        display: flex;
        flex-direction: column;
        max-width: 480px;
        .title {
            font-size: 40px;
            font-weight: bold;
            color: #44444f;
            margin-bottom: 16px;
        }
        .description {
            font-size: 13px;
            color: #aaa;
            line-height: 1.5;
            margin-bottom: 40px;
        }
        .back-btn {
            width: 120px;
            border-radius: 5px;
        }
    }
}
</style>
