<template>
    <el-row class="annual-leave-header">
        <el-col :span="12" class="annual-leave-details">
            <el-dropdown trigger="click" @command="handleDropDownOnClick">
                <span class="el-dropdown-link annual-leave-header-title">
                    {{ title }} :
                    {{ yearLabel }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for="item in yearOptions" v-bind:key="item" :command="item">
                            {{ item }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </el-col>
        <el-col :span="12" class="annual-leave-header-badge">
            <AnnualLeaveDot v-for="item in annualLeaveDotList" :key="item.name" :name="item.name" :color="item.color" />
        </el-col>
    </el-row>
</template>

<script>
import AnnualLeaveDot from "@/components/annual-leave/AnnualLeaveDot";
export default {
    name: "RosterHeader",
    props: ["title", "yearOptions", "annualLeaveDotList", "selectedYear", "setSelectedYear"],
    components: { AnnualLeaveDot },
    setup(props) {
        const handleDropDownOnClick = e => {
            props.setSelectedYear(e);
        };
        return {
            handleDropDownOnClick,
        };
    },
    computed: {
        yearLabel() {
            if (this.yearOptions && this.yearOptions.length > 0) {
                const target = this.yearOptions.find(year => year === this.selectedYear);
                if (target) {
                    return target;
                }
            }
            return "";
        },
    },
};
</script>

<style lang="scss">
.annual-leave-header {
    height: auto;
    align-items: center;
    padding-bottom: 8px;
    .el-dropdown {
        cursor: pointer;
    }
}
.annual-leave-header-title {
    /* font-family: Lato;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 16px;
	/* identical to box height */
    letter-spacing: 0.1px;
    color: #92929d;
}
.annual-leave-header-badge {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
}
</style>
