import axios from "axios";

export const fetchData = async () => {
    try {
        const result = await axios.get(`/admin/general/rank`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
