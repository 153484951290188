<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format and video in mp4/ogg/webm with a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
        </span>
    </div>
    <el-form class="edit-tips-form" ref="editTipsForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Title:" prop="title">
                    <el-input class="add-tips-title word-limit-input" v-model="form.title" placeholder="Title" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Category(s):" prop="category" ref="category">
                    <el-select
                        v-model="form.category.value"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Please enter a keyword"
                        :remote-method="filterSelect"
                        :loading="form.category.isLoading"
                    >
                        <el-option v-for="item in form.category.options" :key="item.value" :label="item.displayName" :value="item.value" :disabled="item.disabled">
                            {{ item.label }}
                        </el-option>
                    </el-select>
                    <div class="category-error">
                        <span>
                            {{ form.category.error }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="Content:" prop="content">
                    <TinyMCE @update:content="handleUpdateContent" :initialValue="form.content"></TinyMCE>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Cover Image:">
                    <el-upload
                        class="upload-cover-image"
                        ref="uploadCoverImage"
                        accept="image/*"
                        :auto-upload="false"
                        :limit="1"
                        :on-change="handleImageChange"
                        :on-remove="handleImageRemove"
                        action="#"
                        :file-list="form.oldCoverImage"
                    >
                        <template #default v-if="isCoverImageEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="top" effect="light">
                                <template #content>
                                    <img :src="imagePreview ? imagePreview : form.oldCoverImage[0] ? form.oldCoverImage[0].url : ''" class="cover-image-preview" />
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleImageRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>{{ form.coverImageError }}</span>
                    </div>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Video:">
                    <el-upload
                        class="upload-video"
                        ref="uploadVideo"
                        accept="video/*"
                        :auto-upload="false"
                        :limit="1"
                        :on-change="handleVideoChange"
                        :on-remove="handleVideoRemove"
                        action="#"
                        :file-list="form.oldVideo"
                    >
                        <template #default v-if="isVideoEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="top" effect="light">
                                <template #content>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name" @click="handleVideoPreview(file)">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleVideoRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>
                            {{ form.videoError }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Publish date:" prop="publishDate">
                    <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.publishDate" format="YYYY-MM-DD HH:mm" />
                </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
				<el-form-item label="End date:" prop="endDate">
					<el-date-picker
						type="date"
						placeholder="Pick a date"
						v-model="form.endDate"
						style="width: 100%"
					/>
				</el-form-item>
			</el-col> -->
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="Remarks:" class="remarks-form-item" prop="remarks">
                    <el-input type="textarea" class="upload-textarea" v-model="form.remarks" placeholder="Please enter remarks" maxlength="50" show-word-limit></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { fetchCategory, putData as editTips } from "@/modules/survival-working-tips";
import TinyMCE from "@/components/TinyMCE";
import moment from "moment";
export default {
    name: "EditTipsForm",
    props: ["isLoading", "tips"],
    components: { TinyMCE },
    data() {
        var validateCategory = (rule, value, callback) => {
            let arr = value.value;
            if (!arr.length) {
                callback(new Error("Please select a category"));
            } else {
                callback();
            }
        };
        return {
            imagePreview: "",
            form: {
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Please enter a title",
                            trigger: ["blur", "change"],
                        },
                    ],
                    category: [
                        {
                            required: true,
                            validator: validateCategory,
                            trigger: ["blur", "change"],
                        },
                    ],
                    content: [
                        {
                            required: true,
                            message: "Please enter content",
                            trigger: ["blur", "change"],
                        },
                    ],
                    publishDate: [
                        {
                            required: true,
                            message: "Please pick a date",
                            trigger: ["blur", "change"],
                        },
                    ],
                    // endDate: [
                    // 	{
                    // 		required: true,
                    // 		message: "Please pick a date",
                    // 		trigger: ["blur", "change"],
                    // 	},
                    // ],
                },
                id: null,
                title: "",
                category: {
                    loading: false,
                    value: [],
                    options: [],
                    list: [],
                    error: "",
                },
                content: "",
                oldCoverImage: [],
                coverImage: {},
                coverImageError: "",
                oldVideo: [],
                video: {},
                videoError: "",
                publishDate: new Date(),
                endDate: new Date(2038, 0, 1),
                remarks: "",
            },
        };
    },
    async mounted() {
        let loading;
        try {
            loading = this.$loading({ target: ".edit-tips-form" });
            await this.fetchCategory();
            this.form.id = this.tips.id;
            this.form.title = this.tips.title;
            this.form.category.value = this.tips.category_list;
            this.form.content = this.tips.description;
            this.form.oldCoverImage = this.tips.cover_image_path ? [{ name: this.tips.cover_image_path, url: this.tips.cover_image_path }] : [];
            this.form.oldVideo = this.tips.video_path ? [{ name: this.tips.video_path, url: this.tips.video_path }] : [];
            this.form.publishDate = this.tips.published_date;
            this.form.endDate = this.tips.end_date;
            this.form.remarks = this.tips.remarks;
        } catch (error) {
            console.log(error);
        } finally {
            loading.close();
        }
    },
    computed: {
        isVideoEmpty() {
            return Object.keys(this.form.video).length == 0 && this.form.oldVideo.length == 0;
        },
        isCoverImageEmpty() {
            return Object.keys(this.form.coverImage).length == 0 && this.form.oldCoverImage.length == 0;
        },
    },
    methods: {
        async editTips() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                const tips = {
                    id: this.form.id,
                    title: this.form.title,
                    categoryList: this.form.category.value,
                    description: this.form.content,
                    coverImage: this.isCoverImageEmpty ? "null" : this.form.coverImage.raw,

                    video: this.isVideoEmpty ? "null" : this.form.video.raw,
                    publishDate: moment(this.form.publishDate).format("YYYY-MM-DD HH:mm"),
                    endDate: moment(this.form.endDate).format("YYYY-MM-DD HH:mm"),
                    remarks: this.form.remarks,
                };
                const result = await editTips(tips);
                if (result) {
                    return tips.title;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async fetchCategory() {
            this.form.category.isLoading = true;
            const data = await fetchCategory();
            if (data) {
                this.form.category.isLoading = false;

                const root = data
                    .filter(({ parent_id }) => parent_id === null)
                    .sort((a, b) => {
                        return a.priority - b.priority;
                    });
                const child = data.filter(({ parent_id }) => parent_id !== null);
                let result = [];
                root.forEach(rootItem => {
                    result = result.concat({
                        ...rootItem,
                        level: 1,
                        displayName: rootItem.name,
                        priority: rootItem.priority,
                    });
                    const c = child
                        .filter(childItem => childItem.parent_id === rootItem.id)
                        .sort((a, b) => {
                            return a.priority - b.priority;
                        });
                    c.forEach(childItem => {
                        result = result.concat({
                            ...childItem,
                            name: `└─${childItem.name}`,
                            displayName: childItem.name,
                            priority: rootItem.priority,
                        });
                        const sub = child
                            .filter(subItem => subItem.parent_id === childItem.id)
                            .sort((a, b) => {
                                return a.priority - b.priority;
                            });
                        result = result.concat(
                            sub.map(item => {
                                return {
                                    ...item,
                                    name: `　　└─${item.name}`,
                                    displayName: item.name,
                                    priority: rootItem.priority,
                                };
                            }),
                        );
                    });
                });

                result = result.map(category => {
                    return {
                        label: category.name,
                        value: category.id,
                        displayName: category.displayName,
                        disabled: category.selectable == 1 ? false : true,
                    };
                });
                this.form.category.options = result;
                this.form.category.list = result;
            }
        },
        handleVideoChange(video) {
            if (this.validateVideo(video)) {
                this.form.video = video;
            } else {
                this.$refs.uploadVideo.clearFiles();
            }
        },
        handleVideoRemove() {
            this.$refs.uploadVideo.clearFiles();
            this.form.oldVideo = [];
            this.form.video = {};
            this.validateVideo(this.form.video);
        },
        handleVideoPreview(file) {
            if (file.url) {
                window.open(file.url, "_blank");
            }
        },
        handleImageChange(image) {
            if (this.validateImage(image)) {
                this.form.coverImage = image;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(image.raw);
            } else {
                this.$refs.uploadCoverImage.clearFiles();
            }
        },
        handleImageRemove() {
            this.$refs.uploadCoverImage.clearFiles();
            this.form.oldCoverImage = [];
            this.form.coverImage = {};
            this.validateImage(this.form.coverImage);
        },
        filterSelect(query) {
            this.form.category.isLoading = true;
            setTimeout(() => {
                this.form.category.isLoading = false;
                this.form.category.options = this.form.category.list.filter(item => {
                    return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }, 0);
        },
        validateVideo(video) {
            if (Object.keys(video).length == 0) {
                return true;
            }
            const isVideo = video.raw.type === "video/mp4" || video.raw.type === "video/ogg" || video.raw.type === "video/webm";
            const isLt10M = video.size / 1024 / 1024 < 100;
            if (!isVideo) {
                this.form.videoError = "Video can only be mp4/ogg/webm format";
                return false;
            }
            if (!isLt10M) {
                this.form.videoError = "Video cannot exceed 100MB";
                return false;
            }
            this.form.videoError = "";
            return true;
        },
        validateImage(image) {
            if (Object.keys(image).length == 0) {
                return true;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;
            if (!isImage) {
                this.form.coverImageError = "Image can only be png/jpeg/jpg format";
                return false;
            }
            if (!isLt10M) {
                this.form.coverImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.coverImageError = "";
            return true;
        },
        async validateForm() {
            try {
                const imageResult = this.validateImage(this.form.coverImage);
                const videoResult = this.validateVideo(this.form.video);
                const result = await this.$refs.editTipsForm.validate();
                return result && imageResult && videoResult;
            } catch (error) {
                return false;
            }
        },
        resetForm() {
            this.$refs.editTipsForm.resetFields();
            this.$refs.uploadVideo.clearFiles();
            this.$refs.uploadCoverImage.clearFiles();
            this.form.title = "";
            this.form.oldVideo = [];
            this.form.video = {};
            this.form.videoError = "";
            this.form.oldCoverImage = [];
            this.form.coverImage = {};
            this.form.coverImageError = "";
            this.form.category.value = [];
            this.form.category.error = "";
            this.form.content = "";
            this.form.publishDate = new Date();
            this.form.endDate = new Date(2038, 1, 0);
            this.form.remarks = "";
        },
        handleUpdateContent(value) {
            this.form.content = value;
        },
    },
};
</script>

<style lang="scss">
#survival-working-tips {
    .category-error {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 100%;
        left: 0;
    }
}
</style>
