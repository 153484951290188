<template>
    <el-dialog
        custom-class="modal-edit-dialog"
        :title="ward.label ? dialog.title + ' (' + ward.label + ')' : dialog.title"
        v-model="isVisible"
        :before-close="resetForm"
        width="640px"
        destroy-on-close
    >
        <div class="description">
            <i class="el-icon-warning-outline el-icon-left"></i>
            <span class="contact">
                This is for MCG request requirement modal
            </span>
        </div>
        <el-form class="upload-form" ref="uploadForm" :model="form" label-position="top" v-loading="isLoading">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="modal">
                        <el-input
                            type="textarea"
                            class="upload-textarea"
                            v-model="form.modal"
                            placeholder="1. Personal privacy to the extent consitent with your care needs.&#10;2. Prompt, reasonable, and courteous responses to any request for services."
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="submitUpload('uploadForm')" :disabled="isLoading">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="resetForm" :disabled="isLoading">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "@/components/useInput";
export default {
    name: "ModalEditDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
        ward: {
            type: Object,
            default: () => {},
            required: true,
        },
        rosterId: {
            type: Number,
            default: null,
            required: true,
        },
    },
    data(props) {
        return {
            isLoading: false,
            form: {
                modal: props.dialog.modal,
            },
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    methods: {
        async submitUpload(formName) {
            this.isLoading = true;

            this.$refs[formName].validate(async valid => {
                if (valid) {
                    try {
                        await this.dialog.upload(this.rosterId, this.form);
                    } finally {
                        this.isLoading = false;
                        this.resetForm();
                    }
                } else {
                    this.isLoading = false;
                    return false;
                }
            });
        },
        resetForm() {
            this.isVisible = false;
            this.$refs.uploadForm.resetFields();
        },
    },
};
</script>

<style lang="scss">
.modal-edit-dialog {
    max-width: 640px;
    border-radius: 5px;
    @media only screen and (max-width: 1024px) {
        margin-top: 5vh !important;
    }
    .el-dialog__header {
        font-weight: bold;
        padding: 20px 40px;
        border-bottom: 1px solid #e2e2ea;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
    }
    .el-dialog__body {
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
        .el-form-item {
            width: 100%;
        }
        .upload-textarea {
            .el-textarea__inner {
                height: 200px;
            }
        }
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
        ::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }
        ::-webkit-scrollbar-thumb {
            background: #4ac292;
            border: 0 solid #ffffff;
            border-radius: 3px;
        }
        ::-webkit-scrollbar-track {
            background: #e8e8e8;
            border: 0 none #ffffff;
            border-radius: 3px;
        }
        ::-webkit-scrollbar-track:hover {
            background: #e8e8e8;
        }
        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
    .el-dialog__footer {
        .save-btn,
        .cancel-btn {
            width: 120px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 32px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
        padding: 25px 40px;
        text-align: left;
    }
}
</style>
