import { computed } from "vue";
const useInput = (props, emit, name = "value") => {
    // console.log(props);
    return computed({
        get: () => props[name],
        set: value => emit(`update:${name}`, value),
    });
};

export default useInput;
