<template>
    <draggable
        class="dragArea"
        tag="div"
        animation="150"
        :list="list"
        :fullList="fullList"
        @end="endChange"
        :group="{ name: 'nested' }"
        item-key="name"
        ghost-class="ghost"
        :disabled="!isManage"
        :move="checkMove"
    >
        <template #item="{ element }">
            <div class="category-item" :item_key="element.id">
                <div class="details" :class="{ draggable: isManage }" :style="[isManage ? 'cursor: move' : 'cursor: inherit']">
                    <span class="name">
                        <i v-if="element.show == 0" class="hide-icon"></i>
                        {{ element.name }}
                        {{ element.ward ? "(" + element.ward + ")" : "" }}
                    </span>
                    <div v-if="!(userLevel == 2 && type == 'survival-working-tips')">
                        <div v-if="!isManage">
                            <div class="btn-wrapper">
                                <a class="edit" @click="handleEdit(element)">
                                    <i class="el-icon-edit"></i>
                                </a>
                                <a class="delete" @click="handleDelete(element)" v-if="userLevel > 2">
                                    <i class="el-icon-delete"></i>
                                </a>
                            </div>
                            <span class="number-of-post" v-if="element.number_of_post != null">{{ element.number_of_post }} Posts</span>
                        </div>
                        <div v-else>
                            <span class="lock">
                                <i class="el-icon-lock"></i>
                            </span>
                        </div>
                    </div>
                    <div v-else>
                        <span class="lock">
                            <i class="el-icon-lock"></i>
                        </span>
                        <span class="view" @click="handleEdit(element)">
                            <i class="el-icon-view"></i>
                        </span>
                    </div>
                </div>
                <nested-draggable
                    class="category-item-list"
                    :fullList="fullList"
                    :list="element.child"
                    :level="level + 1"
                    v-if="level < maxLevel"
                    :isManage="isManage"
                    @edit:category="handleEdit"
                    @delete:category="handleDelete"
                    :move="checkMove"
                    :type="type"
                />
            </div>
        </template>
    </draggable>
</template>

<script>
import draggable from "vuedraggable";
import * as ramda from "ramda";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "nested-draggable",
    components: {
        draggable,
    },
    props: {
        fullList: {
            required: true,
            type: Array,
        },
        list: {
            required: true,
            type: Array,
        },
        level: {
            type: Number,
            default: 0,
        },
        isManage: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            maxLevel: 2,
            dataList: this.list,
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
        };
    },
    methods: {
        checkMove(evt) {
            const draggedContextWardList = evt.draggedContext.element.ward_list;
            const targetContextId = evt.to.parentElement.getAttribute("item_key");

            if (targetContextId) {
                const targetContext = this.findItemNested(this.fullList, targetContextId);
                const targetContextWardList = targetContext.ward_list;

                if (draggedContextWardList.length == 0 && targetContextWardList.length > 0) {
                    return false;
                } else if (draggedContextWardList.length > 0 && targetContextWardList.length == 0) {
                    return true;
                } else if (ramda.difference(draggedContextWardList, targetContextWardList).length > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        endChange() {
            for (var key in this.list) {
                this.dataList[key] = this.validate(this.list[key]);
            }
        },
        validate(list, level) {
            level = level !== undefined ? level : this.level;
            if (level >= this.maxLevel - 1) {
                var allSubmenus = this.getAllSubmenu(list.child || []);
                list.child = allSubmenus || [];
            } else {
                for (var key in list.child) {
                    list.child[key] = this.validate(list.child[key], level + 1);
                }
            }
            return list;
        },
        getAllSubmenu(list) {
            const vm = this;
            return list
                .map(function(list) {
                    var newList = [list, ...vm.getAllSubmenu(list.child || [])];
                    list.child = [];
                    return newList;
                })
                .flat();
        },
        handleDelete(item) {
            this.$emit("delete:category", item);
        },
        handleEdit(item) {
            this.$emit("edit:category", item);
        },
        findItemNested(arr, itemId) {
            return arr.reduce((a, item) => {
                if (a) return a;
                if (item.id == itemId) return item;
                if (item.child) return this.findItemNested(item.child, itemId);
            }, null);
        },
    },
};
</script>

<style lang="scss">
#category {
    .category-item {
        width: 100%;
        max-width: 640px;
        margin-bottom: 8px;
        .category-item-list {
            padding: 0;
        }
        .dragArea {
            background: #fff;
            padding-bottom: 8px;
        }
    }
    .details {
        color: #505f79;
        background: #fff;
        &.hide {
            background: #ddd;
            + .dragArea {
                background: #ddd;
            }
        }
        padding: 8px 12px 4px 24px;
        margin-top: -1px;
        position: relative;
        display: flex;
        justify-content: space-between;
        border-radius: 5px 5px 0 0;
        @media only screen and (max-width: 1024px) {
            padding: 8px 12px 4px 24px;
        }
        span {
            line-height: 1.2;
        }
        &.draggable:before {
            content: "\2807";
            font-size: 12px;
            position: absolute;
            display: flex;
            align-self: center;
            left: 12px;
            top: 8px;
            bottom: 0;
        }
        &:hover .btn-wrapper {
            display: flex;
        }
        &:hover .number-of-post {
            display: none;
        }
        .name {
            font-size: 14px;
            display: flex;
            align-items: center;
            i {
                margin-right: 4px;
            }
        }
        .btn-wrapper {
            display: none;
            a {
                cursor: pointer;
                &:hover {
                    opacity: 0.7;
                }
            }
            .edit {
                font-size: 12px;
                color: #2684ff;
            }
            .delete {
                font-size: 12px;
                color: #ff7452;
                margin-left: 12px;
            }
        }
        .lock,
        .view {
            font-size: 12px;
            float: right;
        }
        .view {
            margin-right: 16px;
            cursor: pointer;
        }
        .number-of-post {
            float: right;
            color: #fff;
            background: #c1c7d0;
            font-size: 12px;
            padding: 0 8px;
            border-radius: 15px;
        }
        .hide-icon {
            &:before {
                content: "";
                display: inline-block;
                height: 16px;
                width: 16px;
                background-image: url("./../../assets/visibility.png");
                background-size: contain;
            }
        }
    }
    .dragArea {
        width: 100%;
        min-height: 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        border-radius: 0 0 5px 5px;
    }
    .category-item-list {
        padding-left: 0px;
        .category-item {
            width: calc(100% - 64px);
            min-width: calc(100% - 64px);
            margin-left: 32px;
            margin-right: 32px;
            margin-bottom: 4px;
            .details {
                background: #f4f5f7;
            }
        }
        .dragArea {
            background: #f4f5f7;
            .category-item {
                .details {
                    background: #dcdfe6;
                    padding-bottom: 8px;
                    border-radius: 5px;
                    @media only screen and (max-width: 1024px) {
                        padding-bottom: 8px;
                    }
                }
                .dragArea {
                    background: transparent;
                }
            }
        }
    }
    .ghost {
        opacity: 0.5;
    }
}
</style>
