<template>
    <div class="info">
        <el-row :gutter="32" class="info-row">
            <el-col :span="8" class="image-col">
                <el-image :src="message.cover_image_path || placeholder" class="cover-image" fit="contain">
                    <template #placeholder>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                    <template #error>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                </el-image>
            </el-col>
            <el-col :span="12" class="message-info">
                <el-row class="title">
                    <el-col :span="24">
                        <span>{{ message.title }}</span>
                    </el-col>
                </el-row>
                <el-row class="message-meta">
                    <el-col :span="24">
                        <div class="field">
                            <span class="field-title">Publish Date:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ message.published_date || "N/A" }}
                            </span>
                        </div>
                        <div class="field">
                            <span class="field-title">Updated At:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ message.updated_at || "N/A" }}
                            </span>
                        </div>
                        <div class="field">
                            <span class="field-title">Author:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ message.author || "N/A" }}
                            </span>
                        </div>
                        <div class="field">
                            <span class="field-title">Target Dept:&nbsp;&nbsp;</span>
                            <span class="field-value">
                                {{ message.ward || "N/A" }}
                            </span>
                        </div>
                        <div class="field file-path">
                            <span class="field-title">Media:&nbsp;&nbsp;</span>
                            <span class="field-value" :class="[message.media_path ? 'field-link' : '']" @click="handleFileOpen(message.media_path)">
                                {{ message.media_path ? "Preview" : "N/A" }}
                            </span>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="4">
                <div class="action-btns">
                    <el-button @click="handleEditMessage" class="save-btn" type="primary" :disabled="!message.id" size="small">
                        Edit
                    </el-button>
                </div>
            </el-col>
        </el-row>
    </div>
    <div class="details">
        <el-tabs v-model="activeName">
            <el-tab-pane class="content" label="Content" name="Content">
                <div class="message-content rich-editor-preview" v-html="message.content"></div>
            </el-tab-pane>
            <el-tab-pane class="views" name="Views" v-if="windowInnerWidth < 1025">
                <template #label>
                    Views
                    <span>({{ views.length }})</span>
                </template>
                <ViewsTab :views="views" />
            </el-tab-pane>
        </el-tabs>
    </div>
    <Drawer :drawer="editMsPunsMessageDrawer" @update:isVisible="editMsPunsMessageDrawer.isVisible = $event">
        <template v-slot:content>
            <EditMsPunsMessageForm ref="editMsPunsMessageForm" :isLoading="editMsPunsMessageDrawer.isLoading" :message="message" />
        </template>
    </Drawer>
</template>

<script>
import placeholder from "@/assets/placeholder.png";
import Drawer from "@/components/Drawer";
import EditMsPunsMessageForm from "@/components/ms-puns-message/EditMsPunsMessageForm";
import ViewsTab from "@/components/ms-puns-message/tabs/ViewsTab";
export default {
    name: "MsPunsMessageDetails",
    components: {
        Drawer,
        EditMsPunsMessageForm,
        ViewsTab,
    },
    props: ["message", "views"],
    data() {
        return {
            windowInnerWidth: null,
            isLoading: false,
            // info
            placeholder: placeholder,
            editMsPunsMessageDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Edit Ms Pun's Message",
                save: () => this.editMsPunsMessage(),
                close: done => this.closeDrawer(done),
            },
            // tabs
            activeName: "Content",
        };
    },
    created() {
        window.addEventListener("resize", this.getWindowInnerWidth);
    },
    mounted() {
        this.getWindowInnerWidth();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.getWindowInnerWidth);
    },
    methods: {
        async editMsPunsMessage() {
            this.editMsPunsMessageDrawer.isLoading = true;
            try {
                const result = await this.$refs.editMsPunsMessageForm.editMsPunsMessage();
                if (result) {
                    this.editMsPunsMessageDrawer.isVisible = false;
                    this.$notify({
                        title: "Ms Pun's Message updated successfully",
                        message: "Ms Pun's Message " + result + " was updated successfully",
                        type: "success",
                    });
                    this.$emit("update:message");
                }
            } finally {
                this.editMsPunsMessageDrawer.isLoading = false;
            }
        },
        handleEditMessage() {
            this.editMsPunsMessageDrawer.isVisible = true;
        },
        closeDrawer() {
            this.$refs.editMsPunsMessageForm.resetForm();
            this.editMsPunsMessageDrawer.isVisible = false;
        },
        getWindowInnerWidth() {
            this.windowInnerWidth = window.innerWidth;
            if (this.windowInnerWidth > 1024) {
                this.activeName = "Content";
            }
        },
        handleFileOpen(filePath) {
            if (filePath) {
                window.open(filePath, "_blank");
            }
        },
    },
};
</script>

<style lang="scss">
#ms-puns-message-details {
    .details-content {
        display: flex;
        flex-direction: column;
    }
    .message-meta {
        flex: 1;
        overflow: auto;
        .field {
            margin-top: 8px;
        }
        .file-path {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .field-link {
                cursor: pointer;
                color: #36b37e;
            }
            .field-link:hover {
                text-decoration: underline;
            }
        }
        .field-title {
            color: #44444f;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 17px;
        }
        .field-value {
            color: #92929d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
    }
    .info {
        height: 188px;
        .info-row,
        .el-image {
            height: 100%;
        }
        .info-row {
            flex: 1;
        }
        .image-col {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .cover-image {
                border-radius: 5px;
            }
        }
        .action-btns {
            display: flex;
            justify-content: flex-end;
            @media (min-width: 1025px) {
                margin-bottom: 40px;
            }
        }
        .message-info {
            text-align: left;
            display: flex;
            flex-direction: column;
            .title {
                color: #171725;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
            }
        }
    }

    .details {
        flex: 1;
        overflow: auto;
        .el-tabs {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .el-tab-pane {
            height: 100%;
            overflow-y: auto;
        }
        .message-content {
            padding-right: 7px;
        }
        .views {
            .el-avatar {
                background: transparent;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    ::-webkit-scrollbar-thumb {
        background: #4ac292;
        border: 0 solid #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track {
        background: #e8e8e8;
        border: 0 none #ffffff;
        border-radius: 3px;
    }
    ::-webkit-scrollbar-track:hover {
        background: #e8e8e8;
    }
    ::-webkit-scrollbar-corner {
        background: transparent;
    }
}
</style>
