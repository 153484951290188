<template>
    <el-table
        class="action-table"
        ref="actionTable"
        :size="windowInnerWidth > 1024 ? 'medium' : 'mini'"
        :data="table.data"
        :style="table.style"
        @selection-change="rows => (selection ? table.handleSelectionChange(rows) : {})"
        :default-sort="table.defaultSort"
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
        :height="table.height || tableHeight"
        :key="table.height || tableHeight"
        v-loading="table.isLoading"
        @row-click="(row, column, e) => (table.handleRowClick ? table.handleRowClick(row, column, e) : {})"
        :cell-class-name="({ row, column, rowIndex, columnIndex }) => (table.classChecker ? table.classChecker({ row, column, rowIndex, columnIndex }) : {})"
        :cell-style="{ padding: windowInnerWidth > 1024 ? '10px 0' : '0' }"
        @filter-change="filters => (table.filterChange ? table.filterChange(filters) : {})"
    >
        <el-table-column v-if="selection" type="selection" width="45"></el-table-column>
        <el-table-column
            v-for="(col, index) in table.cols"
            :key="index"
            :label="col.label.toUpperCase()"
            :prop="col.prop"
            :column-key="col.prop"
            :sortable="col.sortable"
            :width="col.width"
            :formatter="col.formatter"
            :min-width="col.minWidth"
            :class-name="col.className ? col.className : ''"
            :label-class-name="col.className ? 'default-label' : ''"
            show-overflow-tooltip
            @click="col.callback"
            :align="col.align ? col.align : 'left'"
            :filters="col.filters ? col.filters : null"
            :filter-method="(value, row, column) => (col.filters ? (col.filterMethod ? col.filterMethod(value, row, column) : {}) : {})"
            :filter-multiple="col.filters ? (col.filterMultiple != null ? col.filterMultiple : true) : false"
        >
            <template #default="scope" v-if="col.label == 'views'">
                <i class="el-icon-view"></i>
                <span>{{ scope.row[col.prop] }} Users viewed</span>
            </template>
        </el-table-column>
        <el-table-column v-if="table.handleDelete" label="ACTION" :width="table.handleEdit || table.handlePin ? 88 : 72" align="left">
            <template #default="scope">
                <div v-if="table.name == 'Settings'" class="action-button-group">
                    <el-button
                        v-if="table.handleEdit"
                        size="small"
                        type="text"
                        @click="(index, row) => table.handleEdit(scope.$index, scope.row)"
                        :disabled="(scope.row.user_level_id >= 2 && userLevel == 2) || userLevel < scope.row.user_level_id || scope.row.user_id == 1 || scope.row.user_id == 2"
                    >
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button
                        size="small"
                        type="text"
                        @click="(index, row) => table.handleDelete(scope.$index, scope.row)"
                        :disabled="(scope.row.user_level_id >= 2 && userLevel == 2) || userLevel < scope.row.user_level_id || scope.row.user_id == 1 || scope.row.user_id == 2"
                    >
                        <i class="el-icon-delete"></i>
                    </el-button>
                </div>
                <div v-else class="action-button-group">
                    <el-button v-if="table.handleEdit" size="small" type="text" @click="(index, row) => table.handleEdit(scope.$index, scope.row)">
                        <i class="el-icon-edit"></i>
                    </el-button>
                    <el-button size="small" type="text" @click="(index, row) => table.handleDelete(scope.$index, scope.row)">
                        <i class="el-icon-delete"></i>
                    </el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
    <el-row class="action-table-footer" v-show="!table.loading">
        <el-col :span="6">
            <span class="pagination-index">{{ startItemIndex }}-{{ endItemIndex }} of {{ table.numberOfItems }} items</span>
        </el-col>
        <el-col :span="18" class="pagination-container">
            <el-pagination
                class="pagination"
                :currentPage="table.pagination.currentPage"
                :page-size="pageSize"
                :page-sizes="pageSizes"
                :layout="pageSizes.length == 1 ? 'prev, pager, next' : 'prev, pager, next, sizes'"
                :total="Number(table.numberOfItems)"
                @size-change="pageSize => table.pagination.handleSizeChange(pageSize)"
                @current-change="currentPage => table.pagination.handleCurrentChange(currentPage)"
            ></el-pagination>
        </el-col>
    </el-row>
</template>

<script>
import $ from "jquery";
import { getUserInfo } from "@/utils/auth";

export default {
    name: "Table",
    props: {
        table: {
            required: true,
            type: Object,
        },
        selection: {
            type: Boolean,
            default: true,
        },
        canResize: {
            type: Boolean,
            default: true,
        },
        canDelete: {
            type: Boolean,
            default: true,
        },
    },
    data(props) {
        return {
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            // table
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            headerCellStyle: {
                background: "#fafafb",
                border: "0px",
            },
            tableHeight: 500,
            windowInnerWidth: null,

            // pagination
            pageSize: props.table.pagination.pageSize || 10,
            pageSizes: props.table.pagination.pageSizes || [10, 20, 30],
        };
    },
    computed: {
        startItemIndex: function() {
            return 1 + this.table.pagination.pageSize * (this.table.pagination.currentPage - 1);
        },
        endItemIndex: function() {
            return this.table.pagination.pageSize * this.table.pagination.currentPage;
        },
    },
    created() {
        if (this.canResize) {
            window.addEventListener("resize", this.resize);
        }
    },
    beforeUnmount() {
        if (this.canResize) {
            window.removeEventListener("resize", this.resize);
        }
    },
    mounted() {
        if (this.canResize) {
            this.resize();
        }
    },
    methods: {
        // table
        resize() {
            this.windowInnerWidth = window.innerWidth;
            if ($(".el-tabs__header")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 164 : 152;
                this.tableHeight = $(".page-content")[0].clientHeight - constant;
            } else if ($(".page-content")[0]) {
                let constant = this.windowInnerWidth > 1024 ? 88 : 62;
                this.tableHeight = $(".page-content")[0].clientHeight - $(".container-header")[0].clientHeight - constant;
            }
        },
    },
};
</script>

<style lang="scss">
.action-table {
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    .el-table__header-wrapper {
        &::-webkit-scrollbar {
            background: transparent;
            width: 7px;
        }
        &::-webkit-scrollbar-track {
            background: #fafafb;
            border: 0;
        }
        overflow-y: scroll;
        overflow-x: hidden;
        th:not(.filterable) .el-table__column-filter-trigger {
            display: none;
        }
    }
    .el-table__header,
    .el-table__body {
        width: 100%;
    }
    .el-table__body {
        padding-bottom: 10px;
    }
    .default-label {
        color: #44444f;
        font-size: 11px;
    }
    .link-type {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            color: #36b37e;
        }
    }
    .el-button {
        &.is-disabled {
            opacity: 0.25;
        }
    }
    td {
        &:first-child {
            border: 1px solid transparent;
            border-right: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:nth-child(n) {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
        }
        &:last-child {
            border: 1px solid transparent;
            border-left: 0;
        }
    }
    &.el-table--enable-row-hover .el-table__body tr:hover {
        td {
            background-color: #fff;
            &:first-child {
                border: 1px solid #3dd598;
                border-right: 0;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:nth-child(n) {
                border-top: 1px solid #3dd598;
                border-bottom: 1px solid #3dd598;
            }
            &:last-child {
                border: 1px solid #3dd598;
                border-left: 0;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    @media (min-width: 1025px) {
        .hidden-bigger-than-1024 {
            display: none !important;
        }
    }
    @media only screen and (max-width: 1024px) {
        .hidden-smaller-than-1024 {
            display: none !important;
        }
    }
    .action-button-group {
        display: flex;
    }
    .el-icon-delete {
        font-size: 18px;
        color: #ff7452;
    }
    .el-icon-edit {
        font-size: 18px;
        color: #2684ff;
    }
    .pin-icon {
        &:before {
            content: "";
            display: inline-block;
            height: 18px;
            width: 18px;
            background-image: url("./../assets/pin.svg");
            background-size: contain;
        }
    }

    .pin-off-icon {
        &:before {
            content: "";
            display: inline-block;
            height: 18px;
            width: 18px;
            background-image: url("./../assets/pin-off.svg");
            background-size: contain;
        }
    }
}
.action-table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: auto;
    width: 100%;
    margin-top: 8px;
    .pagination-index {
        font-weight: normal;
        color: #44444f;
        font-size: 13px;
    }
    .pagination-container {
        display: flex;
        justify-content: flex-end;
    }
    .pagination {
        display: flex;
        align-items: center;
        padding: 2px 0;
        li {
            font-weight: normal;
            color: #92929d;

            &.active {
                color: #36b37e;
            }
        }
        .btn-next,
        .btn-prev {
            color: #92929d;
            height: 32px;
            width: 32px;
            border: 1px solid #e2e2ea;
            padding: 0;
            border-radius: 5px;
        }
        .el-input__inner {
            height: 32px;
            color: #92929d;
        }
        .el-pagination__sizes {
            height: auto;
            margin: 0;
        }
    }
}
</style>
