import { readonly, ref, watch } from "vue";

export function useState(initialState) {
    const state = ref(initialState);
    const setState = newState => {
        state.value = newState;
    };

    return [readonly(state), setState];
}

export function useStateWatch(initialState, watcher) {
    const state = ref(initialState);
    const setState = newState => {
        state.value = newState;
    };
    watch(state, (state, preState) => {
        if (state && state !== preState) watcher(state);
    });
    return [readonly(state), setState];
}

export function useStateObject(initialState) {
    const state = ref(initialState);
    const setState = newState => {
        state.value = newState;
    };

    return { get: readonly(state), set: setState };
}
