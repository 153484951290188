<template>
    <el-form class="notification-form" ref="notificationForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small" @keydown.enter.prevent>
        <el-row :gutter="32">
            <el-col :span="24">
                <div class="description">
                    <i class="el-icon-warning-outline el-icon-left"></i>
                    <span class="contact">
                        1. Click to select ONLY one training post
                    </span>
                </div>
            </el-col>
            <el-col :span="24">
                <el-input
                    prefix-icon="el-icon-search"
                    :placeholder="postSearchInput.placeholder"
                    :title="postSearchInput.placeholder"
                    v-model="postSearchInput.value"
                    @keyup.enter="searchPostTable()"
                    @clear="searchPostTable()"
                    clearable
                    size="small"
                />
                <div class="noti-post-table">
                    <el-alert title="Post selected" :description="form.post.title" type="success" show-icon v-if="Object.keys(form.post).length > 0" @close="form.post = {}"></el-alert>
                    <el-alert title="Please click to select a post" type="error" show-icon v-else-if="showPostError" :closable="false"></el-alert>
                    <el-table
                        height="300"
                        :highlight-current-row="Object.keys(form.post).length > 0"
                        :header-row-style="headerRowStyle"
                        :row-style="rowStyle"
                        @current-change="handlePostCurrentChange"
                        ref="postTable"
                        :data="postTable.filterData"
                        size="mini"
                    >
                        <el-table-column prop="title" align="left" label="TITLE" label-class-name="default-label"></el-table-column>
                        <el-table-column prop="category_name" align="left" label="CATEGORY" label-class-name="default-label"></el-table-column>
                        <el-table-column prop="target_ward" align="left" label="TARGET DEPT" label-class-name="default-label"></el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="24">
                <div class="description">
                    <i class="el-icon-warning-outline el-icon-left"></i>
                    <span class="contact">
                        2. Enter the content and select audience(s) for the notification
                    </span>
                </div>
            </el-col>
            <el-col :span="24">
                <el-input
                    prefix-icon="el-icon-search"
                    :placeholder="searchInput.placeholder"
                    :title="searchInput.placeholder"
                    v-model="searchInput.value"
                    @keyup.enter="searchTable()"
                    @clear="searchTable()"
                    clearable
                    size="small"
                />
                <el-tabs class="tabs" size="small" v-model="activeName">
                    <el-tab-pane :label="'Dept (' + wardTable.filterData.length + ')'" name="Wards">
                        <el-table
                            height="300"
                            :header-row-style="headerRowStyle"
                            @selection-change="handleWardSelectionChange"
                            ref="wardTable"
                            :data="wardTable.filterData"
                            class="noti-ward-table"
                            size="mini"
                        >
                            <el-table-column type="selection" width="55" align="right" label-class-name="default-label"></el-table-column>
                            <el-table-column prop="icon" width="88" align="left" class-name="icon-col" label="SELECT ALL" label-class-name="default-label">
                                <template #default="scope">
                                    <el-avatar>
                                        {{ handleWardAvatar(scope.row) }}
                                    </el-avatar>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" align="left" class-name="name" label-class-name="default-label"></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane :label="'Users (' + userTable.filterData.length + ')'" name="Users">
                        <el-table
                            height="300"
                            :header-row-style="headerRowStyle"
                            @selection-change="handleUserSelectionChange"
                            ref="userTable"
                            :data="userTable.filterData"
                            class="noti-user-table"
                            size="mini"
                        >
                            <el-table-column type="selection" width="55" align="right" label-class-name="default-label"></el-table-column>
                            <el-table-column prop="icon" width="100" align="left" class-name="icon-col" label="SELECT ALL" label-class-name="default-label">
                                <template #default="scope">
                                    <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                                        <template #placeholder>
                                            <el-image :src="placeholder" fit="contain"></el-image>
                                        </template>
                                        <template #error>
                                            <el-image :src="placeholder" fit="contain"></el-image>
                                        </template>
                                    </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column align="left" class-name="name" width="152" show-overflow-tooltip label="NAME" label-class-name="default-label">
                                <template #default="scope">
                                    {{ scope.row.full_name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="left"
                                class-name="ward"
                                min-width="100"
                                label="DEPT"
                                label-class-name="default-label"
                                column-key="ward"
                                prop="roster_ward"
                                :filters="userTable.wardFilters"
                                :filter-method="filterHandler"
                            ></el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="24">
                <el-form-item label="Notification Content:" prop="content">
                    <el-input type="textarea" class="upload-textarea" v-model="form.content" placeholder="Please enter content" maxlength="1200" show-word-limit></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { fetchData as fetchPosts, sendNotification } from "@/modules/training";
import { fetchData as fetchWards } from "@/modules/general/ward";
import { fetchData as fetchUsers } from "@/modules/settings/user";
import placeholder from "@/assets/placeholder.png";
export default {
    name: "NotificationForm",
    components: {},
    props: ["isLoading"],
    data() {
        return {
            searchInput: {
                placeholder: "Search Dept",
                value: "",
            },
            postSearchInput: {
                placeholder: "Search by post title",
                value: "",
            },
            activeName: "Wards",
            rowStyle: {
                cursor: "pointer",
            },
            headerRowStyle: {
                color: "#44444f",
                fontSize: "11px",
            },
            placeholder: placeholder,
            postTable: {
                numberOfItems: 0,
                isLoading: false,
                filterData: [],
                data: [],
            },
            wardTable: {
                numberOfItems: 0,
                isLoading: false,
                filterData: [],
                data: [],
            },
            userTable: {
                numberOfItems: 0,
                isLoading: false,
                filterData: [],
                data: [],
                wardFilters: [],
            },
            form: {
                rules: {
                    content: [
                        {
                            required: true,
                            message: "Please enter content",
                            trigger: ["change", "blur"],
                        },
                    ],
                },
                title: "Training",
                content: "",
                post: {},
                ward: {
                    value: [],
                },
                user: {
                    value: [],
                },
            },
            showPostError: false,
        };
    },
    mounted() {
        this.fetchPosts();
        this.fetchWards();
        this.fetchUsers();
    },
    watch: {
        activeName(value) {
            this.searchInput.value = "";
            this.searchTable();
            if (value == "Wards") {
                this.searchInput.placeholder = "Search Wards";
            } else if (value == "Users") {
                this.searchInput.placeholder = "Search Users";
            }
            this.form.ward.value = [];
            this.form.user.value = [];
        },
    },
    methods: {
        async fetchPosts() {
            try {
                this.postTable.isLoading = true;
                let pageSize = 2000;
                let pageIndex = 1;
                let searchString = "";
                let wardId = null;
                const result = await fetchPosts(wardId, pageSize, pageIndex, searchString);
                if (result) {
                    this.postTable.data = result.data;
                    this.postTable.filterData = result.data;
                    this.postTable.numberOfItems = result.count;
                }
            } finally {
                this.postTable.isLoading = false;
            }
        },
        async fetchWards() {
            this.wardTable.isLoading = true;
            let pageSize = 2000;
            let pageIndex = 1;
            let searchString = "";
            try {
                const result = await fetchWards("admin");
                if (result) {
                    this.wardTable.data = result;
                    this.wardTable.filterData = result;
                    this.wardTable.numberOfItems = result.length;
                }
            } finally {
                this.wardTable.isLoading = false;
            }
        },
        async fetchUsers() {
            this.userTable.isLoading = true;
            let pageSize = 2000;
            let pageIndex = 1;
            let searchString = "";
            try {
                const result = await fetchUsers(pageSize, pageIndex, searchString);
                if (result) {
                    this.userTable.data = result.data;
                    this.userTable.filterData = result.data;
                    this.userTable.numberOfItems = result.count;
                    this.userTable.wardFilters = result.rosterWardList.map(({ name }) => {
                        return { text: name, value: name };
                    });
                }
            } finally {
                this.userTable.isLoading = false;
            }
        },
        async sendNotification() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                const notification = {
                    wardList: this.form.ward.value,
                    userList: this.form.user.value,
                    title: this.form.title,
                    content: this.form.content,
                    post: this.form.post,
                    type: "training",
                };
                return await sendNotification(notification);
            } catch (error) {
                console.log(error);
            }
        },
        searchTable() {
            if (this.activeName == "Wards") {
                this.wardTable.filterData = this.wardTable.data.filter(data => !this.searchInput.value || data.name.toLowerCase().includes(this.searchInput.value.toLowerCase()));
            } else if (this.activeName == "Users") {
                this.userTable.filterData = this.userTable.data.filter(data => !this.searchInput.value || data.full_name.toLowerCase().includes(this.searchInput.value.toLowerCase()));
            }
        },
        searchPostTable() {
            this.postTable.filterData = this.postTable.data.filter(data => !this.postSearchInput.value || data.title.toLowerCase().includes(this.postSearchInput.value.toLowerCase()));
        },
        handlePostCurrentChange(row) {
            this.form.post = row ? row : {};
        },
        handleWardSelectionChange(rows) {
            this.form.ward.value = rows.map(row => row.id);
        },
        handleUserSelectionChange(rows) {
            this.form.user.value = rows.map(row => row.user_id);
        },
        handleWardAvatar(row) {
            let firstChar = "";
            let SecondChar = "";
            if (row && row.name) {
                firstChar = row.name.split(" ")[0]
                    ? row.name
                          .split(" ")[0]
                          .charAt(0)
                          .toUpperCase()
                    : "";
                SecondChar = row.name.split(" ")[1]
                    ? row.name
                          .split(" ")[1]
                          .charAt(0)
                          .toUpperCase()
                    : "";
            }

            return firstChar + SecondChar;
        },
        resetForm() {
            this.$refs.notificationForm.resetFields();
        },
        async validateForm() {
            try {
                const hasSelectTarget = this.form.ward.value.length > 0 || this.form.user.value.length > 0;
                const hasPost = Object.keys(this.form.post).length > 0;

                if (!hasSelectTarget) {
                    this.$notify({
                        title: "Error",
                        message: "Please select at least one ward or one user to send notification.",
                        type: "error",
                    });
                }

                if (!hasPost) {
                    this.showPostError = true;
                }

                return (await this.$refs.notificationForm.validate()) && hasPost;
            } catch (error) {
                return false;
            }
        },
        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] === value;
        },
    },
};
</script>

<style lang="scss">
.notification-form {
    .tabs {
        margin-bottom: 16px;
        .el-tabs__header {
            margin: 0;
        }
    }
    .upload-textarea {
        .el-textarea__inner {
            height: 150px;
            resize: none;
        }
        .el-input__count {
            right: 8px;
            bottom: -16px;
            background: transparent;
        }
    }
    .noti-post-table {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .noti-post-table,
    .noti-ward-table,
    .noti-user-table {
        .cell-class {
            padding: 0;
        }
        .name,
        .ward {
            color: #171725;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
        .date {
            color: #92929d;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
        }
        .el-avatar {
            background: #36b37e;
        }
        .icon-col {
            .cell {
                display: flex;
                align-content: center;
                > .cover-image {
                    display: flex;
                    align-content: center;
                    border-radius: 50%;
                    width: 44px;
                    height: 44px;
                }
            }
        }
        .default-label {
            color: #44444f;
            font-size: 11px;
            font-weight: bold;
            background: #fafafb;
        }
    }
}
</style>
