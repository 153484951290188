import { render } from "./EditWardGroupForm.vue?vue&type=template&id=2069f53e"
import script from "./EditWardGroupForm.vue?vue&type=script&lang=js"
export * from "./EditWardGroupForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2069f53e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2069f53e', script)) {
    api.reload('2069f53e', script)
  }
  
  module.hot.accept("./EditWardGroupForm.vue?vue&type=template&id=2069f53e", () => {
    api.rerender('2069f53e', render)
  })

}

script.__file = "src/components/settings/EditWardGroupForm.vue"

export default script