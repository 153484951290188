import { render } from "./PageNotFound.vue?vue&type=template&id=c78d7b50"
import script from "./PageNotFound.vue?vue&type=script&lang=js"
export * from "./PageNotFound.vue?vue&type=script&lang=js"

import "./PageNotFound.vue?vue&type=style&index=0&id=c78d7b50&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c78d7b50"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c78d7b50', script)) {
    api.reload('c78d7b50', script)
  }
  
  module.hot.accept("./PageNotFound.vue?vue&type=template&id=c78d7b50", () => {
    api.rerender('c78d7b50', render)
  })

}

script.__file = "src/views/PageNotFound.vue"

export default script