import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/settings/rank?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async rank => {
    try {
        const params = {
            name: rank.name,
            color: rank.color,
            description: rank.description,
            priority: rank.priority,
        };
        const result = await axios.post(`/admin/settings/rank`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async rank => {
    try {
        const params = {
            name: rank.name,
            color: rank.color,
            description: rank.description,
            priority: rank.priority,
        };
        const result = await axios.put(`/admin/settings/rank/${rank.id}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async rankId => {
    try {
        const result = await axios.delete(`/admin/settings/rank/${rankId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
