import { render } from "./RosterDot.vue?vue&type=template&id=60c1272a"
import script from "./RosterDot.vue?vue&type=script&lang=js"
export * from "./RosterDot.vue?vue&type=script&lang=js"

import "./RosterDot.vue?vue&type=style&index=0&id=60c1272a&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "60c1272a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('60c1272a', script)) {
    api.reload('60c1272a', script)
  }
  
  module.hot.accept("./RosterDot.vue?vue&type=template&id=60c1272a", () => {
    api.rerender('60c1272a', render)
  })

}

script.__file = "src/components/roster/RosterDot.vue"

export default script