import { render } from "./AnnualLeaveHeader.vue?vue&type=template&id=34686822"
import script from "./AnnualLeaveHeader.vue?vue&type=script&lang=js"
export * from "./AnnualLeaveHeader.vue?vue&type=script&lang=js"

import "./AnnualLeaveHeader.vue?vue&type=style&index=0&id=34686822&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "34686822"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('34686822', script)) {
    api.reload('34686822', script)
  }
  
  module.hot.accept("./AnnualLeaveHeader.vue?vue&type=template&id=34686822", () => {
    api.rerender('34686822', render)
  })

}

script.__file = "src/components/annual-leave/AnnualLeaveHeader.vue"

export default script