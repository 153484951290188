<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format and pdf with a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
        </span>
    </div>
    <el-form class="add-post-form" ref="addPostForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules" size="small">
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Title:" prop="title">
                    <el-input class="add-post-title word-limit-input" v-model="form.title" placeholder="Title" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="File:" required>
                    <el-upload class="upload-file" ref="upload" accept=".pdf" :auto-upload="false" :limit="1" :on-change="handleFileChange" :on-remove="handleFileRemove" action="#">
                        <template #default v-if="isFileEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="top" effect="light">
                                <template #content>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name" @click="handleFilePreview(file)">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleFileRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>
                            {{ form.fileError }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Publish date:" prop="publishDate">
                    <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.publishDate" format="YYYY-MM-DD HH:mm" />
                </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
				<el-form-item label="End date:" prop="endDate">
					<el-date-picker
						type="date"
						placeholder="Pick a date"
						v-model="form.endDate"
						style="width: 100%"
					/>
				</el-form-item>
			</el-col> -->
            <el-col :span="12">
                <el-form-item label="Category(s):" prop="category" ref="category">
                    <el-select
                        v-model="form.category.value"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Please enter a keyword"
                        :remote-method="filterSelect"
                        :loading="form.category.isLoading"
                    >
                        <el-option-group v-for="group in form.category.options" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options" :key="item.value" :label="item.displayName" :value="item.value">
                                {{ item.label }}
                            </el-option>
                        </el-option-group>
                    </el-select>
                    <div class="category-error">
                        <span>
                            {{ form.category.error }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-form-item label="Remarks:" class="remarks-form-item" prop="remarks">
                    <el-input type="textarea" class="upload-textarea" v-model="form.remarks" placeholder="Please enter remarks" maxlength="50" show-word-limit></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Cover Image:">
                    <el-upload
                        class="upload-cover-image"
                        ref="uploadCoverImage"
                        accept="image/*"
                        :auto-upload="false"
                        :limit="1"
                        :on-change="handleImageChange"
                        :on-remove="handleImageRemove"
                        action="#"
                    >
                        <template #default v-if="isCoverImageEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="top" effect="light">
                                <template #content>
                                    <img :src="imagePreview" class="staff-card-image-preview" />
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleImageRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>{{ form.coverImageError }}</span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { fetchCategory, postData as addPost } from "@/modules/training";
import moment from "moment";
import { filter } from "xe-utils";
export default {
    name: "AddPostForm",
    props: ["isLoading"],
    data() {
        var validateCategory = (rule, value, callback) => {
            let arr = value.value;
            if (!arr.length) {
                callback(new Error("Please select a category"));
            } else {
                callback();
            }
        };
        return {
            imagePreview: "",
            form: {
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Please enter a title",
                            trigger: "blur",
                        },
                    ],
                    publishDate: [
                        {
                            required: true,
                            message: "Please pick a date",
                            trigger: "blur",
                        },
                    ],
                    // endDate: [
                    // 	{
                    // 		required: true,
                    // 		message: "Please pick a date",
                    // 		trigger: "blur",
                    // 	},
                    // ],
                    category: [
                        {
                            required: true,
                            validator: validateCategory,
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                title: "",
                coverImage: {},
                coverImageError: "",
                file: {},
                fileError: "",
                publishDate: new Date(),
                endDate: new Date(2038, 0, 1),
                remarks: "",
                category: {
                    loading: false,
                    value: [],
                    options: [],
                    list: [],
                    error: "",
                },
            },
        };
    },
    computed: {
        isFileEmpty() {
            return Object.keys(this.form.file).length == 0;
        },
        isCoverImageEmpty() {
            return Object.keys(this.form.coverImage).length == 0;
        },
    },
    mounted() {
        this.fetchCategory();
    },
    methods: {
        async fetchCategory() {
            this.form.category.isLoading = true;
            const data = await fetchCategory();
            if (data) {
                this.form.category.isLoading = false;
                let categoryList = data.map(result => {
                    const root = result.options
                        .filter(({ parent_id }) => parent_id === null)
                        .sort((a, b) => {
                            return a.priority - b.priority;
                        });
                    const child = result.options.filter(({ parent_id }) => parent_id !== null);
                    let options = [];
                    root.forEach(rootItem => {
                        options = options.concat({
                            ...rootItem,
                            level: 1,
                            displayName: rootItem.name,
                            priority: rootItem.priority,
                        });
                        const c = child
                            .filter(childItem => childItem.parent_id === rootItem.id)
                            .sort((a, b) => {
                                return a.priority - b.priority;
                            });
                        c.forEach(childItem => {
                            options = options.concat({
                                ...childItem,
                                name: `└─${childItem.name}`,
                                displayName: childItem.name,
                                priority: rootItem.priority,
                            });
                            const sub = child
                                .filter(subItem => subItem.parent_id === childItem.id)
                                .sort((a, b) => {
                                    return a.priority - b.priority;
                                });
                            options = options.concat(
                                sub.map(item => {
                                    return {
                                        ...item,
                                        name: `　　└─${item.name}`,
                                        displayName: item.name,
                                        priority: rootItem.priority,
                                    };
                                }),
                            );
                        });
                    });

                    options = options.map(category => {
                        return {
                            label: category.name,
                            value: category.id,
                            displayName: category.displayName,
                        };
                    });
                    return {
                        label: result.label,
                        options: options,
                    };
                });

                this.form.category.options = categoryList;
                this.form.category.list = categoryList;
            }
        },
        async addPost() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                const post = {
                    title: this.form.title,
                    file: this.form.file.raw,
                    publishDate: moment(this.form.publishDate).format("YYYY-MM-DD HH:mm"),
                    endDate: moment(this.form.endDate).format("YYYY-MM-DD HH:mm"),
                    categoryList: this.form.category.value,
                    remarks: this.form.remarks,
                    coverImage: this.isCoverImageEmpty ? null : this.form.coverImage.raw,
                };
                const result = await addPost(post);
                if (result) {
                    return post.title;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        // Upload drawer
        handleFileChange(file) {
            if (this.validateFile(file)) {
                this.form.file = file;
            } else {
                this.$refs.upload.clearFiles();
            }
        },
        handleFileRemove() {
            this.$refs.upload.clearFiles();
            this.form.file = {};
            this.validateFile(this.form.file);
        },
        handleFilePreview(file) {
            if (file.url) {
                window.open(file.url, "_blank");
            }
        },
        handleImageChange(image) {
            if (this.validateImage(image)) {
                this.form.coverImage = image;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(image.raw);
            } else {
                this.$refs.uploadCoverImage.clearFiles();
            }
        },
        handleImageRemove() {
            this.$refs.uploadCoverImage.clearFiles();
            this.form.coverImage = {};
            this.validateImage(this.form.coverImage);
        },
        filterSelect(query) {
            this.form.category.isLoading = true;
            setTimeout(() => {
                this.form.category.isLoading = false;
                this.form.category.options = this.form.category.list
                    .map(ward => {
                        let filterOptions = ward.options.filter(item => {
                            return item.displayName.indexOf(query.toLowerCase()) > -1;
                        });

                        return {
                            ...ward,
                            options: filterOptions,
                        };
                    })
                    .filter(({ options }) => {
                        return options.length > 0;
                    });
            }, 0);
        },
        validateFile(file) {
            if (Object.keys(file).length == 0) {
                this.form.fileError = "Please upload a pdf";
                return false;
            }
            const isPdf = file.raw.type === "application/pdf";
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isPdf) {
                this.form.fileError = "File can only be pdf format";
                return false;
            }
            if (!isLt10M) {
                this.form.fileError = "File cannot exceed 10MB";
                return false;
            }
            this.form.fileError = "";
            return true;
        },
        validateImage(image) {
            if (Object.keys(image).length == 0) {
                return true;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;

            if (!isImage) {
                this.form.coverImageError = "Image can only be png/jpeg/jpg format";
                return false;
            }
            if (!isLt10M) {
                this.form.coverImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.coverImageError = "";
            return true;
        },
        resetForm() {
            this.$refs.addPostForm.resetFields();
            this.$refs.upload.clearFiles();
            this.$refs.uploadCoverImage.clearFiles();
            this.form.title = "";
            this.form.file = {};
            this.form.fileError = "";
            this.form.publishDate = new Date();
            this.form.endDate = new Date(2038, 0, 1);
            this.form.category.value = [];
            this.form.category.error = "";
            this.form.remarks = "";
            this.form.coverImage = {};
            this.form.coverImageError = "";
        },
        async validateForm() {
            try {
                const fileResult = this.validateFile(this.form.file);
                const imageResult = this.validateImage(this.form.coverImage);
                const result = await this.$refs.addPostForm.validate();
                return result && fileResult && imageResult;
            } catch (error) {
                return false;
            }
        },
    },
};
</script>
