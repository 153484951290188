import { render } from "./RosterHeader.vue?vue&type=template&id=6d076b88"
import script from "./RosterHeader.vue?vue&type=script&lang=js"
export * from "./RosterHeader.vue?vue&type=script&lang=js"

import "./RosterHeader.vue?vue&type=style&index=0&id=6d076b88&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6d076b88"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6d076b88', script)) {
    api.reload('6d076b88', script)
  }
  
  module.hot.accept("./RosterHeader.vue?vue&type=template&id=6d076b88", () => {
    api.rerender('6d076b88', render)
  })

}

script.__file = "src/components/roster/RosterHeader.vue"

export default script