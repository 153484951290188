import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus, { ElNotification, ElMessageBox, ElMessage } from "element-plus";
import "./element-variables.scss";

import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

import router from "./router";
import axios from "axios";
import { logout } from "@/modules/auth";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = window.location.origin;
axios.interceptors.request.use(
    config => {
        if (getToken() && config.url != "/auth/login") {
            config.headers["Authorization"] = "Bearer " + getToken();
        }
        return config;
    },
    error => {
        Promise.reject(error);
    },
);
axios.interceptors.response.use(
    response => {
        if (response.data.statusCode === 200) return response.data.data;
        else throw new Error(response.data.message);
    },
    error => {
        if (error.response && (error.response.data.statusCode === 403 || error.response.data.statusCode === 401)) {
            // invalid credential
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("token_expired");
                return;
            }
            ElMessageBox({
                title: "Authentication Error",
                message: "You will be redirected to login page and please login again",
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                confirmButtonText: "Confirm",
                beforeClose: (action, instance, done) => {
                    logout();
                    done();
                    ElMessage({
                        showClose: true,
                        type: "success",
                        message: "Logged out successfully.",
                    });
                },
            });
        } else if (error.response && error.response.data.statusCode === 402) {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("token_expired");
                return;
            }
            ElMessageBox({
                title: "Token Expired",
                message: "You will be redirected to login page and please login again",
                showCancelButton: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                confirmButtonText: "Confirm",
                beforeClose: (action, instance, done) => {
                    logout();
                    done();
                    ElMessage({
                        showClose: true,
                        type: "success",
                        message: "Logged out successfully.",
                    });
                },
            });
        } else if (error.toString().indexOf("Error: timeout") !== -1 || error.toString().indexOf("Error: Network Error") !== -1) {
            // connection error
            ElNotification.error({
                title: `Error`,
                message: "Connection Error",
                type: "error",
            });
            return;
        } else if (error.response.config.url == "/auth/login") {
            // expectional case for login page
            throw new Error(error.response.data.message);
        } else {
            //normal error
            ElNotification.error({
                title: `Error: ${error.response.data.statusCode}`,
                message: error.response.data.message,
            });
            return;
        }
    },
);

const getToken = () => {
    return window.localStorage.getItem("jwt");
};

const app = createApp(App).use(router);
app.use(ElementPlus);
app.use(VXETable);
app.mount("#app");
app.config.devtools = true;
