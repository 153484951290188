import { render } from "./AddPostForm.vue?vue&type=template&id=517f2734"
import script from "./AddPostForm.vue?vue&type=script&lang=js"
export * from "./AddPostForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "517f2734"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('517f2734', script)) {
    api.reload('517f2734', script)
  }
  
  module.hot.accept("./AddPostForm.vue?vue&type=template&id=517f2734", () => {
    api.rerender('517f2734', render)
  })

}

script.__file = "src/components/knowledge/AddPostForm.vue"

export default script