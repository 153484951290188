<template>
    <div id="ms-puns-message">
        <ViewContainer :title="`Ms Pun's Message`" :numberOfItems="msPunsMessageTable.numberOfItems ? msPunsMessageTable.numberOfItems : 0" :isLoading="isLoading">
            <template v-slot:header>
                <ContainerHeader
                    :searchInput="searchInput"
                    :searchString="searchInput.value"
                    @update:searchString="searchInput.value = $event"
                    :dropdownMenu="containerDropdownMenu"
                    :buttons="containerButtons"
                />
            </template>
            <template v-slot:content>
                <Table :table="msPunsMessageTable" />
            </template>
        </ViewContainer>
        <ViewsDialog v-if="viewsDialog.isVisible" :dialog="viewsDialog" @update:isVisible="viewsDialog.isVisible = $event" />
        <Drawer :drawer="addMsPunsMessageDrawer" @update:isVisible="addMsPunsMessageDrawer.isVisible = $event">
            <template v-slot:content>
                <AddMsPunsMessageForm ref="addMsPunsMessageForm" :isLoading="addMsPunsMessageDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchMsPunsMessages, deleteData as deleteMsPunsMessages } from "@/modules/ms-puns-message";
import ViewContainer from "@/components/ViewContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/Table";
import Drawer from "@/components/Drawer";
import ViewsDialog from "@/components/ms-puns-message/ViewsDialog";
import AddMsPunsMessageForm from "@/components/ms-puns-message/AddMsPunsMessageForm";
export default {
    name: "MsPunsMessage",
    components: {
        ViewContainer,
        ContainerHeader,
        Table,
        Drawer,
        ViewsDialog,
        AddMsPunsMessageForm,
    },
    data() {
        return {
            isLoading: false,
            searchInput: {
                placeholder: "Search by Title, Author",
                value: "",
                callback: () => this.searchMsPunsMessages(),
            },
            containerDropdownMenu: {
                name: "Action",
                items: [
                    {
                        name: "Delete",
                        icon: "el-icon-delete",
                        value: 0,
                        style: "color: #de350b;",
                    },
                ],
                callback: command => this.handleAction(command),
            },
            containerButtons: [
                {
                    name: "New Message",
                    icon: "el-icon-plus",
                    callback: () => this.handleNewMessage(),
                },
            ],
            msPunsMessageTable: {
                isLoading: false,
                handleRowClick: (row, column, e) => this.handleRowClick(row, column, e),
                cols: [
                    {
                        label: "message title",
                        sortable: true,
                        prop: "title",
                        minWidth: "176",
                        className: "link-type",
                    },
                    {
                        label: "publish date",
                        sortable: true,
                        prop: "published_date",
                        minWidth: "128",
                    },
                    {
                        label: "updated at",
                        sortable: true,
                        prop: "updated_at",
                        minWidth: "148",
                    },
                    {
                        label: "target dept",
                        sortable: true,
                        prop: "ward",
                        minWidth: "132",
                    },
                    {
                        label: "views",
                        sortable: false,
                        prop: "number_of_views",
                        width: "148",
                        className: "user-view",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleDelete: (index, row) => this.handleDelete(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            viewsDialog: {
                isVisible: false,
                msgTitle: "",
                messageId: null,
            },
            addMsPunsMessageDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New Ms Pun's Message",
                save: () => this.addMsPunsMessage(),
                close: done => this.closeMsPunsMessageDrawer(done),
            },
        };
    },
    mounted() {
        this.fetchMsPunsMessages();
    },
    methods: {
        async fetchMsPunsMessages() {
            this.isLoading = true;
            let pageSize = this.msPunsMessageTable.pagination.pageSize;
            let pageIndex = this.msPunsMessageTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchMsPunsMessages(pageSize, pageIndex, searchString);
                if (result) {
                    this.msPunsMessageTable.data = result.data;
                    this.msPunsMessageTable.numberOfItems = result.count;
                }
            } finally {
                this.isLoading = false;
            }
        },
        async addMsPunsMessage() {
            try {
                this.addMsPunsMessageDrawer.isLoading = true;
                const result = await this.$refs.addMsPunsMessageForm.addMsPunsMessage();
                if (result) {
                    this.addMsPunsMessageDrawer.isVisible = false;
                    this.$notify({
                        title: "Ms Pun's Message added successfully",
                        message: "Ms Pun's Message " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchMsPunsMessages();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addMsPunsMessageDrawer.isLoading = false;
            }
        },
        async deleteMsPunsMessages(messageList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the Ms Pun's message(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteMsPunsMessages(messageList);
                    if (result) {
                        this.$notify({
                            title: "Ms Pun's Message(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchMsPunsMessages();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // header
        handleAction(selection) {
            if (this.msPunsMessageTable.multipleSelection.length > 0) {
                if (selection == 0) {
                    this.deleteMsPunsMessages(this.msPunsMessageTable.multipleSelection);
                }
            }
        },
        handleNewMessage() {
            this.addMsPunsMessageDrawer.isVisible = true;
        },
        // table
        searchMsPunsMessages() {
            this.msPunsMessageTable.pagination.currentPage = 1;
            this.fetchMsPunsMessages();
        },
        handleDelete(index, row) {
            this.deleteMsPunsMessages([row.id]);
        },
        handleSelectionChange(rows) {
            this.msPunsMessageTable.multipleSelection = rows.map(post => post.id);
        },
        handleRowClick(row, column) {
            if (column.property == "number_of_views") {
                this.viewsDialog.isVisible = true;
                this.viewsDialog.msgTitle = row.title;
                this.viewsDialog.messageId = row.id;
            } else if (column.property == "title") {
                this.$router.push({
                    path: "/ms-puns-message/" + row.id,
                });
            } else {
                return;
            }
        },
        // pagination
        handleSizeChange(pageSize) {
            this.msPunsMessageTable.pagination.pageSize = pageSize;
            this.fetchMsPunsMessages();
        },
        handleCurrentChange(currentPage) {
            this.msPunsMessageTable.pagination.currentPage = currentPage;
            this.fetchMsPunsMessages();
        },
        closeMsPunsMessageDrawer() {
            this.$refs.addMsPunsMessageForm.resetForm();
            this.addMsPunsMessageDrawer.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
#ms-puns-message {
    height: 100%;
    .action-table {
        .user-view {
            font-size: 12px;
            color: #2684ff;
            .cell {
                cursor: pointer;
                display: flex;
                align-items: center;
                .el-icon-view {
                    font-size: 18px;
                    margin-right: 8px;
                }
            }
        }
    }
}
</style>
