import { render } from "./Category.vue?vue&type=template&id=4d5aa038"
import script from "./Category.vue?vue&type=script&lang=js"
export * from "./Category.vue?vue&type=script&lang=js"

import "./Category.vue?vue&type=style&index=0&id=4d5aa038&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4d5aa038"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4d5aa038', script)) {
    api.reload('4d5aa038', script)
  }
  
  module.hot.accept("./Category.vue?vue&type=template&id=4d5aa038", () => {
    api.rerender('4d5aa038', render)
  })

}

script.__file = "src/views/Category.vue"

export default script