<template>
    <div id="policies">
        <TabContainer>
            <template v-slot:content>
                <PoliciesTable :table="policiesTable" />
            </template>
        </TabContainer>
        <Drawer :drawer="editPolicyDrawer" @update:isVisible="editPolicyDrawer.isVisible = $event">
            <template v-slot:content>
                <EditPolicyForm ref="editPolicyForm" :isLoading="editPolicyDrawer.isLoading" :policy="editPolicyDrawer.policy" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchPolicies, fetchDetails as fetchPolicy } from "@/modules/settings/policies";
import EditPolicyForm from "@/components/settings/EditPolicyForm";
import TabContainer from "@/components/TabContainer";
import PoliciesTable from "@/components/settings/PoliciesTable";
import Drawer from "@/components/Drawer";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "Policies",
    components: {
        TabContainer,
        PoliciesTable,
        Drawer,
        EditPolicyForm,
    },
    data() {
        return {
            policiesTable: {
                isLoading: false,
                data: [],
                numberOfItems: null,
                handleEdit: (index, row) => this.handleEdit(index, row),
            },
            editPolicyDrawer: {
                isVisible: false,
                isLoading: false,
                title: "Edit Policy",
                policy: {},
                save: () => this.editPolicy(),
                close: () => this.closeEditPolicyDrawer(),
            },
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
        };
    },
    mounted() {
        this.fetchPolicies();
    },
    methods: {
        async fetchPolicies() {
            this.policiesTable.isLoading = true;
            try {
                const result = await fetchPolicies();
                if (result) {
                    this.policiesTable.data = result;
                    this.$emit("update:numberOfPolicies", result.length);
                }
            } finally {
                this.policiesTable.isLoading = false;
            }
        },
        async handleEdit(index, row) {
            try {
                this.editPolicyDrawer.isLoading = true;
                const result = await fetchPolicy(row.id);
                if (result) {
                    this.editPolicyDrawer.policy = result;
                    this.editPolicyDrawer.title = `Edit Policy (${result.title})`;
                    this.editPolicyDrawer.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editPolicyDrawer.isLoading = false;
            }
        },
        async editPolicy(form) {
            try {
                this.editPolicyDrawer.isLoading = true;
                const result = await this.$refs.editPolicyForm.editPolicy(form);
                if (result) {
                    this.editPolicyDrawer.isVisible = false;
                    this.$notify({
                        title: "Policy edited successfully",
                        message: "Policy was edited successfully",
                        type: "success",
                    });
                    this.fetchPolicies();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editPolicyDrawer.isLoading = false;
            }
        },
        // drawer
        handleEditPolicyDrawer() {
            this.editPolicyDrawer.isVisible = true;
        },
        closeEditPolicyDrawer() {
            this.$refs.editPolicyForm.resetForm();
            this.editPolicyDrawer.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
.policies-table {
}
</style>
