import axios from "axios";

export const fetchData = async () => {
    try {
        const result = await axios.get(`/app/general/ward?type=request-roster`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchAnnualWardData = async () => {
    try {
        const result = await axios.get(`/app/general/ward?type=request-annual`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
