import { render } from "./AddUserForm.vue?vue&type=template&id=9331b618"
import script from "./AddUserForm.vue?vue&type=script&lang=js"
export * from "./AddUserForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9331b618"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9331b618', script)) {
    api.reload('9331b618', script)
  }
  
  module.hot.accept("./AddUserForm.vue?vue&type=template&id=9331b618", () => {
    api.rerender('9331b618', render)
  })

}

script.__file = "src/components/settings/AddUserForm.vue"

export default script