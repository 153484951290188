<template>
    <vxe-table
        border
        row-key
        row-id="user_id"
        auto-resize
        show-overflow
        show-header-overflow
        max-height="100%"
        ref="annualLeave"
        class="annual-leave-table-mobile"
        empty-text="No Data"
        size="mini"
        v-loading="table.isLoading.value"
        :data="table.rows.value"
        :edit-config="{ trigger: 'click', mode: 'cell', showIcon: false, activeMethod: props => checkPermission(props) }"
        :row-class-name="highlightUser || ''"
    >
        <vxe-table-column field="full_name" width="150" fixed="left" v-if="!table.isManage.value">
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span>Date</span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <AnnualLeaveDot :class="[table.isManage.value && scope.row.al_priority > table.currPriority.value ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" />
            </template>
        </vxe-table-column>
        <vxe-table-column field="full_name" width="150" v-else>
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span>Date</span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <AnnualLeaveDot :class="[table.isManage.value && scope.row.al_priority > table.currPriority.value ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" />
            </template>
        </vxe-table-column>
        <vxe-table-column
            v-for="(item, index) in table.columnHeader.value"
            :key="index"
            width="88"
            align="center"
            :class-name="item.tag ? 'hot-season display-cell' : 'display-cell'"
            :header-class-name="item.tag ? 'hot-season' : ''"
            :edit-render="{}"
        >
            <template #header>
                <div class="date">
                    <span>
                        {{ table.columnHeader.value[index].week_start_date }}
                    </span>
                    <span>
                        {{ table.columnHeader.value[index].week_end_date }}
                    </span>
                </div>
                <div class="vacancy">
                    <span>Vacancy</span>
                    <span>{{ table.columnHeader.value[index].vacancy }}</span>
                </div>
            </template>
            <template #default="scope">
                <span>{{ parseLeave(scope, item) }}</span>
                <el-popover placement="top-start" trigger="hover" content="User is blocked for this week" v-if="parseBlock(scope, item)">
                    <template #reference>
                        <i class="el-icon-lock"></i>
                    </template>
                </el-popover>
            </template>
            <template #edit="scope">
                <vxe-select placeholder="Select" ref="annualLeaveSelect" @change="(value, $event) => handleSelectedValue(value, scope)" transfer>
                    <vxe-option
                        v-for="(options, index) in table.leaveOptions.value"
                        :key="index"
                        :value="options.value"
                        :label="options.label"
                        :class-name="parseSelectedLeave(scope, item, options.value)"
                    ></vxe-option>
                </vxe-select>
            </template>
        </vxe-table-column>
    </vxe-table>
</template>

<script>
import AnnualLeaveDot from "@/components/annual-leave/AnnualLeaveDot";
import moment from "moment";
import { getUserInfo } from "@/utils/auth";
export default {
    name: "AnnualLeave",
    components: {
        AnnualLeaveDot,
    },
    props: {
        table: {
            required: true,
            type: Object,
        },
        yearOptions: {
            type: Array,
        },
        selectedYear: {
            type: Number,
        },
    },
    data() {
        return {
            tableHeight: 700,
            isPlatformAdmin: getUserInfo().user_level == 3 || false,
            userId: getUserInfo().id || "",
        };
    },
    computed: {
        yearLabel() {
            if (this.yearOptions && this.yearOptions.length > 0) {
                const target = this.yearOptions.find(year => year == this.selectedYear);
                if (target) {
                    return target;
                }
            }
            return "";
        },
    },
    methods: {
        parseBlock(scope, item) {
            if (scope.row.annual && scope.row.annual.length > 0) {
                const annual = scope.row.annual.find(({ request_week_id }) => {
                    return request_week_id == item.request_week_id;
                });
                if (annual) {
                    return annual.blocked || false;
                }
            }
            return false;
        },
        parseLeave(scope, item) {
            if (scope.row.annual && scope.row.annual.length > 0) {
                const annual = scope.row.annual.find(({ request_week_id }) => {
                    return request_week_id == item.request_week_id;
                });
                if (annual) {
                    return String(annual.value || "");
                }
            }
            return "";
        },
        parseSelectedLeave(scope, item, optionValue) {
            if (scope.row.annual && scope.row.annual.length > 0) {
                const annual = scope.row.annual.find(({ request_week_id }) => {
                    return request_week_id == item.request_week_id;
                });
                if (annual) {
                    if (annual.value && typeof annual.value == "number") {
                        return "AL" == optionValue ? "is--selected" : "";
                    } else {
                        let displayValue = annual.value ? String(annual.value) : "";
                        return displayValue == optionValue ? "is--selected" : "";
                    }
                }
            }
            return "" == optionValue ? "is--selected" : "";
        },
        async handleSelectedValue(value, scope) {
            const selectedWeekId = this.table.columnHeader.value[scope.columnIndex - 1].request_week_id;
            let leave = {
                userId: scope.row.user_id,
                weekId: selectedWeekId,
                value: value.value,
            };
            this.table.rows.value.forEach(row => {
                if (row.user_id == scope.row.user_id) {
                    var updated = false;
                    row.annual.forEach(annual => {
                        if (annual.request_week_id == selectedWeekId) {
                            annual.value = value.value;
                            updated = true;
                        }
                    });
                    if (!updated) {
                        row.annual.push({
                            blocked: false,
                            request_week_id: selectedWeekId,
                            user_id: scope.row.user_id,
                            value: value.value,
                        });
                    }
                }
            });
            this.table.addRequestAnnual(leave);
            setTimeout(() => {
                this.$refs.annualLeave.reloadData(JSON.parse(JSON.stringify(this.table.rows.value)));
            }, 0);
        },
        // findEditable(scope) {
        // 	const selectedIndex = scope.$index;
        // 	const jsonData = JSON.parse(scope.column.label);
        // 	const selectedWeekId = jsonData.request_week_id;
        // 	return this.table.editable.value.find(({ weekId, index }) => {
        // 		return weekId == selectedWeekId && index == selectedIndex;
        // 	});
        // },
        // handleOnClick(scope) {
        // 	this.$emit("click:editCell", scope);
        // },
        highlightUser({ row }) {
            var className = "";
            if (row.user_id == this.userId) {
                className += "highlight-row";
            }

            if (row.al_priority == this.table.currentPriority.value) {
                className += " curr-priority-row";
            }
            return className;
        },
        handlePass(scope) {
            console.log(scope.row);
        },
        checkPermission(scope) {
            const currentRow = this.table.columnHeader.value[scope.columnIndex - 1];
            const requestWeekId = currentRow.request_week_id;
            const vacancy = currentRow.vacancy;
            const requestedAnnual = this.table.requestedAnnual || [];
            const annualRows = scope ? scope.row.annual : [];
            const selectedUser = scope ? scope.row.user_id : null;
            const alPriority = scope ? scope.row.al_priority : 1;

            // block roles for mobile only
            // only modify own line
            if (selectedUser != this.userId) {
                return false;
            }

            if (vacancy <= 0) {
                return false;
            }

            if (alPriority != this.table.currentPriority.value) {
                return false;
            }

            var today = moment().format("YYYY-MM-DD");

            const isBeforeCutOffDate = this.table.cutOffDate ? moment(today).isSameOrBefore(this.table.cutOffDate.value) : true;

            if (!isBeforeCutOffDate) {
                return false;
            }

            const selectedAnnualRow = annualRows.find(row => {
                return row.request_week_id == requestWeekId;
            });

            // only value is empty can change
            if (selectedAnnualRow && selectedAnnualRow.value !== "" && selectedAnnualRow.value != "AL") {
                return false;
            }

            // if admin set blocked
            if (selectedAnnualRow && selectedAnnualRow.blocked) {
                return false;
            }

            // only selected two AL at each request
            if (requestedAnnual.value.length >= 2) {
                var selectedCurr = false;
                requestedAnnual.value.forEach(value => {
                    if (value.weekId == requestWeekId) {
                        selectedCurr = true;
                        return;
                    }
                });
                if (selectedCurr) {
                    return true;
                }
                return false;
            }

            // default
            return true;
        },
    },
};
</script>

<style lang="scss">
.annual-leave-table-mobile {
    width: 100%;
    .vxe-table--render-wrapper {
        height: 100%;
    }
    .vxe-table--main-wrapper {
        height: 100%;
        .vxe-table--body-wrapper {
            height: 100%;
        }
    }
    .vxe-header--column.col--ellipsis > .vxe-cell {
        max-height: 100px !important;
        height: 100px;
    }
    .vxe-header--column:nth-child(1) :nth-last-child(1) {
        .vxe-cell--title {
            width: 100%;
        }
    }
    .display-cell {
        .vxe-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                position: absolute;
                right: 10px;
            }
        }
    }
    .hot-season {
        background: #f9ebf6;
    }
    .highlight-row {
        background: #f0f9eb;
        .hot-season {
            background: #f0f9eb;
        }
    }
    // header/corner
    thead tr {
        padding: 0;
        .cell {
            padding: 0;
            display: flex;
            flex-direction: column;
        }
        th {
            padding: 0;
            .date {
                display: flex;
                flex-direction: column;
                margin-bottom: 8px;
                span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 1.2;
                    text-align: center;
                    color: #061938;
                }
            }
            .vacancy {
                display: flex;
                flex-direction: column;
                span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 1.2;
                    text-align: center;
                    color: #97a0af;
                }
            }
        }
        .first-col {
            span {
                font-size: 12px;
                line-height: 16px;
            }
            .header-corner-date {
                color: #061938;
                text-align: end;
            }
            .header-corner-staff {
                color: #061938;
                text-align: start;
            }
        }
    }
    .draggable:before {
        content: "\2807";
        font-size: 20px;
        color: #ddd;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }
    .curr-priority-row {
        td {
            border-top: 1px solid #049596;
            border-bottom: 1px solid #049596;
        }
    }
}
</style>
