<template>
    <el-form class="edit-ward-group-form" ref="editWardGroupForm" :model="form" label-position="top" v-loading="isLoading" :rules="form.rules">
        <el-row>
            <el-col :span="24">
                <el-form-item label="Dept Name:" prop="name">
                    <el-input class="name" v-model="form.name" placeholder="Enter the dept name"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import { putData as editWardGroup } from "@/modules/settings/ward-group";
export default {
    name: "EditWardGroupForm",
    props: ["isLoading", "ward"],
    components: {},
    data() {
        return {
            form: {
                rules: {
                    name: [
                        {
                            required: true,
                            message: "Please enter dept name",
                            trigger: ["blur", "change"],
                        },
                    ],
                },
                name: "",
            },
        };
    },
    mounted() {
        this.form.name = this.ward.name;
    },
    methods: {
        async editWardGroup() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                let ward = {
                    id: this.ward.id,
                    name: this.form.name,
                };
                const result = await editWardGroup(ward);
                if (result) {
                    return ward.name;
                } else {
                    return result;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async validateForm() {
            try {
                const result = await this.$refs.editWardGroupForm.validate();
                return result;
            } catch (error) {
                return false;
            }
        },
        resetForm() {
            this.$refs.editWardGroupForm.resetFields();
        },
    },
};
</script>
