import { render } from "./AddNOMessageForm.vue?vue&type=template&id=813efd86"
import script from "./AddNOMessageForm.vue?vue&type=script&lang=js"
export * from "./AddNOMessageForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "813efd86"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('813efd86', script)) {
    api.reload('813efd86', script)
  }
  
  module.hot.accept("./AddNOMessageForm.vue?vue&type=template&id=813efd86", () => {
    api.rerender('813efd86', render)
  })

}

script.__file = "src/components/no-message/AddNOMessageForm.vue"

export default script