import { render } from "./SettingsDialog.vue?vue&type=template&id=f4d4f7e2"
import script from "./SettingsDialog.vue?vue&type=script&lang=js"
export * from "./SettingsDialog.vue?vue&type=script&lang=js"

import "./SettingsDialog.vue?vue&type=style&index=0&id=f4d4f7e2&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f4d4f7e2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f4d4f7e2', script)) {
    api.reload('f4d4f7e2', script)
  }
  
  module.hot.accept("./SettingsDialog.vue?vue&type=template&id=f4d4f7e2", () => {
    api.rerender('f4d4f7e2', render)
  })

}

script.__file = "src/components/roster/SettingsDialog.vue"

export default script