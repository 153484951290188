<template>
    <el-table :show-header="false" ref="commentsTable" :data="comments.data" class="comments-table" v-loading="isLoading" size="small" cell-class-name="cell-class">
        <el-table-column prop="icon" width="65" align="center" class-name="icon-col">
            <template #default="scope">
                <el-image :src="scope.row.icon_image_path || placeholder" class="cover-image" fit="cover">
                    <template #placeholder>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                    <template #error>
                        <el-image :src="placeholder" fit="contain"></el-image>
                    </template>
                </el-image>
            </template>
        </el-table-column>
        <el-table-column align="left" class-name="info-col">
            <template #default="scope">
                <span class="name">
                    {{ scope.row.full_name }}
                    <el-tag size="mini" type="danger" class="private-tag" v-if="scope.row.is_private">
                        Private
                    </el-tag>
                </span>
                <span class="comment">{{ scope.row.comment }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="created_at" align="right" class-name="date" width="86"></el-table-column>
        <el-table-column class-name="ellipsis" align="left" width="40">
            <template #default="scope">
                <el-dropdown size="mini" trigger="click">
                    <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="comments.callback(scope.row.id)">
                                Delete
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import placeholder from "@/assets/placeholder.png";

export default {
    props: ["comments"],
    data() {
        return {
            isLoading: false,
            placeholder: placeholder,
        };
    },
    methods: {
        errorHandler() {
            return true;
        },
    },
};
</script>

<style lang="scss">
.comments-table {
    .el-avatar {
        background: transparent;
    }
    .name {
        color: #171725;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
    }
    .comment {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
    .date {
        vertical-align: top;
        color: #92929d;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
    }
    .ellipsis {
        vertical-align: top;
        .el-dropdown-link {
            cursor: pointer;
        }
    }
    .icon-col {
        .cell {
            display: flex;
            align-content: center;
            justify-content: center;
            > .cover-image {
                display: flex;
                align-content: center;
                border-radius: 50%;
                width: 44px;
                height: 44px;
            }
        }
    }
    .info-col {
        .cell {
            display: flex;
            flex-direction: column;
            .private-tag {
                margin-left: 8px;
            }
        }
    }
}
</style>
