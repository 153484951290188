import { render } from "./UploadDialog.vue?vue&type=template&id=48105e6f"
import script from "./UploadDialog.vue?vue&type=script&lang=js"
export * from "./UploadDialog.vue?vue&type=script&lang=js"

import "./UploadDialog.vue?vue&type=style&index=0&id=48105e6f&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "48105e6f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('48105e6f', script)) {
    api.reload('48105e6f', script)
  }
  
  module.hot.accept("./UploadDialog.vue?vue&type=template&id=48105e6f", () => {
    api.rerender('48105e6f', render)
  })

}

script.__file = "src/components/annual-leave/UploadDialog.vue"

export default script