import axios from "axios";

export const fetchData = async (wardId, pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/table/tel-directory?wardId=${wardId}&pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async directory => {
    try {
        const formData = new FormData();
        formData.append("title", directory.title);
        formData.append("file", directory.file);
        formData.append("publishDate", directory.publishDate);
        formData.append("endDate", directory.endDate);
        formData.append("remarks", directory.remarks);
        for (let i = 0; i < directory.wardList.length; i++) {
            formData.append("wardList[]", directory.wardList[i]);
        }
        const result = await axios.post(`/admin/table/tel-directory`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async directoryList => {
    const params = {
        list: directoryList,
    };
    try {
        const result = await axios.post(`/admin/table/tel-directory/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
