import { render } from "./EditCategoryForm.vue?vue&type=template&id=3b3cce86"
import script from "./EditCategoryForm.vue?vue&type=script&lang=js"
export * from "./EditCategoryForm.vue?vue&type=script&lang=js"

import "./EditCategoryForm.vue?vue&type=style&index=0&id=3b3cce86&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3b3cce86"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3b3cce86', script)) {
    api.reload('3b3cce86', script)
  }
  
  module.hot.accept("./EditCategoryForm.vue?vue&type=template&id=3b3cce86", () => {
    api.rerender('3b3cce86', render)
  })

}

script.__file = "src/components/category/EditCategoryForm.vue"

export default script