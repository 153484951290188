<template>
    <vxe-table
        border
        row-key
        row-id="user_id"
        auto-resize
        max-height="100%"
        ref="roster"
        class="roster-table"
        empty-text="No Data"
        size="mini"
        v-loading="table.isLoading.value"
        :data="table.rows.value"
        :edit-config="{ trigger: 'click', mode: 'cell', showIcon: false }"
    >
        <vxe-table-column width="104" fixed="left" v-if="!table.isManage.value" show-overflow>
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span class="month-and-year date-color">
                            {{ parseCornerHeaderYear() }}
                        </span>
                        <span class="month-and-year primary-font-color">
                            {{ parseCornerHeaderMonth() }}
                        </span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <RosterDot :class="[table.isManage.value ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" />
            </template>
        </vxe-table-column>
        <vxe-table-column width="104" v-else show-overflow>
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span class="month-and-year date-color">
                            {{ parseCornerHeaderYear() }}
                        </span>
                        <span class="month-and-year primary-font-color">
                            {{ parseCornerHeaderMonth() }}
                        </span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <RosterDot :class="[table.isManage.value ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" />
            </template>
        </vxe-table-column>
        <vxe-table-column v-for="(item, index) in table.columnHeader.value" :key="index" width="140" align="center" :edit-render="{}">
            <template #header>
                <div class="header-details">
                    <span class="date date-color" :class="[parseHeaderDay(item) == 'Sun' ? 'holiday' : '']">
                        {{ parseHeaderDate(item) }}
                    </span>
                    <span class="day primary-font-color" :class="[parseHeaderDay(item) == 'Sun' ? 'holiday' : '']">
                        {{ parseHeaderDay(item) }}
                    </span>
                    <div class="duty date-color">
                        {{ parseHeaderDuty(item) }}
                    </div>
                </div>
            </template>
            <template #default="scope">
                <span>{{ parseDuty(scope, item) }}</span>
            </template>
            <template #edit="scope">
                <vxe-select placeholder="Select" @change="value => handleSelectDuty(value, item, scope)" transfer>
                    <vxe-option
                        v-for="(options, index) in table.dutyOptions.value"
                        :key="index"
                        :value="options.value"
                        :label="options.label"
                        :class-name="parseSelectedDuty(scope, item, options.value)"
                    ></vxe-option>
                </vxe-select>
            </template>
        </vxe-table-column>
    </vxe-table>
</template>

<script>
import RosterDot from "@/components/roster/RosterDot";
import Sortable from "sortablejs";

export default {
    name: "Roster",
    components: {
        RosterDot,
    },
    props: {
        table: {
            required: true,
            type: Object,
        },
        weeksOptions: {
            type: Array,
        },
        selectedWeek: {
            type: Number,
        },
    },
    data() {
        return {
            sortable: null,
        };
    },
    computed: {
        weekLabel() {
            if (this.weeksOptions && this.weeksOptions.length > 0) {
                const target = this.weeksOptions.find(({ id }) => id === this.selectedWeek);
                if (target) {
                    return target.label;
                }
            }
            return "";
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.setSort();
            }, 0);
        });
    },
    methods: {
        parseCornerHeaderYear() {
            const dates = this.weekLabel.split(" - ");
            if (dates[0]) {
                const fromDate = new Date(dates[0].trim());
                const toDate = new Date(dates[1].trim());
                return `${fromDate.getFullYear()} - ${toDate.getFullYear()}`;
            } else {
                return "";
            }
        },
        parseCornerHeaderMonth() {
            const dates = this.weekLabel.split(" - ");
            if (dates[0]) {
                const fromDate = new Date(dates[0].trim());
                const toDate = new Date(dates[1].trim());
                const MONTHS = ["Jan", "Feb", "Mar", "Api", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                return `${MONTHS[fromDate.getMonth()]} - ${MONTHS[toDate.getMonth()]}`;
            } else {
                return "";
            }
        },
        parseHeaderDate(item) {
            const date = new Date(item.date);
            return `${date.getDate()}-${date.getMonth() + 1}`;
        },
        parseHeaderDay(item) {
            const date = new Date(item.date);
            const day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            return `${day[date.getDay()]}`;
        },
        parseHeaderDuty(item) {
            const duties = item.data;
            const keys = Object.keys(duties);
            let result = "";
            for (let i = 0; i < keys.length; i++) {
                if (duties[keys[i]] > 0) {
                    result = result + String(keys[i]) + "(" + duties[keys[i]] + ") ";
                }
            }
            return result;
        },
        parseDuty(scope, item) {
            if (scope.row.duty && scope.row.duty.length > 0) {
                const duty = scope.row.duty.find(({ date }) => date == item.date);
                if (duty) {
                    return duty.value;
                }
            }
            return "";
        },
        parseSelectedDuty(scope, item, optionValue) {
            if (scope.row.duty && scope.row.duty.length > 0) {
                const duty = scope.row.duty.find(({ date }) => date == item.date);
                if (duty) {
                    return duty.option_id == optionValue ? "is--selected" : "";
                }
            }
        },
        clearScroll() {
            this.$refs.roster.scrollTo(0, 0);
        },
        setSort() {
            const $table = this.$refs.roster;
            this.sortable = Sortable.create($table.$el.querySelector(".vxe-table--body-wrapper > .vxe-table--body tbody"), {
                ghostClass: "sortable-ghost",
                animation: 150,
                disabled: true,
                setData: function(dataTransfer) {
                    dataTransfer.setData("Text", "");
                },
                onEnd: evt => {
                    const targetRow = this.table.rows.value.splice(evt.oldIndex, 1)[0];
                    this.table.rows.value.splice(evt.newIndex, 0, targetRow);
                },
            });
        },
        enableSort() {
            this.sortable.option("disabled", false);
        },
        disableSort() {
            this.sortable.option("disabled", true);
        },
        handleSelectDuty(requestDuty, item, scope) {
            if (scope.row.duty) {
                const duty = scope.row.duty.find(({ date }) => date == item.date);
                if (duty) {
                    // This cell has duty
                    if (duty.option_id == requestDuty.value) {
                        // Cell value equals to request value, no need API call
                        this.clearActive();
                    } else {
                        this.table.postDuty(requestDuty, scope);
                    }
                } else {
                    // This cell is empty
                    let emptyDutyId = 1; // The id of empty value is 1
                    if (requestDuty.value == emptyDutyId) {
                        // Cell value equals to request value, no need API call
                        this.clearActive();
                    } else {
                        this.table.postDuty(requestDuty, scope);
                    }
                }
            }
        },
        clearActive() {
            this.$refs.roster.clearActived();
        },
    },
};
</script>

<style lang="scss">
.roster-table {
    width: 100%;
    .primary-font-color {
        color: #061938;
    }
    .date-color {
        color: #97a0af;
    }
    .holiday {
        color: red;
    }
    .vxe-table--render-wrapper {
        height: 100%;
    }
    .vxe-table--main-wrapper {
        height: 100%;
        .vxe-table--body-wrapper {
            height: 100%;
        }
    }
    .vxe-header--column.col--ellipsis > .vxe-cell {
        max-height: 100px !important;
        height: 100px;
    }
    .vxe-header--column:nth-child(1) {
        .vxe-cell--title {
            width: 100%;
        }
    }
    .display-cell {
        .vxe-cell {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .first-col {
        span {
            font-size: 12px;
            line-height: 16px;
        }
        .header-corner-date {
            display: flex;
            flex-direction: column;
            .month-and-year {
                text-align: end;
            }
        }
    }
    .header-details {
        display: flex;
        flex-direction: column;
        .duty {
            white-space: pre-wrap;
            word-break: break-word;
        }
    }
    .draggable:before {
        content: "\2807";
        font-size: 20px;
        color: #ddd;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }
}
</style>
