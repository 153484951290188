import { render } from "./Knowledge.vue?vue&type=template&id=50ebc0b4"
import script from "./Knowledge.vue?vue&type=script&lang=js"
export * from "./Knowledge.vue?vue&type=script&lang=js"

import "./Knowledge.vue?vue&type=style&index=0&id=50ebc0b4&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "50ebc0b4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('50ebc0b4', script)) {
    api.reload('50ebc0b4', script)
  }
  
  module.hot.accept("./Knowledge.vue?vue&type=template&id=50ebc0b4", () => {
    api.rerender('50ebc0b4', render)
  })

}

script.__file = "src/views/Knowledge.vue"

export default script