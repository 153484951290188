import { render } from "./EditNOMessageForm.vue?vue&type=template&id=1e1ff008"
import script from "./EditNOMessageForm.vue?vue&type=script&lang=js"
export * from "./EditNOMessageForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1e1ff008"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1e1ff008', script)) {
    api.reload('1e1ff008', script)
  }
  
  module.hot.accept("./EditNOMessageForm.vue?vue&type=template&id=1e1ff008", () => {
    api.rerender('1e1ff008', render)
  })

}

script.__file = "src/components/no-message/EditNOMessageForm.vue"

export default script