<template>
    <el-dialog custom-class="change-password-dialog" title="Change Password" v-model="isVisible" :before-close="done => resetForm(done)" width="640px" destroy-on-close>
        <div class="description">
            <i class="el-icon-warning-outline el-icon-left"></i>
            <span class="contact">
                Requirements of password:
                <br />
                - At least one upper case English letter
                <br />
                - At least one lower case English letter
                <br />
                - At least one special character (!@$%&*)
                <br />
                - At least 8 characters in length
            </span>
        </div>
        <el-form class="change-password-form" ref="changePasswordForm" :model="form" v-loading="isLoading" :rules="rules" label-width="192px">
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="Current password:" prop="currentPassword" size="small">
                        <el-input type="password" v-model="form.currentPassword" placeholder="Enter current password" autocomplete="off" clearable show-password></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="New password:" prop="newPassword" size="small">
                        <el-input type="password" v-model="form.newPassword" placeholder="Enter new password" autocomplete="off" clearable show-password></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="32">
                <el-col :span="24">
                    <el-form-item label="Enter new password again:" prop="newPassword2" size="small">
                        <el-input type="password" v-model="form.newPassword2" placeholder="Enter new password again" autocomplete="off" clearable show-password></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="submitUpload('changePasswordForm')" :disabled="isLoading">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="isVisible = false" :disabled="isLoading">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "@/components/useInput";
import { changePassword, logout } from "@/modules/auth/index";
export default {
    name: "ChangePasswordDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
    },

    data() {
        var validatePassword = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("Please enter a new password"));
            } else {
                const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@$%&*])[A-Za-z\d@$!%*?&]{8,}$/g;
                if (!value.match(passwordRegEx)) {
                    callback("The password format is incorrect");
                }
                if (this.form.newPassword2 !== "") {
                    this.$refs.changePasswordForm.validateField("newPassword2");
                }
                callback();
            }
        };
        var validatePassword2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("Please enter a new password again"));
            } else if (value !== this.form.newPassword) {
                callback(new Error("Two passwords don't match"));
            } else {
                callback();
            }
        };
        return {
            isLoading: false,
            form: {
                currentPassword: "",
                newPassword: "",
                newPassword2: "",
            },
            rules: {
                currentPassword: [
                    {
                        required: true,
                        message: "Please enter a password",
                        trigger: ["blur", "change"],
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        validator: validatePassword,
                        trigger: ["blur", "change"],
                    },
                ],
                newPassword2: [
                    {
                        required: true,
                        validator: validatePassword2,
                        trigger: ["blur", "change"],
                    },
                ],
            },
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    beforeUnmount() {
        localStorage.setItem("change_pw", 0);
    },
    methods: {
        async submitUpload(formName) {
            this.isLoading = true;

            this.$refs[formName].validate(async valid => {
                if (valid) {
                    try {
                        const result = await changePassword(this.form);
                        if (result) {
                            this.$alert("Change password success. Please login again.", "Success", {
                                confirmButtonText: "Close",
                                callback: action => {
                                    logout();
                                },
                            });
                        }
                    } finally {
                        this.isLoading = false;
                    }
                } else {
                    this.isLoading = false;
                    return false;
                }
            });
        },
        resetForm(done) {
            this.$refs.changePasswordForm.resetFields();
            done();
        },
    },
};
</script>

<style lang="scss">
.change-password-dialog {
    max-width: 640px;
    border-radius: 5px;
    @media only screen and (max-width: 1024px) {
        margin-top: 5vh !important;
    }
    .el-dialog__header {
        font-weight: bold;
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
        .el-icon-close {
            font-size: 18px;
        }
    }
    .el-dialog__body {
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
        .el-form-item {
            width: 100%;
        }
    }
    .el-dialog__footer {
        .save-btn,
        .cancel-btn {
            width: 120px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 16px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
        text-align: left;
        padding: 16px 40px;
    }
    .el-form-item__label {
        margin: 8px 0;
        padding-right: 8px;
        line-height: 1.3;
        font-weight: bold;
        @media only screen and (max-width: 1024px) {
            font-size: 13px;
        }
    }
}
</style>
