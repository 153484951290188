import { render } from "./MsPunsMessageDetails.vue?vue&type=template&id=4d04195f"
import script from "./MsPunsMessageDetails.vue?vue&type=script&lang=js"
export * from "./MsPunsMessageDetails.vue?vue&type=script&lang=js"

import "./MsPunsMessageDetails.vue?vue&type=style&index=0&id=4d04195f&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4d04195f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4d04195f', script)) {
    api.reload('4d04195f', script)
  }
  
  module.hot.accept("./MsPunsMessageDetails.vue?vue&type=template&id=4d04195f", () => {
    api.rerender('4d04195f', render)
  })

}

script.__file = "src/components/ms-puns-message/MsPunsMessageDetails.vue"

export default script