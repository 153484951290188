import { render } from "./Roster.vue?vue&type=template&id=3e8f8162"
import script from "./Roster.vue?vue&type=script&lang=js"
export * from "./Roster.vue?vue&type=script&lang=js"

import "./Roster.vue?vue&type=style&index=0&id=3e8f8162&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3e8f8162"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3e8f8162', script)) {
    api.reload('3e8f8162', script)
  }
  
  module.hot.accept("./Roster.vue?vue&type=template&id=3e8f8162", () => {
    api.rerender('3e8f8162', render)
  })

}

script.__file = "src/components/roster/Roster.vue"

export default script