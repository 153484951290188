<template>
    <div class="container-header">
        <div class="container-details">
            <el-input
                prefix-icon="el-icon-search"
                class="search-input"
                size="small"
                :placeholder="searchInput.placeholder"
                :title="searchInput.placeholder"
                v-model="inputString"
                @keyup.enter="e => searchInput.callback(e)"
                @clear="e => searchInput.callback(e)"
                clearable
            />
            <el-popover
                v-if="filterPopover"
                v-model:visible="filterPopover.isVisible"
                popper-class="filter-popover"
                :width="200"
                placement="bottom"
                title="Filter:"
                trigger="click"
                @show="showFilter"
                @after-leave="cancelFilter"
            >
                <template #reference>
                    <el-button class="filter-btn" size="small" :icon="filterIcon" :class="[filterCount ? 'is-active' : '']">
                        <span v-if="filterCount">({{ filterCount }})</span>
                    </el-button>
                </template>
                <div class="filter-content">
                    <el-checkbox v-model="filter.title">Title</el-checkbox>
                    <el-checkbox v-model="filter.content">Content</el-checkbox>
                </div>
                <div class="filter-footer">
                    <el-button class="apply-btn" type="primary" size="small" @click="filterPopover.applyFilter(filter)">Apply</el-button>
                    <el-button class="cancel-btn" size="small" @click="cancelFilter">Cancel</el-button>
                </div>
            </el-popover>
            <el-dropdown trigger="click" class="table-actions" size="small" v-if="dropdownMenu && dropdownMenu.items && dropdownMenu.items.length > 0" v-on:command="e => dropdownMenu.callback(e)">
                <el-button class="el-dropdown-link" size="small">
                    {{ dropdownMenu.name }}
                    <i class="el-icon-caret-bottom el-icon--right"></i>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item
                            v-for="dropdownMenuItem in dropdownMenu.items"
                            v-bind:key="dropdownMenuItem.value"
                            :icon="dropdownMenuItem.icon"
                            :style="dropdownMenuItem.style"
                            :class="dropdownMenuItem.class"
                            :command="dropdownMenuItem.value"
                        >
                            <span v-if="!dropdownMenuItem.icon" class="dropdown-item-icon"></span>
                            <span>{{ dropdownMenuItem.name }}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <div v-if="buttons && buttons.length > 0" :span="10" class="container-btn-grp">
            <el-button
                v-for="button in buttons"
                v-bind:key="button.name"
                :ref="setButtonRef"
                :icon="button.icon"
                size="small"
                type="primary"
                @click="() => button.callback()"
                :disabled="button.disabled"
            >
                {{ button.name }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { computed, onBeforeUpdate, onUpdated } from "vue";

export default {
    name: "ContainerHeader",
    props: ["numberOfItems", "buttons", "searchInput", "dropdownMenu", "searchString", "filterPopover"],
    data() {
        return {
            filter: {
                title: true,
                content: false,
            },
        };
    },
    setup(props, { emit }) {
        let buttonRefs = [];
        const inputString = computed({
            get: () => props.searchString,
            set: value => emit("update:searchString", value),
        });
        const setButtonRef = el => {
            if (el) {
                buttonRefs.push(el);
            }
        };
        onBeforeUpdate(() => {
            buttonRefs = [];
        });
        return {
            inputString,
            setButtonRef,
        };
    },
    computed: {
        filterCount() {
            let filter = {
                title: this.filterPopover.title,
                content: this.filterPopover.content,
            };
            return Object.values(filter).filter(filterItem => filterItem == true).length;
        },
        filterIcon() {
            return this.filterCount ? "filter-active-icon" : "filter-icon";
        },
    },
    methods: {
        showFilter() {
            this.filter.title = JSON.parse(JSON.stringify(this.filterPopover.title));
            this.filter.content = JSON.parse(JSON.stringify(this.filterPopover.content));
        },
        cancelFilter() {
            this.filterPopover.cancelFilter();
            this.filter.title = JSON.parse(JSON.stringify(this.filterPopover.title));
            this.filter.content = JSON.parse(JSON.stringify(this.filterPopover.content));
        },
    },
};
</script>

<style lang="scss">
.container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    align-items: flex-start;
    flex-wrap: wrap;
    .container-details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .search-input {
            width: 304px;
        }
        .filter-btn {
            margin-left: 8px;
            padding: 8px 15px;
        }

        .table-actions {
            margin-left: 24px;
            .el-dropdown-link {
                cursor: pointer;
                color: #44444f;
            }
            .el-icon-caret-bottom {
                font-size: 12px;
            }
        }
    }
    .container-btn-grp {
        justify-content: flex-end;
    }
    .filter-icon {
        &:before {
            content: "";
            display: inline-block;
            height: 12px;
            width: 12px;
            background-image: url("./../assets/filter.svg");
            background-size: contain;
        }
    }
    .filter-active-icon {
        &:before {
            content: "";
            display: inline-block;
            height: 12px;
            width: 12px;
            background-image: url("./../assets/filter-active.svg");
            background-size: contain;
        }
    }
}
.filter-popover {
    &.el-popper {
        padding: 16px;
    }
    .el-popover__title {
        font-weight: 600;
    }
    .filter-content {
        min-height: 80px;
        padding: 8px 0;
    }
    .filter-footer {
        display: flex;
        justify-content: flex-end;
        .cancel-btn {
            margin-left: 8px;
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
    }
}
</style>
