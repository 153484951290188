<template>
    <vxe-table
        border
        row-key
        auto-resize
        show-overflow
        show-header-overflow
        max-height="100%"
        ref="annualLeave"
        class="annual-leave-table"
        empty-text="No Data"
        size="mini"
        v-loading="table.isLoading.value"
        :data="table.rows.value"
        :edit-config="{ trigger: 'click', mode: 'cell', showIcon: false }"
        :row-class-name="highlightCurrUser || ''"
    >
        <vxe-table-column field="full_name" width="150" fixed="left" v-if="!table.isManage.value">
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span>Date</span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <AnnualLeaveDot :class="[isUserDraggable(scope) ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" :key="scope.row.user_id" />
            </template>
        </vxe-table-column>
        <vxe-table-column field="full_name" width="150" v-else>
            <template #header>
                <div class="first-col">
                    <div class="header-corner-date">
                        <span>Date</span>
                    </div>
                    <div class="header-corner-staff">
                        <span>Staff</span>
                    </div>
                </div>
            </template>
            <template #default="scope">
                <AnnualLeaveDot :class="[isUserDraggable(scope) ? 'draggable' : '']" :name="scope.row.full_name" :color="scope.row.color" :key="scope.row.user_id" />
            </template>
        </vxe-table-column>
        <vxe-table-column
            v-for="(item, index) in table.columnHeader.value"
            :key="index"
            width="88"
            align="center"
            :class-name="item.tag ? 'hot-season display-cell' : 'display-cell'"
            :header-class-name="item.tag ? 'hot-season' : ''"
            :edit-render="{}"
        >
            <template #header>
                <div class="date">
                    <span>
                        {{ table.columnHeader.value[index].week_start_date }}
                    </span>
                    <span>
                        {{ table.columnHeader.value[index].week_end_date }}
                    </span>
                </div>
                <div class="vacancy">
                    <span>Vacancy</span>
                    <span>{{ table.columnHeader.value[index].vacancy }}</span>
                </div>
            </template>
            <template #default="scope">
                <span>{{ parseLeave(scope, item) }}</span>
                <el-popover placement="top-start" trigger="hover" content="User is blocked for this week" v-if="parseBlock(scope, item)">
                    <template #reference>
                        <i class="el-icon-lock"></i>
                    </template>
                </el-popover>
            </template>
            <template #edit="scope">
                <vxe-select placeholder="Select" @change="value => handleSelectAL(value, item, scope)" transfer>
                    <vxe-option
                        v-for="(options, index) in table.leaveOptions.value"
                        :key="index"
                        :value="options.value"
                        :label="options.label"
                        :class-name="parseSelectedLeave(scope, item, options.value)"
                    ></vxe-option>
                </vxe-select>
            </template>
        </vxe-table-column>
        <vxe-table-column fixed="right" width="88" align="center" header-class-name="last-col" v-if="!table.isManage.value && table.rows.value.length > 0">
            <template #header>
                <span class="round" v-if="table.round.value">
                    Round {{ table.round.value }}
                    <br />
                    ({{ residue }} / {{ table.rows.value.length }})
                </span>
                <el-button
                    @click="handleNextRound(scope)"
                    type="primary"
                    size="mini"
                    class="next-round-btn"
                    v-if="!table.isManage.value"
                    :disabled="table.currPriority.value < table.rows.value.length && table.round.value > 0"
                >
                    {{ table.round.value ? "Next" : "Start" }}
                </el-button>
            </template>
            <template #default="scope">
                <el-button @click="handlePass(scope)" type="primary" size="mini" class="pass-user-btn" v-if="!table.isManage.value && table.currPriority.value == scope.row.al_priority && table.round.value > 0">
                    Pass
                </el-button>
            </template>
        </vxe-table-column>
    </vxe-table>
</template>

<script>
import AnnualLeaveDot from "@/components/annual-leave/AnnualLeaveDot";
import Sortable from "sortablejs";

export default {
    name: "AnnualLeave",
    components: {
        AnnualLeaveDot,
    },
    props: {
        table: {
            required: true,
            type: Object,
        },
        yearOptions: {
            type: Array,
        },
        selectedYear: {
            type: Number,
        },
    },
    data() {
        return {
            sortable: null,
        };
    },
    computed: {
        yearLabel() {
            if (this.yearOptions && this.yearOptions.length > 0) {
                const target = this.yearOptions.find(year => year == this.selectedYear);
                if (target) {
                    return target;
                }
            }
            return "";
        },
        residue() {
            let result = this.table.rows.value.length - this.table.currPriority.value + 1;
            if (result < 0) {
                return 0;
            } else {
                return result;
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.setSort();
            }, 0);
        });
    },
    methods: {
        parseLeave(scope, item) {
            if (scope.row.annual && scope.row.annual.length > 0) {
                const annual = scope.row.annual.find(({ request_week_id }) => {
                    return request_week_id == item.request_week_id;
                });
                if (annual) {
                    let displayValue = annual.value ? String(annual.value) : "";
                    return displayValue;
                }
            }
            return "";
        },
        parseSelectedLeave(scope, item, optionValue) {
            if (scope.row.annual && scope.row.annual.length > 0) {
                const annual = scope.row.annual.find(({ request_week_id }) => {
                    return request_week_id == item.request_week_id;
                });
                if (annual) {
                    if (annual.value && typeof annual.value == "number") {
                        return "AL" == optionValue ? "is--selected" : "";
                    } else {
                        let displayValue = annual.value ? String(annual.value) : "";
                        return displayValue == optionValue ? "is--selected" : "";
                    }
                }
            }
            return "" == optionValue ? "is--selected" : "";
        },
        parseBlock(scope, item) {
            if (scope.row.annual && scope.row.annual.length > 0) {
                const annual = scope.row.annual.find(({ request_week_id }) => {
                    return request_week_id == item.request_week_id;
                });
                if (annual) {
                    return annual.blocked || false;
                }
            }
            return false;
        },
        highlightCurrUser({ row }) {
            let rowClass = "";
            if (row.al_priority == this.table.currPriority.value && this.table.round.value > 0) {
                rowClass += " highlight-row";
            }
            if (this.table.currPriority.value > this.table.rows.value.length) {
                rowClass += "";
            } else if (row.al_priority <= this.table.currPriority.value && this.table.round.value > 0) {
                rowClass += " drag-disabled";
            } else {
                rowClass += "";
            }
            return rowClass;
        },
        handlePass() {
            this.table.passUser();
        },
        handleNextRound() {
            this.table.nextRound();
        },
        clearScroll() {
            this.$refs.annualLeave.scrollTo(0, 0);
        },
        setSort() {
            const $table = this.$refs.annualLeave;
            if ($table) {
                this.sortable = Sortable.create($table.$el.querySelector(".vxe-table--body-wrapper > .vxe-table--body tbody"), {
                    ghostClass: "sortable-ghost",
                    animation: 150,
                    disabled: true,
                    filter: ".drag-disabled",
                    setData: function(dataTransfer) {
                        dataTransfer.setData("Text", "");
                    },
                    onEnd: evt => {
                        const targetRow = this.table.rows.value.splice(evt.oldIndex, 1)[0];
                        this.table.rows.value.splice(evt.newIndex, 0, targetRow);
                    },
                    onMove: evt => {
                        return evt.related.className.indexOf("drag-disabled") === -1;
                    },
                });
            }
        },
        enableSort() {
            this.sortable.option("disabled", false);
        },
        disableSort() {
            this.sortable.option("disabled", true);
        },
        handleSelectAL(requestAL, item, scope) {
            if (scope.row.annual) {
                const annual = scope.row.annual.find(({ request_week_id }) => request_week_id == item.request_week_id);
                if (annual) {
                    // This cell has AL
                    if (annual.value == requestAL.value) {
                        // Cell value equals to request value, no need API call
                        this.clearActive();
                    } else {
                        if (typeof annual.value == "number" && requestAL.value == "AL") {
                            this.clearActive();
                        } else {
                            this.table.postAnnualLeave(requestAL, scope);
                        }
                    }
                } else {
                    // This cell is empty
                    let emptyAL = ""; // The id of empty value is 1
                    if (requestAL.value == emptyAL) {
                        // Cell value equals to request value, no need API call
                        this.clearActive();
                    } else {
                        this.table.postAnnualLeave(requestAL, scope);
                    }
                }
            }
        },
        clearActive() {
            this.$refs.annualLeave.clearActived();
        },
        isUserDraggable(scope) {
            if (this.table.round.value === 0 && this.table.isManage.value) {
                return true;
            }

            if (this.table.isManage.value) {
                if (scope.row.al_priority > this.table.currPriority.value) {
                    return true;
                } else if (this.table.currPriority.value > this.table.rows.value.length) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss">
.annual-leave-table {
    width: 100%;
    .vxe-table--render-wrapper {
        height: 100%;
    }
    .vxe-table--main-wrapper {
        height: 100%;
        .vxe-table--body-wrapper {
            height: 100%;
        }
    }
    .vxe-header--column.col--ellipsis > .vxe-cell {
        max-height: 100px !important;
        height: 100px;
    }
    .vxe-header--column:nth-child(1) :nth-last-child(1) {
        .vxe-cell--title {
            width: 100%;
        }
    }
    .display-cell {
        .vxe-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                position: absolute;
                right: 10px;
            }
        }
    }
    .last-col {
        > .vxe-cell {
            max-height: 100px !important;
            height: 100px;
        }
        .vxe-cell--title {
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .hot-season {
        background: #f9ebf6;
    }
    .highlight-row {
        background: #f0f9eb;
        .hot-season {
            background: #f0f9eb;
        }
    }
    // header/corner
    thead tr {
        th {
            .date {
                display: flex;
                flex-direction: column;
                margin-bottom: 8px;
                span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 1.2;
                    text-align: center;
                    color: #061938;
                }
            }
            .vacancy {
                display: flex;
                flex-direction: column;
                span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 1.2;
                    text-align: center;
                    color: #97a0af;
                }
            }
        }
        .first-col {
            span {
                font-size: 12px;
                line-height: 16px;
            }
            .header-corner-date {
                color: #061938;
                text-align: end;
            }
            .header-corner-staff {
                color: #061938;
                text-align: start;
            }
        }
    }
    .selected-leave {
        background: #e8eaec;
    }
    .draggable:before {
        content: "\2807";
        font-size: 20px;
        color: #ddd;
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
    }

    .action-header .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        .round {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 1.2;
            text-align: center;
            color: #061938;
            margin-bottom: 4px;
        }
        .next-round-btn,
        .pass-user-btn {
            width: 60px;
        }
    }
}
</style>
