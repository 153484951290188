import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/settings/user?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchUser = async userId => {
    try {
        const result = await axios.get(`/admin/settings/user/${userId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async user => {
    try {
        const formData = new FormData();
        formData.append("username", user.username);
        formData.append("email", user.email);
        formData.append("staffNumber", user.staffNumber);
        formData.append("userLevel", user.userLevel);
        formData.append("rankId", user.rankId);
        formData.append("firstName", user.firstName);
        formData.append("lastName", user.lastName);

        if (user.staffCardImage) {
            formData.append("staffCardImage", user.staffCardImage);
        }

        if (user.userLevel >= 2) {
            for (let i = 0; i < user.wardList.length; i++) {
                formData.append("wardList[]", user.wardList[i]);
            }
        } else {
            formData.append("wardList[]", user.wardList);
        }

        if (user.rosterWard) {
            formData.append("rosterWardId", user.rosterWard);
        }

        if (user.userLevel == 2) {
            formData.append("isNoAdmin", user.isManageNORoster);
        }

        const result = await axios.post(`/admin/settings/user`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async user => {
    try {
        const formData = new FormData();
        formData.append("username", user.username);
        formData.append("email", user.email);
        formData.append("staffNumber", user.staffNumber);
        formData.append("userLevel", user.userLevel);
        formData.append("rankId", user.rankId);
        formData.append("firstName", user.firstName);
        formData.append("lastName", user.lastName);

        if (user.staffCardImage) {
            formData.append("staffCardImage", user.staffCardImage);
        }

        if (user.userLevel >= 2) {
            for (let i = 0; i < user.wardList.length; i++) {
                formData.append("wardList[]", user.wardList[i]);
            }
        } else {
            formData.append("wardList[]", user.wardList);
        }

        if (user.rosterWard) {
            formData.append("rosterWardId", user.rosterWard);
        }

        if (user.userLevel == 2) {
            formData.append("isNoAdmin", user.isManageNORoster);
        }

        const result = await axios.put(`/admin/settings/user/${user.userId}`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async userList => {
    const params = {
        list: userList,
    };
    try {
        const result = await axios.post(`/admin/settings/user/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
