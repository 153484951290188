<template>
    <el-dialog custom-class="upload-dialog" :title="'Upload ' + dialog.title" v-model="isVisible" :before-close="done => resetForm(done)" width="640px" destroy-on-close>
        <div class="description">
            <i class="el-icon-warning-outline el-icon-left"></i>
            <span class="contact">
                You may upload file in pdf format with a size less than 10MB at any time.
            </span>
        </div>
        <el-form class="upload-form" ref="uploadForm" :model="form" label-position="top" v-loading="isLoading" :rules="rules">
            <el-row :gutter="32">
                <el-col :span="hasEndDate || hasTargetWard ? 12 : 24">
                    <el-form-item label="Title:" prop="title" size="small">
                        <el-input class="title word-limit-input" v-model="form.title" placeholder="Enter a title" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="hasTargetWard">
                    <el-form-item label="Target dept:" prop="ward" size="small">
                        <el-select v-model="form.ward.value" filterable placeholder="Select dept" multiple>
                            <el-option v-for="item in form.ward.list" :key="item.name" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <div class="ward-error">
                            <span>
                                {{ form.ward.error }}
                            </span>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="File:" size="small" required>
                        <el-upload class="upload-pdf" ref="upload" accept=".pdf" :auto-upload="false" :limit="1" :on-change="handleChange" :on-remove="handleRemove" action="#">
                            <template #default v-if="isFileEmpty">
                                <el-button type="primary">
                                    <i class="el-icon-upload el-icon-left"></i>
                                    <span>Click to upload</span>
                                </el-button>
                            </template>
                            <template #file="{ file }">
                                <el-tooltip placement="top" effect="light">
                                    <template #content>
                                        <span class="file-name">
                                            {{ file.name }}
                                        </span>
                                    </template>
                                    <div class="el-upload-list__item-name">
                                        <i class="el-icon-document"></i>
                                        <span class="file-name">
                                            {{ file.name }}
                                        </span>
                                        <div class="upload-actions">
                                            <i class="el-icon-upload-success el-icon-circle-check"></i>
                                            <i class="el-icon-close el-icon-circle-close" @click="handleRemove"></i>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-upload>
                        <div class="upload-error">
                            <span>{{ form.fileError }}</span>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="dialog.publishDateLabel ? dialog.publishDateLabel : 'Publish date:'" size="small" prop="publishDate">
                        <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.publishDate" format="YYYY-MM-DD HH:mm" />
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="hasEndDate">
                    <el-form-item label="End date:" size="small" prop="endDate">
                        <el-date-picker type="datetime" placeholder="Pick a date" v-model="form.endDate" format="YYYY-MM-DD HH:mm" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="Remarks:" style="width: 100%" prop="remarks" size="small">
                <el-input type="textarea" class="upload-textarea" v-model="form.remarks" placeholder="Please enter remarks" maxlength="50" show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="save-btn" type="primary" @click="submitUpload('uploadForm')" :disabled="isLoading">
                    Save
                </el-button>
                <el-button class="cancel-btn" @click="isVisible = false" :disabled="isLoading">
                    Cancel
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import useInput from "./useInput";
import { validateWards } from "@/utils/validator";
import { fetchData as fetchWard } from "@/modules/general/ward";
export default {
    name: "UploadDialog",
    props: {
        dialog: {
            type: Object,
            default: () => {},
            required: true,
        },
        hasEndDate: {
            type: Boolean,
            default: false,
        },
        hasTargetWard: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            form: {
                title: "",
                file: {},
                fileError: "",
                remarks: "",
                publishDate: new Date(),
                endDate: this.dialog.endDate || new Date(2038, 0, 1),
                ward: {
                    isLoading: false,
                    value: [],
                    list: [],
                    error: "",
                },
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: "Please enter a title",
                        trigger: ["blur", "change"],
                    },
                ],
                publishDate: [
                    {
                        required: true,
                        message: "Please pick a date",
                        trigger: ["blur", "change"],
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: "Please pick a date",
                        trigger: ["blur", "change"],
                    },
                ],
                remarks: [],
                ward: [
                    {
                        required: true,
                        validator: validateWards,
                        trigger: ["blur", "change"],
                    },
                ],
            },
        };
    },
    setup(props, { emit }) {
        const isVisible = useInput(props.dialog, emit, "isVisible");
        return {
            isVisible,
        };
    },
    computed: {
        isFileEmpty() {
            return Object.keys(this.form.file).length == 0;
        },
    },
    watch: {
        "form.publishDate": {
            handler(publishDate) {
                if (publishDate && this.dialog.title == "Canteen menu") {
                    this.form.endDate = new Date(publishDate);
                    this.form.endDate.setMonth(this.form.endDate.getMonth() + 1);
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.hasTargetWard) {
            this.fetchWard();
        }
    },
    methods: {
        async fetchWard() {
            try {
                this.form.ward.isLoading = true;
                const data = await fetchWard();
                if (data) {
                    this.form.ward.list = data.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    });
                }
            } finally {
                this.form.ward.isLoading = false;
            }
        },
        async submitUpload(formName) {
            this.isLoading = true;

            this.$refs[formName].validate(async valid => {
                if (!this.validateFile(this.form.file)) {
                    valid = false;
                }
                if (valid) {
                    try {
                        await this.dialog.upload(this.form);
                    } finally {
                        this.isLoading = false;
                    }
                } else {
                    this.isLoading = false;
                    return false;
                }
            });
        },
        handleChange(file) {
            if (this.validateFile(file)) {
                this.form.file = file;
            } else {
                this.$refs.upload.clearFiles();
            }
        },
        handleRemove() {
            this.$refs.upload.clearFiles();
            this.form.file = {};
            this.validateFile(this.form.file);
        },
        validateFile(file) {
            if (Object.keys(file).length == 0) {
                this.form.fileError = "Please upload a pdf";
                return false;
            }
            const isPdf = file.raw.type === "application/pdf";
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isPdf) {
                this.form.fileError = "File can only be pdf format";
                return false;
            }
            if (!isLt10M) {
                this.form.fileError = "File cannot exceed 10MB";
                return false;
            }
            this.form.fileError = "";
            return true;
        },
        resetForm(done) {
            this.$refs.uploadForm.resetFields();
            this.$refs.upload.clearFiles();

            this.form.file = {};
            this.form.ward.value = [];
            done();
        },
    },
};
</script>

<style lang="scss">
.upload-dialog {
    max-width: 640px;
    border-radius: 5px;
    @media only screen and (max-width: 1024px) {
        margin-top: 5vh !important;
    }
    .el-dialog__header {
        font-weight: bold;
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
        .el-icon-close {
            font-size: 18px;
        }
    }
    .el-dialog__body {
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
        border-bottom: 1px solid #e2e2ea;
        .el-form-item,
        .el-select {
            width: 100%;
        }
        .upload-form {
            padding-bottom: 0;
        }
        .upload-pdf {
            .el-upload {
                display: block;
                text-align: inherit;
                .el-button {
                    width: 100%;
                }
            }
            .el-upload-list__item {
                margin-top: 0;
            }
            .el-upload-list__item-name {
                display: flex;
                align-items: center;
                width: 100%;
                i {
                    font-size: 18px;
                }
                .file-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
                .upload-actions {
                    justify-content: flex-end;
                    width: auto;
                    .el-icon-upload-success {
                        color: #36b37e;
                        float: right;
                    }
                    .el-icon-close {
                        position: relative;
                        top: inherit;
                        right: inherit;
                        float: right;
                    }
                }
                &:hover .el-icon-upload-success {
                    display: none;
                }
            }
        }
        .upload-tips {
            font-size: 12px;
            line-height: 12px;
            margin-top: 16px;
            color: #c1c7d0;
        }
        .upload-error {
            color: #f56c6c;
            font-size: 12px;
            line-height: 1;
            padding-top: 4px;
            position: absolute;
            top: 100%;
            left: 0;
        }
    }
    .el-dialog__footer {
        .save-btn,
        .cancel-btn {
            width: 120px;
            border-radius: 5px;
        }
        .cancel-btn {
            margin-left: 24px;
            @media only screen and (max-width: 1024px) {
                margin-left: 16px;
            }
            background-color: #ebecf0;
            color: #b3bac5;
            border: 0;
        }
        text-align: left;
        padding: 20px 40px;
        @media only screen and (max-width: 1024px) {
            padding: 16px 32px;
        }
    }
    .el-form-item__label {
        padding: 0;
        margin-bottom: 8px;
        line-height: 1.3;
        font-weight: bold;
        @media only screen and (max-width: 1024px) {
            font-size: 13px;
        }
    }
}
</style>
