import axios from "axios";

export const fetchSet = async () => {
    try {
        const result = await axios.get(`/admin/general/set`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchYear = async wardId => {
    try {
        const result = await axios.get(`/admin/general/year?wardId=${wardId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
