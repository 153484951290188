import { render } from "./Settings.vue?vue&type=template&id=d9fdbc2e"
import script from "./Settings.vue?vue&type=script&lang=js"
export * from "./Settings.vue?vue&type=script&lang=js"

import "./Settings.vue?vue&type=style&index=0&id=d9fdbc2e&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "d9fdbc2e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('d9fdbc2e', script)) {
    api.reload('d9fdbc2e', script)
  }
  
  module.hot.accept("./Settings.vue?vue&type=template&id=d9fdbc2e", () => {
    api.rerender('d9fdbc2e', render)
  })

}

script.__file = "src/components/annual-leave/Settings.vue"

export default script