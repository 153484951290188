import jwt from "jsonwebtoken";
import { logout } from "@/modules/auth/index";
export const getUserInfo = () => {
    try {
        const token = window.localStorage.getItem("jwt");
        if (token) {
            const jwtToken = jwt.decode(token);
            if (jwtToken) {
                return jwtToken;
            } else {
                logout();
                return;
            }
        } else {
            logout();
            return;
        }
    } catch (error) {
        console.log(error);
    }
};
