import axios from "axios";

export const fetchData = async (pageSize, pageIndex, searchString) => {
    try {
        const result = await axios.get(`/admin/pun-message?pageSize=${pageSize}&pageIndex=${pageIndex}&search=${searchString ? encodeURIComponent(searchString) : ``}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDetails = async id => {
    try {
        const result = await axios.get(`/admin/pun-message/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchViews = async id => {
    try {
        const result = await axios.get(`/admin/pun-message/views/${id}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async message => {
    try {
        const formData = new FormData();
        formData.append("title", message.title);
        for (let i = 0; i < message.wardList.length; i++) {
            formData.append("wardList[]", message.wardList[i]);
        }
        formData.append("content", message.content);
        if (message.coverImage) {
            formData.append("coverImage", message.coverImage);
        }
        if (message.media) {
            formData.append("media", message.media);
        }
        formData.append("publishedDate", message.publishDate);
        formData.append("endDate", message.endDate);

        const result = await axios.post(`/admin/pun-message`, formData);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async message => {
    try {
        const formData = new FormData();
        formData.append("title", message.title);
        for (let i = 0; i < message.wardList.length; i++) {
            formData.append("wardList[]", message.wardList[i]);
        }
        formData.append("content", message.content);
        if (message.coverImage) {
            formData.append("coverImage", message.coverImage);
        }
        if (message.media) {
            formData.append("media", message.media);
        }
        formData.append("publishedDate", message.publishDate);
        formData.append("endDate", message.endDate);

        const result = await axios.put(`/admin/pun-message/${message.id}`, formData);
        return result;
        return true;
    } catch (error) {
        console.error(error);
    }
};

export const deleteData = async messageList => {
    const params = {
        list: messageList,
    };
    try {
        const result = await axios.post(`/admin/pun-message/delete`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
