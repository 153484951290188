<template>
    <Editor
        api-key="no-api-key"
        v-model="content"
        :init="{
            height: 400,
            plugins:
                'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen ' +
                (image ? 'image' : '') +
                ' link table charmap hr pagebreak nonbreaking toc insertdatetime advlist lists wordcount ' +
                (image ? 'imagetools' : '') +
                ' textpattern noneditable charmap quickbars emoticons',
            menubar: 'file edit view insert format tools table',
            toolbar:
                'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print | insertfile ' +
                (image ? 'image' : '') +
                ' link | ltr rtl',
            autosave_ask_before_unload: false,
            toolbar_mode: 'sliding',
            images_upload_handler: imagesUploadHandler,
            file_picker_types: 'file ' + (image ? 'image' : ''),
            quickbars_image_toolbar: image ? true : false,
            quickbars_insert_toolbar: 'quicktable ' + (image ? 'image' : ''),
            default_link_target: '_blank',
            link_title: false,
            target_list: false,
            forced_root_block: 'p',
        }"
    />
</template>

<script>
import "@/utils/tinymce/js/tinymce/tinymce.min.js";
import Editor from "@tinymce/tinymce-vue";
import { imageUpload } from "@/modules/general/image-upload";
export default {
    name: "tinyMCE",
    props: {
        initialValue: {
            type: String,
        },
        image: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Editor,
    },
    data() {
        return {
            content: "",
        };
    },
    watch: {
        content(value) {
            this.$emit("update:content", value);
        },
        initialValue(value) {
            this.content = value;
        },
    },
    methods: {
        async imagesUploadHandler(blobInfo, success, failure) {
            if (blobInfo.blob()) {
                const isImage = blobInfo.blob().type === "image/png" || blobInfo.blob().type === "image/jpeg" || blobInfo.blob().type === "image/jpg";
                if (!isImage) {
                    failure("Image can only be png/jpeg/jpg", { remove: true });
                    return;
                }
            }
            try {
                const result = await imageUpload(blobInfo.blob());
                if (result) {
                    success(result.location);
                } else {
                    failure("Upload Error.", { remove: true });
                }
            } catch (error) {
                console.log(error);
                failure("Upload Error: " + error, { remove: true });
            }
        },
    },
};
</script>
