import { render } from "./Policies.vue?vue&type=template&id=4f91ed12"
import script from "./Policies.vue?vue&type=script&lang=js"
export * from "./Policies.vue?vue&type=script&lang=js"

import "./Policies.vue?vue&type=style&index=0&id=4f91ed12&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f91ed12"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f91ed12', script)) {
    api.reload('4f91ed12', script)
  }
  
  module.hot.accept("./Policies.vue?vue&type=template&id=4f91ed12", () => {
    api.rerender('4f91ed12', render)
  })

}

script.__file = "src/views/Settings/Policies.vue"

export default script