import axios from "axios";

export const login = async (username, password) => {
    const result = await axios.post("/auth/login", { username, password });
    if (result) {
        window.localStorage.setItem("jwt", result.token);
        window.localStorage.setItem("change_pw", result.change_pw);
        let firstName = result.first_name ? result.first_name + " " : "";
        let lastName = result.last_name ? result.last_name : "";
        window.localStorage.setItem("name", firstName + lastName);
        window.localStorage.setItem("rank_description", result.rank_description);
        window.localStorage.setItem("icon", result.icon);
    }
    return result;
};

export const logout = async () => {
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("name");
    window.localStorage.removeItem("rank_description");
    window.localStorage.removeItem("icon");
    window.localStorage.removeItem("change_pw");

    window.location.replace("#/login");
};

export const changePassword = async form => {
    try {
        const params = {
            oldPassword: form.currentPassword,
            newPassword: form.newPassword,
        };
        const result = await axios.post("/auth/change-password", params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const forgotPassword = async form => {
    try {
        const params = {
            email: form.email,
        };
        const result = await axios.post("/auth/forgot-password", params);
        return result;
    } catch (error) {
        console.error(error);
    }
};
