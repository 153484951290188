import axios from "axios";

export const fetchData = async (wardId, setId) => {
    try {
        const result = await axios.get(`/admin/request/duty-roster?wardId=${wardId}&setId=${setId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postData = async (rosterId, duty) => {
    try {
        const result = await axios.put(`/admin/request/duty-roster/${rosterId}`, duty);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putData = async (rosterId, userIdList) => {
    try {
        const params = {
            list: userIdList,
        };
        const result = await axios.put(`/admin/request/duty-roster/shift/${rosterId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchOptions = async () => {
    try {
        const result = await axios.get(`/admin/request/duty-roster/option`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchModal = async rosterId => {
    try {
        const result = await axios.get(`/admin/request/duty-roster/setting/${rosterId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const postModal = async (rosterId, msg) => {
    try {
        const params = {
            modalMessage: msg,
        };
        const result = await axios.put(`/admin/request/duty-roster/setting/${rosterId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSettings = async rosterId => {
    try {
        const result = await axios.get(`/admin/request/duty-roster/setting/${rosterId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const putSettings = async (rosterId, form) => {
    try {
        const params = {
            show: form.isShowRoster == 1 ? true : false,
            cutOffDate: form.cutOffDate,
        };
        const result = await axios.put(`/admin/request/duty-roster/setting/${rosterId}`, params);
        return result;
    } catch (error) {
        console.error(error);
    }
};

export const fetchRanking = async rosterId => {
    try {
        const result = await axios.get(`/admin/request/duty-roster/rank/${rosterId}`);
        return result;
    } catch (error) {
        console.error(error);
    }
};
