import { render } from "./MsPunsMessage.vue?vue&type=template&id=e19d9566"
import script from "./MsPunsMessage.vue?vue&type=script&lang=js"
export * from "./MsPunsMessage.vue?vue&type=script&lang=js"

import "./MsPunsMessage.vue?vue&type=style&index=0&id=e19d9566&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e19d9566"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e19d9566', script)) {
    api.reload('e19d9566', script)
  }
  
  module.hot.accept("./MsPunsMessage.vue?vue&type=template&id=e19d9566", () => {
    api.rerender('e19d9566', render)
  })

}

script.__file = "src/views/MsPunsMessage.vue"

export default script