<template>
    <div id="user">
        <TabContainer>
            <template v-slot:header>
                <ContainerHeader :searchInput="searchInput" :searchString="searchInput.value" @update:searchString="searchInput.value = $event" :buttons="containerButtons" />
            </template>
            <template v-slot:content>
                <Table :table="userTable" />
            </template>
        </TabContainer>
        <Drawer :drawer="addUserDrawer" @update:isVisible="addUserDrawer.isVisible = $event">
            <template v-slot:content>
                <AddUserForm :isLoading="addUserDrawer.isLoading" ref="addUserForm" />
            </template>
        </Drawer>
        <Drawer :drawer="editUserDrawer" @update:isVisible="editUserDrawer.isVisible = $event">
            <template v-slot:content>
                <EditUserForm ref="editUserForm" :user="editUserDrawer.user" :isLoading="editUserDrawer.isLoading" />
            </template>
        </Drawer>
    </div>
</template>

<script>
import { fetchData as fetchUsers, fetchUser, deleteData as deleteUsers } from "@/modules/settings/user";
import TabContainer from "@/components/TabContainer";
import ContainerHeader from "@/components/ContainerHeader";
import Table from "@/components/settings/UserTable";
import Drawer from "@/components/Drawer";
import AddUserForm from "@/components/settings/AddUserForm";
import EditUserForm from "@/components/settings/EditUserForm";
export default {
    name: "User",
    components: {
        TabContainer,
        ContainerHeader,
        Table,
        Drawer,
        AddUserForm,
        EditUserForm,
    },
    data() {
        return {
            searchInput: {
                placeholder: "Search by User name, Staff no.",
                value: "",
                callback: () => this.searchUsers(),
            },
            containerButtons: [
                {
                    name: "New User",
                    icon: "el-icon-plus",
                    callback: () => this.handleAddUserDrawer(),
                },
            ],
            userTable: {
                name: "Settings",
                isLoading: false,
                cols: [
                    {
                        label: "name",
                        sortable: true,
                        prop: "full_name",
                        minWidth: "112",
                    },
                    {
                        label: "staff rank",
                        sortable: false,
                        prop: "rank",
                        minWidth: "92",
                    },
                    {
                        label: "staff no.",
                        sortable: false,
                        prop: "staff_no",
                        minWidth: "92",
                    },
                    {
                        label: "email",
                        sortable: false,
                        prop: "email",
                        minWidth: "160",
                    },
                    {
                        label: "dept",
                        sortable: false,
                        prop: "ward",
                        minWidth: "96",
                    },
                    {
                        label: "roster",
                        sortable: false,
                        prop: "roster_ward",
                        minWidth: "132",
                    },
                    {
                        label: "role",
                        sortable: false,
                        prop: "user_level",
                        width: "68",
                    },
                ],
                data: [],
                numberOfItems: null,
                multipleSelection: [],
                handleSelectionChange: rows => this.handleSelectionChange(rows),
                handleEdit: (index, row) => this.handleEdit(index, row),
                handleDelete: (index, row) => this.handleDelete(index, row),
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    handleSizeChange: pageSize => this.handleSizeChange(pageSize),
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            addUserDrawer: {
                isVisible: false,
                isLoading: false,
                title: "New User",
                save: () => this.addUser(),
                close: () => this.closeaddUserDrawer(),
            },
            editUserDrawer: {
                user: {},
                isVisible: false,
                isLoading: false,
                title: "Edit User",
                save: () => this.editUser(),
                close: () => this.closeEditUserDrawer(),
            },
            rankSettingsDialog: {
                isVisible: false,
                title: "Rank Settings",
                upload: form => this.editRank(form),
            },
        };
    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            this.userTable.isLoading = true;
            let pageSize = this.userTable.pagination.pageSize;
            let pageIndex = this.userTable.pagination.currentPage;
            let searchString = this.searchInput.value;
            try {
                const result = await fetchUsers(pageSize, pageIndex, searchString);
                if (result) {
                    this.userTable.data = result.data;
                    this.userTable.numberOfItems = result.count;
                    this.$emit("update:numberOfUser", result.count);
                }
            } finally {
                this.userTable.isLoading = false;
            }
        },
        async addUser() {
            try {
                this.addUserDrawer.isLoading = true;
                const result = await this.$refs.addUserForm.addUser();
                if (result) {
                    this.addUserDrawer.isVisible = false;
                    this.$notify({
                        title: "User added successfully",
                        message: "User " + result + " was added successfully",
                        type: "success",
                    });
                    this.fetchUsers();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.addUserDrawer.isLoading = false;
            }
        },
        async editUser() {
            try {
                this.editUserDrawer.isLoading = true;
                const result = await this.$refs.editUserForm.editUser();
                if (result) {
                    this.editUserDrawer.isVisible = false;
                    this.$notify({
                        title: "User updated successfully",
                        message: "User " + result + " was updated successfully",
                        type: "success",
                    });
                    this.fetchUsers();
                }
            } finally {
                this.editUserDrawer.isLoading = false;
            }
        },
        async deleteUsers(userList) {
            try {
                const confirmMsg = await this.$confirm("This will permanently delete the user(s). Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    cancelButtonClass: "cancel-btn",
                    type: "warning",
                });
                if (confirmMsg === "confirm") {
                    this.isLoading = true;
                    const result = await deleteUsers(userList);
                    if (result) {
                        this.$notify({
                            title: "User(s) deleted successfully",
                            type: "success",
                        });
                    }
                    this.fetchUsers();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        },
        // table
        searchUsers() {
            this.userTable.pagination.currentPage = 1;
            this.fetchUsers();
        },
        async handleEdit(index, row) {
            try {
                this.editUserDrawer.isLoading = true;
                const result = await fetchUser(row.user_id);
                if (result) {
                    this.editUserDrawer.user = result;
                    this.editUserDrawer.isVisible = true;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.editUserDrawer.isLoading = false;
            }
        },
        handleDelete(index, row) {
            this.deleteUsers([row.user_id]);
        },
        handleSelectionChange(rows) {
            this.userTable.multipleSelection = rows.map(user => user.user_id);
        },
        // pagination
        handleSizeChange(pageSize) {
            this.userTable.pagination.pageSize = pageSize;
            this.userTable.pagination.currentPage = 1;
            this.fetchUsers();
        },
        handleCurrentChange(currentPage) {
            this.userTable.pagination.currentPage = currentPage;
            this.fetchUsers();
        },
        // drawer
        handleAddUserDrawer() {
            this.addUserDrawer.isVisible = true;
        },
        handleRankSettings() {
            this.rankSettingsDialog.isVisible = true;
        },
        closeaddUserDrawer() {
            this.$refs.addUserForm.resetForm();
            this.addUserDrawer.isVisible = false;
        },
        closeEditUserDrawer() {
            this.$refs.editUserForm.resetForm();
            this.editUserDrawer.isVisible = false;
        },
    },
};
</script>

<style lang="scss">
#user {
    height: 100%;
    .uni-icon-setting {
        margin-right: 4px;
        &:before {
            color: #fff;
        }
    }
}
</style>
