<template>
    <div class="description">
        <i class="el-icon-warning-outline el-icon-left"></i>
        <span class="contact">
            You may upload image in jpg/jpeg/png format with a size less than 10MB at any time.
            <br />
            - Suggested image ratios - 1:1 (Focus area is better to be set in center)
        </span>
    </div>
    <el-form
        class="category-form"
        ref="editCategoryForm"
        :model="form"
        label-position="top"
        v-loading="isFormLoading"
        :rules="form.rules"
        size="small"
        :disabled="type == 'survival-working-tips' && userLevel == 2"
    >
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item label="Title" prop="title">
                    <el-input class="word-limit-input" v-model="form.title" placeholder="Title" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="Cover Image:" required>
                    <el-upload class="upload-cover-image" ref="uploadCoverImage" accept="image/*" :auto-upload="false" :limit="1" :on-change="handleChange" action="#" :file-list="form.oldCoverImage">
                        <template #default v-if="isFileEmpty">
                            <el-button type="primary">
                                <i class="el-icon-upload el-icon-left"></i>
                                <span>Click to upload</span>
                            </el-button>
                        </template>
                        <template #file="{ file }">
                            <el-tooltip placement="bottom" effect="light">
                                <template #content>
                                    <img :src="imagePreview ? imagePreview : form.oldCoverImage[0] ? form.oldCoverImage[0].url : ''" class="cover-image-preview" />
                                </template>
                                <div class="el-upload-list__item-name">
                                    <i class="el-icon-document"></i>
                                    <span class="file-name">
                                        {{ file.name }}
                                    </span>
                                    <div class="upload-actions">
                                        <i class="el-icon-upload-success el-icon-circle-check"></i>
                                        <i class="el-icon-close el-icon-circle-close" @click="handleRemove"></i>
                                    </div>
                                </div>
                            </el-tooltip>
                        </template>
                    </el-upload>
                    <div class="upload-error">
                        <span>
                            {{ form.coverImageError }}
                        </span>
                    </div>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32">
            <el-col :span="12">
                <el-form-item v-if="type == 'survival-working-tips'" label="General:" required>
                    <el-radio-group v-model="form.isGeneral">
                        <el-radio :label="true">Yes</el-radio>
                        <el-radio :label="false">No</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-else label="Show:" required>
                    <el-radio-group class="category-type" v-model="form.isShow">
                        <el-radio :label="true">Yes</el-radio>
                        <el-radio :label="false">No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="32" v-if="!form.isGeneral">
            <el-col :span="12">
                <el-form-item label="Target dept" prop="ward" ref="ward">
                    <el-select v-model="form.ward.value" filterable placeholder="Select dept" multiple :disabled="type != 'survival-working-tips'">
                        <el-option v-for="item in form.ward.list" :key="item.name" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <el-row class="category-table" :gutter="32">
        <el-col :span="24">
            <div class="title">Post(s):</div>
            <el-input size="small" prefix-icon="el-icon-search" placeholder="Search by title or author" v-model="categoryPostTable.searchString" @keyup.enter="fetchTableData" clearable />
            <Table :table="categoryPostTable" :canResize="false" :selection="false" />
        </el-col>
    </el-row>
</template>

<script>
import Table from "@/components/Table";
import { fetchTableData, putData as editCategory, fetchDetails as fetchCategoryDetails } from "@/modules/category";
import { fetchData as fetchWard } from "@/modules/general/ward";
import { getUserInfo } from "@/utils/auth";
import { validateWards } from "@/utils/validator";
import * as ramda from "ramda";
export default {
    name: "EditCategoryForm",
    components: {
        Table,
    },
    props: ["isLoading", "type", "categoryId", "category", "list"],
    data() {
        return {
            isFormLoading: false,
            userLevel: getUserInfo() ? getUserInfo().user_level : null,
            imagePreview: "",
            categoryPostTable: {
                height: "180",
                isLoading: false,
                searchString: "",
                handleCheckRowChange: (value, row) => this.handleCheckRowChange(value, row),
                cols: [
                    {
                        label: "title",
                        sortable: true,
                        prop: "title",
                        width: "176",
                    },
                    {
                        label: "category",
                        sortable: false,
                        prop: "category_name",
                        minWidth: "200",
                    },
                    {
                        label: "author",
                        sortable: false,
                        prop: "author",
                        width: "96",
                    },
                ],
                data: [],
                numberOfItems: null,
                pagination: {
                    currentPage: 1,
                    pageSize: 5,
                    pageSizes: [5],
                    handleCurrentChange: currentPage => this.handleCurrentChange(currentPage),
                },
            },
            form: {
                title: "",
                rules: {
                    title: [
                        {
                            required: true,
                            message: "Please enter a title",
                            trigger: "blur",
                        },
                    ],
                    ward: [
                        {
                            required: true,
                            validator: validateWards,
                            trigger: ["blur", "change"],
                        },
                    ],
                    priority: [
                        {
                            required: true,
                            message: "Please enter the priority",
                            trigger: "blur",
                        },
                    ],
                },
                coverImage: {},
                oldCoverImage: [],
                coverImageError: "",
                isGeneral: false,
                isShow: false,
                isGeneralOldValue: null,
                ward: {
                    isLoading: false,
                    oldValue: [],
                    value: [],
                    list: [],
                    error: "",
                },
                priority: 0,
            },
        };
    },
    computed: {
        isFileEmpty() {
            return Object.keys(this.form.coverImage).length == 0 && this.form.oldCoverImage.length == 0;
        },
    },
    async mounted() {
        try {
            this.isFormLoading = true;
            await this.fetchWard();
            await this.fetchCategoryDetails();
        } catch (error) {
            console.log(error);
        } finally {
            this.isFormLoading = false;
        }
        this.fetchTableData();
    },
    methods: {
        async fetchCategoryDetails() {
            const data = await fetchCategoryDetails(this.type, this.categoryId);
            if (data) {
                this.form.title = data.name;
                this.form.oldCoverImage = [
                    {
                        name: data.cover_image_path,
                        url: data.cover_image_path,
                    },
                ];
                this.form.ward.value = data.ward_list.map(({ id }) => id);
                this.form.ward.oldValue = data.ward_list.map(({ id }) => id);
                this.form.isGeneral = data.general == 1 ? true : false;
                this.form.isGeneralOldValue = data.general == 1 ? true : false;
                this.form.isShow = data.show == 1 ? true : false;
            }
        },
        async fetchTableData() {
            try {
                this.categoryPostTable.isLoading = true;
                let pageSize = this.categoryPostTable.pagination.pageSize;
                let pageIndex = this.categoryPostTable.pagination.currentPage;
                let searchString = this.categoryPostTable.searchString;
                const result = await fetchTableData(this.type, pageSize, pageIndex, searchString, this.categoryId);
                if (result) {
                    this.categoryPostTable.data = result.data;
                    this.categoryPostTable.numberOfItems = result.count;
                }
            } finally {
                this.categoryPostTable.isLoading = false;
            }
        },
        async fetchWard() {
            const type = this.type == "survival-working-tips" ? "full" : null;
            const data = await fetchWard(type);
            if (data) {
                const parentItem = this.findItemNested(this.list, this.category.parent_id);
                this.form.ward.list = data.map(item => {
                    let isDisabled = false;
                    if (parentItem && parentItem.ward_list.length > 0) {
                        isDisabled = parentItem.ward_list.find(id => {
                            return id == item.id;
                        })
                            ? false
                            : true;
                    }
                    return {
                        label: item.name,
                        value: item.id,
                        disabled: isDisabled,
                    };
                });
            }
        },
        async editCategory() {
            const valid = await this.validateForm();
            if (!valid) {
                return false;
            }
            try {
                this.isFormLoading = true;
                let form = {
                    id: this.categoryId,
                    title: this.form.title,
                    coverImage: this.form.coverImage.raw,
                    general: this.form.isGeneral,
                    show: this.form.isShow,
                    wardList: this.form.ward.value,
                };
                const isSame = await this.checkWardDifferent();
                if (isSame) {
                    const result = await editCategory(this.type, form);
                    if (result) {
                        return form.title;
                    } else {
                        return false;
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.isFormLoading = false;
            }
        },
        async validateForm() {
            try {
                const imageResult = this.form.oldCoverImage.length == 0 ? this.validateImage(this.form.coverImage) : true;
                const result = await this.$refs.editCategoryForm.validate();
                return result && imageResult;
            } catch (error) {
                return false;
            }
        },
        async checkWardDifferent() {
            const diff = ramda.symmetricDifference(this.form.ward.value, this.form.ward.oldValue);
            const generalDiff = this.form.isGeneral != this.form.isGeneralOldValue;
            if ((generalDiff || diff.length > 0) && this.category.child.length > 0) {
                const action = await this.$confirm("This will change the belonged dept of child category(s) too. Continue?", "Warning", {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    type: "warning",
                });
                if (action == "confirm") {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        },

        // pagination
        handleCurrentChange(currentPage) {
            this.categoryPostTable.pagination.currentPage = currentPage;
            this.fetchTableData();
        },

        // handle cover image
        handleChange(image) {
            if (this.validateImage(image)) {
                this.form.coverImage = image;
                const reader = new FileReader();
                const vm = this;
                reader.onload = e => {
                    vm.imagePreview = e.target.result;
                };
                reader.readAsDataURL(image.raw);
            } else {
                this.$refs.uploadCoverImage.clearFiles();
            }
        },
        handleRemove() {
            if (this.userLevel == 2 && this.type == "survival-working-tips") {
                return;
            } else {
                this.$refs.uploadCoverImage.clearFiles();
                this.form.oldCoverImage = [];
                this.form.coverImage = {};
                this.validateImage(this.form.coverImage);
            }
        },
        validateImage(image) {
            if (Object.keys(image).length == 0 && this.form.oldCoverImage.length == 0) {
                this.form.coverImageError = "Please upload a cover image";
                return false;
            }
            const isImage = image.raw.type === "image/png" || image.raw.type === "image/jpeg" || image.raw.type === "image/jpg";
            const isLt10M = image.size / 1024 / 1024 < 10;

            if (!isImage) {
                this.form.coverImageError = "Image can only be png/jpeg/jpg format";
                return false;
            }
            if (!isLt10M) {
                this.form.coverImageError = "Image cannot exceed 10MB";
                return false;
            }
            this.form.coverImageError = "";
            return true;
        },
        resetForm() {
            this.$refs.editCategoryForm.resetFields();
            this.$refs.uploadCoverImage.clearFiles();
            this.categoryPostTable.searchString = "";
            this.categoryPostTable.pagination.currentPage = 1;
            this.form.ward.value = [];
            this.form.coverImage = {};
            this.form.oldCoverImage = [];
            this.form.coverImageError = "";
        },
        findItemNested(arr, itemId) {
            return arr.reduce((a, item) => {
                if (a) return a;
                if (item.id == itemId) return item;
                if (item.child) return this.findItemNested(item.child, itemId);
            }, null);
        },
    },
};
</script>

<style lang="scss">
.category-table {
    .title {
        color: #606266;
        font-size: 13px;
        margin-bottom: 8px;
        line-height: 1.3;
        font-weight: bold;
    }
}
.category-type {
    display: flex;
    align-items: center;
    height: 32px;
}
</style>
